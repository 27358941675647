/* eslint-disable react/no-array-index-key */
import { formatCurrency, getCurrencySymbol } from 'utils/currency/formatCurrency'
import { Input } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { LeaseType } from '../LeaseCalculation'

const size = 'large'
const roundedClass = 'rounded-lg flex-1 w-full'

interface LeaseTableProps {
    leases: LeaseType[]
    handleSelectChange: (value: string, index: number) => void
    handleTextChange: (value: string, name: string, index: number) => void
    resudialPercentage: any
}
const LeaseTable = (props: LeaseTableProps) => {
    const { leases, handleSelectChange, handleTextChange, resudialPercentage } = props
    const { t } = useTranslation()

    return (
        <table className="table-fixed w-full bg-white rounded-md">
            <thead className="bg-red-700 h-12 rounded-md text-white">
                <tr>
                    <th className="text-left px-2">
                        <h6 className="text-white">{t('api.lease_data')}</h6>
                    </th>
                    {leases.map((lease, i) => (
                        <td key={`${lease?.lease_duration || 'l'}-${i}`} className="text-left px-4">
                            {i + 1}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr className="bg-white h-12 px-4">
                    <th className="text-left px-2">
                        <h6>{t('api.lease_duration')}</h6>
                    </th>
                    {leases.map((lease: Partial<LeaseType>, i) => (
                        <td className="px-4">
                            <select
                                className={classNames(
                                    'border-1 py-2 rounded-lg focus:outline-none focus:shadow-outline flex items-center w-full',
                                    roundedClass,
                                )}
                                defaultValue={lease?.lease_duration}
                                onChange={e => handleSelectChange(e.target.value, i)}
                            >
                                {resudialPercentage.map((resudial: { year: string }) => (
                                    <option key={resudial.year} value={resudial.year}>
                                        {resudial.year}
                                    </option>
                                ))}
                            </select>
                        </td>
                    ))}
                </tr>
                <tr className="bg-slate-100	 h-12 px-4">
                    <th className="text-left px-2">
                        <h6>{t('api.configuration_quantity')}</h6>
                    </th>
                    {leases.map((lease: Partial<LeaseType>, i) => (
                        <td key={i} className="px-4">
                            {lease?.configuration_quantity}
                        </td>
                    ))}
                </tr>
                <tr className="bg-white h-12 px-4">
                    <th className="text-left px-2">
                        <h6>{t('api.configuration_net_total_per_machine')}</h6>
                    </th>
                    {leases.map((lease: Partial<LeaseType>, i) => (
                        <td className="px-4" key={i}>
                            {formatCurrency(String(lease.net_total))}
                        </td>
                    ))}
                </tr>
                <tr className="bg-slate-100	 h-12 px-4">
                    <th className="text-left px-2">
                        <h6>{t('api.interest_rate')}</h6>
                    </th>
                    {leases.map((lease: Partial<LeaseType>, i) => (
                        <td key={i} className="px-4">
                            <div className="flex items-center gap-2">
                                <Input
                                    size={size}
                                    className={roundedClass}
                                    defaultValue={lease?.interest_rate}
                                    placeholder="Number"
                                    type="number"
                                    min={lease?.interest_rate !== 0 ? lease?.interest_rate : 1}
                                    onChange={e =>
                                        handleTextChange(e.target.value, 'interest_rate', i)
                                    }
                                />{' '}
                                <span className="text-lg">%</span>
                            </div>
                        </td>
                    ))}
                </tr>
                <tr className="bg-white h-12 px-4">
                    <th className="text-left px-2">
                        <h6>{t('api.residual_value_per_machine')}</h6>
                    </th>
                    {leases.map((lease: Partial<LeaseType>, i) => (
                        <td key={i} className="px-4">
                            <div className="flex items-center gap-2">
                                <Input
                                    size={size}
                                    className={roundedClass}
                                    defaultValue={lease?.residual_value}
                                    placeholder="Number"
                                    type="number"
                                    min={1}
                                    onChange={e =>
                                        handleTextChange(e.target.value, 'residual_value', i)
                                    }
                                />
                                <span className="text-lg">{getCurrencySymbol()}</span>
                            </div>
                        </td>
                    ))}
                </tr>
                <tr className="bg-slate-100	 h-12 px-4">
                    <th className="text-left px-2">
                        <h6>{t('api.service_cost_per_machine')}</h6>
                    </th>
                    {leases.map((lease: Partial<LeaseType>, i) => (
                        <td key={i} className="px-4">
                            <div className="flex items-center gap-2">
                                <Input
                                    size={size}
                                    className={roundedClass}
                                    defaultValue={lease?.service_cost}
                                    placeholder="Number"
                                    type="number"
                                    min={1}
                                    onChange={e =>
                                        handleTextChange(e.target.value, 'service_cost', i)
                                    }
                                />
                                <span className="text-lg">{getCurrencySymbol()}</span>
                            </div>
                        </td>
                    ))}
                </tr>
                <tr className="bg-white h-12 px-4">
                    <th className="text-left px-2">
                        <h6>{t('api.price_per_month_per_machine')}</h6>
                    </th>
                    {leases.map((lease: Partial<LeaseType>, i) => (
                        <td key={i} className="px-4">
                            {formatCurrency(String(lease.price_per_month))}
                        </td>
                    ))}
                </tr>
                <tr className="bg-slate-100	 h-12 px-4">
                    <th className="text-left px-2">
                        <h6>{t('api.price_per_month_all_machine')}</h6>
                    </th>
                    {leases.map((lease: Partial<LeaseType>, i) => (
                        <td key={i} className="px-4">
                            {parseFloat(
                                String(
                                    parseInt(lease?.price_per_month || '0', 10) *
                                        (lease?.configuration_quantity || 0),
                                ),
                            ).toFixed(2)}
                            {'  '}
                            {getCurrencySymbol()}
                        </td>
                    ))}
                </tr>
            </tbody>
        </table>
    )
}

export default LeaseTable
