/* eslint-disable camelcase */
import { AiOutlineSolution } from 'react-icons/ai'
import { BsBatteryHalf, BsListTask } from 'react-icons/bs'
import { FaChargingStation } from 'react-icons/fa'
import { MdDevicesOther, MdAttachFile } from 'react-icons/md'
import { GiBatteries } from 'react-icons/gi'
import { useSearchParams } from 'react-router-dom'
import { filter } from 'lodash'
import { useTranslation } from 'react-i18next'

interface Props {
    setExtraItems: (screen: string) => void
}

const OptionItem = (props: Props) => {
    const { setExtraItems } = props
    const [search] = useSearchParams()
    const { t } = useTranslation()

    const addExtraItems = [
        {
            label: t('api.charger'),
            key: 'charger',
            icon: <FaChargingStation size={25} className=" hover:text-primary" />,
            is_electric: true,
        },
        {
            label: t('api.battery'),
            key: 'battery',
            icon: <BsBatteryHalf size={25} className=" hover:text-primary" />,
            is_electric: true,
        },
        {
            label: t('api.battery_accessory'),
            key: 'battery accessories',
            icon: <GiBatteries size={25} className=" hover:text-primary" />,
            is_electric: true,
        },
        {
            label: t('api.hyster_battery'),
            key: 'hyster battery list',
            icon: <GiBatteries size={25} className=" hover:text-primary" />,
            is_electric: true,
        },
        {
            label: t('api.prep_list'),
            key: 'prep list',
            icon: <BsListTask size={25} className=" hover:text-primary" />,
            is_electric: false,
        },
        {
            label: t('api.attachment'),
            key: 'attachment',
            icon: <MdAttachFile size={25} className=" hover:text-primary" />,
            is_electric: false,
        },
        {
            label: t('api.ex_solution'),
            key: 'ex-solution',
            icon: <AiOutlineSolution size={25} className=" hover:text-primary" />,
            is_electric: false,
        },
        {
            label: t('api.special'),
            key: 'special',
            icon: <MdDevicesOther size={25} className=" hover:text-primary" />,
            is_electric: false,
        },
    ]

    const getExtraItems = () => {
        if (search.get('is_electric') === 'true') {
            return addExtraItems
        }
        return filter(addExtraItems, item => !item.is_electric)
    }

    return (
        <div className="flex flex-col gap-6">
            <div>
                <p className="text-secondary border-primary text-lg w-fit font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {t('api.choose_type_of_item')}
                </p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                {getExtraItems().map(item => (
                    <div
                        onClick={() => setExtraItems(item.key)}
                        key={item.key}
                        className="bg-primaryLight border border-primary rounded-md px-8 py-4 h-20 w-full flex justify-between items-center cursor-pointer hover:brightness-90"
                    >
                        <div className="w-1/3">{item.icon}</div>
                        <div className="w-2/3">{item.label}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OptionItem
