import { Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useTranslation } from 'react-i18next'

const size = 'large'
const roundedClass = 'rounded-lg flex-1'

interface DiscountRequestFormProps {
    formik: any
    index: number
}
const DiscountRequestForm = (props: DiscountRequestFormProps) => {
    const { formik, index } = props
    const { t } = useTranslation()
    return (
        <div className="border border-slate-300 py-2 px-4 rounded-md">
            <Form.Item
                name="requested_configuration_id"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: 'Required',
                    },
                ]}
            >
                <div>
                    <h5 className="capitalize">{t('api.configuration')}</h5>
                </div>
                <Input
                    name="requested_configuration_id"
                    type="number"
                    value={formik.values.configurationsRequest[index].requested_configuration_id}
                    placeholder="Enter configuration id"
                    size={size}
                    disabled
                    className={`${roundedClass}, mt-1`}
                />
            </Form.Item>
            <Form.Item
                name="requested_configuration_name"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: 'Required',
                    },
                ]}
            >
                <div>
                    <h5 className="capitalize">{t('api.configuration_name')}</h5>
                </div>
                <Input
                    name="requested_configuration_name"
                    type="text"
                    value={formik.values.configurationsRequest[index].requested_configuration_name}
                    disabled
                    placeholder="Name of configuration"
                    size={size}
                    className={`${roundedClass}, mt-1`}
                />
            </Form.Item>
            <Form.Item
                name="requested_discount_percentage"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: 'Required',
                    },
                ]}
            >
                <div>
                    <h5 className="capitalize">{t('api.request_discount_percentage')}</h5>
                </div>
                <Input
                    name={`configurationsRequest[${index}].requested_discount_percentage`}
                    type="number"
                    value={formik.values.configurationsRequest[index].requested_discount_percentage}
                    onChange={formik.handleChange}
                    placeholder="Enter discount"
                    size={size}
                    className={`${roundedClass}, mt-1`}
                />
            </Form.Item>
            <Form.Item
                name="requested_comment"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: 'Required',
                    },
                ]}
            >
                <div>
                    <h5 className="capitalize">{t('api.request_comment')}</h5>
                </div>
                <TextArea
                    name={`configurationsRequest[${index}].requested_comment`}
                    value={formik.values.configurationsRequest[index].requested_comment}
                    onChange={formik.handleChange}
                    placeholder="Enter comment"
                    size={size}
                    className={`${roundedClass}, mt-1`}
                />
            </Form.Item>
        </div>
    )
}

export default DiscountRequestForm
