import { callAxios } from '../../../plugins/api'

const createSiteSurvey = (data: any) => {
    return callAxios({
        url: `siteSurvey`,
        method: 'POST',
        data,
        isHeffiq: true,
    })
}

const updateSiteSurvey = ({ data, siteSurveyId }: { data: any; siteSurveyId: number }) => {
    return callAxios({
        url: `siteSurvey/${siteSurveyId}`,
        data,
        method: 'PUT',
        isHeffiq: true,
    })
}

const getAllSiteSurvey = () => {
    return callAxios({
        url: 'siteSurvey',
        method: 'GET',
        isHeffiq: true,
    })
}

const deleteSiteSurvey = (sideSurveyId: number) => {
    return callAxios({
        url: `siteSurvey/${sideSurveyId}`,
        method: 'DELETE',
        isHeffiq: true,
    })
}

const getSiteSurveyById = (siteSurveyId: number) => {
    return callAxios({
        url: `siteSurvey/${siteSurveyId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

const uploadImageForSiteSurvey = ({ siteSurveyId, data }: { siteSurveyId: number; data: any }) => {
    return callAxios({
        url: `siteSurvey/image/${siteSurveyId}`,
        method: 'POST',
        data,
        isHeffiq: true,
    })
}

const getALlSegment = () => {
    return callAxios({
        url: `segment`,
        method: 'GET',
        isHeffiq: true,
    })
}

const deleteSiteSurveyImageById = ({ imageId, index }: { imageId: number; index: number }) => {
    return callAxios({
        url: `siteSurvey/image/${imageId}`,
        method: 'delete',
        isHeffiq: true,
    })
}

const getBatteryCapacityByModelId = (modelId: number) => {
    return callAxios({
        url: `site-survey-battery/battery-by-model/${modelId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

const getModelFromConfigurationId = (configurationId: number) => {
    return callAxios({
        url: `siteSurvey/models-per-configuration/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const SiteSurveyQueries = {
    createSiteSurvey,
    getAllSiteSurvey,
    deleteSiteSurvey,
    getSiteSurveyById,
    updateSiteSurvey,
    uploadImageForSiteSurvey,
    getALlSegment,
    deleteSiteSurveyImageById,
    getBatteryCapacityByModelId,
    getModelFromConfigurationId,
}
