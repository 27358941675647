import { useTranslation } from 'react-i18next'
import { IPricingInformation } from '../views/PricingOrderOverview'

type PurchasePriceProps = {
    companyRatesData: IPricingInformation
    totalExtra: number
    financing: string
    initialPrice: any
}

const PurchasePriceCalculationDetail = ({
    companyRatesData,
    totalExtra,
    financing,
    initialPrice,
}: PurchasePriceProps) => {
    const { t } = useTranslation()

    let discountAmount = 0
    if (companyRatesData.special_price_request_percentage) {
        discountAmount =
            (companyRatesData.machine_list_price *
                companyRatesData.special_price_request_percentage) /
            100
    } else {
        discountAmount =
            (companyRatesData.machine_list_price * companyRatesData.standard_discount_percentage) /
            100
    }
    const discountedAmount = companyRatesData.machine_list_price - discountAmount
    const totalCostPriceIncludingDiscount =
        discountedAmount +
        companyRatesData.total_net_value_parts -
        companyRatesData.total_options_selected
    return (
        <div className=" bg-white p-4 rounded-md">
            <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                {t('api.purchase_pricing_calculation')}
            </p>
            <div className="grid grid-cols-2 bg-slate-100 p-2  gap-2  mt-4">
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.net_price_hyster`)}:</span>€{' '}
                    {String(companyRatesData?.novo_list_price?.toFixed(2))}
                </p>
            </div>
            <p className="font-bold mt-2">{t('api.discount')}</p>
            <div className="grid grid-cols-2 bg-slate-100 p-2  gap-2  mt-2">
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.standard_price_rate`)}:</span>
                    {String(companyRatesData?.standard_discount_percentage)}%
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.special_price_request`)}:</span>
                    {String(companyRatesData?.special_price_request_percentage)}%
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.discount_amount`)}:</span>€ {String(discountAmount)}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.total_price_including_discount`)}:</span>€{' '}
                    {String(totalCostPriceIncludingDiscount.toFixed(2))}
                </p>
            </div>
            <p className="font-bold mt-2">{t('api.vpg')}</p>
            <div className="grid grid-cols-2 bg-slate-100 p-2  gap-2  mt-2">
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.vpg_percentage`)}:</span>
                    {companyRatesData?.vpg_percentage?.toFixed(2)}%
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.vpg_amount`)}:</span>€{' '}
                    {companyRatesData?.vpg_amount?.toFixed(2)}
                </p>
            </div>
            <div className="grid grid-cols-2 bg-slate-100 p-2  gap-2  mt-2">
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.bridging_machine`)}:</span>
                    {String(companyRatesData?.bridging_machine_amount)}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.financial_support_amount`)}:</span>€{' '}
                    {String(companyRatesData?.financial_support_amount)}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.net_additional_parts`)}:</span>€{' '}
                    {String(totalExtra?.toFixed(2))}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.data_logger`)}:</span>
                    {String(initialPrice?.datalogger)}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.net_all_cost`)}:</span>€{' '}
                    {String(companyRatesData?.net_all_cost?.toFixed(2))}
                </p>
            </div>
            <p className="font-bold mt-2">{t('api.profit')}</p>
            <div className="grid grid-cols-2 bg-slate-100 p-2  gap-2  mt-2">
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.profit_percent`)}:</span>
                    {String(companyRatesData?.profit_percentage)}%
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.profit_value`)}:</span>€{' '}
                    {String(companyRatesData?.profit_amount?.toFixed(2))}
                </p>
            </div>
            <div className="grid bg-slate-100 p-2  gap-2  mt-2">
                <p className="font-bold flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.quote_price`)}:</span>€{' '}
                    {String(companyRatesData?.novo_quote_price?.toFixed(2))}
                </p>
                {(financing === 'Purchase' || financing === 'Operational Lease and Purchase') &&
                companyRatesData?.manual_price ? (
                    <p className="font-bold flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.manual_price`)}:</span>€{' '}
                        {String(companyRatesData?.manual_price?.toFixed(2))}
                    </p>
                ) : null}
            </div>
            {companyRatesData?.trade_in_value ? (
                <div className="grid bg-slate-100 p-2  gap-2  mt-2">
                    <p className="font-bold flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.trade_in_value`)}:</span>€{' '}
                        {String(companyRatesData?.trade_in_value?.toFixed(2))}
                    </p>
                    <p className="font-bold flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.total_after_trade_in`)}:</span>€{' '}
                        {String(
                            (
                                Number(companyRatesData?.novo_quote_price) -
                                Number(companyRatesData?.trade_in_value)
                            )?.toFixed(2),
                        )}
                    </p>
                </div>
            ) : null}
        </div>
    )
}
export default PurchasePriceCalculationDetail
