import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface ISubmenuHeading {
    quoteName: string
    quoteStatus?: ReactNode
    orderStatus?: ReactNode
    customerName?: string
    createdBy?: string
}

const MenuItem: React.FC<{ title: string; value: string }> = ({
    title,
    value,
    children,
}: {
    title: string
    value?: string
    children?: React.ReactNode
}) => {
    if (children) {
        return (
            <div className="flex items-center gap-3">
                <p className="flex-1 text-placeholderColor text-left">{title}</p>
                {children}
            </div>
        )
    }
    return (
        <div className="flex items-center gap-3">
            <p className="flex-1 text-placeholderColor text-left">{title}</p>
            <p className="flex-1 text-left flex justify-end text-sm">{value}</p>
        </div>
    )
}

const SubmenuHeading = (props: ISubmenuHeading) => {
    const { quoteName, createdBy, customerName, orderStatus, quoteStatus } = props
    const { t } = useTranslation()
    return (
        <div className="flex flex-col gap-2 w-full">
            <MenuItem title={t('api.quote_name')} value={quoteName} />
            {quoteStatus && (
                <MenuItem value="" title={t('api.quote_status')}>
                    {quoteStatus}
                </MenuItem>
            )}
            {orderStatus && (
                <MenuItem value="" title={t('api.order_status')}>
                    {orderStatus}
                </MenuItem>
            )}
            {customerName && <MenuItem title={t('api.customer_name')} value={customerName} />}
            {createdBy && <MenuItem title={t('api.created_by')} value={createdBy} />}
        </div>
    )
}

export default SubmenuHeading
