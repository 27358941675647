interface PanelHeaderProps {
    title: string
    stat?: string
    content?: React.ReactElement
    onHeaderClick?: () => void
}

const PanelHeader = (props: PanelHeaderProps) => {
    const { title, stat, content, onHeaderClick } = props
    return (
        <div
            className="flex w-full justify-between items-center"
            onClick={() => onHeaderClick && onHeaderClick()}
        >
            <div className="font-extrabold text-black capitalize">{title}</div>
            <div className="ml-4">{stat}</div>
            {content && <div>{content}</div>}
        </div>
    )
}

export default PanelHeader
