/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { AiOutlineEdit, AiOutlineDelete, AiFillEdit } from 'react-icons/ai'
import { HiDocumentDuplicate, HiOutlineDuplicate } from 'react-icons/hi'
import DeleteModal from 'components/modal/deleteModal'

import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { MdPhotoCamera } from 'react-icons/md'
import ImageSlideShow from 'components/imageSlideShow'
import { useTranslation } from 'react-i18next'
import { errorToast } from '../../../utils/toasterUtil'
import CopySiteSurveyModal from './CopySiteSurveyModal'
import { SiteSurveyQueries } from '../queries/siteSurveyQueries'

const TableAction = ({
    row,
    refetch,
    activeTab,
}: {
    row: any
    refetch: () => void
    activeTab?: number
}) => {
    const navigation = useNavigate()
    const { t } = useTranslation()
    const [images, setImages] = useState<string[]>([])
    const [showImages, setShowImages] = useState<boolean>(false)

    const deleteMutation = useMutation(
        ['deleteSiteSurvey'],
        () => SiteSurveyQueries.deleteSiteSurvey(row.id),
        {
            onSuccess: data => {
                refetch()
                toast.success(data?.data?.message || t('message.delete_site_survey_success'))
            },
            onError: (error: Error) => {
                toast.error(t(error?.message) || t('message.delete_site_survey_failed'))
            },
        },
    )

    const { isLoading } = useQuery(
        ['getSiteSurveyById', row.id],
        () => SiteSurveyQueries.getSiteSurveyById(row.id),
        {
            enabled: showImages,
            onSuccess: data => {
                const tempImages = data?.data?.images?.map((item: any) => item.image)
                if (images) {
                    setImages(tempImages)
                }
            },
            onError: () => {
                toast.error(t('message.fetch_image_error'))
            },
        },
    )

    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [copyModal, setCopyModal] = useState<boolean>(false)

    const handleModal = () => {
        setDeleteModal(!deleteModal)
    }

    const handleCopyModal = () => {
        setCopyModal(!copyModal)
    }

    const handleEditSiteSurvey = () => {
        navigation(`${row.id}`)
    }

    const handleEditSiteSurveyOnlyRequired = () => {
        navigation(`${row.id}?hide-non-required-fields=true`)
    }

    const handleDelete = (configurationId: number, quoteId?: number) => {
        if (!quoteId) {
            deleteMutation.mutate()
        } else {
            toast('Something went wrong. Try again', errorToast)
        }
    }

    return (
        <div className="flex gap-4 items-center justify-end w-full">
            {row.images?.length > 0 && (
                <button
                    type="button"
                    onClick={() => {
                        setShowImages(true)
                    }}
                >
                    <MdPhotoCamera size={25} className="text-placeholderColor hover:text-primary" />
                </button>
            )}
            <div
                title="Edit Site Survey only required fields"
                className="cursor-pointer hover:text-primary text-lg"
                onClick={handleEditSiteSurveyOnlyRequired}
            >
                <AiFillEdit size={25} className="text-placeholderColor hover:text-primary" />
            </div>
            <div
                title="Edit Site Survey"
                className="cursor-pointer hover:text-primary text-lg"
                onClick={handleEditSiteSurvey}
            >
                <AiOutlineEdit size={25} className="text-placeholderColor hover:text-primary" />
            </div>
            <div
                title="Copy Site Survey"
                className="cursor-pointer hover:text-primary text-lg"
                onClick={() => handleCopyModal()}
            >
                <HiOutlineDuplicate size={25} className="text-secondary hover:text-primary" />
            </div>
            <div
                title="Copy Site Survey only required fields"
                className="cursor-pointer hover:text-primary text-lg"
                onClick={() =>
                    navigation({
                        pathname: `copy/${row.id}`,
                        search: `?copy-only-required-fields=true`,
                    })
                }
            >
                <HiDocumentDuplicate size={25} className="text-secondary hover:text-primary" />
            </div>
            {activeTab !== 3 && (
                <div
                    title="Delete Configuration"
                    className=" cursor-pointer hover:text-primary text-lg"
                    onClick={() => handleModal()}
                >
                    <AiOutlineDelete size={25} className="text-red-700 hover:text-primary" />
                </div>
            )}
            <DeleteModal
                modal={deleteModal}
                closeModal={() => handleModal()}
                onConfirm={() => handleDelete(row.id, row.quote_id)}
            />
            <CopySiteSurveyModal
                siteSurveyId={row.id}
                modal={copyModal}
                closeModal={() => handleCopyModal()}
            />
            {/* <DuplicateModal */}
            {/*    modal={duplicateModal} */}
            {/*    closeModal={() => handleDuplicateModal()} */}
            {/*    onConfirm={() => navigation(`create/${row.id}`)} */}
            {/* /> */}
            <ImageSlideShow
                loading={isLoading}
                images={images}
                showSlideShow={showImages}
                setShowSlideShow={setShowImages}
            />
        </div>
    )
}

export default TableAction
