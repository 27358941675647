import { Button, Dropdown, Menu } from 'antd'
import Modal from 'react-responsive-modal'
import ConfigurationSiteSurvey from 'modules/novo-configuration/components/ConfigurationSiteSurvey'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCalculator, AiOutlineEye } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { HiOutlineTranslate } from 'react-icons/hi'
import { BiHistory } from 'react-icons/bi'
import { MdArrowDropDown } from 'react-icons/md'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'

const OrderAction = ({ configuration }: { configuration: any }) => {
    const { userExtra } = useAuth()
    const { t } = useTranslation()
    const [siteSurveyModal, setSiteSurveyModal] = useState<boolean>(false)
    const navigate = useNavigate()

    return (
        <div className="flex item-center gap-4 py-2">
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item
                            key="1"
                            icon={<AiOutlineCalculator size={16} />}
                            onClick={() => {
                                setSiteSurveyModal(true)
                            }}
                        >
                            {t('api.site_survey')}
                        </Menu.Item>
                        <Menu.Item
                            key="2"
                            icon={<AiOutlineCalculator size={16} />}
                            onClick={() => {
                                navigate(`${configuration.id}/pricing-overview`)
                            }}
                        >
                            {t('api.price_overview')}
                        </Menu.Item>
                        <Menu.Item
                            key="3"
                            icon={<AiOutlineEye size={16} />}
                            onClick={() => {
                                navigate(`${configuration.id}?is_novo=true`)
                            }}
                        >
                            {t('api.show')}
                        </Menu.Item>
                        <Menu.Item
                            key="4"
                            icon={<BiHistory size={16} />}
                            onClick={() => {
                                navigate(
                                    `${configuration.id}/history?is_novo=${
                                        configuration?.is_novo || 'true'
                                    }`,
                                )
                            }}
                        >
                            {t('api.see_history')}
                        </Menu.Item>
                        {userExtra.role_id === ROLE_TYPE.sales_manager && (
                            <Menu.Item
                                key="5"
                                icon={<HiOutlineTranslate size={16} />}
                                onClick={() => {
                                    navigate(`${configuration.id}/translate-parts`)
                                }}
                            >
                                {t('api.translate_parts_description')}
                            </Menu.Item>
                        )}
                    </Menu>
                }
            >
                <Button>
                    <div className="flex gap-4 justify-between items-center">
                        <p>{t('api.actions')}</p>
                        <MdArrowDropDown size={24} />
                    </div>
                </Button>
            </Dropdown>
            <Modal
                center
                open={siteSurveyModal}
                onClose={() => setSiteSurveyModal(false)}
                showCloseIcon={false}
                classNames={{
                    root: 'rounded-lg',
                    modal: 'rounded-lg w-[80%]',
                }}
            >
                <ConfigurationSiteSurvey
                    isNovo={configuration.is_novo}
                    closeModal={() => setSiteSurveyModal(false)}
                    configurationId={configuration.id}
                />
            </Modal>
        </div>
    )
}

export default OrderAction
