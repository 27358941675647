import { Form, Input, DatePicker, Select } from 'antd'
import { t } from 'i18next'
import { map } from 'lodash'
import moment from 'moment'
import { callAxios } from 'plugins/api'
import { useState } from 'react'
import { useQuery } from 'react-query'

const roundedClass = 'rounded-lg flex-1'
const size = 'large'

const deliveryOptions = [
    {
        label: 'fields.delivery_individual',
        value: '0',
    },
    {
        label: 'fields.delivery_together',
        value: '1',
    },
]

type OrderDealerFormProps = {
    formik: any
}

const OrderDealerForm = (props: OrderDealerFormProps) => {
    const { formik } = props
    const [country, setCountry] = useState<any>([])

    const countryFetching = useQuery(
        'country',
        () =>
            callAxios({
                method: 'get',
                url: 'country',
            }),
        {
            onSuccess: data => {
                const countryList = data.data.map(
                    (item: { country_name: string; id: string | number }) => {
                        return {
                            label: item.country_name,
                            value: item.id,
                        }
                    },
                )
                setCountry(countryList)
            },
        },
    )

    return (
        <div className="grid grid-cols-2 gap-x-8">
            <Form.Item
                name="dealer_name"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.dealer_name
                            ? String(formik.errors.dealer_name)
                            : t('message.required_order_date'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.dealer_sc_name')}</h5>
                </div>
                <Input
                    name="dealer_name"
                    value={formik.values.dealer_name}
                    onChange={formik.handleChange}
                    placeholder={t('fields.dealer_name')}
                    size={size}
                    className={roundedClass}
                />
                {formik.errors && formik.errors.dealer_name && formik.touched.dealer_name && (
                    <p className="text-red-600 mt-1">{formik.errors.dealer_name}</p>
                )}
            </Form.Item>
            <Form.Item
                name="v55_email_address"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors
                            ? String(formik.errors.v55_email_address)
                            : t('message.required_v55_email_address'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('api.v55_required')}</h5>
                </div>
                <Input
                    name="v55_email_address"
                    value={formik.values.v55_email_address}
                    onChange={formik.handleChange}
                    placeholder="john@gmail.com"
                    size={size}
                    type="email"
                    className={roundedClass}
                />
                {formik.errors &&
                    formik.errors.v55_email_address &&
                    formik.touched.v55_email_address && (
                        <p className="text-red-600 mt-1">{formik.errors.v55_email_address}</p>
                    )}
            </Form.Item>
            {/* <Form.Item
                name="order_number"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors
                            ? String(formik.errors.order_number)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">Order Number</h5>
                </div>
                <Input
                    name="order_number"
                    disabled
                    value={formik.values.order_number}
                    onChange={formik.handleChange}
                    placeholder="xxx"
                    size={size}
                    type="text"
                    className={roundedClass}
                />
                {formik.errors && formik.errors.order_number && formik.touched.order_number && (
                    <p className="text-red-600 mt-1">{formik.errors.order_number}</p>
                )}
            </Form.Item> */}
            <Form.Item
                name="order_number"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors
                            ? String(formik.errors.order_number)
                            : t('message.required_order_number'),
                    },
                ]}
            >
                <div className="flex gap-4 flex-overflow items-center w-full h-full">
                    <div className="flex-1 min-w-[12rem]">
                        <div className="mb-1">
                            <h5 className="capitalize">{t('fields.order_date')}</h5>
                        </div>
                        <DatePicker
                            className={roundedClass}
                            size={size}
                            style={{
                                width: '100%',
                            }}
                            value={moment(formik.values.order_date, 'DD-MM-YYYY')}
                        />
                        {formik.errors && formik.errors.order_date && formik.touched.order_date && (
                            <p className="text-red-600 mt-1">{formik.errors.order_date}</p>
                        )}
                    </div>
                    <div className="flex-2 min-w-[12rem]">
                        <div className="mb-1">
                            <h5 className="capitalize">{t('fields.purchase_order_name')}</h5>
                        </div>
                        <Input
                            name="purchase_order_number"
                            value={formik.values.purchase_order_number}
                            onChange={formik.handleChange}
                            placeholder="xxx-xxx"
                            size={size}
                            type="text"
                            className={roundedClass}
                        />
                        {formik.errors &&
                            formik.errors.purchase_order_number &&
                            formik.touched.purchase_order_number && (
                                <p className="text-red-600 mt-1">
                                    {formik.errors.purchase_order_number}
                                </p>
                            )}
                    </div>
                </div>
            </Form.Item>
            {/* <Form.Item
                name="order_name"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors
                            ? String(formik.errors.order_name)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">Order Name</h5>
                </div>
                <Input
                    disabled
                    name="order_name"
                    value={formik.values.order_name}
                    onChange={formik.handleChange}
                    placeholder="order name"
                    size={size}
                    type="text"
                    className={roundedClass}
                />
                {formik.errors && formik.errors.order_name && formik.touched.order_name && (
                    <p className="text-red-600 mt-1">{formik.errors.order_name}</p>
                )}
            </Form.Item> */}
            {/* <Form.Item
                name="end_user_name"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors
                            ? String(formik.errors.end_user_name)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">End User Name</h5>
                </div>
                <Input
                    name="end_user_name"
                    disabled
                    value={formik.values.end_user_name}
                    onChange={formik.handleChange}
                    placeholder="John Doe"
                    size={size}
                    type="text"
                    className={roundedClass}
                />
                {formik.errors && formik.errors.end_user_name && formik.touched.end_user_name && (
                    <p className="text-red-600 mt-1">{formik.errors.end_user_name}</p>
                )}
            </Form.Item> */}
            <Form.Item
                name="country"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors
                            ? String(formik.errors.country)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.country')}</h5>
                </div>
                <Select
                    defaultValue={formik.values.country || 'Choose your option'}
                    onChange={(value: string) => formik.setFieldValue('country', value)}
                    loading={countryFetching.isLoading}
                    className="rounded-lg"
                    size={size}
                >
                    {map(country || [], option => (
                        <Select.Option value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
                {formik.errors && formik.errors.country && formik.touched.country && (
                    <p className="text-red-600 mt-1">{formik.errors.country}</p>
                )}
            </Form.Item>
            <Form.Item
                name="state"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.state
                            ? String(formik.errors.state)
                            : t('message.required_state'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.state_province')}</h5>
                </div>
                <Input
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    placeholder={t('fields.enter_state_province')}
                    size={size}
                    className={roundedClass}
                />
                {formik.errors && formik.errors.state && formik.touched.state && (
                    <p className="text-red-600 mt-1">{formik.errors.state}</p>
                )}
            </Form.Item>
            <Form.Item
                name="city"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.city
                            ? String(formik.errors.city)
                            : t('message.required_city'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.city')}</h5>
                </div>
                <Input
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    placeholder={t('fields.enter_city')}
                    size={size}
                    className={roundedClass}
                />
                {formik.errors && formik.errors.city && formik.touched.city && (
                    <p className="text-red-600 mt-1">{formik.errors.city}</p>
                )}
            </Form.Item>
            <Form.Item
                name="postal_code"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.postal_code
                            ? String(formik.errors.postal_code)
                            : t('message.required_postal_code'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.postal_code')}</h5>
                </div>
                <Input
                    name="postal_code"
                    value={formik.values.postal_code}
                    onChange={formik.handleChange}
                    placeholder={t('fields.enter_postal_code')}
                    size={size}
                    className={roundedClass}
                />
                {formik.errors && formik.errors.postal_code && formik.touched.postal_code && (
                    <p className="text-red-600 mt-1">{formik.errors.postal_code}</p>
                )}
            </Form.Item>
            <Form.Item
                name="address1"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.address1
                            ? String(formik.errors.address1)
                            : t('message.required_address_1'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.address_1')}</h5>
                </div>
                <Input
                    name="address1"
                    value={formik.values.address1}
                    onChange={formik.handleChange}
                    placeholder={t('fields.enter_address_1')}
                    size={size}
                    className={roundedClass}
                />
                {formik.errors && formik.errors.address1 && formik.touched.address1 && (
                    <p className="text-red-600 mt-1">{formik.errors.address1}</p>
                )}
            </Form.Item>
            <Form.Item
                name="address2"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.address2
                            ? String(formik.errors.address2)
                            : t('message.required_address_2'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.address_2')}</h5>
                </div>
                <Input
                    name="address2"
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                    placeholder={t('fields.enter_address_2')}
                    size={size}
                    className={roundedClass}
                />
                {formik.errors && formik.errors.address2 && formik.touched.address2 && (
                    <p className="text-red-600 mt-1">{formik.errors.address2}</p>
                )}
            </Form.Item>
            <Form.Item
                name="delivery_option"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors
                            ? String(formik.errors.delivery_option)
                            : t('message.required_delivery_option'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.delivery_option')}</h5>
                </div>
                <Select
                    value={String(formik.values.delivery_option)}
                    onChange={(value: string) => {
                        formik.setFieldValue('delivery_option', parseInt(value, 10))
                    }}
                    placeholder={t('fields.select_delivery_option')}
                    loading={false}
                    className="rounded-lg"
                    size={size}
                >
                    {map(deliveryOptions || [], option => (
                        <Select.Option value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
                {formik.errors &&
                    formik.errors.delivery_option &&
                    formik.touched.delivery_option && (
                        <p className="text-red-600 mt-1">{formik.errors.delivery_option}</p>
                    )}
            </Form.Item>
            {/* <Form.Item */}
            {/*    name="delivery_for_retail" */}
            {/*    className="flex flex-col" */}
            {/*    rules={[ */}
            {/*        { */}
            {/*            required: true, */}
            {/*            message: formik.errors */}
            {/*                ? String(formik.errors.delivery_for_retail) */}
            {/*                : 'Required Order Data', */}
            {/*        }, */}
            {/*    ]} */}
            {/* > */}
            {/*    <div className="mb-1"> */}
            {/*        <h5 className="capitalize">Delivery Options</h5> */}
            {/*    </div> */}
            {/*    <Select */}
            {/*        defaultValue={formik.values.delivery_for_retail || 'Choose your option'} */}
            {/*        onChange={(value: string) => formik.setFieldValue('delivery_for_retail', value)} */}
            {/*        loading={false} */}
            {/*        className="rounded-lg" */}
            {/*        size={size} */}
            {/*    > */}
            {/*        {map(deliveryRetail || [], option => ( */}
            {/*            <Select.Option value={option.value}>{option.label}</Select.Option> */}
            {/*        ))} */}
            {/*    </Select> */}
            {/*    {formik.errors && */}
            {/*        formik.errors.delivery_for_retail && */}
            {/*        formik.touched.delivery_for_retail && ( */}
            {/*            <p className="text-red-600 mt-1">{formik.errors.delivery_for_retail}</p> */}
            {/*        )} */}
            {/* </Form.Item> */}
        </div>
    )
}

export default OrderDealerForm
