import Input from 'components/input'
import TextArea from 'components/textArea'
import { useTranslation } from 'react-i18next'

interface RequestTsConfirmationModalProps {
    notes: string
    setNotes: (value: string) => void
    priorityDate: string
    setPriorityDate: (date: string) => void
    dateError?: boolean
    descError?: boolean
}
const RequestTsConfirmationModal = (props: RequestTsConfirmationModalProps) => {
    const { notes, setNotes, priorityDate, setPriorityDate, dateError, descError } = props
    const { t } = useTranslation()

    return (
        <div className="flex flex-col gap-6 w-full">
            <div>
                <p>{t('api.request_for_price')}</p>
            </div>
            <div className="flex flex-col gap-2">
                <p className="text-left text-sm">{t('api.reason_of_request')}</p>
                <TextArea
                    placeholder="Description"
                    value={notes}
                    name="description"
                    error={descError ? 'Description is required' : ''}
                    onChange={e => setNotes(e.target.value)}
                />
                {/* <div className="flex gap-4 items-center">
                    <p className="text-sm">{t('fields.is_priority')}</p>
                    <Checkbox checked={isPriority} onClick={() => setIsPriority(!isPriority)} />
                </div> */}
                <Input
                    type="date"
                    value={priorityDate}
                    name="priorityDate"
                    error={dateError ? 'Date is required' : ''}
                    onChange={e => setPriorityDate(e.target.value)}
                />
            </div>
        </div>
    )
}

export default RequestTsConfirmationModal
