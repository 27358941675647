/* eslint-disable no-nested-ternary */
import React, { forwardRef } from 'react'
import { addThousandSeparator } from 'utils/formatNumber'

interface InputProps {
    type?: 'text' | 'number' | 'password' | 'file' | 'checkbox' | 'date'
    horizontal?: boolean
    title?: string
    inputWidth?: string
    placeholder?: string
    labelStyle?: string
    value: any
    required?: boolean
    min?: number
    max?: number
    label?: string
    noLabel?: boolean
    name: string
    error?: string | null | boolean
    disabled?: boolean
    step?: string
    center?: boolean
    checked?: boolean
    showOnlyCurrency?: boolean
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    leftContent?: React.ReactElement
    rightContent?: React.ReactElement
    customInputStyles?: string
    isBeyondAccess?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
    const {
        type,
        horizontal,
        title,
        placeholder,
        inputWidth,
        value,
        required,
        label,
        noLabel,
        labelStyle,
        name,
        disabled,
        onBlur,
        step,
        min,
        max,
        center,
        checked,
        error,
        showOnlyCurrency,
        leftContent,
        rightContent,
        customInputStyles,
        isBeyondAccess,
        onChange,
    } = props
    const outlineInput = 'border-2 border-inputBorder placeholder-placeholderColor'
    const inputClass = 'rounded-lg flex items-center gap-2'

    return (
        <div
            className={`flex ${
                horizontal
                    ? 'flex-col md:flex-row md:items-center justify-between'
                    : 'flex-col justify-start'
            }   gap-1 ${noLabel ? 'w-fit' : 'w-full'}`}
            title={title}
        >
            {!noLabel && (
                <div
                    className={`${horizontal ? (inputWidth ? 'flex-1' : 'w-[27%] mt-2') : ''}  ${
                        labelStyle || ''
                    }`}
                >
                    {label && (
                        <label
                            htmlFor={name}
                            className={`${horizontal ? 'text-md ' : 'text-md '} px-1 flex gap-2`}
                        >
                            {label} {required && <span className="text-red-700">*</span>}
                        </label>
                    )}
                </div>
            )}
            <div
                className={`${horizontal ? inputWidth || 'w-[70%]' : ''} ${disabled && 'bg-gray'}`}
            >
                <div className={`${inputClass} ${outlineInput} ${error && 'border-red-700'}`}>
                    {leftContent && (
                        <span className="pl-2 text-placeholderColor ">{leftContent}</span>
                    )}
                    {showOnlyCurrency ? (
                        <p
                            className={`py-2 px-4 w-full focus:outline-none rounded-lg ${
                                center && 'text-center'
                            } ${customInputStyles} ${disabled && 'bg-gray'} ${
                                isBeyondAccess && 'bg-warningColor'
                            }`}
                        >
                            {addThousandSeparator(Number(value))}
                        </p>
                    ) : (
                        <input
                            ref={ref}
                            type={type || 'text'}
                            placeholder={placeholder}
                            value={value || ''}
                            min={min}
                            max={max}
                            step={step}
                            onBlur={onBlur}
                            onChange={onChange}
                            name={name}
                            checked={checked}
                            disabled={Boolean(disabled)}
                            className={`py-2 px-2 w-full focus:outline-none rounded-lg ${
                                center && 'text-center'
                            } ${customInputStyles} ${disabled && 'bg-gray'} ${
                                isBeyondAccess && 'bg-warningColor'
                            }`}
                        />
                    )}
                    {rightContent && <span className="pr-2">{rightContent}</span>}
                </div>
                {error && <span className="text-red-700 text-xs">{error}</span>}
            </div>
        </div>
    )
})

export default Input
