/* eslint-disable react/button-has-type */
import { FormikProps } from 'formik'
import { useDropzone } from 'react-dropzone'
import { useEffect, useState } from 'react'
import { MdCancel } from 'react-icons/md'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { ISiteSurveyForm } from './AddSiteSurvey'
import { SiteSurveyQueries } from '../queries/siteSurveyQueries'
import Loading from '../../../components/loading'
import useTranslationData from '../../translation/hooks/useTranslationData'

interface IFinance {
    formik: FormikProps<ISiteSurveyForm>
}

const ImageUploadSiteSurvey = (props: IFinance) => {
    const {
        translation: { t },
    } = useTranslationData()

    const { formik } = props

    const [selectedImageIndex, setSelectedImageIndex] = useState<number>()

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone()

    const deleteImageForSiteSurvey = useMutation(
        ['deleteImageSiteSurvey'],
        ({ imageId, index }: { imageId: number; index: number }) =>
            SiteSurveyQueries.deleteSiteSurveyImageById({ imageId, index }),
        {
            onSuccess: (data, { imageId, index }: { imageId: number; index: number }) => {
                const tempImages = formik.values.uploadedImages
                tempImages.splice(index, 1)
                formik.setFieldValue('uploadedImages', tempImages)
                setSelectedImageIndex(undefined)
            },
            onError: error => {
                toast.error('Something went wrong, please try again')
            },
        },
    )

    useEffect(() => {
        formik.setFieldValue(
            'images',
            acceptedFiles.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            ),
        )
    }, [acceptedFiles])

    const removeImage = (index: number) => {
        const tempImages = formik.values.images
        tempImages.splice(index, 1)
        formik.setFieldValue('images', tempImages)
    }

    const removeUploadedImage = async (imageId: number, index: number) => {
        setSelectedImageIndex(index)
        await deleteImageForSiteSurvey.mutate({ imageId, index })
    }

    return (
        <div className="flex flex-col gap-4 w-full">
            <div
                {...getRootProps({
                    className:
                        'w-full dropzone border-primary rounded border-2 border-dashed flex justify-center align-middle items-center files-center h-36 p-2 cursor-pointer ',
                })}
            >
                <input {...getInputProps()} />
                <p>
                    {t('api.drag_and_drop_files')}, {t('api.or')} {t('api.click_to_select_giles')}
                </p>
            </div>
            {formik.values.images && formik.values.images.length > 0 && (
                <>
                    <p className="text-center">Images To Upload</p>
                    <div className="w-full h-fit flex gap-4 flex-wrap my-2">
                        {formik.values.images &&
                            formik.values.images.map((image: any, index: number) => (
                                <div
                                    key={index}
                                    className="w-52 h-52 border-primary shadow-xl rounded-md relative"
                                >
                                    <MdCancel
                                        onClick={() => removeImage(index)}
                                        className="absolute text-red-700 hover:text-red-500 -top-2 -right-2 cursor-pointer"
                                        size="24"
                                    />
                                    <img
                                        className="w-full h-full object-contain"
                                        src={URL.createObjectURL(image)}
                                        alt={`${image.name}`}
                                    />
                                </div>
                            ))}
                    </div>
                </>
            )}
            {formik.values.uploadedImages && formik.values.uploadedImages.length > 0 && (
                <>
                    <p className="text-center mt-4">Uploaded Images</p>
                    <div className="w-full h-fit flex gap-4 flex-wrap my-2">
                        {formik.values.uploadedImages &&
                            formik.values.uploadedImages.map((image: any, index: number) => {
                                return deleteImageForSiteSurvey.isLoading &&
                                    index === selectedImageIndex ? (
                                    <div className="flex justify-center items-center w-52 h-52 border-primary shadow-xl rounded-md">
                                        <Loading />
                                    </div>
                                ) : (
                                    <div
                                        key={index}
                                        className="w-52 h-52 border-primary shadow-xl rounded-md relative"
                                    >
                                        <MdCancel
                                            onClick={() => removeUploadedImage(image.id, index)}
                                            className="absolute text-red-700 hover:text-red-500 -top-2 -right-2 cursor-pointer"
                                            size="24"
                                        />
                                        <img
                                            className="w-full h-full object-contain"
                                            src={`${process.env.REACT_APP_S3_IMAGE_URL}${image.thumbnail_image}`}
                                            alt={`${image.name}`}
                                        />
                                    </div>
                                )
                            })}
                    </div>
                </>
            )}
        </div>
    )
}

export default ImageUploadSiteSurvey
