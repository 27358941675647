/* eslint-disable react/no-unescaped-entities */
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import NotFoundImage from '../../assets/svg/404.svg'

const NotFound = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div className="h-full w-full bg-white px-8 py-4 flex flex-col items-center gap-8 rounded-md">
            <div className="w-full h-[80vh] grid place-content-center gap-8 px-4">
                <p className="text-center text-xl tracking-widest w-[600px] leading-9">
                    {t('message.not_found')}
                </p>
                <div className="h-[30vh]">
                    <img src={NotFoundImage} alt="page not found" />
                </div>
                <div className="flex items-center justify-center">
                    <div className="w-1/3">
                        <Button label={t('api.back_to_home')} onClick={() => navigate('/')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
