/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import { useState } from 'react'
import { FormikProps } from 'formik'
import { useQueries, useQuery } from 'react-query'
import { Collapse, Radio, TreeSelect, Form, Select } from 'antd'
import { map } from 'lodash'
import Loading from 'components/loading'
import { useLocation } from 'react-router-dom'
import { callAxios } from 'plugins/api'
import { useTranslation } from 'react-i18next'
import { CreateConfigurationType } from '../../views/createConfiguration'
import { getProduct, getProductTreeSelect } from '../../queries/product'
import Input from '../../../../components/input'
import { ConfigurationQueries } from '../../queries/configuration'
import Image from '../../../../components/image'
import { useAuth } from '../../../auth/context/useAuth'

interface ConfigurationInformationProps {
    formik: FormikProps<CreateConfigurationType>
    configurationId?: number
}

const size = 'large'

const ConfigurationInformation = (props: ConfigurationInformationProps) => {
    const { formik, configurationId } = props
    const { t } = useTranslation()
    const storage = process.env.REACT_APP_IMAGE_URL
    const { Panel } = Collapse

    const location = useLocation()

    const { userExtra } = useAuth()

    const [products, setProducts] = useState([])
    const [machineImage, setMachineImage] = useState<string>('')
    const [users, setUserList] = useState<[]>([])

    useQueries([
        {
            queryKey: [
                'getProducts',
                { roleId: userExtra.role_id, countryId: userExtra.country[0].id },
            ],
            queryFn: () => {
                if (configurationId) {
                    return getProductTreeSelect({
                        configurationCategory: formik.values.productId,
                        roleId: userExtra.role_id,
                        countryId: userExtra.country[0].id,
                    })
                }
                return getProduct({ roleId: userExtra.role_id, countryId: userExtra.country[0].id })
            },
            onSuccess: (res: any) => {
                if (res && res.data) {
                    setProducts(res.data)
                } else {
                    setProducts([])
                }
            },
        },
        {
            queryKey: ['userListConfiguration'],
            queryFn: () =>
                callAxios({
                    method: 'get',
                    url: 'auth/users',
                }),
            onSuccess: (data: any) => {
                const userList = data?.data.map(
                    (user: { name: string; id: string; email: string; role_name: string }) => {
                        return {
                            label: `${user.name} | ${user.role_name} | [${user.email}]`,
                            value: user.id,
                        }
                    },
                )
                setUserList(userList)
            },
        },
    ])

    const { isLoading: machineDetailAndFeatureLoading, data: machineDetailAndFeatureData } =
        useQuery(
            ['getMachineDetailAndFeature', formik.values.productId],
            () =>
                ConfigurationQueries.getMachineDetailAndFeature({
                    configurationId: formik.values.productId,
                }),
            {
                enabled: !!formik.values.productId,
            },
        )

    const { isLoading: lastConfigurationLoading, data: lastConfigurationData } = useQuery(
        ['getLastConfiguration', formik.values.productId, machineDetailAndFeatureData],
        () => ConfigurationQueries.getLastConfiguration(),
        {
            onSuccess: (res: any) => {
                if (
                    !location.pathname.includes('edit') &&
                    machineDetailAndFeatureData &&
                    machineDetailAndFeatureData.data &&
                    machineDetailAndFeatureData.data.machine_category_details &&
                    machineDetailAndFeatureData.data.machine_category_details.length > 0
                ) {
                    formik.setFieldValue(
                        'productConfigurationName',
                        `${
                            machineDetailAndFeatureData?.data?.machine_category_details[0]
                                .machine_category
                        }-${
                            res.data && res.data.length > 0 ? parseInt(res.data[0].id, 10) + 1 : 1
                        }`,
                    )
                    setMachineImage(
                        `${storage}${machineDetailAndFeatureData?.data?.machine_category_details[0].machine_category_image}`,
                    )
                }
            },
            enabled: !!formik.values.productId && !!machineDetailAndFeatureData,
        },
    )

    const handleTreeSelectChange = (name: string, value: number) => {
        formik.setFieldValue(name, value)
    }

    return (
        <form className="flex flex-col gap-4">
            <div>
                <label>Select Product</label>
                <TreeSelect
                    className="w-full"
                    showSearch
                    value={formik.values.productId !== 0 ? formik.values.productId : undefined}
                    treeData={products}
                    placeholder={t('fields.select_product_placeholder')}
                    treeDefaultExpandAll
                    treeNodeFilterProp={t('fields.product')}
                    onChange={value => {
                        handleTreeSelectChange('productId', value)
                    }}
                />
                {formik.touched.productId && formik.errors.productId && (
                    <p className="text-validation-warning">{formik.errors.productId}</p>
                )}
            </div>
            {/* only for sales support */}
            {userExtra.role_id === 4 && (
                <div>
                    <Form.Item
                        name="user"
                        className="flex flex-col w-[45%]"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <div>
                            <h5 className="capitalize">{t('api.user')}</h5>
                        </div>
                        <Select
                            defaultValue="Choose user"
                            onChange={(value: string) => formik.setFieldValue('user', value)}
                            className="rounded-lg"
                            size={size}
                        >
                            {map(users || [], (option: any) => (
                                <Select.Option value={option.value}>{option.label}</Select.Option>
                            ))}
                        </Select>
                        {formik.errors && formik.errors.user && formik.touched.user && (
                            <p className="text-red-600 mt-1">{formik.errors.user}</p>
                        )}
                    </Form.Item>
                </div>
            )}

            {formik.values.productConfigurationName && (
                <>
                    <div>
                        <Input
                            type="text"
                            value={formik.values.productConfigurationName}
                            onChange={formik.handleChange}
                            name="productConfigurationName"
                            label={t('fields.product_configuration_name')}
                        />
                        {formik.errors.productConfigurationName &&
                            formik.errors.productConfigurationName && (
                                <p className="text-validation-warning">
                                    {formik.errors.productConfigurationName}
                                </p>
                            )}
                    </div>
                    <div className="h-64">
                        <Image
                            src={machineImage}
                            objectFit="object-contain"
                            altText={formik.values.productConfigurationName}
                        />
                    </div>
                </>
            )}
            {machineDetailAndFeatureData && lastConfigurationData && (
                <Collapse>
                    <Panel header={t('api.specification')} key={1}>
                        <div className="flex justify-around items-center flex-wrap">
                            {machineDetailAndFeatureData?.data?.specifications.map(
                                (specification: any) => (
                                    <div
                                        key={specification.id}
                                        className="flex flex-col w-1/3 my-2 px-2"
                                    >
                                        <p className="font-bold text-center">
                                            {specification.specification_name}
                                        </p>
                                        <p className="mt-1 text-center">
                                            {specification.specification_value}
                                        </p>
                                    </div>
                                ),
                            )}
                        </div>
                    </Panel>
                    <Panel header={t('api.features')} key={2}>
                        <div className="flex justify-around items-center flex-wrap">
                            {machineDetailAndFeatureData?.data?.features.map((feature: any) => (
                                <div key={feature.id} className="flex flex-col w-2/5 my-2 px-2">
                                    <p className="font-bold text-center">{feature.feature_title}</p>
                                    <p className="mt-1 text-center">
                                        {feature.feature_description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </Panel>
                </Collapse>
            )}
            {(machineDetailAndFeatureLoading || lastConfigurationLoading) && (
                <div className="m-auto">
                    <Loading />
                </div>
            )}
            <div>
                <p className="font-bold">Only Parts:</p>
                <Radio.Group
                    name="onlyParts"
                    onChange={formik.handleChange}
                    value={formik.values.onlyParts}
                >
                    <Radio value>{t('api.yes')}</Radio>
                    <Radio value={false}>{t('api.no')}</Radio>
                </Radio.Group>
            </div>
        </form>
    )
}

export default ConfigurationInformation
