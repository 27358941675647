import { Options } from 'components/select'

export const floorOptions: Options[] = [
    {
        label: 'fields.less_25_percent_uneven',
        value: '< 25% uneven',
    },
    {
        label: 'fields.50_percent_smooth',
        value: '50% smooth',
    },
    {
        label: 'fields.more_75_percent_uneven',
        value: '> 75% uneven',
    },
]

export const floorConditionOption: Options[] = [
    {
        label: 'fields.clean_and_dry',
        value: 'Clean and dry',
    },
    {
        label: 'fields.50_percent_clean',
        value: '50% clean and dry, 50% wet and / or dirty',
    },
    {
        label: 'fields.wet_or_dirty',
        value: 'wet and / or dirty',
    },
]

export const branchOption: Options[] = [
    {
        label: 'fields.foundry',
        value: 'Foundry',
    },
    {
        label: 'fields.recycling',
        value: 'Recycling',
    },
    {
        label: 'fields.limestone',
        value: 'Limestone',
    },
    {
        label: 'fields.concrete',
        value: 'Concrete',
    },
    {
        label: 'fields.freezer',
        value: 'Freezer',
    },
    {
        label: 'fields.meat',
        value: 'Meat',
    },
    {
        label: 'fields.fish',
        value: 'Fish',
    },
    {
        label: 'fields.food',
        value: 'Food',
    },
    {
        label: 'fields.breweries',
        value: 'Breweries',
    },
    {
        label: 'fields.distribution',
        value: 'Distribution',
    },
    {
        label: 'fields.wood',
        value: 'Wood',
    },
    {
        label: 'fields.paper_mils',
        value: 'Paper mils',
    },
    {
        label: 'fields.graphic_industry',
        value: 'Graphic industry',
    },
    {
        label: 'fields.chemicals',
        value: 'Chemicals',
    },
    {
        label: 'fields.terminals',
        value: 'Terminals',
    },
    {
        label: 'fields.other',
        value: 'other',
    },
]

export const couplingOptions: Options[] = [
    {
        label: 'fields.klik_koppeling_recht',
        value: 'Klik koppeling recht',
    },
    {
        label: 'fields.schroef_koppeling_recht',
        value: 'Schroef koppeling recht',
    },
    {
        label: 'fields.easy_clip_koppeling_recht',
        value: 'Easy-clip koppeling recht',
    },
    {
        label: 'fields.klik_koppeling_45_graden',
        value: 'Klik koppeling 45 graden',
    },
    {
        label: 'fields.schroef_koppeling_45_graden',
        value: 'Schroef koppeling 45 graden',
    },
    {
        label: 'fields.easy_clip_koppeling_45_graden',
        value: 'Easy-clip koppeling 45 graden',
    },
    {
        label: 'fields.klik_koppeling_90_graden',
        value: 'Klik koppeling 90 graden',
    },
    {
        label: 'fields.schroef_koppeling_90_graden',
        value: 'Schroef koppeling 90 graden',
    },
    {
        label: 'fields.easy_clip_koppeling_90_graden',
        value: 'Easy-clip koppeling 90 graden',
    },
    {
        label: 'fields.n_v_t',
        value: 'N.v.t',
    },
]

export const indoorOrOutdoorOptions: Options[] = [
    {
        label: 'fields.indoor_only',
        value: 'Indoor Only',
    },
    {
        label: 'fields.outdoor_only',
        value: 'Outdoor Only',
    },
    {
        label: 'fields.special_option_for_indoors_included',
        value: 'Special option for indoors included',
    },
    {
        label: 'fields.inddors_and_outdoor',
        value: 'Indoors and Outdoors',
    },
    {
        label: 'fields.special_option_for_outdoors_included',
        value: 'Special option for outdoors included',
    },
]

export const dockShelterOrRampsOptions: Options[] = [
    {
        label: 'fields.none',
        value: 'none',
    },
    {
        label: 'fields.sometimes_50_percent_of_time',
        value: 'sometimes (+/- 50% of the times)',
    },
    {
        label: 'fields.always',
        value: 'always',
    },
]

export const seasonalCrowdOption: Options[] = [
    {
        label: 'api.no',
        value: 'no',
    },
    {
        label: 'api.yes_take_busiest_period_when_choosing_battery',
        value: 'yes',
    },
]

export const workInShiftOptions: Options[] = [
    {
        label: 'fields.not_applicable',
        value: 'not applicable',
    },
    {
        label: 'fields.sometimes',
        value: 'Sometimes',
    },
    {
        label: 'fields.24_7',
        value: '24 / 7',
    },
]

export const batteryTypeOptions: Options[] = [
    {
        label: 'fields.loodzuur',
        value: 'Loodzuur',
    },
    {
        label: 'fields.lithium_ion',
        value: 'Lithium-ion',
    },
]

export const hysterTrackerOption: Options[] = [
    {
        label: 'fields.monitoring',
        value: 'Monitoring',
    },
    {
        label: 'fields.access',
        value: 'Access',
    },
    {
        label: 'fields.verification',
        value: 'Verification',
    },
]

export const typeBandanOptions: Options[] = [
    {
        label: 'fields.single_std',
        value: 'Single Std.',
    },
    {
        label: 'fields.double_std',
        value: 'Double Std.',
    },
    {
        label: 'fields.single_nm',
        value: 'Single NM',
    },
    {
        label: 'fields.dual_nm',
        value: 'Dual NM',
    },
]

export const safetyOptions: Options[] = [
    {
        label: 'fields.streambelt',
        value: 'Streambelt',
    },
    {
        label: 'fields.safety_doors',
        value: 'Safety doors',
    },
]

export const workingEnvironmentTemperatureOptions: Options[] = [
    {
        label: 'fields.normal_greater_than_7_gr_c',
        value: 'Normal > 7gr. C',
    },
    {
        label: 'fields.wet_and_cooling_0_7_gr_c',
        value: 'Wet and/of cooling 0-7 gr. C',
    },
    {
        label: 'fields.coldstore_less_than_0_gr_c',
        value: 'Coldstore < 0gr. C',
    },
]

// const loadStrainOptions: Options[] = [
//     {
//         label: 'increased',
//         value: 'increased',
//     },
//     {
//         label: 'decreased',
//         value: 'decreased',
//     },
// ]
// const loadTimeOptions: Options[] = [
//     {
//         label: '8 uur',
//         value: '8 uur',
//     },
//     {
//         label: '11 uur',
//         value: '11 uur',
//     },
//     {
//         label: '12 uur',
//         value: '12 uur',
//     },
// ]
export const palletSizeOptions: Options[] = [
    {
        label: 'fields.europallet_80_120',
        value: 'Europallet 80 x 120 cm',
    },
    {
        label: 'fields.blokpallet_100_120_cm_meest_voorkomend',
        value: 'Blokpallet 100 x 120 cm (meest voorkomend)',
    },
    {
        label: 'fields.display_60_80_cm_of_40_60',
        value: 'Display 60 x 80 cm of 40 x 60cm',
    },
    {
        label: 'fields.amerikaanse_pallet_102_122_cm',
        value: 'Amerikaanse pallet 102 x 122 cm',
    },
    {
        label: 'fields.dusseldorf_pallets_60_80_cm',
        value: 'Düsseldorf pallets 60 x 80 cm',
    },
    {
        label: 'fields.other',
        value: 'other',
    },
]

export const lightingOption: Options[] = [
    {
        label: 'fields.standard',
        value: 'Standard',
    },
    {
        label: 'fields.led',
        value: 'LED',
    },
]

// const loadRackOption: Options[] = [
//     {
//         label: 'Raised',
//         value: 'Raised',
//     },
//     {
//         label: 'Not Raised',
//         value: 'Not Raised',
//     },
// ]

export const lflOrFFlOption: Options[] = [
    {
        label: 'fields.lfl',
        value: 'LFL',
    },
    {
        label: 'fields.ffl',
        value: 'FFL',
    },
]

export const palOption: Options[] = [
    {
        label: 'fields.front_blue',
        value: 'Front blue',
    },
    {
        label: 'fields.front_red',
        value: 'Front red',
    },
    {
        label: 'fields.rear_blue',
        value: 'Rear blue',
    },
    {
        label: 'fields.rear_red',
        value: 'Rear red',
    },
]

export const forkWheelOption: Options[] = [
    {
        label: 'fields.single',
        value: 'Single',
    },
    {
        label: 'fields.double',
        value: 'Double',
    },
]

export const mastOptions: Options[] = [
    {
        label: 'fields.2_delig_zonder_freelift',
        value: '2-delig-zonder-freelift',
    },
    {
        label: 'fields.2_weg',
        value: '2-weg',
    },
    {
        label: 'fields.3_weg',
        value: '3-weg',
    },
    {
        label: 'fields.4_weg',
        value: '4-weg',
    },
]

export const inductanceOrRailConductionOptions: Options[] = [
    {
        label: 'fields.induction',
        value: 'Induction',
    },
    {
        label: 'fields.rail_conduction',
        value: 'Rail conduction',
    },
]

export const controlOptions: Options[] = [
    {
        label: 'fields.level',
        value: 'Level',
    },
    {
        label: 'fields.minilevers',
        value: 'Minilevers',
    },
    {
        label: 'fields.joystick',
        value: 'Joystick',
    },
    {
        label: 'fields.dual_side',
        value: 'Dual side',
    },
]

export const steeringOptions: Options[] = [
    {
        label: '180',
        value: '180',
    },
    {
        label: '360',
        value: '360',
    },
    {
        label: '180/360',
        value: '180/360',
    },
]

export const eloOrHipOptions: Options[] = [
    {
        label: 'fields.hip_modus',
        value: 'hip',
    },
    {
        label: 'fields.elo_modus',
        value: 'elo',
    },
]

export const plugTypeOption: Options[] = [
    {
        label: 'fields.schiko_230_v_stekker',
        value: 'Schiko 230V stekker',
    },
    {
        label: 'fields.4p_16a_400v_stekker',
        value: '4P/16A 400V stekker',
    },
    {
        label: 'fields.5p_16a_400v_stekker',
        value: '5P/16A 400V stekker',
    },
    {
        label: 'fields.4p_32a_400v_stekker',
        value: '4P/32a 400v stekker',
    },
    {
        label: 'fields.5p_32a_400v_stekker',
        value: '5P/32a 400v stekker',
    },
    {
        label: 'fields.63_a_li_ion',
        value: '63A (Li-ion)',
    },
    {
        label: 'fields.125_a_li_ions',
        value: '125A (Li-ion)',
    },
]

export const financeOptions: Options[] = [
    {
        label: 'fields.purchase',
        value: 'Purchase',
    },
    {
        label: 'fields.operational_lease',
        value: 'Operational Lease',
    },
    {
        label: 'fields.financial_lease',
        value: 'Financial Lease',
    },
    {
        label: 'fields.operational_lease_and_purchase',
        value: 'Operational Lease and Purchase',
    },
]

export const tankOption: Options[] = [
    {
        label: 'fields.aluminium_rijngas',
        value: 'Aluminium rijngas',
    },
    {
        label: 'fields.composite',
        value: 'Composite',
    },
    {
        label: 'fields.steel',
        value: 'Steel',
    },
]

export const batteryBrandOption: Options[] = [
    {
        label: 'fields.hyster_celectric',
        value: 'Hyster/Celectric',
    },
    {
        label: 'fields.hawker',
        value: 'Hawker',
    },
    {
        label: 'fields.midac',
        value: 'Midac',
    },
    {
        label: 'fields.hoppecke',
        value: 'Hoppecke',
    },
    {
        label: 'fields.r_and_w',
        value: 'R&W',
    },
]

export const chargingTimeOptions: Options[] = [
    {
        label: 'fields.1_hrs',
        value: '1 hrs',
    },
    {
        label: 'fields.2_hrs',
        value: '2 hrs',
    },
    {
        label: 'fields.3_hrs',
        value: '3 hrs',
    },
    {
        label: 'fields.4_hrs',
        value: '4 hrs',
    },
    {
        label: 'fields.8_hrs',
        value: '8 hrs',
    },
    {
        label: 'fields.11_hrs',
        value: '11 hrs',
    },
    {
        label: 'fields.12_hrs',
        value: '12 hrs',
    },
]

export const attachmentOption: Options[] = [
    {
        label: 'fields.fork_positioner',
        value: 'Fork positioner',
    },
    {
        label: 'fields.sideshift',
        value: 'Sideshift',
    },
    {
        label: 'fields.sideshift_fork_adjuster',
        value: 'Sideshift fork adjuster',
    },
    {
        label: 'fields.paper_clamp',
        value: 'Paper clamp',
    },
    {
        label: 'fields.recycling_clamp',
        value: 'Recycling clamp',
    },
    {
        label: 'fields.rotary_clamp',
        value: 'Rotary clamp',
    },
    {
        label: 'fields.white_goods_clamp',
        value: 'White goods clamp',
    },
    {
        label: 'fields.other',
        value: 'Other',
    },
]

export const FuelTypeOptions: Options[] = [
    {
        label: 'fields.diesel',
        value: 'Diesel',
    },
    {
        label: 'fields.lpg',
        value: 'LPG',
    },
]
