/* eslint-disable react/no-array-index-key */
import { Outlet } from 'react-router-dom'
import Image from 'components/image'
import Translation from '../../translation'

import appConfig from '../../../app.json'

import companyLogo from '../../../assets/heffiq/logo.svg'
import Footer from '../../shared/components/Footer'

const AuthLayout = () => {
    const { companyName } = appConfig

    return (
        <div className="w-screen h-screen flex flex-col">
            <div className="bg-authBg bg-no-repeat bg-cover h-full flex flex-col">
                <div className="h-full w-full bg-blackWithTransparency">
                    <div className="h-fit w-full">
                        <div className="container m-auto">
                            <div className="mt-2 w-12 float-right">
                                <Translation />
                            </div>
                        </div>
                    </div>
                    <div className="container m-auto flex justify-center md:justify-end items-center min-h-fit h-full py-12">
                        <div className="bg-white my-auto rounded-md h-fit w-[94%] md:w-2/6 md:max-w-[28rem] md:min-w-[24rem] py-6 px-8 md:p-12">
                            <div className="my-4 h-12 w-full flex justify-between">
                                <Image
                                    src={companyLogo}
                                    altText={companyName}
                                    objectFit="object-contain"
                                    height="h-12"
                                    width="w-fit"
                                />
                            </div>
                            <Outlet />
                        </div>
                    </div>
                </div>
                <Footer hasOverlay />
            </div>
        </div>
    )
}

export default AuthLayout
