/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import { HiOutlineDuplicate } from 'react-icons/hi'
import DeleteModal from 'components/modal/deleteModal'

import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DuplicateModal from './duplicateModal'
import { ConfigurationQueries } from '../queries/configuration'
import { errorToast, successToast } from '../../../utils/toasterUtil'

const TableAction = ({
    row,
    refetch,
    activeTab,
    tag,
}: {
    row: any
    refetch: () => void
    activeTab?: number
    tag: string
}) => {
    const navigation = useNavigate()
    const { t } = useTranslation()

    const deleteMutation = useMutation(
        ['deleteConfiguration'],
        (configurationId: number) =>
            ConfigurationQueries.deleteConfiguration({ configurationId, tag }),
        {
            onSuccess: data => {
                refetch()
                toast(
                    data?.data?.message || t('message.delete_configuration_success'),
                    successToast,
                )
            },
            onError: (error: any) => {
                toast(error?.message || t('message.delete_configuration_failed'), successToast)
            },
        },
    )

    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [duplicateModal, setDuplicateModal] = useState<boolean>(false)

    const handleModal = () => {
        setDeleteModal(!deleteModal)
    }

    const handleDuplicateModal = () => {
        setDuplicateModal(!duplicateModal)
    }

    const handleEditConfiguration = () => {
        navigation(`edit/${row.id}${activeTab === 2 ? '/library' : ''}`)
    }

    const handleDelete = (configurationId: number, quoteId?: number) => {
        if (!quoteId) {
            deleteMutation.mutate(configurationId)
        } else {
            toast(t('message.configuration_already_used_cannot_delete'), errorToast)
        }
    }

    return (
        <div className="flex gap-4 items-center">
            {activeTab !== 4 && (
                <div
                    title={t('api.edit_configuration')}
                    className=" cursor-pointer hover:text-primary text-lg"
                    onClick={handleEditConfiguration}
                >
                    <AiOutlineEdit size={25} className="text-placeholderColor hover:text-primary" />
                </div>
            )}
            <div
                title={t('api.duplicate_and_edit_configuration')}
                className=" cursor-pointer hover:text-primary text-lg"
                onClick={() => handleDuplicateModal()}
            >
                <HiOutlineDuplicate
                    size={25}
                    className="text-placeholderColor hover:text-primary"
                />
            </div>
            {activeTab !== 4 && (
                <div
                    title={t('api.delete_configuration')}
                    className=" cursor-pointer hover:text-primary text-lg"
                    onClick={() => handleModal()}
                >
                    <AiOutlineDelete
                        size={25}
                        className="text-placeholderColor hover:text-primary"
                    />
                </div>
            )}
            <DeleteModal
                modal={deleteModal}
                closeModal={() => handleModal()}
                onConfirm={() => handleDelete(row.id, row.quote_id)}
            />
            <DuplicateModal
                modal={duplicateModal}
                closeModal={() => handleDuplicateModal()}
                onConfirm={() =>
                    navigation(
                        `create/${activeTab === 4 ? row.configuration_id : row.id}${
                            activeTab === 2 || activeTab === 4 ? '/library' : ''
                        }`,
                    )
                }
            />
        </div>
    )
}

export default TableAction
