import { useTranslation } from 'react-i18next'
import ExtraPricingComponent from 'modules/quotes/components/ExtraPricingComponent'
import { useEffect } from 'react'

type ExtraItemsDetailsProps = {
    initialPrice: any
    setTotalExtra: any
}

const ExtraItemsDetail = ({ initialPrice, setTotalExtra }: ExtraItemsDetailsProps) => {
    const { t } = useTranslation()
    const extras = initialPrice?.extra_service

    // function sumArrayElements(arr: number[]) {
    //     let total = 0
    //     // eslint-disable-next-line no-plusplus
    //     for (let i = 0; i < arr.length; i++) {
    //         total += arr[i]
    //     }
    //     return total
    // }
    const calculateTotalPrice = (extraItems: any[]) => {
        let totalPrice = 0
        if (extraItems && extraItems.length > 0) {
            extraItems.forEach(extraItem => {
                totalPrice += parseFloat(String(extraItem.total_price))
            })
        }
        return parseFloat(String(totalPrice))
    }

    const totalPrepList = calculateTotalPrice(extras?.prepList)
    const totalChargers = calculateTotalPrice(extras?.chargers)
    const totalBatteries = calculateTotalPrice(extras?.batteries)
    const totalBatteryAccessories = calculateTotalPrice(extras?.batteryAccessories)
    const totalAttachments = calculateTotalPrice(extras?.attachment)
    const totalExSolutions = calculateTotalPrice(extras?.exSolution)
    const totalSpecial = calculateTotalPrice(extras?.special)
    const totalHysterBatteries = calculateTotalPrice(extras?.hysterBatteries)
    const totalExtra =
        totalPrepList +
        totalChargers +
        totalBatteries +
        totalBatteryAccessories +
        totalAttachments +
        totalExSolutions +
        totalSpecial +
        totalHysterBatteries

    useEffect(() => {
        setTotalExtra(totalExtra)
    }, [totalExtra])

    return (
        <div className="rounded-md p-4 bg-white">
            <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                {t('api.extra_items')}
            </p>
            {extras && (
                <ExtraPricingComponent
                    attachment={extras.attachment || []}
                    batteries={extras.batteries || []}
                    batteryAccessories={extras.batteryAccessories || []}
                    chargers={extras.chargers || []}
                    exSolution={extras.exSolution || []}
                    prepList={extras.prepList || []}
                    special={extras.special || []}
                    hysterBatteries={extras.hysterBatteries || []}
                    totalPreplist={totalPrepList}
                    totalChargers={totalChargers}
                    totalBatteries={totalBatteries}
                    totalBatteryAccessories={totalBatteryAccessories}
                    totalAttachments={totalAttachments}
                    totalExSolutions={totalExSolutions}
                    totalSpecial={totalSpecial}
                    totalExtra={totalExtra}
                    totalHysterBattery={totalHysterBatteries}
                    viewOnly
                />
            )}
            {/* {extras?.chargers.length > 0 ? (
                <>
                    <p className="font-bold mt-2">{t('api.chargers')}</p>
                    <div className="w-full bg-slate-100 p-2 border-l-[3px] border-primary gap-2 text-blackWithTransparency mt-2 overflow-x-scroll">
                        <table className="w-full">
                            <thead>
                                <tr className="text-left">
                                    <th>{t(`fields.brand`)}</th>
                                    <th>{t(`fields.type`)}</th>
                                    <th>{t(`fields.battery_type`)}</th>
                                    <th>{t(`fields.price`)}</th>
                                    <th>{t(`fields.quantity`)}</th>
                                    <th>{t(`fields.total_price`)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {extras?.chargers?.map(
                                    ({ charger, quantity }: any, index: number) => (
                                        <tr className="" key={index}>
                                            <td>{charger[0].charger_brand}</td>
                                            <td>{charger[0].charger_type}</td>
                                            <td>{charger[0].battery_type}</td>
                                            <td>{charger[0].price}</td>
                                            <td>{quantity}</td>
                                            <td>{charger[0].price * quantity}</td>
                                        </tr>
                                    ),
                                )}
                            </tbody>
                        </table>
                        <p className="flex justify-between items-center pr-2 border-r-[3px] border-white mt-2">
                            <span className="font-bold">{t(`fields.chargers`)}:</span>
                            {String(
                                sumArrayElements(
                                    extras?.chargers?.map(({ total_price: totalPrice }: any) => {
                                        return Number(totalPrice)
                                    }),
                                ),
                            )}
                        </p>
                    </div>
                </>
            ) : null} */}
        </div>
    )
}
export default ExtraItemsDetail
