import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'antd'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/button'

/* eslint-disable react/no-unescaped-entities */
interface CopySiteSurveyModalProps {
    siteSurveyId: number
    modal: boolean
    label?: string
    title?: string
    loading?: boolean
    closeModal: () => void
}

interface CopySiteSurveyComponentInterface {
    label: string
    value: boolean
}

const initialCopySiteSurveyData: CopySiteSurveyComponentInterface[] = [
    {
        label: 'surrounding',
        value: true,
    },
    {
        label: 'load',
        value: false,
    },
    {
        label: 'truck model',
        value: false,
    },
    {
        label: 'offer options',
        value: true,
    },
]

const CopySiteSurveyModal = (props: CopySiteSurveyModalProps) => {
    const { modal, closeModal, label, title, loading, siteSurveyId } = props

    const navigate = useNavigate()
    const { t } = useTranslation()

    const [copySiteSurveyComponents, setCopySiteSurveyComponents] =
        useState<CopySiteSurveyComponentInterface[]>(initialCopySiteSurveyData)

    return (
        <Modal
            open={modal}
            onClose={closeModal}
            center
            showCloseIcon={false}
            classNames={{
                root: 'rounded-lg',
                modal: 'rounded-lg',
            }}
        >
            <div className="relative px-4 w-full max-w-md h-full md:h-auto">
                <div className="p-6 pt-0 text-center">
                    {title && (
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {title}
                        </h3>
                    )}
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {label || 'Are you sure you want to copy this site survey?'}
                    </h3>

                    <p className="my-4">{t('api.select_the_fields_to_copy')}</p>
                    <ul className="w-fit mx-auto">
                        {copySiteSurveyComponents.map(siteSurvey => (
                            <li className="flex gap-2 mb-2 items-center justify-start">
                                <Checkbox
                                    checked={siteSurvey.value}
                                    onChange={() => {
                                        setCopySiteSurveyComponents(
                                            copySiteSurveyComponents.map(copy =>
                                                copy.label === siteSurvey.label
                                                    ? {
                                                          label: siteSurvey.label,
                                                          value: !siteSurvey.value,
                                                      }
                                                    : copy,
                                            ),
                                        )
                                    }}
                                />
                                <p>{siteSurvey.label}</p>
                            </li>
                        ))}
                    </ul>
                    <div className="flex mt-4 justify-center gap-4">
                        <Button
                            label={t('api.yes_i_am_sure')}
                            onClick={() =>
                                navigate({
                                    pathname: `copy/${siteSurveyId}`,
                                    search: `?surrounding=${
                                        copySiteSurveyComponents.find(
                                            c => c.label === 'surrounding',
                                        )?.value
                                    }&load=${
                                        copySiteSurveyComponents.find(c => c.label === 'load')
                                            ?.value
                                    }&truck-model=${
                                        copySiteSurveyComponents.find(
                                            c => c.label === 'truck model',
                                        )?.value
                                    }&offer-options=${
                                        copySiteSurveyComponents.find(
                                            c => c.label === 'offer options',
                                        )?.value
                                    }`,
                                })
                            }
                            variant="primary"
                            isLoading={loading}
                            disabled={loading}
                        />
                        <Button
                            label={t('api.no_and_cancel')}
                            onClick={() => closeModal()}
                            variant="ghost"
                            isLoading={loading}
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CopySiteSurveyModal
