import Header from 'components/header'
import { Outlet } from 'react-router-dom'
import Footer from '../modules/shared/components/Footer'

function DashboardLayout() {
    return (
        <div className="bg-inputBorder flex flex-col justify-between gap-4 relative h-screen overflow-hidden">
            <div className="w-full z-100">
                <Header />
            </div>
            <div className="container mx-auto overflow-auto flex flex-col gap-4 flex-1 px-2 md:px-0 w-full ">
                <Outlet />
            </div>
            <div className="flex items-center justify-center w-full">
                <Footer />
            </div>
        </div>
    )
}

export default DashboardLayout
