import { t } from 'i18next'
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5'
import { Link } from 'react-router-dom'

export const repliedRequestColumn = [
    {
        name: t('api.requested_by'),
        left: true,
        selector: (row: any) => row.name,
    },
    {
        name: t('api.requested_discount_percentage'),
        selector: (row: any) => row.requested_discount_percentage,
    },
    {
        name: t('api.approved_discount_percentage'),
        selector: (row: any) => row.provided_discount,
    },
    {
        name: t('api.configuration_name'),
        selector: (row: any) => row.configuration_name,
    },
    {
        name: t('api.comment'),
        selector: (row: any) => row.comment,
    },
    {
        name: t('api.requested_at'),
        selector: (row: any) => row.requested_at,
    },
    {
        name: t('api.replied_by'),
        selector: (row: any) => row.replied_by_name,
    },
    {
        name: t('api.replied_at'),
        selector: (row: any) => row.published_at,
    },
    {
        name: t('api.configuration_link'),
        cell: (row: any) => (
            <Link className="red-link" to={`/configuration/${row.requested_configuration_id}`}>
                {t('api.see_configuration')}
            </Link>
        ),
    },
]
export const RequestedNotificationColumn = [
    {
        name: t('api.replied_by'),
        left: true,
        selector: (row: any) => row.name,
    },
    {
        name: t('api.reply_status'),
        center: true,
        cell: (row: any) =>
            row.is_approved ? (
                <IoCheckmarkCircle className="text-green-500" size={24} />
            ) : (
                <IoCloseCircle className="text-red-500" size={24} />
            ),
    },
    {
        name: t('api.requested_discount_percentage'),
        center: true,
        selector: (row: any) => row.requested_discount_percentage,
    },
    {
        name: t('api.approved_discount'),
        center: true,
        selector: (row: any) => row.provided_discount,
    },
    {
        name: t('api.configuration_name'),
        selector: (row: any) => row.configuration_name,
    },
    {
        name: t('api.comment'),
        selector: (row: any) => row.comment,
    },
    {
        name: t('api.requested_at'),
        selector: (row: any) => row.requested_at,
    },
    {
        name: t('api.replied_ay'),
        selector: (row: any) => row.published_at,
    },
    {
        name: t('api.configuiration_link'),
        cell: (row: any) => (
            <Link className="red-link" to={`/configuration/${row.requested_configuration_id}`}>
                {t('api.see_configuration')}
            </Link>
        ),
    },
]
