/* eslint-disable react/no-unused-prop-types */
/* eslint-disable camelcase */
import { useState } from 'react'

import { FiDownloadCloud, FiPlus, FiUploadCloud } from 'react-icons/fi'
import { BsTrashFill } from 'react-icons/bs'
import { GrOverview } from 'react-icons/gr'
import { AiOutlineEdit, AiOutlineShoppingCart, AiOutlineUnorderedList } from 'react-icons/ai'

import ButtonLoader from 'components/buttonLoader'
import DeleteModal from 'components/modal/deleteModal'
import { errorToast, successToast } from 'utils/toasterUtil'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'modules/auth/context/useAuth'
import classNames from 'classnames'
import { SiMatrix } from 'react-icons/si'
import { useTranslation } from 'react-i18next'
import { MdRequestPage } from 'react-icons/md'
import { IoNewspaperOutline } from 'react-icons/io5'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { BiText } from 'react-icons/bi'
import { Modal } from 'antd'
import {
    approveQuote,
    deleteQuote,
    getExtraByQuoteId,
    orderQuote,
    orderQuoteData,
    requestQuoteApproval,
    updateQuoteTemplate,
} from '../queries/quotes'
import QuoteGenericQuestion from './QuoteGenericQuestion'
import ExtraItems from './ExtraItems'

const QuoteDetailActions = (props: {
    quoteDetail: any
    templates: any
    refetch: () => void
    configurationList?: any
    closeModal: () => void
}) => {
    const { quoteDetail, templates, refetch, closeModal } = props

    const {
        user,
        userExtra: { need_approval, is_dealer, project_overview_visible, role, role_id },
    } = useAuth()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [orderModal, setOrderModal] = useState<boolean>(false)
    const { t } = useTranslation()
    const [approveRequest, setApproveRequest] = useState<boolean>(false)
    const [requestApproval, setRequestApproval] = useState<boolean>(false)
    const [orderQuoteModal, setOrderQuote] = useState<boolean>(false)
    const [quoteStatusModal, setQuoteStatusModal] = useState<boolean>(false)
    const [showExtraModal, setShowExtraModal] = useState<boolean>(false)
    const [, setQuoteOldStatus] = useState<string>(quoteDetail?.status || '')
    // const [, setInputTouched] = useState<boolean>(false)

    const [quoteStatus, setQuoteStatus] = useState<any>({})

    // const editQuoteAction = useMutation(
    //     ['quoteEdit'],
    //     (quotationName: string) =>
    //         editQuote(quoteDetail.id, {
    //             configurations: configurationList.map((configuration: any) => ({
    //                 label: configuration.name,
    //                 value: configuration.id,
    //             })),
    //             quotation_name: quotationName,
    //             template: quoteDetail.template_id,
    //             user_id: quoteDetail.user_id,
    //             quotation_for: quoteDetail.quotation_for,
    //             status: quoteDetail.status,
    //         }),
    //     {
    //         onSuccess: (data: any) => {
    //             toast.success(
    //                 data?.message || `${quoteDetail.quotation_name} updated successfully`,
    //                 successToast,
    //             )
    //             setInputTouched(false)
    //         },
    //         onError: (error: any) => {
    //             toast(
    //                 error?.message || `Failed to update quote ${quoteDetail.quotation_name}`,
    //                 errorToast,
    //             )
    //         },
    //     },
    // )

    const deleteOrderAction = useMutation(['deleteOrder'], () => deleteQuote(quoteDetail.id), {
        onSuccess: (data: any) => {
            toast.success(
                data?.message || `${quoteDetail.quotation_name} ${t('message.delete_success')}`,
                successToast,
            )
            navigate('/quotes')
        },
        onError: (error: any) => {
            toast(
                error?.message || `${t('message.failed_to_delete')} ${quoteDetail.quotation_name}`,
                errorToast,
            )
        },
    })

    const approveQuoteRequest = useMutation(
        ['approveQuoteRequest'],
        () => approveQuote(quoteDetail.id, user.id),
        {
            onSuccess: (data: any) => {
                toast(
                    data?.message || `${t('message.approved_quote')} ${quoteDetail.quotation_name}`,
                    successToast,
                )
                refetch()
            },
            onError: (error: any) => {
                toast(
                    error?.message ||
                        `${t('message.failed_to_approve')} ${quoteDetail.quotation_name}`,
                    errorToast,
                )
            },
        },
    )

    const requestQuoteApprove = useMutation(
        ['requestQuoteApprove'],
        () => requestQuoteApproval(quoteDetail.id, user.id),
        {
            onSuccess: (data: any) => {
                toast(
                    data?.message ||
                        `${t('message.approval_sent_for')} ${quoteDetail.quotation_name}`,
                    successToast,
                )
                refetch()
                setRequestApproval(false)
            },
            onError: (error: any) => {
                toast(error?.message || t('message.approval_sent_failed'), errorToast)
            },
        },
    )

    const orderQuoteRequest = useMutation(['orderQuote'], () => orderQuote(quoteDetail.id), {
        onSuccess: (data: any) => {
            toast(
                data?.message ||
                    ` ${t('api.message')} ${quoteDetail.quotation_name} ${t(
                        'message.ordered_successs',
                    )}`,
                successToast,
            )
            navigate('/order')
        },
        onError: (error: any) => {
            toast(
                error?.message ||
                    `${t('message.failed_order_quote')} ${quoteDetail.quotation_name}`,
                errorToast,
            )
        },
    })

    // const orderOpportunity = useMutation(
    //     ['orderOppurnity'],
    //     (item: number | string) => updateOrderOpportunity(quoteDetail.id, item),
    //     {
    //         onSuccess: (data: any) => {
    //             toast(
    //                 data?.message ||
    //                     ` Order oppurnity for ${quoteDetail.quotation_name} is updated`,
    //             )
    //             refetch()
    //         },
    //         onError: (error: any) => {
    //             toast(
    //                 error?.message ||
    //                     `Failed update order oppurnity of ${quoteDetail.quotation_name}`,
    //                 errorToast,
    //             )
    //         },
    //     },
    // )

    const updateTemplate = useMutation(
        ['template'],
        (value: string) => updateQuoteTemplate(quoteDetail.id, value),
        {
            onSuccess: (data: any) => {
                toast(
                    data?.message ||
                        ` ${t('api.template_for')} ${quoteDetail.quotation_name} ${t(
                            'api.has_been_updated',
                        )}`,
                    successToast,
                )
                refetch()
            },
            onError: (error: any) => {
                toast(
                    error?.message ||
                        `${t('message.failed_to_update_template_for')} ${
                            quoteDetail.quotation_name
                        }`,
                    errorToast,
                )
            },
        },
    )

    const orderThisQuote = useMutation(
        ['orderThisQuote'],
        () =>
            orderQuoteData(
                {
                    configuration_id: quoteDetail.configuration_id,
                    quote_id: quoteDetail.id,
                    quotation_name: quoteDetail.quotation_name,
                },
                user.id,
            ),
        {
            onSuccess: (data: any) => {
                toast(
                    data?.message ||
                        `${t('api.quote')} ${quoteDetail.quotation_name} ${t(
                            'api.is_ordered_success',
                        )}`,
                    successToast,
                )
                queryClient.removeQueries(['orderList'])
                navigate('/order')
            },
            onError: (error: any) => {
                toast(
                    error?.message ||
                        `${t('api.failed_to_update_order_opportunity')} ${
                            quoteDetail.quotation_name
                        }`,
                    errorToast,
                )
            },
        },
    )

    // const updateExpectedDeliveryDate = useMutation(
    //     ['expectedDeliveryDate'],
    //     (value: string) => updateExpectedDiscount(quoteDetail.id, value),
    //     {
    //         onSuccess: (data: any) => {
    //             toast(
    //                 data?.message ||
    //                     ` Expected devliery date has been updated for ${quoteDetail.quotation_name}`,
    //             )
    //             refetch()
    //         },
    //         onError: (error: any) => {
    //             toast(
    //                 error?.message ||
    //                     `Failed to update exprected delivery date ${quoteDetail.quotation_name}`,
    //                 errorToast,
    //             )
    //         },
    //     },
    // )

    const handleOrder = () => {
        orderThisQuote.mutate()
    }

    const handleDelete = () => {
        deleteOrderAction.mutate()
        setDeleteModal(false)
        refetch()
    }

    const handleApproveRequest = () => {
        approveQuoteRequest.mutate()
        setApproveRequest(false)
        refetch()
    }

    const handleRequestApproval = async () => {
        requestQuoteApprove.mutate()
    }

    const handleQuoteOrder = () => {
        orderQuoteRequest.mutate()
        setOrderQuote(false)
        refetch()
    }

    // const handleOrderOppurnityChanged = (item: any) => {
    //     orderOpportunity.mutate(item)
    //     refetch()
    // }

    // const handleQuoteStatusChangedOpenModal = (value: any) => {
    //     setQuoteStatusModal(value !== 'ordered')
    //     setQuoteStatus({
    //         quoteId: quoteDetail.id,
    //         oldStatus: quoteDetail.status,
    //         newStatus: value,
    //     })
    // }

    const handleQuoteStatusChangedCloseModal = () => {
        setQuoteStatusModal(false)
        setQuoteStatus({
            quoteId: '',
            oldStatus: '',
            newStatus: '',
        })
        setQuoteOldStatus(quoteDetail.status)
    }

    return (
        <>
            <div className="px-4 flex flex-col gap-4 overflow-y-scroll">
                <h2 className=" text-secondary border-primary mb-2 font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {t(`api.quote_menu`)}
                </h2>
                {is_dealer || !need_approval ? (
                    <>
                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => {
                                navigate(`/quotes/${quoteDetail.id}/download-document`)
                                closeModal()
                            }}
                        >
                            <FiDownloadCloud size={25} />
                            <span>{t('api.download_document')}</span>
                        </div>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        />
                        {/* <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => {
                                downloadExcelDoc(quoteDetail.quotation_file_xls)
                                closeModal()
                            }}
                        >
                            <BsDownload size={25} />
                            <span>{t('api.download_excel_sheet')}</span>
                        </div>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        /> */}
                    </>
                ) : null}

                {/* eslint-disable-next-line no-nested-ternary */}
                {/* {quoteDetail.approve_request === 0 ? (
                    // eslint-disable-next-line no-nested-ternary
                    quoteDetail.user_id === user.id ? (
                        !is_dealer && need_approval ? (
                            <>
                                <div
                                    className={classNames(
                                        'flex items-center gap-4 cursor-pointer hover:text-primary',
                                        quoteDetail.approve_pending === 0
                                            ? 'pointer-events-auto'
                                            : 'pointer-events-none',
                                    )}
                                    onClick={() => {
                                        setRequestApproval(!approveRequest)
                                        closeModal()
                                    }}
                                >
                                    <FcAcceptDatabase size={25} />
                                    {quoteDetail.approve_pending === 0 ? (
                                        <span>{t('api.request_approval')}</span>
                                    ) : (
                                        <span>{t('api.approval_pending')}</span>
                                    )}
                                </div>
                                <hr
                                    style={{
                                        color: '#e6e6e6',
                                    }}
                                />
                            </>
                        ) : null
                    ) : (
                        <>
                            <div
                                className={classNames(
                                    'flex items-center gap-4 cursor-pointer hover:text-primary',
                                )}
                                onClick={() => {
                                    setApproveRequest(!approveRequest)
                                    closeModal()
                                }}
                            >
                                <FcAcceptDatabase size={25} />
                                <span>{t('api.approve_request')}</span>
                            </div>
                            <hr
                                style={{
                                    color: '#e6e6e6',
                                }}
                            />
                        </>
                    )
                ) : null} */}
                {/* <div */}
                {/*    className="flex mt-4 items-center gap-4 cursor-pointer hover:text-primary" */}
                {/*    onClick={() => downloadWordDoc(quoteDetail.quotation_file_doc)} */}
                {/* > */}
                {/*    <FiDownloadCloud size={24} /> */}
                {/*    <span>Download Documents</span> */}
                {/* </div> */}
                {/* <hr */}
                {/*    style={{ */}
                {/*        color: '#e6e6e6', */}
                {/*    }} */}
                {/* /> */}
                <div
                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                    onClick={() => {
                        navigate('upload-document')
                        closeModal()
                    }}
                >
                    <FiUploadCloud size={24} />
                    <span>{t('api.upload_document')}</span>
                </div>
                <hr
                    style={{
                        color: '#e6e6e6',
                    }}
                />
                <div
                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                    onClick={() => {
                        navigate('rental-contract')
                        closeModal()
                    }}
                >
                    <IoNewspaperOutline size={24} />
                    <span>{t('api.rental_contract')}</span>
                </div>
                <hr
                    style={{
                        color: '#e6e6e6',
                    }}
                />
                <div
                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                    onClick={() => {
                        navigate('free-text')
                        closeModal()
                    }}
                >
                    <BiText size={24} />
                    <span>{t('api.free_text')}</span>
                </div>
                <hr
                    style={{
                        color: '#e6e6e6',
                    }}
                />
                <div
                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                    onClick={() => {
                        navigate('requirement-matrix')
                        closeModal()
                    }}
                >
                    <SiMatrix size={24} />
                    <span>{t('api.requirement_matrix')}</span>
                </div>
                <hr
                    style={{
                        color: '#e6e6e6',
                    }}
                />
                {/* <div */}
                {/*    className="flex items-center gap-4 cursor-pointer hover:text-primary" */}
                {/*    onClick={() => downloadExcelDoc(quoteDetail.quotation_file_xls)} */}
                {/* > */}
                {/*    <BsDownload size={24} /> */}
                {/*    <span>Download Excel Sheet</span> */}
                {/* </div> */}
                {/* <hr */}
                {/*    style={{ */}
                {/*        color: '#e6e6e6', */}
                {/*    }} */}
                {/* /> */}

                {/* quote name */}
                {role !== 'Sales management' ? (
                    <>
                        <a
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            href={`/quotes/${quoteDetail.id}/edit`}
                            onClick={() => closeModal()}
                        >
                            <AiOutlineEdit size={25} />
                            <span>{t('api.edit_quote')}</span>
                        </a>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        />
                    </>
                ) : null}

                {/* <div className="flex flex-col gap-4">
                    <span>Order Opportunity</span>
                    <Select
                        defaultValue={quoteDetail?.order_opportunity || 0}
                        onChange={(option: any) => handleOrderOppurnityChanged(option)}
                        className="rounded-lg"
                        size={size}
                    >
                        {[
                            {
                                label: 0,
                                value: 0,
                            },
                            {
                                label: 10,
                                value: 10,
                            },
                            {
                                label: 30,
                                value: 30,
                            },
                            {
                                label: 70,
                                value: 70,
                            },
                            {
                                label: 90,
                                value: 90,
                            },
                        ].map((option: any) => (
                            <Select.Option value={option.value} key={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <hr
                style={{
                            color: '#e6e6e6',
                        }}
                />
                <div className="flex flex-col gap-4">
                    <span>Expected Delivery Data</span>
                    {!quoteDetail.expected_delivery_date ? (
                        <DatePicker
                            onChange={(date: any) =>
                                updateExpectedDeliveryDate.mutate(moment(date).format('YYYY-MM-DD'))
                            }
                            size={size}
                            className={roundedClass}
                            style={{
                                width: '100%',
                            }}
                        />
                    ) : (
                        <DatePicker
                            defaultValue={moment(quoteDetail.expected_delivery_date, dateFormat)}
                            className={roundedClass}
                            onChange={(date: any) =>
                                updateExpectedDeliveryDate.mutate(moment(date).format('YYYY-MM-DD'))
                            }
                            size={size}
                            style={{
                                width: '100%',
                            }}
                        />
                    )}
                </div>
                <hr
                style={{
                            color: '#e6e6e6',
                        }}
                />
                <div className="flex flex-col gap-4">
                    <span>Quote Status</span>
                    <Select
                        value={quoteOldStatus}
                        defaultValue={quoteOldStatus}
                        onChange={(value: string) => handleQuoteStatusChangedOpenModal(value)}
                        className="rounded-lg"
                        size={size}
                    >
                        {[
                            {
                                label: 'pending',
                                value: 'pending',
                            },
                            {
                                label: 'lost',
                                value: 'lost',
                            },
                            {
                                label: 'postponed',
                                value: 'postponed',
                            },
                            {
                                label: 'ordered',
                                value: 'ordered',
                            },
                            {
                                label: 'cancelled',
                                value: 'cancelled',
                            },
                        ].map((option: any) => (
                            <Select.Option value={option.value} key={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <hr
                style={{
                            color: '#e6e6e6',
                        }}
                /> */}
                <a
                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                    href={`/quotes/${quoteDetail.id}`}
                    onClick={() => closeModal()}
                >
                    <AiOutlineUnorderedList size={25} />
                    <span>{t('api.configuration_list')}</span>
                </a>
                <hr
                    style={{
                        color: '#e6e6e6',
                    }}
                />
                {/* {higher_discount_request ? (
                    <>
                        <a
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            href={`/quotes/${quoteDetail.id}/multiple-discount-request`}
                        >
                            <GrMultiple size={25} />
                            <span>{t('api.multiple_discount_request')}</span>
                        </a>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        />
                    </>
                ) : null} */}
                <a
                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                    href={`/quotes/${quoteDetail.id}/overview?type=project-overview`}
                    onClick={() => closeModal()}
                >
                    <GrOverview size={25} />
                    <span>{t('api.project_overview')}</span>
                </a>
                <hr
                    style={{
                        color: '#e6e6e6',
                    }}
                />

                <Link
                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                    to={`/quotes/${quoteDetail.id}/overview?type=pricing-request-approval`}
                    onClick={() => closeModal()}
                >
                    <MdRequestPage size={25} />
                    <span>{t('api.pricing_request_approval')}</span>
                </Link>
                <hr
                    style={{
                        color: '#e6e6e6',
                    }}
                />

                <div
                    className={classNames(
                        'flex items-center gap-4 cursor-pointer hover:text-primary',
                    )}
                    onClick={() => {
                        setOrderModal(!orderModal)
                        closeModal()
                    }}
                >
                    <AiOutlineShoppingCart size={25} />
                    <span>{t('api.order_this_quote')}</span>
                </div>
                <hr
                    style={{
                        color: '#e6e6e6',
                    }}
                />

                <div
                    className={classNames(
                        'flex items-center gap-4 cursor-pointer hover:text-primary',
                    )}
                    onClick={() => {
                        setShowExtraModal(!showExtraModal)
                        closeModal()
                    }}
                >
                    <FiPlus size={25} />
                    <span>{t('api.show_extra')}</span>
                </div>
                <hr
                    style={{
                        color: '#e6e6e6',
                    }}
                />

                <div
                    className="flex items-center justify-start gap-4 cursor-pointer hover:text-primary	"
                    onClick={() => {
                        setDeleteModal(!deleteModal)
                        closeModal()
                    }}
                >
                    {deleteOrderAction.isLoading ? (
                        <ButtonLoader />
                    ) : (
                        <>
                            <BsTrashFill size={25} />
                            <span>{t('api.delete_quote')}</span>
                        </>
                    )}
                </div>
            </div>
            <DeleteModal
                modal={orderModal}
                closeModal={() => setOrderModal(!orderModal)}
                label={t('api.order_quote_title')}
                title={`${t('api.order')} ${quoteDetail.quotation_name}`}
                onConfirm={handleOrder}
            />

            <DeleteModal
                modal={deleteModal}
                closeModal={() => setDeleteModal(!deleteModal)}
                label={t('api.delete_quote_title')}
                title={`${t('api.delete')} ${quoteDetail.quotation_name}`}
                onConfirm={handleDelete}
            />

            <DeleteModal
                modal={approveRequest}
                closeModal={() => setApproveRequest(!approveRequest)}
                label={t('api.approve_quote_title')}
                title={`${t('api.approve')} ${quoteDetail.quotation_name}`}
                onConfirm={handleApproveRequest}
            />

            <DeleteModal
                modal={requestApproval}
                closeModal={() => setRequestApproval(!requestApproval)}
                label={t('api.request_approval_quote_title')}
                title={`${t('api.request_approval_for')} ${quoteDetail.quotation_name}`}
                onConfirm={handleRequestApproval}
            />

            <DeleteModal
                modal={orderQuoteModal}
                closeModal={() => setOrderQuote(!orderQuote)}
                label={t('api.order_quote_title')}
                title={`${t('api.order')} ${quoteDetail.quotation_name}`}
                onConfirm={handleQuoteOrder}
            />
            <Modal
                title={t('api.extra')}
                open={showExtraModal}
                centered
                footer={null}
                className="h-[90vh]"
                width={1000}
                onCancel={() => setShowExtraModal(false)}
            >
                <ExtraItems quoteId={quoteDetail.id} />
            </Modal>
            <QuoteGenericQuestion
                openModal={quoteStatusModal}
                quoteId={quoteDetail.id}
                oldStatus={quoteDetail.status}
                newStatus={quoteStatus.newStatus}
                refetch={() => refetch()}
                callback={() => handleQuoteStatusChangedCloseModal()}
            />
        </>
    )
}

export default QuoteDetailActions
