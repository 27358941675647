/* eslint-disable @typescript-eslint/no-empty-function */
import Loading from 'components/loading'
import Select, { Options } from 'components/select'
import moment from 'moment'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { numberFormater } from 'utils/numberFormater'
import GetPricingStatusText from 'utils/pricingStatus'
import { getPriceLogByDate, getPriceLogVersionTimeByConfigurationId } from '../queries/log'

const PriceLogs = () => {
    const { t } = useTranslation()
    const { configurationId } = useParams()
    const [timeStamp, setTimeStamp] = useState<Options[]>([])
    const [leftDateDropdown, setLeftDateDropdown] = useState<string>()
    const [rightDateDropdown, setRightDateDropdown] = useState<string>()
    const [leftData, setLeftData] = useState<any>()
    const [rightData, setRightData] = useState<any>()
    const investmentGroupOptions = [
        {
            min_value: 0,
            max_value: 10000,
        },
        {
            min_value: 10000,
            max_value: 45000,
        },
        {
            min_value: 45000,
            max_value: 100000,
        },
        {
            min_value: 100000,
            max_value: 200000,
        },
        {
            min_value: 200000,
            max_value: 0,
        },
    ]

    const [selectedDurationOfContract, setSelectedDurationOfContract] = useState<number>(0)
    const getLogData = useMutation(
        ['priceLogs'],
        (params: { date: string; direction: 'left' | 'right' }) =>
            getPriceLogByDate(String(configurationId), params.date),
        {
            onSuccess(data, variables) {
                if (variables.direction === 'left') {
                    setLeftData(data.data[0])
                    if (data.data[0]?.monthly_configuration_price) {
                        setSelectedDurationOfContract(
                            data.data[0]?.monthly_configuration_price[0]?.duration_of_contract,
                        )
                    }
                } else {
                    setRightData(data.data[0])
                }
            },
            onError: (error: Error) => {
                toast.error(String(t(error.message)) || t('message.error'))
            },
        },
    )

    const { isLoading: timeLoading, isRefetching } = useQuery(
        ['priceLogs'],
        () => getPriceLogVersionTimeByConfigurationId(String(configurationId)),
        {
            enabled: !!configurationId,
            onSuccess: data => {
                if (data?.data) {
                    setTimeStamp(
                        data.data.map((x: string) => ({
                            label: moment(x).format('YYYY-MM-DD hh:mm:ss A'),
                            value: x,
                        })),
                    )
                }
                if (data?.data && data.data.length > 0) {
                    setLeftDateDropdown(data.data[0])
                    getLogData.mutate({
                        date: data.data[0],
                        direction: 'left',
                    })
                }
                if (data?.data && data.data.length > 1) {
                    setRightDateDropdown(data.data[1])
                    getLogData.mutate({
                        date: data.data[1],
                        direction: 'right',
                    })
                }
            },
            onError: (error: Error) => {
                toast.error(String(t(error.message)) || t('message.error'))
            },
        },
    )

    const handleLeftDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setLeftDateDropdown(e.target.value)
        getLogData.mutate({
            date: e.target.value,
            direction: 'left',
        })
    }

    const handleRightDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setRightDateDropdown(e.target.value)
        getLogData.mutate({
            date: e.target.value,
            direction: 'right',
        })
    }

    if (getLogData.isLoading || timeLoading || isRefetching) {
        return (
            <tr>
                <td colSpan={3} className="flex justify-center items-center">
                    <Loading />
                </td>
            </tr>
        )
    }

    return (
        <div className="flex flex-col gap-2">
            <table className="border border-gray rounded min-w-[600px] max-w-4xl">
                <thead className="bg-gray-100">
                    <tr>
                        <th className="border border-gray p-2 text-start">{t('api.attributes')}</th>
                        <th className="border border-gray p-2 w-48">
                            <Select
                                name="leftDateDropdown"
                                options={timeStamp}
                                showPlaceHolder
                                placeHolderOption={t('api.select_date')}
                                // isLoading={timeLoading || isRefetching}
                                value={leftDateDropdown}
                                onChange={handleLeftDateChange}
                            />
                        </th>
                        <th className="border border-gray p-2 w-48">
                            <Select
                                options={timeStamp}
                                showPlaceHolder
                                placeHolderOption={t('api.select_date')}
                                // isLoading={timeLoading || isRefetching}
                                value={rightDateDropdown}
                                name="rightDateDropdown"
                                onChange={handleRightDateChange}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.price_status')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            <GetPricingStatusText pendingStatus={leftData?.status} />
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            <GetPricingStatusText pendingStatus={rightData?.status} />
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.list_price_hyster')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(leftData?.novo_list_price)}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(rightData?.novo_list_price)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">
                            {t('fields.standard_price_rate')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.standard_discount_percentage || 0} %
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.standard_discount_percentage || 0} %
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">
                            {t('fields.special_price_request')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.special_price_rate ||
                                leftData?.standard_discount_percentage ||
                                0}{' '}
                            %
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.special_price_rate ||
                                rightData?.standard_discount_percentage ||
                                0}{' '}
                            %
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.vpg')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.vpg_percentage} %
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.vpg_percentage} %
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.vpg_amount')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(leftData?.vpg_amount)}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(rightData?.vpg_amount)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.bridging_machine')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(leftData?.bridging_machine_amount)}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(rightData?.bridging_machine_amount)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">
                            {t('fields.financial_support_amount')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(leftData?.financial_support_amount)}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(rightData?.financial_support_amount)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.data_logger')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(leftData?.data_logger)}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(rightData?.data_logger)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.net_all_cost')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(leftData?.net_all_cost)}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(rightData?.net_all_cost)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.profit')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.profit_percentage || 0} %
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.profit_percentage || 0} %
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.quote_price')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(leftData?.novo_quote_price || 0)}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(rightData?.novo_quote_price || 0)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.trade_in_value')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(leftData?.trade_in_value || 0)}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(rightData?.trade_in_value || 0)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">
                            {t('fields.total_after_trade_in')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(leftData?.quote_minus_trade_in || 0)}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(rightData?.quote_minus_trade_in || 0)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">
                            {t('fields.additional_residual_percentage')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.additional_residual_percentage} %
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.additional_residual_percentage} %
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.investment_group')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            <Select
                                options={investmentGroupOptions?.map((investmentGroup: any) => ({
                                    label: `${numberFormater(investmentGroup?.min_value)} ${
                                        investmentGroup?.max_value === 0
                                            ? ' +'
                                            : ` - ${numberFormater(investmentGroup?.max_value)}`
                                    }`,
                                    value: parseFloat(String(investmentGroup?.min_value)),
                                }))}
                                label={t('fields.investment_group')}
                                name="investmentGroup"
                                showPlaceHolder
                                placeHolderOption={t('fields.select_investment_group')}
                                selectWidth="w-[14rem]"
                                value={leftData?.min_investment_group || 0}
                                disabled
                                noLabel
                            />
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            <Select
                                options={investmentGroupOptions?.map((investmentGroup: any) => ({
                                    label: `${numberFormater(investmentGroup?.min_value)} ${
                                        investmentGroup?.max_value === 0
                                            ? ' +'
                                            : ` - ${numberFormater(investmentGroup?.max_value)}`
                                    }`,
                                    value: parseFloat(String(investmentGroup?.min_value)),
                                }))}
                                label={t('fields.investment_group')}
                                name="investmentGroup"
                                showPlaceHolder
                                placeHolderOption={t('fields.select_investment_group')}
                                selectWidth="w-[14rem]"
                                value={rightData?.min_investment_group || 0}
                                disabled
                                noLabel
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">
                            {t('fields.discount_service_all_in')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.discount_service_all_in} %
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.discount_service_all_in} %
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">
                            {t('fields.discount_service_pm')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.discount_service_pm} %
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.discount_service_pm} %
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.add_tyre')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.add_tyre ? t('api.yes') : t('api.no')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.add_tyre ? t('api.yes') : t('api.no')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.add_battery')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.add_battery ? t('api.yes') : t('api.no')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.add_battery ? t('api.yes') : t('api.no')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.add_attachment')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.add_attachment ? t('api.yes') : t('api.no')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.add_attachment ? t('api.yes') : t('api.no')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.add_specials')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.add_specials ? t('api.yes') : t('api.no')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.add_specials ? t('api.yes') : t('api.no')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.bmwt')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.bmwt ? t('api.yes') : t('api.no')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.bmwt ? t('api.yes') : t('api.no')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.bmwt_battery')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.add_bmwt_battery ? t('api.yes') : t('api.no')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.add_bmwt_battery ? t('api.yes') : t('api.no')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.add_ex_solution')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.add_ex_solutions ? t('api.yes') : t('api.no')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.add_ex_solutions ? t('api.yes') : t('api.no')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.category')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            <Select
                                disabled
                                options={[
                                    {
                                        label: t('api.light'),
                                        value: 1,
                                    },
                                    {
                                        label: t('api.medium'),
                                        value: 2,
                                    },
                                    {
                                        label: t('api.heavy'),
                                        value: 3,
                                    },
                                ]}
                                label={t('fields.category')}
                                name="category"
                                placeHolderOption={t('fields.select_category')}
                                selectWidth="w-[14rem]"
                                value={leftData?.category}
                                noLabel
                            />
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            <Select
                                noLabel
                                disabled
                                options={[
                                    {
                                        label: t('api.light'),
                                        value: 1,
                                    },
                                    {
                                        label: t('api.medium'),
                                        value: 2,
                                    },
                                    {
                                        label: t('api.heavy'),
                                        value: 3,
                                    },
                                ]}
                                label={t('fields.category')}
                                name="category"
                                placeHolderOption={t('fields.select_category')}
                                selectWidth="w-[14rem]"
                                value={rightData?.category}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.battery_service')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.battery_service} {t('api.times')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.battery_service} {t('api.times')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.no_of_batteries')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {leftData?.number_of_batteries}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {rightData?.number_of_batteries}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>{t('api.pricing_data')}</td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.select_period')}</td>
                        <td colSpan={2} className="border border-gray p-2 w-48 text-end">
                            <Select
                                noLabel
                                options={
                                    leftData?.monthly_configuration_price
                                        ? leftData?.monthly_configuration_price?.map(
                                              (monthlyConfigurationPrice: any) => ({
                                                  label: `${
                                                      monthlyConfigurationPrice.duration_of_contract
                                                  } ${t('api.month')}`,
                                                  value: monthlyConfigurationPrice.duration_of_contract,
                                              }),
                                          )
                                        : []
                                }
                                label={t('fields.selected_duration_of_contract')}
                                name="selected_duration_of_contract"
                                placeHolderOption={t('fields.select_selected_duration_of_contract')}
                                selectWidth="w-[14rem]"
                                onChange={e => {
                                    setSelectedDurationOfContract(Number(e.target.value))
                                }}
                                value={selectedDurationOfContract}
                            />
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.financial_part')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.financial_part || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                rightData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.financial_part || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">
                            {t('fields.service_basic_truck')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.basic_truck || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                rightData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.basic_truck || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.service_preplist')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.service_extra_preplist || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                rightData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.service_extra_preplist || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.service_tyre')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.service_tyre || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                rightData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.service_tyre || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.service_battery')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.service_battery || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.service_battery || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.total_service')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.total_service || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                rightData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.total_service || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.insurance_cost')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.insurance_cost || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                rightData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.insurance_cost || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td colSpan={3}>{t('api.yearly_maintenance_cost')}</td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.bmwt_truck')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                Number(
                                    leftData?.monthly_configuration_price?.find(
                                        (x: any) =>
                                            x.duration_of_contract === selectedDurationOfContract,
                                    )?.total_price_per_year_inclusive_bmwt || 0,
                                ) -
                                    Number(
                                        leftData?.monthly_configuration_price?.find(
                                            (x: any) =>
                                                x.duration_of_contract ===
                                                selectedDurationOfContract,
                                        )?.total_price_per_year_ex_bmwt || 0,
                                    ),
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                Number(
                                    rightData?.monthly_configuration_price?.find(
                                        (x: any) =>
                                            x.duration_of_contract === selectedDurationOfContract,
                                    )?.total_price_per_year_inclusive_bmwt || 0,
                                ) -
                                    Number(
                                        rightData?.monthly_configuration_price?.find(
                                            (x: any) =>
                                                x.duration_of_contract ===
                                                selectedDurationOfContract,
                                        )?.total_price_per_year_ex_bmwt || 0,
                                    ),
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.bmwt_battery')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.bmwt_battery || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                rightData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.bmwt_battery || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">
                            {t('fields.preventive_maintenance')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.preventive_maintenance || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                rightData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.preventive_maintenance || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">
                            {t('fields.battery_service_cost')}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                leftData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.batteries_service_cost || '-',
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                rightData?.monthly_configuration_price?.find(
                                    (x: any) =>
                                        x.duration_of_contract === selectedDurationOfContract,
                                )?.batteries_service_cost || '-',
                            )}
                        </td>
                    </tr>
                    <tr className="border border-gray p-2">
                        <td className="border border-gray p-2">{t('fields.total_cost')}</td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                Number(
                                    leftData?.monthly_configuration_price?.find(
                                        (x: any) =>
                                            x.duration_of_contract === selectedDurationOfContract,
                                    )?.total_price_per_year_inclusive_bmwt || 0,
                                ) +
                                    Number(
                                        leftData?.monthly_configuration_price?.find(
                                            (x: any) =>
                                                x.duration_of_contract ===
                                                selectedDurationOfContract,
                                        )?.bmwt_battery || 0,
                                    ),
                            )}
                        </td>
                        <td className="border border-gray p-2 w-48 text-end">
                            {formatCurrency(
                                Number(
                                    rightData?.monthly_configuration_price?.find(
                                        (x: any) =>
                                            x.duration_of_contract === selectedDurationOfContract,
                                    )?.total_price_per_year_inclusive_bmwt || 0,
                                ) +
                                    Number(
                                        rightData?.monthly_configuration_price?.find(
                                            (x: any) =>
                                                x.duration_of_contract ===
                                                selectedDurationOfContract,
                                        )?.bmwt_battery || 0,
                                    ),
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default PriceLogs
