/* eslint-disable no-unused-expressions,camelcase,no-nested-ternary,no-plusplus,react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Table from 'components/table'
import { FormikProps } from 'formik'
import { CreateConfigurationType } from 'modules/configuration/views/createConfiguration'
import { useEffect, useState } from 'react'
import { Collapse } from 'antd'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { useAuth } from 'modules/auth/context/useAuth'
import { MdPhotoCamera } from 'react-icons/md'
import { Modal } from 'react-responsive-modal'
import { useTranslation } from 'react-i18next'
import ManualAdjustment from './manualAdjustment'
import Quantity from '../../../../components/quantity'
import Loading from '../../../../components/loading'
import PanelHeader from '../panelHeader'
import { infoToast } from '../../../../utils/toasterUtil'
import { getCurrencySymbol } from '../../../../utils/currency/formatCurrency'

interface PartSelectionProps {
    formik: FormikProps<CreateConfigurationType>
    compatibleMachinePartsIsLoading: boolean
    machineConfigurationDetailIsLoading: boolean
    configurationSequenceIsLoading: boolean
}

const PratSelection = (props: PartSelectionProps) => {
    const {
        formik,
        compatibleMachinePartsIsLoading,
        machineConfigurationDetailIsLoading,
        configurationSequenceIsLoading,
    } = props
    const { userExtra } = useAuth()
    const { Panel } = Collapse
    const { t } = useTranslation()

    const [parentModelNumber, setParentModelNumber] = useState<number[]>([])
    const [negativeParentModelNumber, setNegativeParentModelNumber] = useState<number[]>([])
    const [panelIndex, setPanelIndex] = useState<number[]>([])
    const [activePanelIndex, setActivePanelIndex] = useState<number[]>([])
    const [openImageModal, setOpenImageModal] = useState<boolean>(false)
    const [image, setImage] = useState<string>('')

    useEffect(() => {
        setActivePanelIndex([1, ...panelIndex])
    }, [panelIndex])

    const handleChangeActiveIndex = (index: number[]) => {
        setActivePanelIndex(index)
    }

    const handleSetQuantity = (quantity: number, machinePartId: number) => {
        const machineParts = formik.values.compatibleMachinePartsData
        const machinePart = _.find(machineParts, { machine_part_id: machinePartId })
        const index = _.findIndex(machineParts, {
            machine_part_id: machinePart.machine_part_id,
        })
        if (machinePart) {
            let message = t('message.please_select_one_of_these')
            let message1 = `${t('message.because_you_select_the_model_number')} ${
                machineParts[index].machine_part_model_number
            }, ${t('message.please_dont_select_one_of_these')} `

            const tempParentModelNumber = parentModelNumber.filter(
                modelNumber => modelNumber !== machineParts[index].machine_part_model_number,
            )
            let tempNegativeParentModelNumber = negativeParentModelNumber

            if (machineParts[index].quantity > quantity && machineParts[index].quantity === 1) {
                if (
                    machineParts[index].custom_negative_parent_model_number !== '' &&
                    machineParts[index].custom_negative_parent_model_number !== null
                ) {
                    const splitted_model_number =
                        machineParts[index].custom_negative_parent_model_number.split(',')
                    splitted_model_number.forEach(async (element: number) => {
                        tempNegativeParentModelNumber = tempNegativeParentModelNumber.filter(
                            f => f !== element,
                        )
                    })
                }
            }

            if (
                machineParts[index].quantity < quantity ||
                (machineParts[index].quantity > quantity && machineParts[index].quantity > 1)
            ) {
                if (
                    machineParts[index].custom_negative_parent_model_number !== '' &&
                    machineParts[index].custom_negative_parent_model_number !== null
                ) {
                    let splitted_model_number =
                        machineParts[index].custom_parent_model_number.split(',')
                    splitted_model_number.forEach((element: number) => {
                        const part_quantity = machineParts.filter((item: any) =>
                            item.machine_part_model_number.includes(element),
                        )

                        if (part_quantity.length === 0) {
                            splitted_model_number = splitted_model_number.filter(
                                (f: number) => f !== element,
                            )
                        }
                    })

                    let is_selected = 0

                    splitted_model_number.forEach((element: number) => {
                        const part_quantity = formik.values.compatibleMachinePartsData.filter(
                            (item: any) =>
                                item.machine_part_model_number.includes(element) &&
                                item.quantity > 0,
                        )

                        if (part_quantity.length > 0) {
                            is_selected += 1
                        }
                    })

                    if (splitted_model_number.length === 0) {
                        is_selected = 1
                    }

                    if (is_selected === 0) {
                        splitted_model_number.forEach((element: number) => {
                            const part_quantity = formik.values.compatibleMachinePartsData.filter(
                                (item: any) => item.machine_part_model_number.includes(element),
                            )
                            if (part_quantity.length === 0) {
                                message = `${t(
                                    'message.please_ask_admin_to_add_model',
                                )} ${element} ${t('message.to_system_or_compatibility')}`
                            } else {
                                message = `${message},${part_quantity[0].machine_part_model_number}`
                                tempParentModelNumber.push(
                                    part_quantity[0].machine_part_model_number,
                                )
                            }
                        })

                        if (quantity === 0) {
                            toast(message, infoToast)
                        }
                    }
                }
                if (
                    machineParts[index].custom_negative_parent_model_number !== '' &&
                    machineParts[index].custom_negative_parent_model_number !== null
                ) {
                    const splitted_model_number =
                        machineParts[index].custom_negative_parent_model_number.split(',')
                    splitted_model_number.forEach((element: number) => {
                        const part_quantity = formik.values.compatibleMachinePartsData.filter(
                            (item: any) => item.machine_part_model_number.includes(element),
                        )
                        if (part_quantity.length === 0) {
                            message1 = ''
                        } else {
                            message1 = `${message1},${part_quantity[0].machine_part_model_number}`
                            tempNegativeParentModelNumber.push(
                                part_quantity[0].machine_part_model_number,
                            )
                        }
                    })

                    if (message1 !== null && machineParts[index].quantity === 0) {
                        toast(message1, infoToast)
                    }
                }
            }

            setParentModelNumber(tempParentModelNumber)
            setNegativeParentModelNumber(tempNegativeParentModelNumber)

            machinePart.quantity = quantity
            machinePart.total_price = quantity * machinePart.price

            if (machineParts[index].quantity)
                machineParts[index].total_price = quantity * machineParts[index].price
            formik.setFieldValue('compatibleMachinePartsData', machineParts)
        }
        const selectedParts = machineParts.filter((parts: any) => parts.quantity !== 0)
        formik.setFieldValue('selectedParts', selectedParts)
    }

    const handeSetPanelIndex = () => {
        const tempIndex = []
        for (let i = 0; i < formik.values.totalTypes.length; i++) {
            tempIndex.push(i + 2)
        }
        setPanelIndex(tempIndex)
    }

    useEffect(() => {
        handeSetPanelIndex()
    }, [formik.values.totalTypes])

    const compatiblePartsColumns: any = [
        {
            id: 'machine_part_model_number',
            name: t('api.model_number'),
            left: true,
            minWidth: '100px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return (
                    <p className="text-md">
                        {row.machine_part_model_number
                            ? row.machine_part_model_number
                            : row.machine_category_model_number}
                    </p>
                )
            },
        },
        {
            id: 'machine_part_name',
            name: t('api.part_name'),
            left: true,
            width: '192px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return (
                    <p className="text-md text-left">
                        {row.machine_part_name ? row.machine_part_name : row.machine_category}
                    </p>
                )
            },
        },
        {
            id: 'machine_part_remarks',
            name: t('api.remark'),
            left: true,
            width: '168px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return <p className="text-md">{row.machine_part_remarks}</p>
            },
        },
        {
            id: 'machine_part_note',
            name: t('api.note'),
            left: true,
            width: '168px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return <p className="text-md">{row.machine_part_note}</p>
            },
        },
        {
            id: 'cost_price',
            name: t('api.cost_price'),
            right: true,
            omit: Boolean(userExtra?.cost_price_visible === 0),
            selector: (row: any) => {
                return <p className="text-md">{parseFloat(row.cost_price).toFixed(2)}</p>
            },
        },
        {
            id: 'price',
            name: t('api.price'),
            right: true,
            selector: (row: any) => {
                return (
                    <p className="text-md">
                        {getCurrencySymbol()} {parseFloat(row.price).toFixed(2)}{' '}
                        {row.is_included ? '(included)' : null}
                    </p>
                )
            },
        },
        {
            id: 'quantity',
            name: t('api.quantity'),
            center: true,
            width: '150px',
            cell: (row: any) => {
                return (
                    <div className="text-md">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {row.quantity !== undefined ? (
                            row.is_included ? (
                                row.quantity
                            ) : (
                                <Quantity
                                    name="quantity"
                                    quantity={row.quantity}
                                    setQuantity={(quantity = 1) => {
                                        handleSetQuantity(quantity, row.machine_part_id)
                                    }}
                                />
                            )
                        ) : formik.values.onlyParts ? (
                            0
                        ) : (
                            1
                        )}
                    </div>
                )
            },
        },
        {
            id: 'total_price',
            name: t('api.total_price'),
            right: true,
            selector: (row: any) => {
                return (
                    <p className="text-md">
                        {getCurrencySymbol()}
                        {row.total_price
                            ? parseFloat(row.total_price).toFixed(2)
                            : formik.values.onlyParts
                            ? (0).toFixed(2)
                            : (row.price * (row.quantity ? row.quantity : 1)).toFixed(2)}{' '}
                        {row.is_included ? '(included)' : null}
                    </p>
                )
            },
        },
        {
            id: 'machine_category_image',
            name: t('api.image'),
            center: true,
            selector: (row: any) => {
                if (row.machine_part_image) {
                    return (
                        <MdPhotoCamera
                            className="cursor-pointer"
                            onClick={() => {
                                setImage(row.machine_part_image)
                                setOpenImageModal(true)
                            }}
                        />
                    )
                }
                return null
            },
        },
    ]

    const MachineColumns: any = [
        {
            id: 'machine_part_model_number',
            name: t('api.model_number'),
            left: true,
            minWidth: '100px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return (
                    <p className="text-md">
                        {row.machine_part_model_number
                            ? row.machine_part_model_number
                            : row.machine_category_model_number}
                    </p>
                )
            },
        },
        {
            id: 'machine_part_name',
            name: t('api.part_name'),
            left: true,
            width: '192px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return (
                    <p className="text-md text-left">
                        {row.machine_part_name ? row.machine_part_name : row.machine_category}
                    </p>
                )
            },
        },
        {
            id: 'machine_part_remarks',
            name: t('api.remark'),
            left: true,
            width: '168px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return <p className="text-md">{row.machine_part_remarks}</p>
            },
        },
        {
            id: 'machine_part_note',
            name: t('api.note'),
            left: true,
            width: '168px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return <p className="text-md">{row.machine_part_note}</p>
            },
        },
        {
            id: 'cost_price',
            name: t('api.cost_price'),
            right: true,
            omit: Boolean(userExtra?.cost_price_visible === 0),
            selector: (row: any) => {
                return <p className="text-md">{parseFloat(row.cost_price).toFixed(2)}</p>
            },
        },
        {
            id: 'price',
            name: t('api.price'),
            right: true,
            selector: (row: any) => {
                return (
                    <p className="text-md">
                        {getCurrencySymbol()} {parseFloat(row.price).toFixed(2)}{' '}
                        {row.is_included ? '(included)' : null}
                    </p>
                )
            },
        },
        {
            id: 'quantity',
            name: t('api.quantity'),
            center: true,
            width: '150px',
            cell: (row: any) => {
                return (
                    <div className="text-md">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {row.quantity !== undefined ? (
                            row.is_included ? (
                                row.quantity
                            ) : (
                                <Quantity
                                    name="quantity"
                                    quantity={row.quantity}
                                    setQuantity={(quantity = 1) => {
                                        handleSetQuantity(quantity, row.machine_part_id)
                                    }}
                                />
                            )
                        ) : formik.values.onlyParts ? (
                            0
                        ) : (
                            1
                        )}
                    </div>
                )
            },
        },
        {
            id: 'total_price',
            name: t('api.total_price'),
            right: true,
            selector: (row: any) => {
                return (
                    <p className="text-md">
                        {getCurrencySymbol()}
                        {row.total_price
                            ? parseFloat(row.total_price).toFixed(2)
                            : formik.values.onlyParts
                            ? (0).toFixed(2)
                            : (row.price * (row.quantity ? row.quantity : 1)).toFixed(2)}{' '}
                        {row.is_included ? '(included)' : null}
                    </p>
                )
            },
        },
        {
            id: 'machine_category_image',
            name: t('api.image'),
            center: true,
            selector: (row: any) => {
                if (row.machine_category_image) {
                    return (
                        <MdPhotoCamera
                            className="cursor-pointer"
                            onClick={() => {
                                setImage(row.machine_category_image)
                                setOpenImageModal(true)
                            }}
                        />
                    )
                }
                return null
            },
        },
    ]

    const conditionalRowStyles = [
        {
            when: (row: any) => negativeParentModelNumber.includes(row.machine_part_model_number),
            style: {
                backgroundColor: 'rgba(185, 55, 61, 0.4)',
                color: '#000000',
            },
        },
        // You can also pass a callback to style for additional customization
        {
            when: (row: any) =>
                parentModelNumber.includes(row.machine_part_model_number) &&
                !negativeParentModelNumber.includes(row.machine_part_model_number),
            style: {
                backgroundColor: 'rgba(46, 204, 113, 0.4)',
                color: '#ffffff',
            },
        },
    ]

    return (
        <>
            <Collapse
                activeKey={activePanelIndex}
                onChange={(key: any) => handleChangeActiveIndex(key)}
            >
                <Panel header={PanelHeader({ title: t('api.component') })} key={1}>
                    <Table
                        columns={MachineColumns}
                        data={formik.values.machineConfigurationDetailData}
                        loading={machineConfigurationDetailIsLoading}
                        pagination={false}
                        striped={false}
                    />
                </Panel>
                {configurationSequenceIsLoading || compatibleMachinePartsIsLoading ? (
                    <div className="m-auto">
                        <Loading />
                    </div>
                ) : (
                    formik.values.totalTypes.length &&
                    formik.values.totalTypes.map(
                        (sequence: string, index: number) =>
                            formik.values.compatibleMachinePartsData &&
                            formik.values.compatibleMachinePartsData.filter(
                                (machine: any) =>
                                    machine.machine_part_type.toUpperCase() ===
                                    sequence.toUpperCase(),
                            ).length && (
                                <Panel
                                    header={PanelHeader({
                                        title: sequence,
                                        stat: `(${
                                            formik.values.compatibleMachinePartsData?.filter(
                                                (machine: any) =>
                                                    machine.machine_part_type.toUpperCase() ===
                                                    sequence.toUpperCase(),
                                            ).length
                                        } ${t('api.total_part')})`,
                                    })}
                                    key={index + 2}
                                >
                                    <Table
                                        columns={compatiblePartsColumns}
                                        data={formik.values.compatibleMachinePartsData?.filter(
                                            (machine: any) =>
                                                machine.machine_part_type.toUpperCase() ===
                                                sequence.toUpperCase(),
                                        )}
                                        tableHeight="fit"
                                        loading={compatibleMachinePartsIsLoading}
                                        pagination={false}
                                        noHeader
                                        striped={false}
                                        conditionalRowStyles={conditionalRowStyles}
                                    />
                                </Panel>
                            ),
                    )
                )}
            </Collapse>
            <ManualAdjustment editable formik={formik} />
            <Modal center open={openImageModal} onClose={() => setOpenImageModal(false)}>
                <div>
                    {image ? (
                        <img
                            loading="lazy"
                            width="500"
                            src={`${process.env.REACT_APP_IMAGE_URL}/${image}`}
                            alt="machine-part"
                        />
                    ) : null}
                </div>
            </Modal>
        </>
    )
}

export default PratSelection
