import { Collapse } from 'antd'
import Loading from 'components/loading'
import Table from 'components/table'
import PanelHeader from 'modules/configuration/components/panelHeader'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getBatteryInformationForCharger } from '../queries/quotes'

interface BatteryChargerInfoProps {
    configurationId: string
}

const BatteryChargerInfo = (props: BatteryChargerInfoProps) => {
    const { configurationId } = props
    const { t } = useTranslation()
    const columns = [
        {
            name: t('api.model_code'),
            left: true,
            width: '200px',
            cell: (row: any) => {
                return (
                    <div>
                        <p>{row['Model Code']}</p>
                    </div>
                )
            },
        },
        {
            name: t('api.description'),
            left: true,
            width: 200,
            wrap: true,
            selector: (row: any) => (
                <p title={row['Part Description']}>{row['Part Description']}</p>
            ),
        },
        {
            name: t('api.part_number'),
            left: true,
            cell: (row: any) => {
                return (
                    <div>
                        <p>{row['Part Number']}</p>
                    </div>
                )
            },
        },
    ]
    const { isLoading, data } = useQuery(['batteryChargerInfo'], () =>
        getBatteryInformationForCharger(configurationId),
    )
    if (isLoading) {
        return (
            <div className="border-2 rounded-lg border-[#6b6868]">
                <Loading />
            </div>
        )
    }

    return (
        <Collapse
            className="w-full"
            defaultActiveKey={[1]}
            style={{
                backgroundColor: 'transparent',
            }}
        >
            <Collapse.Panel
                key={1}
                header={PanelHeader({ title: 'Batteries' })}
                style={{
                    border: '1px solid #6b6868',
                    borderRadius: '12px',
                }}
            >
                <Table
                    columns={columns}
                    data={data?.data?.data ?? []}
                    pagination={false}
                    striped={false}
                />
            </Collapse.Panel>
        </Collapse>
    )
}

export default BatteryChargerInfo
