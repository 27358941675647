import Loading from 'components/loading'
import Select, { Options } from 'components/select'
import { maintenanceOptions } from 'modules/siteSurvey/components/siteSurveyDropDown'
import moment from 'moment'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getGeneralLogByDate, getGeneralLogVersionTimeByConfigurationId } from '../queries/log'

const GeneralLogs = () => {
    const { t } = useTranslation()
    const { configurationId } = useParams()
    const [timeStamp, setTimeStamp] = useState<Options[]>([])
    const [leftDateDropdown, setLeftDateDropdown] = useState<string>()
    const [rightDateDropdown, setRightDateDropdown] = useState<string>()
    const [leftData, setLeftData] = useState<any>()
    const [rightData, setRightData] = useState<any>()

    const getLogData = useMutation(
        ['configurationLogs'],
        (params: { date: string; direction: 'left' | 'right' }) =>
            getGeneralLogByDate(String(configurationId), params.date),
        {
            onSuccess(data, variables) {
                if (variables.direction === 'left') {
                    setLeftData(data.data[0])
                } else {
                    setRightData(data.data[0])
                }
            },
            onError: (error: Error) => {
                toast.error(String(t(error.message)) || t('message.error'))
            },
        },
    )

    const { isLoading: timeLoading } = useQuery(
        ['configurationLogs'],
        () => getGeneralLogVersionTimeByConfigurationId(String(configurationId)),
        {
            enabled: !!configurationId,
            onSuccess: data => {
                if (data?.data) {
                    setTimeStamp(
                        data.data.map((x: string) => ({
                            label: moment(x).format('YYYY-MM-DD hh:mm:ss A'),
                            value: x,
                        })),
                    )
                }
                if (data?.data && data.data.length > 0) {
                    setLeftDateDropdown(data.data[0])
                    getLogData.mutate({
                        date: data.data[0],
                        direction: 'left',
                    })
                }
                if (data?.data && data.data.length > 1) {
                    setRightDateDropdown(data.data[1])
                    getLogData.mutate({
                        date: data.data[1],
                        direction: 'right',
                    })
                }
            },
            onError: (error: Error) => {
                toast.error(String(t(error.message)) || t('message.error'))
            },
        },
    )

    const handleLeftDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setLeftDateDropdown(e.target.value)
        getLogData.mutate({
            date: e.target.value,
            direction: 'left',
        })
    }

    const handleRightDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setRightDateDropdown(e.target.value)
        getLogData.mutate({
            date: e.target.value,
            direction: 'right',
        })
    }

    if (timeLoading) {
        return (
            <tr>
                <td colSpan={3} className="flex justify-center items-center">
                    <Loading />
                </td>
            </tr>
        )
    }

    return (
        <div className="flex flex-col gap-2">
            <table className="border border-gray border border-gray-collapse rounded min-w-[600px] max-w-4xl">
                <thead className="bg-gray-100">
                    <tr>
                        <th className="border border-gray p-2 text-start">{t('api.attributes')}</th>
                        <th className="border border-gray p-2 w-80">
                            <Select
                                name="leftDateDropdown"
                                options={timeStamp}
                                showPlaceHolder
                                placeHolderOption={t('api.select_date')}
                                // isLoading={timeLoading || isRefetching}
                                value={leftDateDropdown}
                                onChange={handleLeftDateChange}
                            />
                        </th>
                        <th className="border border-gray p-2 w-80">
                            <Select
                                options={timeStamp}
                                showPlaceHolder
                                placeHolderOption={t('api.select_date')}
                                // isLoading={timeLoading || isRefetching}
                                value={rightDateDropdown}
                                name="rightDateDropdown"
                                onChange={handleRightDateChange}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.financing')}</td>
                        <td className="border border-gray p-2 w- text-end">
                            {leftData?.financing}
                        </td>
                        <td className="border border-gray p-2 w-80 text-end">
                            {rightData ? rightData.financing : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.maintenance')}</td>
                        <td className="border border-gray p-2 w-80 text-end">
                            <Select
                                disabled
                                customStyle="w-full"
                                noLabel
                                label={t('fields.maintenance')}
                                value={leftData?.maintenance}
                                showPlaceHolder
                                placeHolderOption={`${t('fields.select')} ${t(
                                    'fields.maintenance',
                                )}`}
                                options={maintenanceOptions.map(x => ({
                                    label: t(x.label),
                                    value: x.value,
                                }))}
                                name="maintenance"
                            />
                        </td>
                        <td className="border border-gray p-2 w-80 text-end">
                            <Select
                                disabled
                                noLabel
                                customStyle="w-full"
                                label={t('fields.maintenance')}
                                value={rightData?.maintenance}
                                showPlaceHolder
                                placeHolderOption={`${t('fields.select')} ${t(
                                    'fields.maintenance',
                                )}`}
                                options={maintenanceOptions.map(x => ({
                                    label: t(x.label),
                                    value: x.value,
                                }))}
                                name="maintenance"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.insurance')}</td>
                        <td className="border border-gray p-2 w-80 text-end">
                            {leftData?.insurance ? t('api.yes') : t('api.no')}
                        </td>
                        <td className="border border-gray p-2 w-80 text-end">
                            {rightData?.insurance ? t('api.yes') : t('api.no')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.running_hours')}</td>
                        <td className="border border-gray p-2 w-80 text-end">
                            {leftData?.running_hours} {t('api.hrs')}
                        </td>
                        <td className="border border-gray p-2 w-80 text-end">
                            {rightData?.running_hours} {t('api.hrs')}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">
                            {t('fields.show_all_price_separate')}
                        </td>
                        <td className="border border-gray p-2 w-80 text-end">
                            {leftData?.show_all_prices_separate}
                        </td>
                        <td className="border border-gray p-2 w-80 text-end">
                            {rightData?.show_all_prices_separate}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray p-2">{t('fields.return')}</td>
                        <td className="border border-gray p-2 w-80 text-end">
                            {leftData?.return ? t('api.yes') : t('api.no')}
                        </td>
                        <td className="border border-gray p-2 w-80 text-end">
                            {rightData?.return ? t('api.yes') : t('api.no')}
                        </td>
                    </tr>
                    {(leftData?.return || rightData?.return) && (
                        <tr>
                            <td className="border border-gray p-2">
                                {t('fields.return_description')}
                            </td>
                            <td className="border border-gray p-2 w-80 text-end">
                                {leftData?.return_description}
                            </td>
                            <td className="border border-gray p-2 w-80 text-end">
                                {rightData ? rightData.return_description : '-'}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default GeneralLogs
