/* eslint-disable jsx-a11y/label-has-associated-control */
import { FaUser } from 'react-icons/fa'

import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useAuth } from '../../auth/context/useAuth'
import Table from '../../../components/table'

interface ProfileVal {
    key: string
    value: string
}

const Profile = () => {
    const { user, userExtra } = useAuth()
    const { t } = useTranslation()
    const [userProfile, setUserProfile] = useState<ProfileVal[]>([])

    const columns = [
        {
            name: '',
            width: '300px',
            cell: (row: { key: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold">{t(row.key)}</p>
                    </div>
                )
            },
        },
        {
            name: '',
            left: true,
            width: '300px',
            cell: (row: { value: string }) => {
                return (
                    <div>
                        <p className="">{t(row.value)}</p>
                    </div>
                )
            },
        },
    ]

    useEffect(() => {
        setUserProfile([
            {
                key: 'api.name',
                value: user.name,
            },
            {
                key: 'api.email',
                value: user.email,
            },
            {
                key: 'api.role',
                value: userExtra.role,
            },
            {
                key: 'api.email_subscribed',
                value: user.is_subscribed ? 'api.yes' : 'api.no',
            },
        ])
    }, [user])

    return (
        <div className="px-8 py-4 rounded-xl flex flex-col items-center gap-4">
            <div className="mt-20 m-1 mr-2 md:w-48 md:h-48 relative flex justify-center items-center rounded-full bg-primary text-xl text-white uppercase">
                <FaUser size={72} />
            </div>
            <div className="w-[700px] rounded-md border border-primaryLight p-10 flex flex-col justify-center items-center gap-4">
                <div className="w-[100%] flex justify-between">
                    <span className="font-bold">{t('api.name')}</span>
                    <span>{user.name}</span>
                </div>
                <div className="w-[100%] flex justify-between">
                    <span className="font-bold">{t('api.email')}</span>
                    <span>{user.email}</span>
                </div>
                <div className="w-[100%] flex justify-between">
                    <span className="font-bold">{t('api.role')}</span>
                    <span>{userExtra.role}</span>
                </div>
                <div className="w-[100%] flex justify-between">
                    <span className="font-bold">{t('api.is_subscribed')}</span>
                    <span>{user.is_subscribed ? t('api.yes') : t('api.no')}</span>
                </div>
            </div>
        </div>
    )
}

export default Profile
