import { callAxios } from 'plugins/api'

const getAuthorizationMatrixByRoleId = (roleId: number) =>
    callAxios({
        url: `authorization-matrix/role/${roleId}`,
        method: 'GET',
        isHeffiq: true,
    })

const getAuthUserDetails = () =>
    callAxios({
        url: 'user-info',
        method: 'GET',
        isHeffiq: true,
    })

const updateRole = (roleId: number) =>
    callAxios({
        url: `updateRole/${roleId}`,
        method: 'POST',
        isHeffiq: true,
    })

const getSingleUserDetail = () =>
    callAxios({
        url: 'getSingleUserDetail',
        method: 'GET',
        isHeffiq: true,
    })

export const AuthQueries = {
    getAuthorizationMatrixByRoleId,
    getAuthUserDetails,
    updateRole,
    getSingleUserDetail,
}
