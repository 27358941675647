import { Form, Input, Collapse } from 'antd'
import Table from 'components/table'
import PanelHeader from 'modules/configuration/components/panelHeader'
import TextArea from 'antd/es/input/TextArea'
import { useTranslation } from 'react-i18next'
import appJson from '../../../app.json'

interface OrderConfigurationFormProps {
    formik: any
}

const roundedClass = 'rounded-lg flex-1'
const size = 'large'

const OrderConfigurationForm = (props: OrderConfigurationFormProps) => {
    const { formik } = props
    const { Panel } = Collapse
    const { t } = useTranslation()

    const columns: any = [
        {
            id: 'group',
            name: t('api.model_number'),
            left: true,
            cell: (row: any) => {
                return (
                    <div className="flex align-center items-center gap-2">
                        <h1 className="text-semibold mb-2 text-md text-center">
                            {row.machine_part_model_number
                                ? row.machine_part_model_number
                                : row.machine_category_model_number}
                            {row.manual_adjustment_model_number}
                        </h1>
                    </div>
                )
            },
        },
        {
            id: 'description',
            name: t('api.description'),
            left: true,
            selector: (row: any) => (
                <p title={row.machine_part_name} className="cursor-pointer text-md text-left">
                    {row.machine_part_name ? row.machine_part_name : row.machine_category}
                    {row.manual_adjustment_name}
                </p>
            ),
        },
        {
            id: 'remarks',
            name: t('api.remark'),
            left: true,
            selector: (row: any) => <p className="text-md">{row.name}</p>,
        },
        {
            id: 'note',
            name: t('api.note'),
            selector: (row: any) => (
                <p className="text-md">
                    {' '}
                    {row.name} {row.manual_adjustment_note}
                </p>
            ),
        },
        {
            id: 'price',
            name: t('api.price'),
            right: true,
            selector: (row: any) => (
                <p className="text-md">
                    {appJson.companyCurrency} {row.price} {row.manual_adjustment_price}
                </p>
            ),
        },
        {
            id: 'qty',
            name: t('api.quantity'),
            right: true,
            selector: (row: any) => (
                <p className="text-md">
                    {row.quantity} {row.manual_adjustment_quantity}
                </p>
            ),
        },
        {
            id: 'total_price',
            name: t('api.total_price'),
            right: true,
            selector: (row: any) => (
                <p className="text-md">
                    {appJson.companyCurrency}
                    {row.manual_adjustment_quantity
                        ? row.manual_adjustment_quantity * row.manual_adjustment_price
                        : row.price && row.quantity && row.price * row.quantity}
                </p>
            ),
        },
    ]

    return (
        <div className="flex flex-col gap-y-8 mb-8">
            <hr />
            {formik.values.configurations.length ? (
                formik.values.configurations.map((configuration: any, index: number) => (
                    <div>
                        <h5 className="capitalize font-bold text-xl">
                            {configuration.item.configuration[0].name}
                        </h5>
                        <div className="grid grid-cols-2 gap-x-8 mt-4">
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">{t('api.serial_number')}</h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].serial_number`}
                                    value={formik.values.configurations[index].serial_number}
                                    onChange={formik.handleChange}
                                    placeholder={t('api.serial_number')}
                                    size={size}
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">{t('api.special_instruction')}</h5>
                                </div>
                                <TextArea
                                    name={`configurations[${index}].special_instruction`}
                                    value={formik.values.configurations[index].special_instruction}
                                    onChange={formik.handleChange}
                                    placeholder={t('api.special_instruction')}
                                    size={size}
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">{t('api.trade_in_information')}</h5>
                                </div>
                                <TextArea
                                    name={`configurations[${index}].trade_in_information`}
                                    value={formik.values.configurations[index].trade_in_information}
                                    onChange={formik.handleChange}
                                    placeholder={t('api.trade_in_information')}
                                    size={size}
                                    className={roundedClass}
                                />
                            </Form.Item>
                        </div>
                        <div className="grid grid-cols-3 gap-x-8">
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">{t('api.discount_given')}</h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].discount_given`}
                                    value={configuration.item.configuration[0].discount_percentage}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">{t('api.discount_amount')}</h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].discount_amount`}
                                    value={configuration.item.configuration[0].discount_amount}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">{t('api.total_retail_price')}</h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].total_retail_price`}
                                    value={parseFloat(
                                        String(
                                            configuration.item.configuration[0].machine_total_price,
                                        ),
                                    ).toFixed(2)}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">{t('api.net_price')}</h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].net_price`}
                                    value={(
                                        parseFloat(
                                            String(
                                                configuration.item.configuration[0]
                                                    .machine_total_price,
                                            ),
                                        ) -
                                        parseFloat(
                                            String(
                                                configuration.item.configuration[0].discount_amount,
                                            ),
                                        ) *
                                            parseFloat(
                                                String(
                                                    configuration.item.configuration[0].quantity,
                                                ),
                                            ) -
                                        (parseFloat(
                                            String(
                                                configuration.item.configuration[0]
                                                    .machine_total_price,
                                            ),
                                        ) *
                                            parseFloat(
                                                String(
                                                    configuration.item.configuration[0]
                                                        .discount_percentage,
                                                ),
                                            )) /
                                            100
                                    ).toFixed(2)}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">{t('api.trade_in_value')}</h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].trade_in_value`}
                                    value={configuration.item.configuration[0].trade_in_value}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                            <Form.Item name="configuration_name" className="flex flex-col">
                                <div className="mb-1">
                                    <h5 className="capitalize">{t('api.quantity')}</h5>
                                </div>
                                <Input
                                    name={`configurations[${index}].quantity`}
                                    value={configuration.item.configuration[0].quantity}
                                    onChange={formik.handleChange}
                                    size={size}
                                    disabled
                                    className={roundedClass}
                                />
                            </Form.Item>
                        </div>
                        <Collapse
                            style={{ backgroundColor: 'transparent' }}
                            // defaultActiveKey={[1, 2, 3]}
                        >
                            <Panel key={1} header={PanelHeader({ title: t('api.component') })}>
                                <Table
                                    columns={columns}
                                    data={configuration.item.configuration}
                                    pagination={false}
                                    striped={false}
                                />
                            </Panel>
                            {configuration.item.parts.length > 0 && (
                                <Panel
                                    key={2}
                                    header={PanelHeader({ title: t('api.compatible_parts') })}
                                >
                                    <Table
                                        columns={columns}
                                        data={configuration.item.parts}
                                        pagination={false}
                                        striped={false}
                                    />
                                </Panel>
                            )}
                            {configuration.item.manual_adjustments.length > 0 && (
                                <Panel
                                    key={3}
                                    header={PanelHeader({ title: t('api.manual_adjustment') })}
                                >
                                    <Table
                                        columns={columns}
                                        data={configuration.item.manual_adjustments}
                                        pagination={false}
                                        striped={false}
                                    />
                                </Panel>
                            )}
                        </Collapse>
                        <hr />
                    </div>
                ))
            ) : (
                <p>{t('api.no_configuration')}</p>
            )}
        </div>
    )
}

export default OrderConfigurationForm
