import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useAuth } from '../context/useAuth'
import { AuthQueries } from '../context/auth'

const RoleSelector = () => {
    const { t } = useTranslation()
    const [group, setGroup] = useState([])
    const { selectRole } = useAuth()

    useQuery(['auth.get_single_user'], () => AuthQueries.getSingleUserDetail(), {
        onSuccess: data => {
            setGroup(data?.data?.groups)
        },
        onError: (error: Error) => {
            toast.error(error?.message || 'Get Roles Error')
        },
    })

    return (
        <div className="flex flex-col gap-4">
            <p>{t('fields.select_desired_role')}</p>
            <div className="flex gap-4 flex-wrap max-h-80 overflow-auto">
                {group.map((item: any) => (
                    <button
                        type="button"
                        onClick={() => {
                            selectRole.mutate(item.id)
                        }}
                        className="flex justify-center rounded-md items-center h-24 w-24 bg-primary text-white hover:brigntness-125"
                    >
                        {item.role_name}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default RoleSelector
