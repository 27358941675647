import Input from 'components/input'
import Select, { Options } from 'components/select'
import TextArea from 'components/textArea'
import { Collapse } from 'antd'
import { FormikProps } from 'formik'
import { ISiteSurveyForm } from './AddSiteSurvey'
import {
    // batteryCapacityStaticOption,
    durationOfContractOptions,
    maintenanceOptions,
    yesNoOptions,
} from './siteSurveyDropDown'
import {
    attachmentOption,
    batteryBrandOption,
    batteryTypeOptions,
    chargingTimeOptions,
    controlOptions,
    couplingOptions,
    eloOrHipOptions,
    financeOptions,
    forkWheelOption,
    hysterTrackerOption,
    inductanceOrRailConductionOptions,
    lflOrFFlOption,
    lightingOption,
    mastOptions,
    palOption,
    plugTypeOption,
    safetyOptions,
    steeringOptions,
    tankOption,
    typeBandanOptions,
} from './SiteSurveyOptions'
import useTranslationData from '../../translation/hooks/useTranslationData'

interface ITruckSpecification {
    drive: string
    formik: FormikProps<ISiteSurveyForm>
    machineExtraFields: string[]
    batteryCapacityOptions: any[]
    thirdFormCollapseActiveKey: string[]
    showNonRequiredFields: boolean
    setThirdFormCollapseActiveKey: (value: string[]) => void
    isPmPlusAvailable: boolean
}

const TruckSpecification = (props: ITruckSpecification) => {
    const {
        drive,
        formik,
        machineExtraFields,
        batteryCapacityOptions,
        setThirdFormCollapseActiveKey,
        showNonRequiredFields,
        thirdFormCollapseActiveKey,
        isPmPlusAvailable,
    } = props
    const {
        translation: { t },
    } = useTranslationData()

    const { Panel } = Collapse

    const showAllPriceSeparateOptions: Options[] = [
        {
            label: t('api.yes'),
            value: 'yes',
        },
        {
            label: t('api.no'),
            value: 'no',
        },
        {
            label: t('api.only_service_separated'),
            value: 'only service separated',
        },
        {
            label: t('api.only_insurance_separated'),
            value: 'only insurance separated',
        },
    ]

    return (
        <Collapse
            ghost
            accordion
            expandIconPosition="end"
            className="bg-white w-full"
            activeKey={thirdFormCollapseActiveKey}
            onChange={(key: any) => {
                setThirdFormCollapseActiveKey(key)
            }}
        >
            <Panel
                className="bg-white"
                header={<p className="text-lg">{t('api.truck_specification')}</p>}
                key="1"
            >
                <div className="p-4 grid grid-cols-1 gap-4 w-full">
                    {/* <Input
                horizontal
                value={formik.values.couplingPosition}
                name="couplingPosition"
                onChange={formik.handleChange}
                type="text"
                error={formik.touched.couplingPosition ? formik.errors.couplingPosition : undefined}
                label="Coupling Position"
                placeholder="Enter Coupling position"
            /> */}
                    {drive === 'Elektrisch' && (
                        <Select
                            horizontal
                            required
                            value={formik.values.eloOrHip}
                            onChange={item => formik.setFieldValue('eloOrHip', item.target.value)}
                            options={eloOrHipOptions}
                            placeHolderOption={`${t('fields.select')} ${t('fields.elo_or_hip')}`}
                            showPlaceHolder
                            name="eloOrHip"
                            label={t('fields.elo_or_hip')}
                            error={formik.touched.eloOrHip ? formik.errors.eloOrHip : undefined}
                        />
                    )}
                    {drive === 'Elektrisch' && (
                        <Input
                            horizontal
                            required
                            value={formik.values.maxWorkHour}
                            name="maxWorkHour"
                            onChange={formik.handleChange}
                            type="number"
                            error={
                                formik.touched.maxWorkHour ? formik.errors.maxWorkHour : undefined
                            }
                            label={t('fields.max_effective_working_hours')}
                            rightContent={<p>{t('api.hr')}</p>}
                            placeholder={`${t('fields.enter')} ${t(
                                'fields.max_effective_working_hours_according_to_customer',
                            )}`}
                        />
                    )}
                    <Select
                        value={formik.values.overBuildEquipmentOnSite ? 'yes' : 'no'}
                        onChange={item =>
                            formik.setFieldValue(
                                'overBuildEquipmentOnSite',
                                item.target.value === 'yes',
                            )
                        }
                        required
                        horizontal
                        options={yesNoOptions}
                        placeHolderOption={`${t('fields.select')} ${t(
                            'fields.overbuild_equipment_on_site',
                        )}`}
                        showPlaceHolder
                        name="overbuildEquipmentOnSite"
                        label={t('fields.overbuild_equipment_on_site')}
                        error={
                            formik.touched.overBuildEquipmentOnSite
                                ? formik.errors.overBuildEquipmentOnSite
                                : undefined
                        }
                    />
                    {showNonRequiredFields && (
                        <>
                            {machineExtraFields?.includes('coupling') &&
                                formik.values.fuel === 'LPG' && (
                                    <Select
                                        horizontal
                                        value={formik.values.coupling}
                                        onChange={item =>
                                            formik.setFieldValue('coupling', item.target.value)
                                        }
                                        options={couplingOptions}
                                        placeHolderOption={`${t('fields.select')} ${t(
                                            'fields.coupling',
                                        )}`}
                                        showPlaceHolder
                                        name="coupling"
                                        label={t('fields.coupling')}
                                        error={
                                            formik.touched.coupling
                                                ? formik.errors.coupling
                                                : undefined
                                        }
                                    />
                                )}
                            {machineExtraFields?.includes('tank') &&
                                formik.values.fuel === 'LPG' && (
                                    <Select
                                        horizontal
                                        value={formik.values.tank}
                                        onChange={item =>
                                            formik.setFieldValue('tank', item.target.value)
                                        }
                                        options={tankOption}
                                        placeHolderOption={`${t('fields.select')} ${t(
                                            'fields.tank',
                                        )}`}
                                        showPlaceHolder
                                        name="tank"
                                        label={t('fields.tank')}
                                        error={formik.touched.tank ? formik.errors.tank : undefined}
                                    />
                                )}
                            {machineExtraFields?.includes('battery_brand') && (
                                <Select
                                    horizontal
                                    value={formik.values.batteryBrand}
                                    onChange={item =>
                                        formik.setFieldValue('batteryBrand', item.target.value)
                                    }
                                    options={batteryBrandOption}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.battery_brand',
                                    )}`}
                                    showPlaceHolder
                                    name="batteryBrand"
                                    label={t('fields.battery_brand')}
                                    error={
                                        formik.touched.batteryBrand
                                            ? formik.errors.batteryBrand
                                            : undefined
                                    }
                                />
                            )}
                        </>
                    )}
                    {machineExtraFields?.includes('battery_type') && (
                        <Select
                            horizontal
                            value={formik.values.batteryType}
                            onChange={item =>
                                formik.setFieldValue('batteryType', item.target.value)
                            }
                            options={batteryTypeOptions}
                            placeHolderOption={`${t('fields.select')} ${t('fields.battery_type')}`}
                            required
                            showPlaceHolder
                            name="batteryType"
                            label={t('fields.battery_type')}
                            error={
                                formik.touched.batteryType ? formik.errors.batteryType : undefined
                            }
                        />
                    )}
                    {showNonRequiredFields && (
                        <>
                            {machineExtraFields?.includes('battery_capacity') && (
                                <Input
                                    horizontal
                                    value={formik.values.voltage}
                                    name="voltage"
                                    onChange={formik.handleChange}
                                    type="text"
                                    error={
                                        formik.touched.voltage ? formik.errors.voltage : undefined
                                    }
                                    label={t('fields.voltage')}
                                    placeholder={`${t('fields.enter')} ${t('fields.voltage')}`}
                                />
                            )}
                            {machineExtraFields?.includes('central_filling_system') && (
                                <Select
                                    horizontal
                                    value={formik.values.centralFillingSystem ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'centralFillingSystem',
                                            item.target.value === 'yes',
                                        )
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.central_filling_system',
                                    )}`}
                                    showPlaceHolder
                                    name="centralFillingSystem"
                                    label={t('fields.central_filling_system')}
                                    error={
                                        formik.touched.centralFillingSystem
                                            ? formik.errors.centralFillingSystem
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('load_time') && (
                                <Select
                                    horizontal
                                    value={formik.values.chargingTime}
                                    onChange={item =>
                                        formik.setFieldValue('chargingTime', item.target.value)
                                    }
                                    options={chargingTimeOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.charging_time_in_hours',
                                    )}`}
                                    showPlaceHolder
                                    name="chargingTime"
                                    label={t('fields.charging_time_in_hours')}
                                    error={
                                        formik.touched.chargingTime
                                            ? formik.errors.chargingTime
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('plug_type') && (
                                <Select
                                    horizontal
                                    value={formik.values.plugType}
                                    onChange={item =>
                                        formik.setFieldValue('plugType', item.target.value)
                                    }
                                    options={plugTypeOption}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.plug_type',
                                    )}`}
                                    showPlaceHolder
                                    name="plugType"
                                    label={t('fields.plug_type')}
                                    error={
                                        formik.touched.plugType ? formik.errors.plugType : undefined
                                    }
                                />
                            )}
                            {/* {machineExtraFields?.includes('fork_positioner') && (
                <Select
                    horizontal
                    value={formik.values.forkPositioner ? 'yes' : 'no'}
                    onChange={item =>
                        formik.setFieldValue('forkPositioner', item.target.value === 'yes')
                    }
                    options={yesNoOptions}
                    placeHolderOption="Select Fork Positioner"
                    showPlaceHolder
                    name="forkPositioner"
                    label="Fork Positioner"
                    error={formik.touched.forkPositioner ? formik.errors.forkPositioner : undefined}
                />
            )} */}
                            {machineExtraFields?.includes('fork_dimension') && (
                                <Input
                                    horizontal
                                    value={formik.values.forkDimension}
                                    name="forkDimension"
                                    onChange={formik.handleChange}
                                    type="text"
                                    error={
                                        formik.touched.forkDimension
                                            ? formik.errors.forkDimension
                                            : undefined
                                    }
                                    label={t('fields.fork_dimension')}
                                    placeholder={`${t('fields.enter')} ${t(
                                        'fields.fork_dimension',
                                    )}`}
                                />
                            )}
                            {machineExtraFields?.includes('measure_over_between_forks') ||
                                (machineExtraFields?.includes('measure_over_forks') && (
                                    <Input
                                        horizontal
                                        value={formik.values.measureOverForks}
                                        name="measureOverForks"
                                        onChange={formik.handleChange}
                                        type="text"
                                        error={
                                            formik.touched.measureOverForks
                                                ? formik.errors.measureOverForks
                                                : undefined
                                        }
                                        label={t('fields.measure_over_the_forks')}
                                        placeholder={`${t('fields.enter')} ${t(
                                            'fields.measure_over_the_forks',
                                        )}`}
                                    />
                                ))}
                            {machineExtraFields?.includes('measure_over_between_forks') ||
                                (machineExtraFields?.includes('measure_between_forks') && (
                                    <Input
                                        horizontal
                                        value={formik.values.measureBetweenForks}
                                        name="measureBetweenForks"
                                        onChange={formik.handleChange}
                                        type="text"
                                        error={
                                            formik.touched.measureBetweenForks
                                                ? formik.errors.measureBetweenForks
                                                : undefined
                                        }
                                        label={t('fields.measure_between_the_forks')}
                                        placeholder={`${t('fields.enter')} ${t(
                                            'fields.measure_between_the_forks',
                                        )}`}
                                    />
                                ))}
                            {machineExtraFields?.includes('fork_wheels') && (
                                <Select
                                    horizontal
                                    value={formik.values.forkWheels}
                                    onChange={item =>
                                        formik.setFieldValue('forkWheels', item.target.value)
                                    }
                                    options={forkWheelOption}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.fork_wheels',
                                    )}`}
                                    showPlaceHolder
                                    name="forkWheels"
                                    label={t('fields.fork_wheels')}
                                    error={
                                        formik.touched.forkWheels
                                            ? formik.errors.forkWheels
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('heavy_clamp') && (
                                <Select
                                    horizontal
                                    value={formik.values.heavyClamp ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'heavyClamp',
                                            item.target.value === 'yes',
                                        )
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.heavy_clamps',
                                    )}`}
                                    showPlaceHolder
                                    name="heavyClamp"
                                    label={t('fields.heavy_clamps')}
                                    error={
                                        formik.touched.heavyClamp
                                            ? formik.errors.heavyClamp
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('attachments') && (
                                <Select
                                    value={formik.values.attachments}
                                    onChange={item =>
                                        formik.setFieldValue('attachments', item.target.value)
                                    }
                                    horizontal
                                    options={attachmentOption}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.attachments',
                                    )}`}
                                    showPlaceHolder
                                    name="attachmentOption"
                                    label={t('fields.attachments')}
                                    error={
                                        formik.touched.attachments
                                            ? formik.errors.attachments
                                            : undefined
                                    }
                                />
                            )}
                            {formik.values.attachments === 'other' && (
                                <Input
                                    value={formik.values.attachmentsOther}
                                    name="attachmentsOther"
                                    onChange={formik.handleChange}
                                    type="text"
                                    horizontal
                                    error={
                                        formik.touched.attachmentsOther
                                            ? formik.errors.attachmentsOther
                                            : undefined
                                    }
                                    label={t('fields.attachments_other')}
                                    placeholder={`${t('fields.enter')} ${t(
                                        'fields.attachments_other',
                                    )}`}
                                />
                            )}
                            {machineExtraFields?.includes('load_rack') && (
                                <Select
                                    horizontal
                                    value={formik.values.loadRack ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'loadRack',
                                            item.target.value === 'yes',
                                        )
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.load_rack',
                                    )}`}
                                    showPlaceHolder
                                    name="loadRack"
                                    label={t('fields.load_rack')}
                                    error={
                                        formik.touched.loadRack ? formik.errors.loadRack : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('lfl_or_ffl') && (
                                <Select
                                    horizontal
                                    value={formik.values.lflOrFfl}
                                    onChange={item =>
                                        formik.setFieldValue('lflOrFfl', item.target.value)
                                    }
                                    options={lflOrFFlOption}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.lfl_or_ffl',
                                    )}`}
                                    showPlaceHolder
                                    name="lflOrFfl"
                                    label={t('fields.lfl_or_ffl')}
                                    error={
                                        formik.touched.lflOrFfl ? formik.errors.lflOrFfl : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('mast') && (
                                <Select
                                    horizontal
                                    value={formik.values.mast}
                                    onChange={item =>
                                        formik.setFieldValue('mast', item.target.value)
                                    }
                                    options={mastOptions}
                                    placeHolderOption={`${t('fields.select')} ${t('fields.mast')}`}
                                    showPlaceHolder
                                    name="mast"
                                    label={t('fields.mast')}
                                    error={formik.touched.mast ? formik.errors.mast : undefined}
                                />
                            )}
                            {machineExtraFields?.includes('platform_height') && (
                                <Input
                                    horizontal
                                    value={formik.values.platformHeight}
                                    name="platformHeight"
                                    onChange={formik.handleChange}
                                    type="text"
                                    error={
                                        formik.touched.attachments
                                            ? formik.errors.attachments
                                            : undefined
                                    }
                                    label={t('fields.platform_height')}
                                    placeholder={`${t('fields.enter')} ${t(
                                        'fields.platform_height',
                                    )}`}
                                />
                            )}
                            {machineExtraFields?.includes('inductance_or_railconduction') && (
                                <Select
                                    horizontal
                                    value={formik.values.inductanceOrRailConduction}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'inductanceOrRailConduction',
                                            item.target.value,
                                        )
                                    }
                                    options={inductanceOrRailConductionOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.induction_or_rail_conduction',
                                    )}`}
                                    showPlaceHolder
                                    name="inductanceOrRailConduction"
                                    label={t('fields.induction_or_rail_conduction')}
                                    error={
                                        formik.touched.inductanceOrRailConduction
                                            ? formik.errors.inductanceOrRailConduction
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('control') && (
                                <Select
                                    horizontal
                                    value={formik.values.control}
                                    onChange={item =>
                                        formik.setFieldValue('control', item.target.value)
                                    }
                                    options={controlOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.control',
                                    )}`}
                                    showPlaceHolder
                                    name="control"
                                    label={t('fields.control')}
                                    error={
                                        formik.touched.control ? formik.errors.control : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('steering') && (
                                <Select
                                    horizontal
                                    value={formik.values.steering}
                                    onChange={item =>
                                        formik.setFieldValue('steering', item.target.value)
                                    }
                                    options={steeringOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.steering',
                                    )}`}
                                    showPlaceHolder
                                    name="steering"
                                    label={t('fields.steering')}
                                    error={
                                        formik.touched.steering ? formik.errors.steering : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('roof_plate') && (
                                <Select
                                    horizontal
                                    value={formik.values.roofPlate ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'roofPlate',
                                            item.target.value === 'yes',
                                        )
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.roof_plate',
                                    )}`}
                                    showPlaceHolder
                                    name="roofPlate"
                                    label={t('fields.roof_plate')}
                                    error={
                                        formik.touched.roofPlate
                                            ? formik.errors.roofPlate
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('cabine') && (
                                <Select
                                    horizontal
                                    value={formik.values.cabin ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue('cabin', item.target.value === 'yes')
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t('fields.cabin')}`}
                                    showPlaceHolder
                                    name="cabin"
                                    label={t('fields.cabin')}
                                    error={formik.touched.cabin ? formik.errors.cabin : undefined}
                                />
                            )}
                            {machineExtraFields?.includes('heating_or_air_condition') && (
                                <Select
                                    horizontal
                                    value={formik.values.heatingOrAirCondition ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'heatingOrAirCondition',
                                            item.target.value === 'yes',
                                        )
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.heating_or_air_condition',
                                    )}`}
                                    showPlaceHolder
                                    name="airCondition"
                                    label={t('fields.heating_or_air_condition')}
                                    error={
                                        formik.touched.heatingOrAirCondition
                                            ? formik.errors.heatingOrAirCondition
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('mirrors') && (
                                <Select
                                    horizontal
                                    value={formik.values.mirrors ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue('mirrors', item.target.value === 'yes')
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.mirrors',
                                    )}`}
                                    showPlaceHolder
                                    name="mirrors"
                                    label={t('fields.mirrors')}
                                    error={
                                        formik.touched.mirrors ? formik.errors.mirrors : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('radio') && (
                                <Select
                                    horizontal
                                    value={formik.values.radio ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue('radio', item.target.value === 'yes')
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t('fields.radio')}`}
                                    showPlaceHolder
                                    name="radio"
                                    label={t('fields.radio')}
                                    error={formik.touched.radio ? formik.errors.radio : undefined}
                                />
                            )}
                            {machineExtraFields?.includes('safety') && (
                                <Select
                                    horizontal
                                    value={formik.values.safety}
                                    onChange={item =>
                                        formik.setFieldValue('safety', item.target.value)
                                    }
                                    options={safetyOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.streambelt_or_security_door',
                                    )}`}
                                    showPlaceHolder
                                    name="safety"
                                    label={t('fields.streambelt_or_security_door')}
                                    error={formik.touched.safety ? formik.errors.safety : undefined}
                                />
                            )}
                            {machineExtraFields?.includes('lighting') && (
                                <Select
                                    horizontal
                                    value={formik.values.lighting}
                                    onChange={item =>
                                        formik.setFieldValue('lighting', item.target.value)
                                    }
                                    options={lightingOption}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.lighting',
                                    )}`}
                                    showPlaceHolder
                                    name="lighting"
                                    label={t('fields.std_or_led')}
                                    error={
                                        formik.touched.lighting ? formik.errors.lighting : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('public_road') && (
                                <Select
                                    horizontal
                                    value={formik.values.publicRoad ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'publicRoad',
                                            item.target.value === 'yes',
                                        )
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.public_road',
                                    )}`}
                                    showPlaceHolder
                                    name="publicRoad"
                                    label={t('fields.public_road')}
                                    error={
                                        formik.touched.publicRoad
                                            ? formik.errors.publicRoad
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('work_lights') && (
                                <Select
                                    horizontal
                                    value={formik.values.drivingLight ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'drivingLight',
                                            item.target.value === 'yes',
                                        )
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.driving_light',
                                    )}`}
                                    showPlaceHolder
                                    name="drivingLight"
                                    label={t('fields.driving_light')}
                                    error={
                                        formik.touched.drivingLight
                                            ? formik.errors.drivingLight
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('flashing_light') && (
                                <Select
                                    horizontal
                                    value={formik.values.flashingLight ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'flashingLight',
                                            item.target.value === 'yes',
                                        )
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.flashing_light',
                                    )}`}
                                    showPlaceHolder
                                    name="flashingLight"
                                    label={t('fields.flashing_light')}
                                    error={
                                        formik.touched.flashingLight
                                            ? formik.errors.flashingLight
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('pal') && (
                                <Select
                                    horizontal
                                    value={formik.values.pal}
                                    onChange={item =>
                                        formik.setFieldValue('pal', item.target.value)
                                    }
                                    options={palOption}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.pal_real_or_front',
                                    )}`}
                                    showPlaceHolder
                                    name="pal"
                                    label={t('fields.pal_real_or_front')}
                                    error={formik.touched.pal ? formik.errors.pal : undefined}
                                />
                            )}
                            {machineExtraFields?.includes('driving_direction') && (
                                <Select
                                    horizontal
                                    value={formik.values.drivingDirection}
                                    onChange={item =>
                                        formik.setFieldValue('drivingDirection', item.target.value)
                                    }
                                    options={yesNoOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.driving_direction',
                                    )}`}
                                    showPlaceHolder
                                    name="drivingDirection"
                                    label={t('fields.driving_direction')}
                                    error={
                                        formik.touched.drivingDirection
                                            ? formik.errors.drivingDirection
                                            : undefined
                                    }
                                />
                            )}
                            {machineExtraFields?.includes('type_banden') && (
                                <Select
                                    horizontal
                                    value={formik.values.typeBanden}
                                    onChange={item =>
                                        formik.setFieldValue('typeBanden', item.target.value)
                                    }
                                    options={typeBandanOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.type_banden',
                                    )}`}
                                    showPlaceHolder
                                    name="typeBanden"
                                    label={t('fields.type_banden')}
                                    error={
                                        formik.touched.typeBanden
                                            ? formik.errors.typeBanden
                                            : undefined
                                    }
                                />
                            )}
                            {/* {formik.values.drive === 'Elektrisch' && (
                <Input
                    horizontal
                    value={formik.values.extremeTemperature}
                    name="extremeTemperature"
                    onChange={formik.handleChange}
                    type="text"
                    error={
                        formik.touched.extremeTemperature
                            ? formik.errors.extremeTemperature
                            : undefined
                    }
                    label="Extreme temperature"
                    placeholder="Enter Extreme temperature"
                />
            )} */}
                            {/* <Select
                horizontal
                value={formik.values.roofSheet ? 'yes' : 'no'}
                onChange={item => formik.setFieldValue('roofSheet', item.target.value === 'yes')}
                options={yesNoOptions}
                placeHolderOption="Select roof sheet"
                showPlaceHolder
                name="roofSheet"
                label="Roof Sheet"
                error={formik.touched.roofSheet ? formik.errors.roofSheet : undefined}
            /> */}
                            {machineExtraFields?.includes('hyster_tracker') && (
                                <Select
                                    horizontal
                                    value={formik.values.hysterTracker}
                                    onChange={item =>
                                        formik.setFieldValue('hysterTracker', item.target.value)
                                    }
                                    options={hysterTrackerOption}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.hyster_tracker',
                                    )}`}
                                    showPlaceHolder
                                    name="hysterTracker"
                                    label={t('fields.hyster_tracker')}
                                    error={
                                        formik.touched.hysterTracker
                                            ? formik.errors.hysterTracker
                                            : undefined
                                    }
                                />
                            )}
                            <TextArea
                                horizontal
                                value={formik.values.truckModelAdditionalInformation}
                                name="truckModelAdditionalInformation"
                                onChange={formik.handleChange}
                                rows={3}
                                error={
                                    formik.touched.truckModelAdditionalInformation
                                        ? formik.errors.truckModelAdditionalInformation
                                        : undefined
                                }
                                label={t('fields.additional_information')}
                                placeholder={`${t('fields.enter')} ${t(
                                    'fields.additional_information',
                                )}`}
                            />
                        </>
                    )}
                </div>
            </Panel>
            <Panel
                key="2"
                className="bg-white"
                header={<p className="text-lg">{t('api.offer_options_lease_purchase')}</p>}
            >
                <div className="p-4 grid grid-cols-1 gap-4 w-full">
                    <Select
                        horizontal
                        required
                        value={formik.values.financing}
                        onChange={item => formik.setFieldValue('financing', item.target.value)}
                        options={financeOptions}
                        placeHolderOption={`${t('fields.select')} ${t('fields.financing')}`}
                        showPlaceHolder
                        name="financing"
                        label={t('fields.financing')}
                        error={formik.touched.financing ? formik.errors.financing : undefined}
                    />
                    <Select
                        horizontal
                        required
                        value={formik.values.maintenance}
                        onChange={item => formik.setFieldValue('maintenance', item.target.value)}
                        options={
                            isPmPlusAvailable
                                ? maintenanceOptions
                                : maintenanceOptions.filter(
                                      option => option.value !== 'PREVENTIVE_MAINTENANCE_PLUS',
                                  )
                        }
                        placeHolderOption={`${t('fields.select')} ${t('fields.maintenance')}`}
                        showPlaceHolder
                        name="maintenance"
                        label={t('fields.maintenance')}
                        error={formik.touched.maintenance ? formik.errors.maintenance : undefined}
                    />
                    {(showNonRequiredFields ? true : formik.values.maintenance === 'ALL_IN') && (
                        <Select
                            horizontal
                            required={formik.values.maintenance === 'ALL_IN'}
                            value={formik.values.durationOfContract}
                            onChange={item =>
                                formik.setFieldValue('durationOfContract', item.target.value)
                            }
                            options={durationOfContractOptions}
                            placeHolderOption={`${t('fields.select')} ${t(
                                'fields.duration_of_contract',
                            )}`}
                            rightContent={<p>{t('fields.months')}</p>}
                            showPlaceHolder
                            name="durationOfContract"
                            label={t('fields.duration_of_contract_in_months')}
                            error={
                                formik.touched.durationOfContract
                                    ? formik.errors.durationOfContract
                                    : undefined
                            }
                        />
                    )}
                    {(showNonRequiredFields ? true : formik.values.batteryType === 'Loodzuur') &&
                        batteryCapacityOptions &&
                        machineExtraFields?.includes('battery_capacity') && (
                            <Select
                                horizontal
                                required={formik.values.batteryType === 'Loodzuur'}
                                value={formik.values.batteryCapacity}
                                onChange={item =>
                                    formik.setFieldValue('batteryCapacity', item.target.value)
                                }
                                options={
                                    // as per request
                                    // batteryCapacityOptions.length > 0
                                    //     ? batteryCapacityOptions
                                    //     : batteryCapacityStaticOption
                                    batteryCapacityOptions
                                }
                                placeHolderOption={`${t('fields.select')} ${t(
                                    'fields.battery_capacity',
                                )}`}
                                showPlaceHolder
                                name="batteryCapacity"
                                label={t('fields.battery_capacity_amphere')}
                                error={
                                    formik.touched.batteryCapacity
                                        ? formik.errors.batteryCapacity
                                        : undefined
                                }
                            />
                        )}
                    {showNonRequiredFields && (
                        <>
                            {/* <Select
                                horizontal
                                value={formik.values.alternativeFinancing}
                                onChange={item =>
                                    formik.setFieldValue('alternativeFinancing', item.target.value)
                                }
                                options={financeOptions}
                                placeHolderOption={`${t('fields.select')} ${t(
                                    'fields.alternative_financing',
                                )}`}
                                showPlaceHolder
                                name="alternativeFinancing"
                                label={t('fields.alternative_financing')}
                                error={
                                    formik.touched.alternativeFinancing
                                        ? formik.errors.alternativeFinancing
                                        : undefined
                                }
                            /> */}
                            {/* <Select */}
                            {/*    horizontal */}
                            {/*    value={formik.values.numberOfTurns} */}
                            {/*    onChange={item => */}
                            {/*        formik.setFieldValue('numberOfTurns', item.target.value) */}
                            {/*    } */}
                            {/*    options={numberOfTurnsOptions} */}
                            {/*    placeHolderOption="Number of Turns" */}
                            {/*    showPlaceHolder */}
                            {/*    name="numberOfTurns" */}
                            {/*    label="Number of Turns" */}
                            {/*    error={ */}
                            {/*        formik.touched.numberOfTurns */}
                            {/*            ? formik.errors.numberOfTurns */}
                            {/*            : undefined */}
                            {/*    } */}
                            {/* /> */}
                            <Select
                                horizontal
                                value={formik.values.insurance ? 'yes' : 'no'}
                                onChange={item =>
                                    formik.setFieldValue('insurance', item.target.value === 'yes')
                                }
                                options={yesNoOptions}
                                placeHolderOption={`${t('fields.select')} ${t('fields.insurance')}`}
                                showPlaceHolder
                                name="insurance"
                                label={t('fields.insurance')}
                                error={
                                    formik.touched.insurance ? formik.errors.insurance : undefined
                                }
                            />
                            <Select
                                horizontal
                                value={formik.values.showAllPricesSeparate}
                                onChange={item =>
                                    formik.setFieldValue('showAllPricesSeparate', item.target.value)
                                }
                                options={showAllPriceSeparateOptions}
                                placeHolderOption="Show all prices separate"
                                showPlaceHolder
                                name="showAllPricesSeparate"
                                label="Show all prices separate"
                                error={
                                    formik.touched.showAllPricesSeparate
                                        ? formik.errors.showAllPricesSeparate
                                        : undefined
                                }
                            />
                            <Select
                                horizontal
                                value={formik.values.return ? 'yes' : 'no'}
                                onChange={item =>
                                    formik.setFieldValue('return', item.target.value === 'yes')
                                }
                                options={yesNoOptions}
                                placeHolderOption={`${t('fields.select')} ${t('fields.return')}`}
                                showPlaceHolder
                                name="return"
                                label={t('fields.return')}
                                error={formik.touched.return ? formik.errors.return : undefined}
                            />
                            <Input
                                horizontal
                                value={formik.values.replacementMachine}
                                name="replacementMachine"
                                onChange={formik.handleChange}
                                placeholder={`${t('fields.enter')} ${t(
                                    'fields.replacement_machine',
                                )}`}
                                type="text"
                                error={
                                    formik.touched.replacementMachine
                                        ? formik.errors.replacementMachine
                                        : undefined
                                }
                                label={t('fields.replacement_machine')}
                            />
                            {/* <Input */}
                            {/*    horizontal */}
                            {/*    value={formik.values.durationOfContract} */}
                            {/*    name="durationOfContract" */}
                            {/*    onChange={formik.handleChange} */}
                            {/*    placeholder="Enter Duration of Contract in Months" */}
                            {/*    type="number" */}
                            {/*    rightContent={<p>Months</p>} */}
                            {/*    error={ */}
                            {/*        formik.touched.durationOfContract */}
                            {/*            ? formik.errors.durationOfContract */}
                            {/*            : undefined */}
                            {/*    } */}
                            {/*    label="Duration of Contract" */}
                            {/* /> */}
                            <TextArea
                                horizontal
                                value={formik.values.offerAdditionalInformation}
                                name="offerAdditionalInformation"
                                onChange={formik.handleChange}
                                rows={3}
                                error={
                                    formik.touched.offerAdditionalInformation
                                        ? formik.errors.offerAdditionalInformation
                                        : undefined
                                }
                                label={t('fields.additional_information')}
                                placeholder={`${t('fields.enter')} ${t(
                                    'fields.additional_information',
                                )}`}
                            />
                        </>
                    )}
                </div>
            </Panel>
        </Collapse>
    )
}

export default TruckSpecification
