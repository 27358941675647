import ButtonLoader from 'components/buttonLoader'
import Input from 'components/input'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdBlock, MdCancel, MdCheckCircle, MdDone, MdEdit } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { CALCULATION_STATUS } from 'utils/calculationStatus'
import { formatCurrency } from 'utils/currency/formatCurrency'

interface IEditableInputCell {
    originalValue: number
    min?: number
    max?: number
    name: string
    selectedIndex?: number
    category?: number
    profitPercentage?: number
    index?: number
    disabled?: boolean
    success?: boolean
    status?: CALCULATION_STATUS
    error?: boolean
    loading?: boolean
    onApprove?: () => void
    onChange: (value: number) => void
    type?: 'currency' | 'percentage' | 'time' | 'months'
}

const EditableInputCell: React.FC<IEditableInputCell> = props => {
    const {
        name,
        originalValue,
        onChange,
        onApprove: approve,
        success,
        loading,
        selectedIndex,
        index,
        error,
        status,
        disabled,
        category,
        profitPercentage,
        max,
        min,
        type,
    } = props
    const { orderId } = useParams()
    const { user } = useAuth()
    const [tempValue, setTempValue] = useState<number>(originalValue)
    const [edit, setEdit] = useState<boolean>(false)
    const { authorizationMatrix } = useAuth()
    const { t } = useTranslation()
    const [isBeyondAccess, setIsBeyondAccess] = useState<boolean>(false)

    const isFleet = user.roles[0].role_name === 'Fleet'
    const extraCondition = category === 3 ? '3_category,less_than' : '1_2_category,less_than'
    const discountAllInAuthorizationMatrix = isFleet
        ? authorizationMatrix
              .filter(
                  (element: any) =>
                      element.authorization_condition === 'DISCOUNT_ALL_IN' &&
                      element.extra_condition === extraCondition,
              )
              .sort((a: any, b: any) => a.max_value - b.max_value)
        : authorizationMatrix
              .filter((element: any) => element.authorization_condition === 'DISCOUNT_ALL_IN')
              .sort((a: any, b: any) => a.max_value - b.max_value)

    useEffect(() => {
        setTempValue(originalValue || 0)
    }, [originalValue, error, success])

    const checkIsBeyondAccess = (value: number) => {
        switch (name) {
            case 'profit_percentage':
                if (
                    authorizationMatrix?.find(
                        (auth: any) => auth.authorization_condition === 'MARGIN',
                    )?.min_value === '0'
                ) {
                    setIsBeyondAccess(true)
                } else if (
                    authorizationMatrix?.find(
                        (auth: any) => auth.authorization_condition === 'MARGIN',
                    )?.min_value <= value
                ) {
                    setIsBeyondAccess(false)
                } else if (user.roles[0].id === ROLE_TYPE.managing_director) {
                    setIsBeyondAccess(false)
                } else {
                    setIsBeyondAccess(true)
                }
                break
            case 'extraResidualValue':
                if (
                    authorizationMatrix?.find(
                        (auth: any) => auth.authorization_condition === 'RESIDUAL_VALUE',
                    )?.max_value < value
                ) {
                    setIsBeyondAccess(true)
                } else {
                    setIsBeyondAccess(false)
                }
                break
            case 'discount_service_all_in':
                if (
                    discountAllInAuthorizationMatrix[0].extra_condition === 'ALL' ||
                    Number(
                        discountAllInAuthorizationMatrix.find(
                            (a: any) => Number(a.max_value) >= Number(profitPercentage),
                        )?.value,
                    ) >= value
                ) {
                    setIsBeyondAccess(false)
                } else if (
                    discountAllInAuthorizationMatrix === null ||
                    discountAllInAuthorizationMatrix.length === 0
                ) {
                    setIsBeyondAccess(false)
                } else if (value === 0) {
                    setIsBeyondAccess(false)
                } else {
                    setIsBeyondAccess(true)
                }
                break
            case 'discount_service_pm':
                if (
                    authorizationMatrix?.find(
                        (auth: any) => auth.authorization_condition === 'DISCOUNT_PM',
                    )?.max_value < value
                ) {
                    setIsBeyondAccess(true)
                } else {
                    setIsBeyondAccess(false)
                }
                break
            default:
                break
        }
    }

    useEffect(() => {
        checkIsBeyondAccess(tempValue)
    }, [authorizationMatrix, tempValue, profitPercentage])

    const handleChange = (value: number) => {
        checkIsBeyondAccess(value)
        setTempValue(value)
    }

    if (loading && selectedIndex === index) {
        return (
            <div className="flex w-full bg-gray-200 justify-end items-center gap-2">
                <ButtonLoader />
            </div>
        )
    }

    return (
        <div className="flex w-full justify-end items-center gap-2">
            {!edit ? (
                <>
                    <div
                        className={`${
                            status === CALCULATION_STATUS.ADJUSTED
                                ? 'text-warningColor'
                                : status === CALCULATION_STATUS.APPROVED
                                ? 'text-successColor'
                                : ''
                        }`}
                    >
                        <p
                            className={`${
                                status === CALCULATION_STATUS.ADJUSTED
                                    ? 'text-warningColor'
                                    : status === CALCULATION_STATUS.APPROVED
                                    ? 'text-successColor'
                                    : 'text-black'
                            }  text-right w-full text-bold`}
                        >
                            {type === 'currency' && formatCurrency(tempValue)}
                            {type === 'percentage' && `${tempValue}%`}
                            {type === 'time' && `${tempValue} ${t('api.hrs')}`}
                            {type === 'months' && `${tempValue} ${t('api.months')}`}
                            {!type && tempValue}
                        </p>
                    </div>
                    {!disabled && (
                        <div className="flex gap-2">
                            <MdEdit
                                className="mb-1 cursor-pointer"
                                size="16"
                                title={t('api.edit')}
                                onClick={() => setEdit(true)}
                            />
                            {status !== CALCULATION_STATUS.APPROVED && approve && (
                                <MdCheckCircle
                                    className={`mb-1 cursor-pointer ${
                                        !isBeyondAccess ? 'text-green-600' : 'text-dangerColor'
                                    } hover:brightness-125`}
                                    size="16"
                                    title={t('api.approve')}
                                    onClick={() => {
                                        if (!isBeyondAccess && approve) {
                                            approve()
                                        }
                                    }}
                                />
                            )}
                        </div>
                    )}
                </>
            ) : (
                <Input
                    name={name}
                    type="number"
                    min={min}
                    max={max}
                    placeholder="0"
                    disabled={disabled}
                    isBeyondAccess={isBeyondAccess}
                    customInputStyles="p-0 text-right"
                    inputWidth="w-[7rem]"
                    value={tempValue}
                    rightContent={
                        !disabled ? (
                            <div className="flex gap-2">
                                <MdDone
                                    className="cursor-pointer hover:text-green-300"
                                    size={16}
                                    title={t('api.ok')}
                                    onClick={() => {
                                        onChange(tempValue)
                                        setEdit(false)
                                    }}
                                />
                                <MdCancel
                                    className="cursor-pointer hover:text-dangerColor"
                                    size={16}
                                    title={t('api.cancel')}
                                    onClick={() => {
                                        setEdit(false)
                                        setTempValue(originalValue)
                                    }}
                                />
                            </div>
                        ) : undefined
                    }
                    onChange={e => handleChange(parseFloat(e.target.value))}
                />
            )}
        </div>
    )
}

export default EditableInputCell
