import { Collapse } from 'antd'
import Button from 'components/button'
import Input from 'components/input'
import Loading from 'components/loading'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AiOutlineDelete } from 'react-icons/ai'
import DeleteModal from 'components/modal/deleteModal'
import RequirementMatrixQueries from '../queries/requirementMatrix'
import RequirementMatrixForm from './RequirementMatrixForm'

const RequirementMatrix = () => {
    const { t } = useTranslation()
    const { orderId, quoteId } = useParams()
    const [selectedSubjectId, setSelectedSubjectId] = useState<number>()
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const { Panel } = Collapse
    const [showAddSubjectForm, setShowAddSubjectForm] = useState<boolean>(false)
    const [subjectName, setSubjectName] = useState<string>('')
    const [subject, setSubject] = useState<any[]>()

    const { isLoading: subjectLoading, refetch } = useQuery(
        ['getAllSubject'],
        () => RequirementMatrixQueries.getSubject(parseInt(String(quoteId), 10)),
        {
            onSuccess: (d: any) => {
                setSubject(d.data.data)
            },
            onError: () => {
                toast.error(t('api.fetch_question_failed'))
            },
        },
    )

    const { data: questionData, isLoading } = useQuery(
        ['getAllQuestion'],
        () => RequirementMatrixQueries.getQuestions(),
        {
            onError: () => {
                toast.error(t('api.fetch_question_failed'))
            },
        },
    )

    const { data: requirementMatrixData, isLoading: requirementMatrixDataLoading } = useQuery(
        ['getAllRequirementMatrixByQuoteId'],
        () =>
            RequirementMatrixQueries.findRequirementMatrixByQuoteId(parseInt(String(quoteId), 10)),
        {
            enabled: !!quoteId,
            onError: () => {
                toast.error(t('api.fetch_question_failed'))
            },
        },
    )

    const addRequirementMatrixSubject = useMutation(
        ['addRequirementMatrix'],
        () =>
            RequirementMatrixQueries.addSubject({
                quote_id: parseInt(String(quoteId), 10),
                subject: subjectName,
            }),
        {
            onSuccess: () => {
                toast.success(t('message.subject_add_success'))
                refetch()
                setShowAddSubjectForm(false)
            },
            onError: () => {
                toast.error(t('message.subject_add_error'))
            },
        },
    )

    const deleteRequirementMatrixSubject = useMutation(
        ['deleteRequirementMatrix'],
        (id: number) => RequirementMatrixQueries.deleteRequirementMatrix(id),
        {
            onSuccess: () => {
                toast.success(t('message.subject_delete_success'))
                refetch()
            },
            onError: () => {
                toast.error(t('message.subject_delete_error'))
            },
        },
    )

    return (
        <div className="h-[74vh]">
            <div className="flex justify-between w-full">
                <p className="mb-4 text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {t('api.requirement_matrix')}
                </p>
                {!showAddSubjectForm && (
                    <div>
                        <Button
                            disabled={Boolean(orderId)}
                            type="button"
                            onClick={() => setShowAddSubjectForm(true)}
                            variant="primary"
                            label={t('api.add_subject')}
                        />
                    </div>
                )}
            </div>
            {showAddSubjectForm && (
                <form
                    className="flex w-full items-end gap-4 my-4 flex-col"
                    onSubmit={event => {
                        addRequirementMatrixSubject.mutate()
                        event.preventDefault()
                    }}
                >
                    <Input
                        name="subject"
                        value={subjectName}
                        onChange={e => setSubjectName(e.target.value)}
                        label={t('api.subject_name')}
                        horizontal
                    />
                    <div>
                        <Button
                            isLoading={addRequirementMatrixSubject.isLoading}
                            type="submit"
                            variant="outline"
                            label={t('api.save')}
                        />
                    </div>
                </form>
            )}
            {subjectLoading || isLoading || requirementMatrixDataLoading ? (
                <div className="justify-center items-center h-full w-full">
                    <Loading />
                </div>
            ) : (
                <Collapse accordion>
                    {subject && subject?.length > 0 ? (
                        subject?.map((sub: any) => (
                            <Panel
                                header={
                                    <div className="w-full flex items-center justify-between">
                                        <p>{sub.subject}</p>
                                        <AiOutlineDelete
                                            size={25}
                                            onClick={(e: any) => {
                                                setSelectedSubjectId(sub.id)
                                                setDeleteModal(true)
                                                e.stopPropagation()
                                            }}
                                        />
                                    </div>
                                }
                                key={sub.id}
                            >
                                {questionData?.data?.data.length > 0 ? (
                                    questionData?.data?.data.map((question: any, index: number) => (
                                        <>
                                            <RequirementMatrixForm
                                                question={question}
                                                answer={requirementMatrixData?.data.data.find(
                                                    (element: any) =>
                                                        element.question_id === question.id &&
                                                        element.subject_id === sub.id,
                                                )}
                                                subjectId={sub.id}
                                                key={index}
                                            />
                                            {questionData.data.data.length - 1 !== index && (
                                                <div className="mb-4 bg-primary w-24 h-[4px]" />
                                            )}
                                        </>
                                    ))
                                ) : (
                                    <div className="flex justify-center items-center p-4">
                                        <p>{t('api.no_question_available_please_contact_admin')}</p>
                                    </div>
                                )}
                            </Panel>
                        ))
                    ) : (
                        <p>{t('api.no_subject_added_please_add_subject')}</p>
                    )}
                </Collapse>
            )}
            <DeleteModal
                modal={deleteModal}
                closeModal={() => {
                    setDeleteModal(false)
                }}
                onConfirm={() =>
                    selectedSubjectId && deleteRequirementMatrixSubject.mutate(selectedSubjectId)
                }
            />
        </div>
    )
}

export default RequirementMatrix
