import Button from 'components/button'
import Stepper from 'components/stepper'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { pick, omit } from 'lodash'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useAuth } from 'modules/auth/context/useAuth'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import OrderDealerForm from '../component/OrderDealerForm'
import OrderQuoteForm from '../component/OrderQuoteForm'
import OrderConfigurationForm from './OrderConfigurationForm'
import { saveOrderFormData, updateOrderFormData } from '../queries/order'

const OrderFormLayout = (props: { order: any; configurations: any; orderId: string }) => {
    const { orderId, order, configurations } = props
    const { user, userExtra } = useAuth()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [step, setStep] = useState(0)

    const changeScreen = (key: string) => {
        switch (key) {
            case 'next':
                setStep(step + 1)
                break
            case 'previous':
                setStep(step - 1)
                break
            default:
                setStep(0)
                break
        }
    }

    const submitOrderMutation = useMutation(
        'submitOrder',
        (orderFormData: any) => updateOrderFormData(orderId, orderFormData, user.id),
        {
            onSuccess: () => {
                toast.success(t('message.order_submitted_success'))
                if (step === 2) {
                    navigate(`/order`)
                } else {
                    changeScreen('next')
                }
            },
            onError: () => {
                toast.error(t('message.order_submitted_failed'))
            },
        },
    )

    const saveOrderForm = useMutation(
        'saveOrder',
        (orderFormData: any) => saveOrderFormData(orderId, orderFormData, user.id),
        {
            onSuccess: () => {
                toast.success(t('message.order_update_success'))
                if (step === 2) {
                    navigate(`/order`)
                } else {
                    changeScreen('next')
                }
            },
            onError: () => {
                toast.error(t('message.order_update_failed'))
            },
        },
    )

    const validationSchemaList = [
        Yup.object().shape({
            dealer_name: Yup.string().required(t('message.name_is_required')),
            v55_email_address: Yup.string()
                .email(t('message.invalid_email'))
                .required(t('message.email_required')),
            country: Yup.string().required(t('message.country_required')),

            state: Yup.string().required(t('message.state_required')),
            city: Yup.string().required(t('message.city_required')),
            postal_code: Yup.string().required(t('message.postal_code_required')),
            address1: Yup.string().required(t('message.address_1_required')),

            delivery_option: Yup.string().required(t('message.delivery_option_required')),
            // delivery_for_retail: Yup.string().required('Delivery Retail is required'),
        }),
        Yup.object().shape({
            // finance_account_number: Yup.string().required('Finance account number is required'),
        }),
    ]

    const formik = useFormik({
        initialValues: {
            is_update: order?.customer_id !== null ? 1 : 0 || 0,
            dealer_name: order?.customer_name || '',
            v55_email_address: order?.customer_v55_email_address || '',
            order_number: order.id || '',
            order_date: moment(order?.order_date),
            order_name: order?.order_name || '',
            purchase_order_number: order?.purchase_order_number || '',
            end_user_name: order?.quotation_for || '',

            country: order?.country_id || '',
            state: order?.customer_state || '',
            city: order?.customer_city || '',
            postal_code: order?.customer_postal_code || '',
            address1: order?.customer_address1 || '',
            address2: order?.customer_address2 || '',

            delivery_option: 0,
            delivery_for_retail: order?.delivery_type || '',

            finance_account_number: order?.customer_account_number || '',
            finance_company_name: order?.finance_name || '',
            exisiting_finance_company: order?.finance_id || '',
            finance_company_exists: true,

            dealer_request_date: moment(order?.expected_date) || moment(),
            expected_delivery_date: moment(order?.expected_date) || moment(),
            payment_terms: false,
            is_dealer: false,
            type: false,

            name: order?.name || '',
            email: order?.email || '',

            orderBy: user?.name || '',
            orderByEmail: user?.email || '',

            configurations: configurations?.map((item: any, index: number) => {
                return {
                    configuration_id: item?.configuration[index]?.configuration_id || '',
                    serial_number: item?.configuration[index]?.serial_number || '',
                    trade_in_information: item?.configuration[index]?.trade_in_information || '',
                    special_instruction: item?.configuration[index]?.special_instruction || '',
                    item,
                }
            }),
        },
        validationSchema: validationSchemaList[step],
        onSubmit: values => {
            const orderFormData = {
                ...values,
                configurations: (values.configurations || []).map((configuration: any) =>
                    pick(configuration, [
                        'configuration_id',
                        'serial_number',
                        'trade_in_information',
                        'special_instruction',
                        'orderBy',
                        'orderByEmail',
                    ]),
                ),
            }
            if (step < 2) {
                saveOrderForm.mutate(
                    omit(orderFormData, [
                        'is_dealer',
                        'email',
                        'end_user_name',
                        'name',
                        'order_date',
                        'order_number',
                        'order_name',
                    ]),
                )
            }
            if (step === 2) {
                if (userExtra.submit_order_form) {
                    submitOrderMutation.mutate(
                        omit(orderFormData, [
                            'is_dealer',
                            'email',
                            'end_user_name',
                            'name',
                            'order_date',
                            'order_number',
                            'order_name',
                        ]),
                    )
                } else {
                    saveOrderForm.mutate(
                        omit(orderFormData, [
                            'is_dealer',
                            'email',
                            'end_user_name',
                            'name',
                            'order_date',
                            'order_number',
                            'order_name',
                        ]),
                    )
                }
            }
        },
    })

    useEffect(() => {
        formik.setFieldValue(
            'configurations',
            configurations?.map((item: any, index: number) => {
                return {
                    configuration_id: item?.configuration[index]?.configuration_id || '',
                    serial_number: item?.configuration[index]?.serial_number || '',
                    trade_in_information: item?.configuration[index]?.trade_in_information || '',
                    special_instruction: item?.configuration[index]?.special_instruction || '',
                    item,
                }
            }),
        )
    }, [configurations])

    useEffect(() => {
        formik.setFieldValue('is_update', order?.customer_id !== null ? 1 : 0 || 0)
        formik.setFieldValue('dealer_name', order?.customer_name || '')
        formik.setFieldValue('v55_email_address', order?.customer_v55_email_address || '')
        formik.setFieldValue('order_number', order.id || '')
        formik.setFieldValue('order_date', moment(order?.order_date))
        formik.setFieldValue('order_name', order?.order_name || '')
        formik.setFieldValue('purchase_order_number', order?.purchase_order_number || '')
        formik.setFieldValue('end_user_name', order?.quotation_for || '')
        formik.setFieldValue('country', order?.country_id || '')
        formik.setFieldValue('state', order?.customer_state || '')
        formik.setFieldValue('city', order?.customer_city || '')
        formik.setFieldValue('postal_code', order?.customer_postal_code || '')
        formik.setFieldValue('address1', order?.customer_address1 || '')
        formik.setFieldValue('address2', order?.customer_address2 || '')
        formik.setFieldValue('delivery_option', order?.delivery_option || 0)
        formik.setFieldValue('finance_account_number', order?.customer_account_number || '')
        formik.setFieldValue('finance_company_name', order?.finance_name || '')
        formik.setFieldValue('exisiting_finance_company', order?.finance_id || '')
        formik.setFieldValue('dealer_request_date', moment(order?.expected_date) || moment())
        formik.setFieldValue('expected_delivery_date', moment(order?.expected_date) || moment())
        formik.setFieldValue('type', parseInt(order?.customer_type, 10) === 1)
        formik.setFieldValue('payment_terms', parseInt(order?.payment_terms, 10) === 1)
        formik.setFieldValue('email', order?.email || '')
        formik.setFieldValue('orderBy', user?.name || '')
        formik.setFieldValue('orderByEmail', user?.email || '')
    }, [order])

    const steppers = [
        {
            id: 0,
            step: 0,
            label: t('api.order_form_configuration'),
            component: <OrderDealerForm formik={formik} />,
        },
        {
            id: 1,
            step: 1,
            label: t('api.order_quote_an_finance_configuration'),
            component: <OrderQuoteForm formik={formik} />,
        },
        {
            id: 2,
            step: 2,
            label: t('api.order_configuration'),
            component: <OrderConfigurationForm formik={formik} />,
        },
    ]
    return (
        <div className="flex h-full flex-col gap-4">
            <div className="h-[6%]">
                <Stepper steppers={steppers} activeStep={steppers[step]} />
            </div>
            <div className="h-[84%] overflow-y-scroll">{steppers[step].component}</div>
            <div className="h-[4%] flex items-center justify-end gap-8">
                {step > 0 && (
                    <div className="w-28">
                        <Button
                            label={t('api.previous')}
                            variant="outline"
                            onClick={() => changeScreen('previous')}
                        />
                    </div>
                )}
                {step < steppers.length - 1 && (
                    <div className="w-28">
                        <Button
                            isLoading={submitOrderMutation.isLoading || saveOrderForm.isLoading}
                            label={t('api.next')}
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                )}
                {step === steppers.length - 1 && (
                    <div className="w-28">
                        <Button
                            isLoading={submitOrderMutation.isLoading || saveOrderForm.isLoading}
                            label={`${
                                userExtra.submit_order_form ? t('api.submit') : t('api.update')
                            }`}
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrderFormLayout
