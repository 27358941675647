import { useState } from 'react'
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { MdOutlineClose } from 'react-icons/md'
import Loading from './loading'

interface SlideshowProps {
    showSlideShow: boolean
    setShowSlideShow: (showSlideShow: boolean) => void
    images: string[]
    loading: boolean
}

const ImageSlideShow: React.FC<SlideshowProps> = props => {
    const { images, loading, setShowSlideShow, showSlideShow } = props
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const { t } = useTranslation()

    const goToPreviousImage = () => {
        setCurrentImageIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1))
    }

    const goToNextImage = () => {
        setCurrentImageIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1))
    }

    const closeSlideShow = () => {
        setShowSlideShow(false)
    }

    if (loading) {
        return (
            <div className="w-full h-full flex flex-col items-center justify-center bg-black">
                <div className="fixed inset-0 flex z-100 items-center justify-center bg-black bg-opacity-75">
                    <Loading />
                </div>
            </div>
        )
    }

    if (!showSlideShow) {
        return null
    }

    if (images.length === 0) {
        return (
            <div className="w-full h-full flex flex-col items-center justify-center bg-black">
                <div className="fixed inset-0 flex z-100 items-center justify-center bg-black bg-opacity-75">
                    <button
                        type="button"
                        className="absolute right-4 top-4 bg-black bg-opacity-80 hover:bg-opacity-50 text-white font-bold p-2 rounded-full"
                        onClick={closeSlideShow}
                    >
                        <MdOutlineClose size={25} className="text-white" />
                    </button>
                    <p>{t('message.no_image_available')}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full h-full flex flex-col items-center justify-center bg-black">
            <div className="fixed inset-0 flex z-100 items-center justify-center bg-black bg-opacity-75">
                <button
                    type="button"
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-80 hover:bg-opacity-50 text-white font-bold py-2 px-4 rounded"
                    onClick={goToPreviousImage}
                >
                    <IoMdArrowDropleft size={25} />
                </button>
                <button
                    type="button"
                    className="absolute right-4 top-4 bg-black bg-opacity-80 hover:bg-opacity-50 text-white font-bold p-2 rounded-full"
                    onClick={closeSlideShow}
                >
                    <MdOutlineClose size={25} className="text-white" />
                </button>
                <img
                    className="mx-4 object-contain bg-no-repeat"
                    src={`${process.env.REACT_APP_S3_IMAGE_URL}${images[currentImageIndex]}`}
                    alt={images[currentImageIndex]}
                />
                <button
                    type="button"
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-80 hover:bg-opacity-50 text-white font-bold py-2 px-4 rounded"
                    onClick={goToNextImage}
                >
                    <IoMdArrowDropright size={25} />
                </button>
            </div>
        </div>
    )
}

export default ImageSlideShow
