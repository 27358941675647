import { useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import moment from 'moment'
import Fuse from 'fuse.js'

import { Tooltip } from 'antd'
import SiteSurveyTabs from './components/SiteSurveyTabs'
import Table from '../../components/table'
import { SiteSurveyQueries } from './queries/siteSurveyQueries'
import TableAction from './components/TableActions'
import useTranslationData from '../translation/hooks/useTranslationData'

const cardClass = 'h-full w-ful bg-white p-4 rounded-md flex-1'

const SiteSurvey = () => {
    const [status, setStatus] = useState('not-connected')
    const {
        translation: { t },
    } = useTranslationData()

    const [activeTab, setActiveTab] = useState<number>(1)
    const [pattern, setPattern] = useState<string>('')

    const handleTabChanged = (tab: number) => {
        setActiveTab(tab)
    }

    const { data, isLoading, refetch } = useQuery(
        'getAllSiteSurvey',
        () => SiteSurveyQueries.getAllSiteSurvey(),
        {
            onError: () => {
                toast.error('Something went worng, please try again')
            },
        },
    )

    const getTabRowAction = (row: any) => {
        return <TableAction activeTab={activeTab} row={row} refetch={refetch} />
    }

    const columns = [
        {
            name: t('api.id'),
            selector: (row: any) => row.id,
            width: '6rem',
        },
        {
            name: t('api.customer'),
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: any) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row?.customer}</p>
                    </div>
                )
            },
        },
        {
            name: t('api.contact_person'),
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: any) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row?.contact_person}</p>
                    </div>
                )
            },
        },
        {
            name: t('api.model'),
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: any) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row?.models.name}</p>
                    </div>
                )
            },
        },
        {
            name: t('api.connected'),
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: any) => {
                const text = (
                    <div className="flex flex-col gap-2 items-start">
                        <div className="flex items-center justify-start gap-2">
                            <span>Quote Number:</span>
                            <span>{row?.novo_quote_number ? row?.novo_quote_number : 'N/A'}</span>
                        </div>
                        <div className="flex items-center justify-start gap-2">
                            <span>Model:</span>
                            <span>{row?.models ? row?.models?.name : 'N/A'}</span>
                        </div>
                    </div>
                )
                return (
                    <Tooltip className="cursor-pointer" title={row?.site_survey_id ? text : ''}>
                        <span className="cursor-pointer">
                            {row?.site_survey_id ? t('api.connected') : t('api.not_connected')}
                        </span>
                    </Tooltip>
                )
            },
        },
        {
            name: t('api.date'),
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: any) => {
                return (
                    <div>
                        <p className="text-black text-bold">
                            {moment(row?.date).format('YYYY MMM DD')}
                        </p>
                    </div>
                )
            },
        },
        {
            name: t('api.action'),
            center: true,
            cell: (row: any) => getTabRowAction(row),
        },
    ]

    const fuse = new Fuse(data ? data?.data?.data : [], {
        shouldSort: true,
        threshold: 0.3,
        keys: ['customer', 'contact_person', 'models.name'],
    })

    const results = fuse.search(pattern)
    const novoConfigurationResults = pattern
        ? results.map(siteSurvey => siteSurvey.item)
        : data?.data?.data

    const onChangeStatus = (value: string) => {
        setStatus(value)
    }

    const getFilterData = () => {
        let tempDataList = novoConfigurationResults
        if (status !== 'all' && status !== 'connected') {
            tempDataList = tempDataList?.filter(
                (item: { site_survey_id: number }) => !item.site_survey_id,
            )
        }
        if (status !== 'all' && status === 'connected') {
            tempDataList = tempDataList?.filter(
                (item: { site_survey_id: number }) => item.site_survey_id,
            )
        }
        return tempDataList
    }

    return (
        <div className={cardClass}>
            <div className="min-h-fit">
                <SiteSurveyTabs
                    activeTab={activeTab}
                    setActiveTab={(index: number) => handleTabChanged(index)}
                    pattern={pattern}
                    setPattern={setPattern}
                    onChangeStatus={onChangeStatus}
                />
            </div>
            <div className=" border border-gray rounded-md">
                <Table
                    columns={columns}
                    data={getFilterData() || []}
                    loading={isLoading}
                    striped
                    pagination
                    totalRows={10}
                    tableHeight="94%"
                />
            </div>
        </div>
    )
}

export default SiteSurvey
