import { Descriptions } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import { useTranslation } from 'react-i18next'
import { getCurrencySymbol } from '../../../../utils/currency/formatCurrency'
import Input from '../../../../components/input'
import Quantity from '../../../../components/quantity'

interface ConfigurationCard {
    configurationName: string
    productCategory: string
    retailPrice: number
    discountPercentage: number
    discountPercentageAmount: number
    netTotal: number
    oemDiscountAmount: number
    netNetPurchasePrice: number
    extraDiscount: number
    extraExtraDiscountPercentageAmount: number
    netNetNetPurchasePrice: number
    endCustomerPurchasePrice: number
    configurationQuantity: number
    currencySymbol: string
    discountAmount: number
    customerDiscountPercentage: number
    dealerDiscountAmount: number
    handelDealerDiscountChange: (amount: number) => void
    salesMarginAmount: number
    salesMarginPercentage: number
    totalCostPriceAllMachine: number
    totalPrice: number
    totalMargin: number
    retailMargin: number
    isLibrary: boolean
    showIsLibrary?: boolean
    setIsLibrary: (name: string, value: boolean) => void
    setDiscountPercentage: (amount: number) => void
    handleDiscountAmountChange: (amount: number) => void
    handleOemDiscountAmountChange: (amount: number) => void
    handleExtraDiscountChanges: (amount: number) => void
    handleEndCustomerPurchasePriceChanges: (amount: number) => void
    setConfigurationQuantity: (name: string, quantity: number) => void
    showDealerView: boolean
    userRole: string
    isCostPriceVisible: boolean
    isProfitMarginVisible: boolean
    isOemEditable: boolean
    isExtraExtraDiscountEditable: boolean
    isSalesMarginVisible: boolean
    isCustomerDiscountVisible: boolean
    landedCost: number
    tradeInValue: number
    setTradeInValue: (amount: number) => void
}

const ConfigurationCard = (props: ConfigurationCard) => {
    const {
        configurationName,
        productCategory,
        retailPrice,
        currencySymbol,
        discountAmount,
        handleDiscountAmountChange,
        discountPercentageAmount,
        discountPercentage,
        dealerDiscountAmount,
        handelDealerDiscountChange,
        netTotal,
        oemDiscountAmount,
        setDiscountPercentage,
        handleOemDiscountAmountChange,
        netNetPurchasePrice,
        extraDiscount,
        handleExtraDiscountChanges,
        extraExtraDiscountPercentageAmount,
        netNetNetPurchasePrice,
        endCustomerPurchasePrice,
        totalPrice,
        configurationQuantity,
        setConfigurationQuantity,
        customerDiscountPercentage,
        handleEndCustomerPurchasePriceChanges,
        salesMarginPercentage,
        salesMarginAmount,
        totalCostPriceAllMachine,
        totalMargin,
        retailMargin,
        showDealerView,
        userRole,
        isExtraExtraDiscountEditable,
        isCostPriceVisible,
        isProfitMarginVisible,
        isSalesMarginVisible,
        isOemEditable,
        isCustomerDiscountVisible,
        isLibrary,
        showIsLibrary,
        setIsLibrary,
        landedCost,
        tradeInValue,
        setTradeInValue,
    } = props

    const { t } = useTranslation()

    return (
        <div className="container w-full rounded-sm">
            <Descriptions title="" layout="horizontal" column={{ xs: 1, sm: 1, md: 2 }} bordered>
                <DescriptionsItem label={t('api.configuration_name')}>
                    {configurationName}
                </DescriptionsItem>
                <DescriptionsItem label={t('api.product_category')}>
                    {productCategory}
                </DescriptionsItem>
                <DescriptionsItem label={t('api.retail_price')}>{retailPrice}</DescriptionsItem>
                <DescriptionsItem label={t('api.discount_percentage')}>
                    <div className="flex items-center gap-2">
                        <div className="w-28">
                            <Input
                                customInputStyles="w-24 py-0 px-1"
                                type="number"
                                value={discountPercentage}
                                name="discount_amount"
                                onChange={e => setDiscountPercentage(parseFloat(e.target.value))}
                            />
                        </div>
                        <p className="font-bold text-lg">%</p>
                    </div>
                </DescriptionsItem>
                <DescriptionsItem label={t('api.discount_percentage_amount')}>
                    {discountPercentageAmount.toFixed(2)}
                    {getCurrencySymbol()}
                </DescriptionsItem>
                <DescriptionsItem label={t('api.discount_amount')}>
                    <div className="flex items-center gap-2">
                        <div className="w-28">
                            <Input
                                customInputStyles="w-24 py-0 px-1"
                                type="number"
                                value={discountAmount}
                                name="discount_amount"
                                onChange={e =>
                                    handleDiscountAmountChange(parseFloat(e.target.value))
                                }
                            />
                        </div>
                        <p className="font-bold text-md"> {getCurrencySymbol()}</p>
                    </div>
                </DescriptionsItem>
                <DescriptionsItem label={t('api.net_total')}>
                    <div className="flex items-center gap-1">
                        <div>{netTotal.toFixed(2)}</div>
                        <p> {getCurrencySymbol()}</p>
                    </div>
                </DescriptionsItem>
                {!userRole.toLowerCase().includes('customer') ? (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        {showDealerView ? (
                            <>
                                <DescriptionsItem label={t('api.standard_dealer_percentage')}>
                                    {discountPercentage?.toFixed(2)}
                                </DescriptionsItem>
                                <DescriptionsItem label={t('api.dealer_net_percentage_amount')}>
                                    {discountPercentageAmount.toFixed(2)}
                                    {getCurrencySymbol()}
                                </DescriptionsItem>
                                <DescriptionsItem label={t('api.dealer_net_total')}>
                                    <div className="flex items-center gap-1">
                                        <div>{netTotal.toFixed(2)}</div>
                                        <p> {getCurrencySymbol()}</p>
                                    </div>
                                </DescriptionsItem>
                                {isOemEditable ? (
                                    <DescriptionsItem label={t('api.oem_discount_amount')}>
                                        <div className="flex items-center gap-2">
                                            <div className="w-28">
                                                <Input
                                                    customInputStyles="w-24 py-0 px-1"
                                                    type="number"
                                                    value={oemDiscountAmount}
                                                    name="discount_amount"
                                                    onChange={e =>
                                                        handleOemDiscountAmountChange(
                                                            parseFloat(e.target.value),
                                                        )
                                                    }
                                                />
                                            </div>
                                            <p className="font-bold text-md">
                                                {getCurrencySymbol()}
                                            </p>
                                        </div>
                                    </DescriptionsItem>
                                ) : (
                                    <DescriptionsItem label={t('api.oem_discount_amount')}>
                                        {oemDiscountAmount} {getCurrencySymbol()}
                                    </DescriptionsItem>
                                )}
                                <DescriptionsItem label={t('api.net_net_purchase_price')}>
                                    <div className="flex items-center gap-1">
                                        <div>{netNetPurchasePrice.toFixed(2)}</div>
                                        <p> {getCurrencySymbol()}</p>
                                    </div>
                                </DescriptionsItem>
                                {isExtraExtraDiscountEditable ? (
                                    <DescriptionsItem label={t('api.extra_discount')}>
                                        <div className="flex item-center gap-2">
                                            <div className="w-28">
                                                <Input
                                                    customInputStyles="w-24 py-0 px-1"
                                                    type="number"
                                                    value={extraDiscount || 0}
                                                    name="discount_amount"
                                                    onChange={e =>
                                                        handleExtraDiscountChanges(
                                                            parseFloat(e.target.value || '0'),
                                                        )
                                                    }
                                                />
                                            </div>
                                            <p className="font-bold text-lg">%</p>
                                        </div>
                                    </DescriptionsItem>
                                ) : (
                                    <DescriptionsItem label={t('api.extra_discount')}>
                                        <div className="flex items-center gap-2">
                                            <div>{extraDiscount.toFixed(2)}</div>
                                            <p className="font-bold text-lg">%</p>
                                        </div>
                                    </DescriptionsItem>
                                )}
                                <DescriptionsItem label={t('api.extra_extra_support')}>
                                    <div className="flex items-center gap-2">
                                        <div>{extraExtraDiscountPercentageAmount.toFixed(2)} </div>
                                        <p className="font-bold text-md">{getCurrencySymbol()}</p>
                                    </div>
                                </DescriptionsItem>
                                <DescriptionsItem label={t('api.net_net_net_price')}>
                                    <div className="flex items-center gap-2">
                                        <div>{netNetNetPurchasePrice.toFixed(2)} </div>
                                        <p className="font-bold text-md">{getCurrencySymbol()}</p>
                                    </div>
                                </DescriptionsItem>
                                {isSalesMarginVisible && (
                                    <>
                                        <DescriptionsItem
                                            label={t('api.end_customer_purchase_price')}
                                        >
                                            <div className="flex items-center gap-2">
                                                <div className="w-28">
                                                    <Input
                                                        customInputStyles="w-24 py-0 px-1"
                                                        type="number"
                                                        value={endCustomerPurchasePrice}
                                                        name="end_customer_purchase_price"
                                                        onChange={e =>
                                                            handleEndCustomerPurchasePriceChanges(
                                                                parseFloat(e.target.value),
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <p className="font-bold text-md">
                                                    {getCurrencySymbol()}
                                                </p>
                                            </div>
                                        </DescriptionsItem>
                                        <DescriptionsItem label={t('api.dealer_margin_amount')}>
                                            <div className="flex items-center gap-2">
                                                <div>
                                                    {salesMarginAmount > 0
                                                        ? salesMarginAmount.toFixed(2)
                                                        : 0.0}{' '}
                                                </div>
                                                <p className="font-bold text-md">
                                                    {getCurrencySymbol()}
                                                </p>
                                            </div>
                                        </DescriptionsItem>
                                        <DescriptionsItem label={t('api.dealer_margin')}>
                                            {salesMarginPercentage > 0
                                                ? salesMarginPercentage.toFixed(2)
                                                : 0.0}{' '}
                                            {' %'}
                                        </DescriptionsItem>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {isCustomerDiscountVisible && (
                                    <DescriptionsItem label={t('api.customer_discount')}>
                                        {customerDiscountPercentage.toFixed(2)} {' %'}
                                    </DescriptionsItem>
                                )}
                                {isExtraExtraDiscountEditable && (
                                    <DescriptionsItem label={t('api.extra_discount')}>
                                        <div className="flex items-center gap-2">
                                            <div className="w-28">
                                                <Input
                                                    customInputStyles="w-24 py-0 px-1"
                                                    type="number"
                                                    value={dealerDiscountAmount || 0}
                                                    name="dealer_discount_amount"
                                                    onChange={e =>
                                                        handelDealerDiscountChange(
                                                            parseFloat(e.target.value || '0'),
                                                        )
                                                    }
                                                />
                                            </div>
                                            <p className="font-bold text-md">
                                                {getCurrencySymbol()}
                                            </p>
                                        </div>
                                    </DescriptionsItem>
                                )}
                            </>
                        )}
                    </>
                ) : null}
                {showIsLibrary && (
                    <DescriptionsItem label={t('api.library_configuration')}>
                        <div className="w-28">
                            <input
                                type="checkbox"
                                checked={isLibrary}
                                name="Library Configuration"
                                onChange={() => setIsLibrary('isLibrary', !isLibrary)}
                            />
                        </div>
                    </DescriptionsItem>
                )}
                <DescriptionsItem label={t('api.configuration_quantity')}>
                    <Quantity
                        name="quantity"
                        quantity={configurationQuantity}
                        min={1}
                        max={20}
                        setQuantity={(quantity = 1) => {
                            setConfigurationQuantity('quantity', quantity)
                        }}
                    />
                </DescriptionsItem>
                {isCostPriceVisible && !showDealerView && (
                    <DescriptionsItem label={t('api.total_cost_all_machines')}>
                        {totalCostPriceAllMachine.toFixed(2)} {getCurrencySymbol()}
                    </DescriptionsItem>
                )}
                {!showDealerView && (
                    <DescriptionsItem label={t('api.total_price_all_machine')}>
                        {totalPrice.toFixed(2)} {getCurrencySymbol()}
                    </DescriptionsItem>
                )}

                {!showDealerView && isProfitMarginVisible && (
                    <DescriptionsItem label={t('api.total_margin_all_machine')}>
                        {totalMargin.toFixed(2)} {' %'}
                    </DescriptionsItem>
                )}
                {!showDealerView && (
                    <DescriptionsItem label={t('api.part_exchange_trade_in')}>
                        <div className="flex items-center gap-2">
                            <div className="w-1/3">
                                <Input
                                    value={tradeInValue}
                                    name="trade_in_value"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTradeInValue(parseInt(e.target.value, 10))
                                    }
                                />
                            </div>
                            <p>{getCurrencySymbol()}</p>
                        </div>
                    </DescriptionsItem>
                )}
                {retailMargin && landedCost && (
                    <DescriptionsItem label={t('api.retail_margin')}>
                        {retailMargin.toFixed(2)} {' %'}
                    </DescriptionsItem>
                )}
                {!showDealerView && <div />}
                {!showDealerView && (
                    <DescriptionsItem label={t('api.price_for_customer')}>
                        {(totalPrice - tradeInValue).toFixed(2)} {getCurrencySymbol()}
                    </DescriptionsItem>
                )}
            </Descriptions>
        </div>
    )
}

export default ConfigurationCard
