/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-unstable-nested-components */

import Table from 'components/table'
import moment from 'moment'
import { FaBolt, FaLeaf } from 'react-icons/fa'
import { GrFlagFill } from 'react-icons/gr'

import TextArea from 'components/textArea'
import { updateReference } from 'modules/request_ts/query/request_ts'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import useTranslationData from '../../translation/hooks/useTranslationData'
import ColumnListAction from './ColumnListAction'

type SheetData = {
    active_version_number: number
    version_number_count: number
    novo_quote_number: string
    description: string
    novo_configuration_data_id: number
    created_by: {
        name: string
        id: number
    }
    updated_by: {
        name: string
        id: number
    }
    updated_at: string
    created_at: string
    quote_sequence: number
    id: number
}

interface ExcelTableProps {
    excelData: readonly SheetData[]
    isLoading?: boolean
    refetch?: () => void
}

const ExcelTable = (props: ExcelTableProps) => {
    const { excelData, isLoading, refetch } = props
    const {
        translation: { t },
    } = useTranslationData()

    const updateReferenceMutation: any = useMutation(
        ['updateReference'],
        (data: { configurationId: number; reference: string }) => {
            return updateReference(data.configurationId, data.reference)
        },
        {
            onSuccess: () => {
                toast.success(t('message.update_reference_success'))
                if (refetch) {
                    refetch()
                }
            },
            onError: () => {
                toast.error(t('message.update_reference_failed'))
            },
        },
    )

    const columns = [
        {
            id: 'id',
            name: t('api.id'),
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '70px',
            selector: (row: { id: number }) => row.id,
        },
        {
            id: 'status',
            name: '',
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '84px',
            cell: (row: {
                is_deleted: boolean
                lithium_battery: boolean
                is_electric: boolean
                is_non_hyster: boolean
            }) => (
                <div className="flex items-center gap-2">
                    {row.lithium_battery && (
                        <div
                            title="This configuration is based on Lithium Battery"
                            className="text-green-700 cursor-pointer"
                        >
                            <FaLeaf size={15} />
                        </div>
                    )}
                    {row?.is_deleted && (
                        <div
                            title="This configuration is deleted sequence"
                            className="text-red-700 cursor-pointer"
                        >
                            <GrFlagFill size={15} />
                        </div>
                    )}
                    {row.is_electric && (
                        <div
                            title="This configuration is based on Electric"
                            className="text-yellow-400 cursor-pointer"
                        >
                            <FaBolt size={15} />
                        </div>
                    )}
                    {row.is_non_hyster ? (
                        <div
                            title={t('message.non_novo_configuration')}
                            className="text-red-500 text-lg text-bold cursor-pointer"
                        >
                            O
                        </div>
                    ) : (
                        <div
                            title={t('message.novo_configuration')}
                            className="text-red-500 text-lg text-bold cursor-pointer"
                        >
                            N
                        </div>
                    )}
                </div>
            ),
        },
        {
            id: 'novo_quote_number',
            name: t('api.novo_quote_number'),
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            selector: (row: { novo_quote_number: number }) => row.novo_quote_number,
        },
        {
            id: 'part_number',
            name: t('api.part_number'),
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            selector: (row: { part_number: number }) => row.part_number,
        },

        {
            id: 'model_series',
            name: t('api.model_series'),
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            cell: (row: { model: string; series: string }) => {
                return (
                    <p className="flex items-center gap-2">
                        {row.model} - {row.series}{' '}
                    </p>
                )
            },
        },
        {
            id: 'connected',
            name: t('api.connected'),
            left: true,
            height: 'fit-content',
            wrap: true,
            omit: true,
            width: '120px',
            selector: (row: { novo_quote_number: number }) => row.novo_quote_number,
        },
        {
            id: 'description',
            name: t('api.description'),
            left: true,
            width: '20rem',
            height: 'fit-content',
            wrap: true,
            selector: (row: { description: string }) => (
                <p className="text-md text-left py-2"> {row.description}</p>
            ),
        },
        {
            id: 'reference',
            name: t('api.reference'),
            left: true,
            width: '20rem',
            height: 'fit-content',
            wrap: true,
            selector: (row: { id: number; reference: string }) => {
                const [tempReference, setTempReference] = useState<string>(row?.reference || '')

                return (
                    <TextArea
                        name="reference"
                        value={tempReference}
                        onChange={e => setTempReference(e.target.value)}
                        onBlur={e =>
                            updateReferenceMutation.mutate({
                                configurationId: row.id,
                                reference: e.target.value,
                            })
                        }
                    />
                )
            },
        },
        {
            id: 'updated_by',
            name: t('api.updated_by'),
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            omit: true,
            selector: (row: {
                updated_by: {
                    name: string
                }
            }) => row?.updated_by?.name || '',
        },
        {
            id: 'updated_at',
            name: t('api.updated_at'),
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            omit: true,
            selector: (row: { updated_at: string }) =>
                moment(row?.updated_at).format('MMM-ddd-YYYY HH:mm a') || '',
        },
        {
            id: 'active_version_number',
            name: t('api.active_version_number'),
            center: true,
            height: 'fit-content',
            wrap: true,
            width: '100px',
            selector: (row: { active_version_number: number }) => row.active_version_number,
        },
        {
            id: 'action',
            name: t('api.action'),
            center: true,
            height: 'fit-content',
            width: '200px',
            wrap: true,
            cell: (row: { id: number; is_non_hyster: boolean }) => (
                <ColumnListAction
                    refetch={refetch}
                    configuration={row.id}
                    isNonHyster={row.is_non_hyster}
                />
            ),
        },
    ]

    return (
        <Table
            loading={isLoading}
            columns={columns}
            tableHeight="100"
            data={excelData}
            pagination
            striped
        />
    )
}

export default ExcelTable
