import { callAxios } from 'plugins/api'

const getTranslations = async () => {
    const res = await callAxios({
        method: 'get',
        url: 'translations',
        isHeffiq: true,
    })
    if (res) {
        return res.data
    }
    return false
}

export const TranslationService = {
    getTranslations,
}
