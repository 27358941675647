import Button from 'components/button'
import { FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loading from 'components/loading'
import { DatePicker } from 'antd'
import moment from 'moment'
import Input from 'components/input'
import Table from 'components/table'
import { RentalContractQueries } from '../queries/rentalContract'

interface IRentalContractForm {
    chamber_of_commerce: string
    rental_contract_title: string
    debt_number: string
}

// type EditConfigType = {
//     id: number
//     quote_id: number
//     model: string
//     contract_number: string
//     serie_number: string
//     commencement_date: Date | null
// }

const RentalContract = () => {
    const { t } = useTranslation()
    const { orderId, quoteId } = useParams()
    const queryClient = useQueryClient()

    const [configurations, setConfigurations] = useState<any>([])

    // const [isEditing, setIsEditing] = useState(false)
    // const [configEdit, setConfigEdit] = useState({} as EditConfigType)

    const updateOrCreateRentalMutation = useMutation(
        ['updateOrCreateRentalContract'],
        (payload: any) =>
            RentalContractQueries.updateOrCreateRentalContract(String(quoteId), payload),
        {
            onSuccess: () => {
                toast.success(t('message.rental_contract_success'))
                queryClient.invalidateQueries('getRentalContract')
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.rental_contract_failed'))
            },
        },
    )

    // const checkNull = (items: any) => {
    //     return items.map((item: any) => {
    //         if (Object.values(item).some((x: any) => x === null || x === '')) {
    //             return true
    //         }
    //         return false
    //     })
    // }

    const rentalContractForm: FormikProps<IRentalContractForm> = useFormik<IRentalContractForm>({
        initialValues: {
            chamber_of_commerce: '',
            rental_contract_title: '',
            debt_number: '',
        },
        validationSchema: Yup.object().shape({
            rental_contract_title: Yup.string().required(
                t('message.rental_contract_title_required'),
            ),
            chamber_of_commerce: Yup.string().required(t('message.chamber_of_commerce_required')),
            // start_date: Yup.date().required(t('message.start_date_required')),
            debt_number: Yup.string().required(t('message.debt_number_required')),
        }),
        onSubmit: (values: IRentalContractForm) => {
            updateOrCreateRentalMutation.mutate({
                chamber_of_commerce: values?.chamber_of_commerce,
                rental_contract_title: values?.rental_contract_title,
                debt_number: values?.debt_number,
                id: quoteId,
                novo_configurations: configurations,
            })
        },
    })

    const editContractNumber = (itemId: number, newName: any) => {
        const updatedItems = [...configurations]
        const itemIndex = updatedItems.findIndex(item => item.id === itemId)
        if (itemIndex !== -1) {
            const updatedItem = {
                ...updatedItems[itemIndex],
                contract_number: newName,
            }
            updatedItems[itemIndex] = updatedItem
            setConfigurations(updatedItems)
        }
    }

    const editSerieNumber = (itemId: number, newName: any) => {
        const updatedItems = [...configurations]
        const itemIndex = updatedItems.findIndex(item => item.id === itemId)
        if (itemIndex !== -1) {
            const updatedItem = {
                ...updatedItems[itemIndex],
                serie_number: newName,
            }
            updatedItems[itemIndex] = updatedItem
            setConfigurations(updatedItems)
        }
    }
    const editDate = (itemId: number, newDate: any) => {
        const updatedItems = [...configurations]
        const itemIndex = updatedItems.findIndex(item => item.id === itemId)
        if (itemIndex !== -1) {
            const updatedItem = {
                ...updatedItems[itemIndex],
                starting_date: moment(newDate).format(),
            }
            updatedItems[itemIndex] = updatedItem
            setConfigurations(updatedItems)
        }
    }

    const columns = [
        {
            name: t('api.id'),
            left: true,
            width: '5rem',
            selector: (row: { id: string }) => {
                return <p className="text-black text-bold">{row.id || '-'}</p>
            },
        },

        {
            name: t('api.model'),
            left: true,
            width: '8rem',
            selector: (row: { model: string }) => {
                return <p className="text-black text-bold">{row.model || '-'}</p>
            },
        },

        {
            name: t('api.contract_number'),
            left: true,
            width: '200px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return (
                    <Input
                        name={t('api.contract_number')}
                        value={row?.contract_number}
                        disabled={Boolean(orderId)}
                        onChange={e => editContractNumber(row.id, e.target.value)}
                    />
                )
            },
            sortable: true,
        },
        {
            name: t('api.serie_number'),
            left: true,
            width: '200px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return (
                    <Input
                        name={t('api.serie_number')}
                        value={row?.serie_number}
                        disabled={Boolean(orderId)}
                        onChange={e => editSerieNumber(row.id, e.target.value)}
                    />
                )
            },
            sortable: true,
        },
        {
            name: t('api.starting_date'),
            left: true,
            width: '180px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return (
                    <DatePicker
                        defaultValue={
                            row?.starting_date && moment(row?.starting_date || new Date(Date.now()))
                        }
                        disabled={Boolean(orderId)}
                        onChange={date => editDate(row.id, date)}
                    />
                )
            },
            sortable: true,
        },
        {
            name: t('api.belongs'),
            left: true,
            width: '8rem',
            selector: (row: { belongs: string }) => {
                return <p className="text-black text-bold">{row?.belongs || '-'}</p>
            },
        },
        {
            name: t('api.running_hours'),
            left: true,
            width: '8rem',
            selector: (row: { running_hours: number }) => {
                return <p className="text-black text-bold">{row?.running_hours || '-'}</p>
            },
        },
        {
            name: t('api.duration_of_contract'),
            left: true,
            width: '8rem',
            selector: (row: { selected_duration_of_contract: number | null }) => {
                return (
                    <p className="text-black text-bold">
                        {row?.selected_duration_of_contract || '-'}
                    </p>
                )
            },
        },
        {
            name: t('api.lease_price'),
            left: true,
            width: '8rem',
            selector: (row: { lease_price: number }) => {
                return <p className="text-black text-bold">{row?.lease_price || '-'}</p>
            },
        },
        // {
        //     name: t('api.action'),
        //     right: true,
        //     width: '10rem',
        //     selector: (row: { id: string | number; configuration_price_status: number }) => (
        //         <Button
        //             // isLoading={}
        //             disabled={isEditing && configEdit?.id !== row.id}
        //             onClick={updateHandler}
        //             label={t('api.update')}
        //             customButtonClass="w-full md:w-fit "
        //             variant="outline"
        //         />
        //     ),
        //     sortable: true,
        // },
    ]

    const { isLoading } = useQuery(
        'getRentalContract',
        () => RentalContractQueries.getRentalContract(String(quoteId)),
        {
            onSuccess: data => {
                const documentData = data?.data?.data ? data?.data?.data : undefined
                if (documentData) {
                    rentalContractForm.setFieldValue(
                        'chamber_of_commerce',
                        documentData?.rental_contract?.chamber_of_commerce,
                    )
                    rentalContractForm.setFieldValue(
                        'rental_contract_title',
                        documentData?.rental_contract?.rental_contract_title,
                    )
                    rentalContractForm.setFieldValue(
                        'debt_number',
                        documentData?.rental_contract?.debt_number,
                    )
                    setConfigurations(documentData?.novo_configurations)
                }
            },
            onError: () => {
                toast.error(t('message.something_went_wrong'))
            },
        },
    )

    if (isLoading) {
        return (
            <div className="flex items-center justify-center w-full h-full">
                <Loading />
            </div>
        )
    }

    return (
        <div className="h-full w-full flex flex-col gap-4">
            <form className="flex flex-col gap-4" onSubmit={rentalContractForm.handleSubmit}>
                <div className="flex justify-between items-center mb-4">
                    <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                        {t('api.rental_contract')}
                    </p>
                    {!orderId && (
                        <Button
                            variant="primary"
                            isLoading={updateOrCreateRentalMutation.isLoading}
                            customButtonClass="w-fit"
                            type="submit"
                            label={t('api.save_rental_contract')}
                        />
                    )}
                </div>
                <Input
                    horizontal
                    label={t('fields.rental_contract_title')}
                    placeholder={`${t('fields.enter')} ${t('fields.rental_contract_title')}`}
                    disabled={Boolean(orderId)}
                    value={rentalContractForm.values.rental_contract_title}
                    onChange={rentalContractForm.handleChange}
                    error={
                        rentalContractForm.touched.rental_contract_title &&
                        rentalContractForm.errors.rental_contract_title
                    }
                    name="rental_contract_title"
                    required
                />
                <Input
                    horizontal
                    label={t('fields.chamber_of_commerce')}
                    placeholder={`${t('fields.enter')} ${t('fields.chamber_of_commerce')}`}
                    disabled={Boolean(orderId)}
                    value={rentalContractForm.values.chamber_of_commerce}
                    onChange={rentalContractForm.handleChange}
                    error={
                        rentalContractForm.touched.chamber_of_commerce &&
                        rentalContractForm.errors.chamber_of_commerce
                    }
                    name="chamber_of_commerce"
                    required
                />
                {/* 
                <div className="flex flex-col md:flex-row gap-4 justify-between md:items-center">
                    <h3>{t(`api.date`)}</h3>
                    <DatePicker
                        className="w-[70%]"
                        status={
                            (rentalContractForm.touched.start_date &&
                                rentalContractForm.errors.start_date &&
                                'error') ||
                            ''
                        }
                        onChange={date =>
                            rentalContractForm.setFieldValue('start_date', date?.format())
                        }
                    />
                </div> */}
                <Input
                    horizontal
                    required
                    disabled={Boolean(orderId)}
                    label={t('fields.debt_number')}
                    placeholder={`${t('fields.enter')} ${t('fields.debt_number')}`}
                    value={rentalContractForm.values.debt_number}
                    onChange={rentalContractForm.handleChange}
                    error={
                        rentalContractForm.touched.debt_number &&
                        rentalContractForm.errors.debt_number
                    }
                    name="debt_number"
                />

                <Table
                    columns={columns}
                    data={configurations || []}
                    loading={isLoading}
                    striped
                    pagination
                    totalRows={25}
                    tableHeight="90%"
                />
            </form>
        </div>
    )
}

export default RentalContract
