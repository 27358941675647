/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Select } from 'antd'
import Button from 'components/button'
import Input from 'components/input'
import { BiSearch } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import useTranslationData from '../../translation/hooks/useTranslationData'

const SiteSurveyTabs = ({
    activeTab,
    setActiveTab,
    pattern,
    setPattern,
    onChangeStatus,
}: {
    activeTab: number
    setActiveTab: (index: number) => void
    pattern: string
    setPattern: (value: string) => void
    onChangeStatus: (value: string) => void
}) => {
    const liClass = `text-bold text-md cursor-pointer hover:border-gray-300 w-full md:w-auto border-none md:border-b-2 md:border`
    const navigate = useNavigate()
    const {
        translation: { t },
    } = useTranslationData()

    return (
        <div className="flex w-full items-center justify-between flex-wrap mb-4 gap-4">
            <div>
                <ul className="flex flex-wrap gap-8 -mb-px">
                    <li
                        className={`${
                            activeTab === 1
                                ? 'text-secondary border-primary font-medium underline underline-offset-8 decoration-4 decoration-primary'
                                : 'text-placeholderColor border-transparent'
                        } ${liClass}`}
                        onClick={() => setActiveTab(1)}
                    >
                        {t('api.site_survey')}
                    </li>
                </ul>
            </div>
            <div className="flex md:justify-center justify-end items-center flex-wrap md:flex-nowrap gap-4 mt-4 lg:mt-0">
                <div className="w-full sm:w-fit md:w-auto">
                    <Input
                        type="text"
                        value={pattern}
                        name="search"
                        placeholder={t(`api.search`)}
                        leftContent={<BiSearch />}
                        onChange={event => {
                            setPattern(event.target.value)
                        }}
                    />
                </div>
                <Select
                    defaultValue="not-connected"
                    placeholder="Select Status"
                    size="large"
                    className="rounded-md min-w-[10vw]"
                    onChange={onChangeStatus}
                >
                    {[
                        {
                            value: 'all',
                            label: t('api.all'),
                        },
                        {
                            value: 'connected',
                            label: t('api.connected'),
                        },
                        {
                            value: 'not-connected',
                            label: t('api.not_connected'),
                        },
                    ].map(item => (
                        <Select.Option value={item.value}>{item.label}</Select.Option>
                    ))}
                </Select>
                <div className="w-fit md:w-auto">
                    <Button
                        label={`${t('api.create')} ${t('api.site_survey')} ${t(
                            'api.only_required',
                        )}`}
                        variant="outline"
                        onClick={() =>
                            navigate('/site-survey/create?hide-non-required-fields=true')
                        }
                    />
                </div>
                <div className="w-fit md:w-auto">
                    <Button
                        label={`${t('api.create')} ${t('api.site_survey')}`}
                        variant="outline"
                        onClick={() => navigate('/site-survey/create')}
                    />
                </div>
            </div>
        </div>
    )
}

export default SiteSurveyTabs
