/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import Table from 'components/table'
import settingImage from 'assets/svg/setting.png'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Fuse from 'fuse.js'
import { isNaN } from 'lodash'

import { useTranslation } from 'react-i18next'
import { ConfigurationQueries } from './queries/configuration'
import ConfigureTabs from './components/tabs/configureTabs'
import { getLibrary } from './queries/library'
import TableAction from './components/tableAction'
import { useAuth } from '../auth/context/useAuth'
import { formatCurrency } from '../../utils/currency/formatCurrency'

const imageUrl = process.env.REACT_APP_IMAGE_URL

const initialUserSelectList = [
    {
        label: 'All',
        value: 'All',
    },
]

const Configuration = () => {
    const { user, userExtra } = useAuth()
    const [activeTab, setActiveTab] = useState<number>(userExtra.role === 'Sales Merged' ? 3 : 1)
    const [pattern, setPattern] = useState<string>('')
    const navigate = useNavigate()
    const [list, setList] = useState<[]>([])
    const [userList, setUserList] = useState<any[]>(initialUserSelectList)
    const [selectedUser, setSelectedUser] = useState<any>()
    const [tempConfigurationData, setTempConfigurationData] = useState<any>()
    const { t } = useTranslation()

    useQuery(['userList'], () => ConfigurationQueries.getUserList(), {
        onSuccess: data => {
            if (data && data.data && data.data.teamUsers) {
                setUserList(
                    data?.data.teamUsers.map((x: any) => ({
                        label: x.name,
                        value: x.name,
                    })),
                )
            }
            if (data && data.data && data.data.childUser) {
                setUserList(
                    data?.data.childUser.map((x: any) => ({
                        label: x.name,
                        value: x.name,
                    })),
                )
            }
        },
    })

    const {
        data: configurationData,
        isLoading: configurationLoading,
        refetch,
    } = useQuery(
        ['allConfiguration', { userId: user.id, tab: activeTab }],
        () => {
            if (activeTab === 1) {
                if (userExtra.is_support) {
                    return ConfigurationQueries.getConfiguration(user.id)
                }
                return ConfigurationQueries.getUserConfigurations({ userId: user.id })
            }
            if (activeTab === 2) {
                return getLibrary({ userId: user.id })
            }
            if (activeTab === 3) {
                return ConfigurationQueries.getChildrenConfiguration({ userId: user.id })
            }
            if (activeTab === 4) {
                return ConfigurationQueries.getParentLibraryConfiguration({ userId: user.id })
            }
            return null
        },
        {
            onSuccess: data => {
                setTempConfigurationData(data?.data)
            },
        },
    )

    const fuse = new Fuse(tempConfigurationData || [], {
        shouldSort: true,
        threshold: 0.1,
        ignoreLocation: true,
        keys: ['name'],
    })

    useEffect(() => {
        if (pattern.length > 0) {
            const results: any = fuse.search(pattern)
            setList(results.map((b: any) => b.item).flat())
        } else {
            setList([])
        }
    }, [pattern])

    const handleTabChanged = (tab: number) => {
        setActiveTab(tab)
    }

    useEffect(() => {
        if (tempConfigurationData && tempConfigurationData.length > 0) {
            setTempConfigurationData([])
        }
    }, [activeTab])

    useEffect(() => {
        if (selectedUser) {
            if (selectedUser.value === 'All') {
                setTempConfigurationData(configurationData?.data)
            } else {
                setTempConfigurationData(
                    configurationData?.data.filter(
                        (configuration: any) =>
                            configuration.user_name.toLowerCase() ===
                            selectedUser.value.toLowerCase(),
                    ),
                )
            }
        }
    }, [selectedUser])

    const getTabRowAction = (row: any) => {
        return (
            <TableAction
                activeTab={activeTab}
                row={row}
                refetch={refetch}
                tag={activeTab === 2 ? 'library' : ''}
            />
        )
    }

    const handleRowClicked = (configurationItem: any) => {
        navigate(
            `/configuration/${activeTab === 4 || activeTab === 2 ? 'library/' : ''}${
                activeTab !== 4 ? configurationItem.id : configurationItem.configuration_id
            }`,
        )
    }

    const columns = [
        {
            name: t('api.model'),
            cell: (row: any) => {
                return (
                    <img
                        onClick={() => handleRowClicked(row)}
                        src={`${
                            row.machine_category_image
                                ? `${imageUrl}${row.machine_category_image}`
                                : settingImage
                        }`}
                        alt=""
                        className="rounded-md object-contain h-[10vh] w-[10vw]"
                        loading="lazy"
                    />
                )
            },
        },
        {
            name: t('api.name'),
            left: true,
            selector: (row: any) => row.name,
            cell: (row: any) => (
                <div className="flex flex-col py-4" onClick={() => handleRowClicked(row)}>
                    <h1 className="text-black text-semibold text-lg mb-2">
                        {row?.name || 'unknown'}
                    </h1>
                    <h5 className="text-placeholderColor flex flex-col justify-start font-semibold gap-2">
                        <span className="text-md">Category:</span>
                        <span className="text-md">{row?.machine_category || ''}</span>
                    </h5>
                </div>
            ),
            sortable: true,
        },
        {
            name: t('api.connected_to'),
            sortable: true,
            selector: (row: any) => Boolean(row.quote_id),
            cell: (row: any) => (
                <div className="flex flex-col text-base" onClick={() => handleRowClicked(row)}>
                    {row?.quote_id ? (
                        <div>
                            <a
                                href={`/quotes/${row?.quote_id}`}
                                className="leading-3 flex font-semibold text-primaryLight hover:text-primary"
                                aria-label="Connected to"
                            >
                                {/* {row?.quote_id} */}
                                {t('api.connected')}
                            </a>
                        </div>
                    ) : (
                        <p>{t('api.new')}</p>
                    )}
                </div>
            ),
            omit: Boolean(activeTab === 2),
        },
        {
            name: t('api.retail_price'),
            left: true,
            selector: (row: any) => row.retail_price,
            cell: (row: any) => {
                const retailPrice = row.machine_total_price / (row ? row.quantity : 1)
                return (
                    <div className="flex flex-col" onClick={() => handleRowClicked(row)}>
                        <h1 className="text-black text-base text-semibold mb-2">
                            {formatCurrency(
                                isNaN(retailPrice) ? (0).toFixed(2) : retailPrice.toFixed(2),
                            )}
                        </h1>
                    </div>
                )
            },
        },
        {
            name: t('api.quantity'),
            center: true,
            selector: (row: any) => row.quantity,
            cell: (row: any) => {
                return (
                    <p className="text-base" onClick={() => handleRowClicked(row)}>
                        {row.quantity}
                    </p>
                )
            },
            sortable: true,
            omit: Boolean(activeTab === 3),
        },
        {
            name: t('api.total_price'),
            center: true,
            cell: (row: any) => {
                const netTotal =
                    row.machine_total_price / row.quantity -
                    (row.discount_percentage * row.machine_total_price) / row.quantity / 100 -
                    row.discount_amount -
                    row.dealer_discount_amount
                const grandTotal = netTotal * row.quantity
                return (
                    <div onClick={() => handleRowClicked(row)}>
                        <p className="text-base">{formatCurrency(grandTotal.toFixed(2))}</p>
                    </div>
                )
            },
            sortable: true,
            omit: Boolean(activeTab === 3),
        },
        {
            name: t('api.create_by'),
            sortable: true,
            selector: (row: any) => {
                return (
                    <p onClick={() => handleRowClicked(row)} className="text-lg">
                        {row.user_name}
                    </p>
                )
            },
            omit: Boolean(activeTab !== 3),
        },
        {
            name: t('api.created_at'),
            sortable: true,
            selector: (row: any) => {
                return (
                    <p onClick={() => handleRowClicked(row)} className="text-lg">
                        {moment(row.created_at).format('YYYY-MM-DD') || '-'}
                    </p>
                )
            },
            omit: Boolean(activeTab !== 3),
        },
        {
            name: t('api.action'),
            center: true,
            cell: (row: any) => getTabRowAction(row),
        },
    ]

    const cardClass = 'h-full w-ful bg-white px-4 py-4 rounded-md h-[85vh]'

    return (
        <div className={cardClass}>
            <div className="min-h-fit h-[8%]">
                <ConfigureTabs
                    activeTab={activeTab}
                    setActiveTab={(index: number) => handleTabChanged(index)}
                    pattern={pattern}
                    setPattern={setPattern}
                    userList={userList}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                />
            </div>
            <div className="h-[92%]">
                <Table
                    columns={columns}
                    data={list.length > 0 ? list : tempConfigurationData || []}
                    loading={configurationLoading}
                    striped
                    onRowCLicked={handleRowClicked}
                    pagination
                    totalRows={10}
                    tableHeight="94%"
                />
            </div>
        </div>
    )
}

export default Configuration
