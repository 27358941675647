import { callAxios } from 'plugins/api'
import { IResidualValue } from '../views/Pricing'

export interface IAdditionalConfigurationData {
    id?: number
    configuration_id: number
    financing: string
    maintenance: string
    insurance: string | boolean
    show_all_prices_separate: string
    return: string | boolean
    running_hours: number
    return_description: string
    theoretical_category: string
}
export interface ISiteSurveyPriceData {
    return: string
    financing: string
    maintenance: string
    insurance: string | boolean
    show_all_prices_separate: string
    running_hour: number
    replacement_machine: string
}

export interface IPricingParams {
    specialPriceRequestPercentage: number
    standardPriceRate: number
    discountAmount: number
    vpg: number
    bridgingMachineAmount: number
    financialSupportAmount: number
    totalExtra: number
    totalOptions: number
    dataLogger: number
    netAllCost: number
    profitPercentage: number
    profitAmount: number
    quotePrice: number
    tradeInValue: number
    qouteminusTradeIn: number
    additionalResidualPercentage: number
    investmentGroup: number
    runningHours: number
    addTyre: boolean
    addBattery: boolean
    batteryService: number
    addAttachment: boolean
    addSpecials: boolean
    bmwt: boolean
    category: number
    residualValue: IResidualValue[]
}

export interface IMonthlyConfigurationPrice {
    duration_of_contract: number
    total_residual_value: number
    standard_residual_value: number
    lease_factor: number
    lease_price_options: number
    financial_part: number
    insurance_cost: number
    basic_truck: number
    service_extra_preplist: number
    service_extra_special: number
    service_extra_attachments: number
    service_battery: number
    service_big_truck_tyre_per_hour: number
    service_tyre?: number
    total_service?: number
    service_price_big_truck_all_in?: number
    service_price_big_truck_pm?: number
    total_price_per_year_ex_bmwt: number
    total_price_per_year_inclusive_bmwt: number
    preventive_maintenance: number
    batteries_service_cost: number
    bmwt_battery?: number
}

export interface IBigTruckCalculationPayload {
    months: string
    standard_residual_value: number
    additional_residual_percentage: number
    factor: number
    service_price_all: number
    service_price_pm: number
    min_investment_group: number
    quote_total: number
    trade_in_amount: number
    service_discount_all_in: number
    service_big_truck_tyre_per_hour: number
    service_discount_pm: number
    include_service_tyre: boolean
    include_service_battery: boolean
    include_service_attachment: boolean
    include_service_specials: boolean
    include_service_exsolution: boolean
    quantity_battery_service_job: number
    bmwt_selected: boolean
    batteries_selected: boolean
    is_whe: boolean
    margin_for_options: number
}

export interface ISavePricing {
    novo_list_price: number
    machine_list_price: number
    total_net_value_parts: number
    total_options_selected: number
    special_price_request_percentage: number
    discount_amount: number
    total_extra: number
    total_options: number
    net_all_cost: number
    novo_quote_price: number
    min_investment_group: number
    running_hours: number
    category: number
    novo_configuration_id: number
    standard_discount_percentage: number
    bridging_machine_amount: number
    financial_support_amount: number
    data_logger: number
    profit_percentage: number
    profit_amount: number
    manual_price: number | null
    trade_in_value: number
    quote_minus_trade_in: number
    trade_in_value_text_field: string
    show_trade_in_value_text_field?: boolean
    use_quote_price_minus_trade_in_for_calculation: boolean
    discount_service_all_in: number
    discount_service_pm: number
    additional_residual_percentage: number
    vpg_percentage: number
    number_of_batteries: number
    vpg_amount: number
    add_tyre: boolean
    add_battery: boolean
    add_attachment: boolean
    add_specials: boolean
    add_ex_solutions: boolean
    request_fleet: boolean
    battery_service: number
    bmwt: boolean
    is_whe: boolean
    add_bmwt_battery: boolean
    monthly_configuration_prices: IMonthlyConfigurationPrice[]
    margin_for_options: number
    type_banden: string
    selected_duration_of_contract?: number
}

export interface ISavePricingWithFeedback extends ISavePricing {
    feedback: string
}

const getPricingForConfigurationId = (configurationId: number) =>
    callAxios({
        url: `price/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })

const getInitialValueForPricing = (configurationId: number) =>
    callAxios({
        url: `price/${configurationId}/initial`,
        method: 'GET',
        isHeffiq: true,
    })

const isPmPlusAvailable = (seriesCode: string) => {
    return callAxios({
        url: `service-price-vpg/check-pm-plus-available`,
        method: 'POST',
        isHeffiq: true,
        data: {
            serie_code: seriesCode,
        },
    })
}

const updateSiteSurveyPrice = (siteSurveyId: number, formData: ISiteSurveyPriceData) =>
    callAxios({
        url: `siteSurvey/${siteSurveyId}/service-price-update`,
        method: 'PATCH',
        data: formData,
        isHeffiq: true,
    })

const getResidualAndFinancialPart = (
    configurationId: number,
    params: {
        months: string
        additional_residual_percentage: number
        min_investment_group: number
        quote_total: number
        trade_in_amount: number
        service_discount_all_in: number
        service_discount_pm: number
        include_service_tyre: boolean
        include_service_battery: boolean
        include_service_attachment: boolean
        include_service_specials: boolean
        include_service_exsolution: boolean
        quantity_battery_service_job: number
        number_of_batteries: number
        bmwt_selected: boolean
        bmwt_battery_selected: boolean
        batteries_selected: boolean
        tyre_type: string
        is_whe: boolean
        margin_for_options: number
    },
) =>
    callAxios({
        url: `price/${configurationId}/calculate`,
        method: 'POST',
        isHeffiq: true,
        data: params,
    })

const getResidualAndFinancialPartBigTruck = (configurationId: number, params: any[]) =>
    callAxios({
        url: `price/${configurationId}/big-truck/month-calculate`,
        method: 'POST',
        isHeffiq: true,
        data: params,
    })

const savePricing = (params: ISavePricing, configurationId: number) =>
    callAxios({
        url: `price/${configurationId}`,
        method: 'POST',
        data: params,
        isHeffiq: true,
    })

const requestFleet = (configurationId: number, configurationPriceId: number, remark: string) =>
    callAxios({
        url: `price/${configurationId}/request-fleet/${configurationPriceId}`,
        method: 'PATCH',
        data: {
            remark,
        },
        isHeffiq: true,
    })

const sendFeedback = (params: ISavePricingWithFeedback, configurationId: number) =>
    callAxios({
        url: `price/${configurationId}/adjust-fleet-feedback`,
        method: 'PUT',
        isHeffiq: true,
        data: params,
    })

const requestWorkshop = (configurationId: number, configurationPriceId: number) =>
    callAxios({
        url: `price/${configurationId}/request-workshop/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
    })

const getRequestWorkshop = () =>
    callAxios({
        url: `quote/request-workshop`,
        method: 'GET',
        isHeffiq: true,
    })

const requestApproval = (configurationId: number, configurationPriceId: number) =>
    callAxios({
        url: `price/${configurationId}/request-approval/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
    })

const approveAllByConfigurationId = (configurationId: number, configurationPriceId: number) =>
    callAxios({
        url: `price/${configurationId}/approve-all/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
    })

export type IAdjustType = {
    value: number
    status: number
    type?: 'all_in' | 'preventive_maintenance'
}

const adjustProfit = (configurationId: number, configurationPriceId: number, params: IAdjustType) =>
    callAxios({
        url: `price/${configurationId}/adjust-profit/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
        data: params,
    })

const adjustInvestment = (
    configurationId: number,
    configurationPriceId: number,
    params: IAdjustType,
) =>
    callAxios({
        url: `price/${configurationId}/adjust-investment/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
        data: params,
    })

const adjustFactor = (configurationId: number, configurationPriceId: number, params: IAdjustType) =>
    callAxios({
        url: `price/${configurationId}/adjust-factor/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
        data: params,
    })

const adjustDiscount = (
    configurationId: number,
    configurationPriceId: number,
    params: IAdjustType,
) =>
    callAxios({
        url: `price/${configurationId}/adjust-discount/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
        data: params,
    })

const adjustSpr = (configurationId: number, configurationPriceId: number, params: IAdjustType) =>
    callAxios({
        url: `price/${configurationId}/adjust-spr/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
        data: params,
    })

const adjustResidualValue = (
    configurationId: number,
    configurationPriceId: number,
    params: IAdjustType,
) =>
    callAxios({
        url: `price/${configurationId}/adjust-residual-value/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
        data: params,
    })

const approveAll = (configurationId: number, configurationPriceId: number) =>
    callAxios({
        url: `price/${configurationId}/approve-all/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
    })

const removeConfigurationMonth = (configurationPriceId: number, configurationPriceMonth: number) =>
    callAxios({
        url: `price/${configurationPriceId}/month/${configurationPriceMonth}`,
        method: 'DELETE',
        isHeffiq: true,
    })

const createAdditionalConfigurationData = (params: IAdditionalConfigurationData) =>
    callAxios({
        url: `price/additional-configuration-data/create`,
        method: 'POST',
        isHeffiq: true,
        data: params,
    })

const getAdditionalConfigurationData = (configurationId: number) =>
    callAxios({
        url: `price/additional-configuration-data/show/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })

const exportProjectOverview = (exportArray: string[], quoteId: number) =>
    callAxios({
        url: `quote/exportProjectOverview/${quoteId}`,
        method: 'POST',
        responseType: 'blob',
        isHeffiq: true,
        data: {
            exportArray,
        },
    })

const setStatusToDraft = (configurationId: number, configurationPriceId: number) =>
    callAxios({
        url: `price/${configurationId}/reset-draft/${configurationPriceId}`,
        method: 'PATCH',
        isHeffiq: true,
    })

export const PricingQueries = {
    getPricingForConfigurationId,
    getInitialValueForPricing,
    updateSiteSurveyPrice,
    getResidualAndFinancialPart,
    getResidualAndFinancialPartBigTruck,
    savePricing,
    requestFleet,
    sendFeedback,
    requestWorkshop,
    getRequestWorkshop,
    requestApproval,
    adjustProfit,
    adjustInvestment,
    adjustFactor,
    adjustDiscount,
    adjustResidualValue,
    adjustSpr,
    approveAllByConfigurationId,
    approveAll,
    removeConfigurationMonth,
    createAdditionalConfigurationData,
    getAdditionalConfigurationData,
    exportProjectOverview,
    setStatusToDraft,
    isPmPlusAvailable,
}
