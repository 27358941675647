/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAuth } from 'modules/auth/context/useAuth'
import Configure from '../components/details/configure'

import { ConfigurationQueries } from '../queries/configuration'
import Loading from '../../../components/loading'
import { getNovoConfigurationById } from '../../novo-configuration/query'
import NovoDetail from '../../novo-configuration/views/novoDetail'

const DetailConfiguartion = (props: {
    configurationId?: string
    hideImage?: boolean
    goBackRoute?: string
    hideBack?: boolean
}) => {
    const { configurationId, orderId, from } = useParams()
    const [search] = useSearchParams()
    const { user } = useAuth()
    const [machine, setMachineDetail] = useState<any>([])
    const [parts, setParts] = useState<any>([])
    const [adjustment, setAdjustment] = useState<any>([])
    const id = props?.configurationId || configurationId
    const [isOrdered, setIsOrdered] = useState<boolean>(false)
    const [isNovo, setIsNovo] = useState<boolean>(false)

    const { isLoading, isFetching } = useQuery(
        ['currentMachine', configurationId],
        () => {
            if (from === 'library') {
                return ConfigurationQueries.configurationDetailLibrary({
                    configurationId: id || '',
                })
            }
            const isNovo = search.get('is_novo')
            if (isNovo && isNovo === 'true' && id) {
                setIsNovo(isNovo === 'true')
                return getNovoConfigurationById(parseInt(id, 10))
            }

            return ConfigurationQueries.configurationDetail({
                configurationId: id || '',
                userId: user.id,
            })
        },
        {
            enabled: !!id,
            keepPreviousData: true,
            refetchOnMount: true,
            refetchInterval: false,
            onSuccess: (data: any) => {
                setMachineDetail(
                    data?.data?.data ? data.data.data || [] : data.data.configuration[0] || [],
                )
                setAdjustment(
                    data?.data?.data
                        ? data.data.data.manual_adjustments || []
                        : data.data.manual_adjustments || [],
                )
                setParts(data?.data?.data ? data.data.data.parts || [] : data.data.parts || [])
            },
        },
    )

    useQuery(
        ['checkIfConfigurationIsOrdered', machine.configuration_id],
        () =>
            ConfigurationQueries.checkIfConfigurationIsOrdered({
                configurationId: parseInt(machine.configuration_id, 10),
            }),
        {
            enabled: !!machine.configuration_id,
            refetchInterval: false,
            onSuccess: data => {
                if (
                    data?.data.message.toLowerCase() === 'Configuration not ordered'.toLowerCase()
                ) {
                    setIsOrdered(false)
                } else {
                    setIsOrdered(true)
                }
            },
        },
    )

    if (isLoading || isFetching) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return !isNovo ? (
        <Configure
            configuration={{
                machine: machine || {},
                parts,
                adjustment,
            }}
            orderId={orderId}
            isOrder={isOrdered}
            hideImage={props?.hideImage || false}
        />
    ) : (
        <NovoDetail
            novoIdProp={machine.id}
            goBackRoute={props?.goBackRoute}
            hideBack={props.hideBack}
        />
    )
}

export default DetailConfiguartion
