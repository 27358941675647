/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components,no-nested-ternary */
import Input from 'components/input'
import DataTable from 'react-data-table-component'
import { BiTrash } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { ManualAdjustmentType } from '../../views/createConfiguration'
import Quantity from '../../../../components/quantity'
import { getCurrencySymbol } from '../../../../utils/currency/formatCurrency'

interface ManualAdjustmentProps {
    editable: boolean
    formik: any
}

const ManualAdjustment = (props: ManualAdjustmentProps) => {
    const { formik, editable } = props
    const { t } = useTranslation()

    const initialManualAdjustment: ManualAdjustmentType = {
        manual_adjustment_model_number: '',
        manual_adjustment_name: '',
        manual_adjustment_type: '',
        manual_adjustment_note: '',
        manual_adjustment_price: 0,
        manual_adjustment_quantity: 1,
        currency_symbol_html: formik.values.currency,
    }

    const handleAddButton = () => {
        formik.setFieldValue('manualAdjustments', [
            ...formik.values.manualAdjustments,
            initialManualAdjustment,
        ])
    }

    const handleSetQuantity = (quantity: number, index: number) => {
        const { manualAdjustments } = formik.values
        const manualAdjustment = manualAdjustments[index]
        if (manualAdjustment) {
            manualAdjustment.manual_adjustment_quantity = quantity
            manualAdjustments[index] = manualAdjustment
            formik.setFieldValue('manualAdjustments', manualAdjustments)
        }
    }

    return (
        <div className="py-2">
            <DataTable
                columns={[
                    {
                        id: 'manual_adjustment_model_number',
                        name: t('api.model'),
                        center: true,
                        cell: (row: any, index: any) => {
                            return (
                                <Input
                                    type="text"
                                    disabled={!editable}
                                    value={
                                        formik.values.manualAdjustments[index]
                                            ? formik.values.manualAdjustments[index]
                                                  .manual_adjustment_model_number
                                            : null
                                    }
                                    name={`manualAdjustments.${index}.manual_adjustment_model_number`}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.errors.manualAdjustments &&
                                        formik.touched.manualAdjustments &&
                                        formik.touched.manualAdjustments[index] &&
                                        formik.touched.manualAdjustments[index]
                                            .manual_adjustment_model_number &&
                                        formik.errors.manualAdjustments[index]
                                            .manual_adjustment_model_number &&
                                        formik.errors.manualAdjustments[index]
                                            .manual_adjustment_model_number
                                    }
                                />
                            )
                        },
                    },
                    {
                        id: 'manual_adjustment_name',
                        name: t('api.description'),
                        center: true,
                        cell: (row, index) => (
                            <Input
                                type="text"
                                disabled={!editable}
                                value={
                                    formik.values.manualAdjustments[index]
                                        ? formik.values.manualAdjustments[index]
                                              .manual_adjustment_name
                                        : null
                                }
                                name={`manualAdjustments.${index}.manual_adjustment_name`}
                                onChange={formik.handleChange}
                                error={
                                    formik.errors.manualAdjustments &&
                                    formik.touched.manualAdjustments &&
                                    formik.touched.manualAdjustments[index] &&
                                    formik.touched.manualAdjustments[index]
                                        .manual_adjustment_name &&
                                    formik.errors.manualAdjustments[index].manual_adjustment_name &&
                                    formik.errors.manualAdjustments[index].manual_adjustment_name
                                }
                            />
                        ),
                    },
                    {
                        id: 'manual_adjustment_note',
                        name: t('api.note'),
                        center: true,
                        cell: (row, index) => (
                            <Input
                                type="text"
                                disabled={!editable}
                                value={
                                    formik.values.manualAdjustments[index]
                                        ? formik.values.manualAdjustments[index]
                                              .manual_adjustment_note
                                        : null
                                }
                                name={`manualAdjustments.${index}.manual_adjustment_note`}
                                onChange={formik.handleChange}
                            />
                        ),
                    },
                    {
                        id: 'manual_adjustment_price',
                        name: t('api.price'),
                        center: true,
                        cell: (row, index) => (
                            <>
                                <p className="font-bold mr-4">{getCurrencySymbol()}</p>
                                <Input
                                    type="number"
                                    disabled={!editable}
                                    value={
                                        formik.values.manualAdjustments[index]
                                            ? formik.values.manualAdjustments[index]
                                                  .manual_adjustment_price
                                            : null
                                    }
                                    name={`manualAdjustments.${index}.manual_adjustment_price`}
                                    onChange={e => {
                                        formik.handleChange(e)
                                    }}
                                    error={
                                        formik.errors.manualAdjustments &&
                                        formik.touched.manualAdjustments &&
                                        formik.touched.manualAdjustments[index] &&
                                        formik.touched.manualAdjustments[index]
                                            .manual_adjustment_price &&
                                        formik.errors.manualAdjustments &&
                                        formik.errors.manualAdjustments[index]
                                            .manual_adjustment_price &&
                                        formik.errors.manualAdjustments[index]
                                            .manual_adjustment_price
                                    }
                                />
                            </>
                        ),
                    },
                    {
                        id: 'manual_adjustment_quantity',
                        name: t('api.quantity'),
                        center: true,
                        cell: (row, index) =>
                            editable ? (
                                <Quantity
                                    name={`manualAdjustments.${index}.manual_adjustment_quantity`}
                                    quantity={
                                        formik.values.manualAdjustments[index]
                                            ? formik.values.manualAdjustments[index]
                                                  .manual_adjustment_quantity
                                            : 1
                                    }
                                    setQuantity={(quantity = 1) => {
                                        handleSetQuantity(quantity, index)
                                    }}
                                />
                            ) : formik.values.manualAdjustments[index] ? (
                                formik.values.manualAdjustments[index].manual_adjustment_quantity
                            ) : (
                                1
                            ),
                    },
                    {
                        id: 'total_price',
                        name: t('api.total_price'),
                        center: true,
                        cell: (row, index) => (
                            <>
                                <p className="font-bold mr-4">{getCurrencySymbol()}</p>
                                <p>
                                    {formik.values.manualAdjustments[index]
                                        ? (
                                              formik.values.manualAdjustments[index]
                                                  .manual_adjustment_price *
                                              formik.values.manualAdjustments[index]
                                                  .manual_adjustment_quantity
                                          ).toFixed(2)
                                        : null}
                                </p>
                            </>
                        ),
                    },
                    {
                        id: 'action',
                        name: t('api.action'),
                        center: true,
                        cell: (row, index) => (
                            <button
                                className="bg-red-700 text-white p-2 rounded-md hover:bg-black hover:text-white"
                                type="button"
                                onClick={() => {
                                    const tempManualAdjustment = formik.values.manualAdjustments
                                    tempManualAdjustment.splice(index, 1)
                                    formik.setFieldValue('manualAdjustments', tempManualAdjustment)
                                }}
                            >
                                <BiTrash size={20} />
                            </button>
                        ),
                        omit: !editable,
                    },
                ]}
                data={formik.values.manualAdjustments}
                title={
                    <p className="text-md text-secondary">
                        {t('api.manual_adjustment')}{' '}
                        <span className="text-placeholderColor text-sm">
                            {formik.values.manualAdjustments.length > 0 &&
                                `(${formik.values.manualAdjustments.length})`}
                        </span>
                    </p>
                }
                actions={
                    editable && (
                        <div>
                            <button
                                onClick={() => handleAddButton()}
                                type="button"
                                className="w-32 bg-accent hover:border-black hover:bg-secondary hover:text-white text-sm  font-bold py-3 px-3 rounded-lg flex items-center justify-center gap-3"
                            >
                                {t('api.add_more')}
                            </button>
                        </div>
                    )
                }
                fixedHeaderScrollHeight="fit"
                persistTableHead
                noDataComponent={<p />}
            />
        </div>
    )
}

export default ManualAdjustment
