import { ChangeEvent } from 'react'
import Select, { Options } from 'components/select'
import useTranslationData from './hooks/useTranslationData'

const Translation = () => {
    const { handleLangChanged, selectedLang } = useTranslationData()

    const languagesList: Options[] = [
        { label: 'en', value: 'en' },
        { label: 'nl', value: 'nl' },
    ]
    return (
        <Select
            options={languagesList}
            name="Select Language"
            value={selectedLang || 'en'}
            showPlaceHolder={false}
            customStyle="rounded-none bg-white py-0"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLangChanged(e.target.value)}
        />
    )
}

export default Translation
