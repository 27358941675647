import Button from 'components/button'
import Loading from 'components/loading'
import { useFormik } from 'formik'
import { useAuth } from 'modules/auth/context/useAuth'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { successToast } from 'utils/toasterUtil'
import DiscountRequestForm from '../components/DiscountRequestForm'
import { requestMultipleDiscountRequest } from '../queries/quotes'
import { useQuoteLayout } from '../QuoteLayout'

const MultipleDiscountRequest = () => {
    const { quoteConfigurations, isLoading } = useQuoteLayout()
    const { user } = useAuth()
    const { t } = useTranslation()

    const higherDiscountRequest = useMutation(
        ['higeherDiscountrequest'],
        (discountRequest: any) => requestMultipleDiscountRequest(discountRequest, user.id),
        {
            onSuccess: data => {
                toast(data?.data?.message || t('message.discount_request_success'), successToast)
            },
            onError: (error: any) => {
                toast(error?.message || t('message.discount_request_failed'), successToast)
            },
        },
    )

    const formik = useFormik({
        initialValues: {
            configurationsRequest: [
                {
                    requested_comment: '',
                    requested_configuration_id: '',
                    requested_configuration_name: '',
                    requested_discount_percentage: '',
                },
            ],
        },
        // validationSchema: Yup.object().shape({
        //     configurationsRequest: Yup.array().of(
        //         Yup.object().shape({
        //             requested_comment: Yup.string().required('Comment is required'),
        //             // requested_configuration_id: Yup.string().required(
        //             //     'Configuration is required',
        //             // ),
        //             // requested_configuration_name: Yup.string().required(
        //             //     'Configuration is required',
        //             // ),
        //             requested_discount_percentage: Yup.string().required('Discount is required'),
        //         }),
        //     ),
        // }),
        onSubmit: values => {
            higherDiscountRequest.mutate(values)
        },
    })

    useEffect(() => {
        if (quoteConfigurations) {
            const quoteDiscountItems = quoteConfigurations.map(
                (item: { id: string; name: string }) => {
                    return {
                        requested_comment: '',
                        requested_configuration_id: item.id,
                        requested_configuration_name: item.name,
                        requested_discount_percentage: '',
                    }
                },
            )
            formik.setFieldValue('configurationsRequest', quoteDiscountItems)
        }
    }, [])

    if (isLoading) {
        return <Loading />
    }

    return (
        <div className="h-full flex flex-col gap-4 h-screen overflow-auto">
            <div className="flex justify-between items-center">
                <p className="text-black text-2xl">{t('api.multiple_discount_request')}</p>
                <div className="w-fit">
                    <Button
                        label={t('api.submit_discount_required')}
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
            <hr className="mt-4 text-black border-[#E0E0E0]" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 flex-1 overflow-auto">
                {(formik.values.configurationsRequest || []).map(
                    (item: { requested_configuration_id: string }, index: number) => (
                        <div key={item.requested_configuration_id}>
                            <DiscountRequestForm formik={formik} index={index} />
                        </div>
                    ),
                )}
            </div>
        </div>
    )
}

export default MultipleDiscountRequest
