import Button from 'components/button'
import Input from 'components/input'
import Loading from 'components/loading'
import Select from 'components/select'
import TextArea from 'components/textArea'
import { FormikProps, useFormik } from 'formik'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import ConfigurationPartTranslation from '../queries/configurationPartTranslation'

interface ITranslationPart {
    part_number: string
    description: string
    locale: string
    value: string
}

const EditConfigurationParts = () => {
    const { t } = useTranslation()
    const { quoteId, partNumber, configurationId } = useParams()
    const navigate = useNavigate()

    const addTranslatedParts = useMutation(
        ['addTranslatedPart'],
        (formData: ITranslationPart) =>
            ConfigurationPartTranslation.saveOrUpdatePartTranslation({
                partNumber: formData.part_number,
                description: formData.description,
                locale: formData.locale,
                value: formData.value,
            }),
        {
            onSuccess: () => {
                toast.success(t('message.part_description_save_success'))
                navigate(`/quotes/${quoteId}/${configurationId}/translate-parts`)
            },
            onError: (error: { message: string }) => {
                toast.error(error?.message || t('message.part_description_save_failed'))
            },
        },
    )

    const formik: FormikProps<ITranslationPart> = useFormik<ITranslationPart>({
        initialValues: {
            part_number: partNumber || '',
            description: '',
            locale: 'nl',
            value: '',
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().required(t('message.value_required')),
        }),
        onSubmit: async values => {
            addTranslatedParts.mutate({
                part_number: values.part_number,
                description: values.description,
                value: values.value,
                locale: 'nl',
            })
        },
    })

    const { isLoading } = useQuery(
        ['getCOnfigurationPartList', configurationId, partNumber],
        () =>
            ConfigurationPartTranslation.findByPartNumber({
                partNumber: String(partNumber),
                configuration_id: parseInt(String(configurationId), 10),
            }),
        {
            enabled: !!(partNumber && configurationId),
            refetchOnMount: true,
            onSuccess: (data: any) => {
                formik.setFieldValue('description', data.data.data.description)
                formik.setFieldValue('part_number', data.data.data.part_number)
                formik.setFieldValue('value', data.data.data.value)
            },
            onError: (error: { message: string }) => {
                toast.error(error?.message || t('message.translation_part_fetch_failed'))
            },
        },
    )

    if (isLoading) {
        return (
            <div className="w-full h-screen grid place-content-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex gap-8 flex-col">
            <p className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer">
                {t('api.configuration_part_list')}
            </p>
            <div className="h-full w-full flex-col gap-4">
                <form className="flex flex-col gap-4">
                    <Input
                        horizontal
                        label={t('fields.part_number')}
                        name="part_number"
                        disabled
                        value={formik.values.part_number}
                        onChange={formik.handleChange}
                    />
                    <TextArea
                        horizontal
                        label={t('fields.description')}
                        name="description"
                        disabled
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />
                    <TextArea
                        horizontal
                        label={t('fields.value')}
                        name="value"
                        value={formik.values.value}
                        onChange={formik.handleChange}
                        error={(formik.touched.value && formik.errors.value) || ''}
                    />
                    <div className="flex justify-end gap-4">
                        <div className="w-24">
                            <Button
                                label={t('api.back')}
                                onClick={() =>
                                    navigate(
                                        `/quotes/${quoteId}/${configurationId}/translate-parts`,
                                    )
                                }
                            />
                        </div>
                        <div className="w-24">
                            <Button
                                label={t('api.save')}
                                isLoading={addTranslatedParts.isLoading}
                                onClick={() => formik.handleSubmit()}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditConfigurationParts
