import { Options } from 'components/select'
import Tabs, { TabType } from 'components/tabs'
import { useSearchParams } from 'react-router-dom'
import { numberFormater } from 'utils/numberFormater'
import { useTranslation } from 'react-i18next'
import LeasePriceOptions from './LeasePriceOptions'
import PricingApproval from './PricingApproval'
import ProjectOverview from './ProjectOverview'

const cardClass =
    'w-ful bg-white px-4 py-4 rounded-md overscroll-auto overflow-auto flex flex-col gap-8'

const tabs: TabType[] = [
    {
        name: 'fields.project_overview',
        value: 'project-overview',
    },
    {
        name: 'fields.pricing_request_approval',
        value: 'pricing-request-approval',
    },
    {
        name: 'fields.options',
        value: 'options',
    },
]

const Overview = () => {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const activeTab = (() => {
        switch (searchParams.get('type')) {
            case 'project-overview':
                return tabs[0]
            case 'pricing-request-approval':
                return tabs[1]
            case 'options':
                return tabs[2]
            default:
                return tabs[0]
        }
    })()

    const investGroupData = [
        {
            min_value: 0,
            max_value: 10000,
        },
        {
            min_value: 10000,
            max_value: 45000,
        },
        {
            min_value: 45000,
            max_value: 100000,
        },
        {
            min_value: 100000,
            max_value: 200000,
        },
        {
            min_value: 200000,
            max_value: 0,
        },
    ]

    const investGroupOption: Options[] = investGroupData.map((investmentGroup: any) => ({
        label: `${numberFormater(investmentGroup.min_value)} ${
            investmentGroup.max_value === 0
                ? ' +'
                : ` - ${numberFormater(investmentGroup.max_value)}`
        }`,
        value: parseFloat(String(investmentGroup.min_value)),
    }))

    const category: Options[] = [
        {
            label: t('api.category_1'),
            value: 1,
        },
        {
            label: t('api.category_2'),
            value: 2,
        },
        {
            label: t('api.category_3'),
            value: 3,
        },
    ]

    const renderContent = () => {
        switch (searchParams.get('type')) {
            case 'project-overview':
                return <ProjectOverview investmentGroupOptions={investGroupOption} />
            case 'pricing-request-approval':
                return (
                    <PricingApproval
                        investmentGroupOptions={investGroupOption}
                        categoryOptions={category}
                    />
                )
            case 'options':
                return <LeasePriceOptions />
            default:
                return <ProjectOverview investmentGroupOptions={investGroupOption} />
        }
    }

    return (
        <div className={cardClass}>
            <Tabs
                initialTab={activeTab}
                tabs={tabs || []}
                getActiveTab={(tab: TabType) => {
                    setSearchParams({ type: tab.value })
                }}
            />
            {renderContent()}
        </div>
    )
}

export default Overview
