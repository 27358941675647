import { callAxios } from 'plugins/api'

const getAllPartsByConfigurationId = (configurationId: number) =>
    callAxios({
        url: `novo/configurations/${configurationId}/part-description`,
        method: 'GET',
        isHeffiq: true,
    })

const saveOrUpdatePartTranslation = (formData: {
    partNumber: string
    description: string
    locale: string
    value: string
}) =>
    callAxios({
        url: 'translation-part',
        method: 'PUT',
        data: formData,
        isHeffiq: true,
    })

const findByPartNumber = (formData: { partNumber: string; configuration_id: number }) =>
    callAxios({
        url: `translation-part`,
        method: 'POST',
        data: formData,
        isHeffiq: true,
    })

const deleteTranslationForPart = (partId: string) =>
    callAxios({
        url: `translation-part/${partId}`,
        method: 'DELETE',
        isHeffiq: true,
    })

const ConfigurationPartTranslation = {
    getAllPartsByConfigurationId,
    saveOrUpdatePartTranslation,
    findByPartNumber,
    deleteTranslationForPart,
}

export default ConfigurationPartTranslation
