import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/button'

const ConfigurationSuccess = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const navigateToNewConfiguration = () => {
        navigate('/configuration/create')
    }

    const navigateToConfiguration = () => {
        navigate('/configuration')
    }

    const navigateToNewQuote = () => {
        navigate('/quotes/create')
    }

    return (
        <div className="mt-4 bg-white px-8 py-4 rounded-xl flex flex-col justify-between gap-4 h-[86vh]">
            <div className="w-full border-b-2 border-placeholderColor">
                <h5 className="heading-3 text-lg text-placeholderColor">Review & Confirm</h5>
            </div>
            <div className="w-full display flex flex-col justify-center">
                <div className="container1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-success"
                        viewBox="0 0 24 24"
                    >
                        <g strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                            <circle className="success-circle-outline" cx="12" cy="12" r="11.5" />
                            <circle className="success-circle-fill" cx="12" cy="12" r="11.5" />
                            <polyline className="success-tick" points="17,8.5 9.5,15.5 7,13" />
                        </g>
                    </svg>
                </div>
                <p className="text-lg text-center">
                    {t('message.configuration_created_successful')}
                </p>
            </div>
            <div className="buttons flex w-full justify-center items-center gap-4">
                <div className="w-64">
                    <Button
                        label={t('api.create_new_configuration')}
                        onClick={navigateToNewConfiguration}
                        variant="primary"
                    />
                </div>
                <div className="w-64">
                    <Button
                        label={t('api.go_to_configuration')}
                        onClick={navigateToConfiguration}
                        variant="primary"
                    />
                </div>
                <div className="w-64">
                    <Button
                        label={t('api.create_new_quote')}
                        onClick={navigateToNewQuote}
                        variant="primary"
                    />
                </div>
            </div>
        </div>
    )
}

export default ConfigurationSuccess
