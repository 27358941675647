/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'

interface DropdownProps {
    labelContent: React.ReactElement
    fallbackContent?: React.ReactElement
    content: React.ReactElement
}

const Dropdown = (props: DropdownProps) => {
    const { labelContent, fallbackContent, content } = props
    const ddRef = useRef<any>(null)
    const [open, setOpen] = useState<boolean>(false)

    const dropdownClass = `${
        open ? 'visible' : 'hidden'
    } z-100 text-base list-none bg-white rounded divide-y w-64 divide-gray-100 shadow dark:bg-gray-700 absolute right-0 mt-8`
    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (ddRef.current && !ddRef.current.contains(event.target)) {
                setOpen(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [setOpen])

    return (
        <div className="relative" ref={ddRef}>
            <div
                onClick={e => {
                    e.stopPropagation()
                    setOpen(!open)
                }}
                id="dropdownNavbarLink"
                data-dropdown-toggle="dropdownNavbarLink"
            >
                {open && fallbackContent ? fallbackContent : labelContent}
            </div>

            <div id="dropdownNavbar" className={dropdownClass}>
                {content}
            </div>
        </div>
    )
}

export default Dropdown
