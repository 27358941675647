/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import ReactQuill, { ReactQuillProps } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './text.css'

interface TextEditorProps extends ReactQuillProps {
    label?: string
    errorMessage?: string
    editorStyle?: React.CSSProperties
}
export const TextEditor = (props: TextEditorProps) => {
    const { label, errorMessage, editorStyle } = props
    return (
        <div className="flex flex-col gap-2">
            {label && <label className="label capitalize">{label}</label>}
            <div style={editorStyle}>
                <ReactQuill theme="snow" {...props} />
            </div>
            {errorMessage && <label className="text-red-500">{errorMessage}</label>}
        </div>
    )
}
