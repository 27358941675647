import { useTranslation } from 'react-i18next'
import { AdditionalDataType } from '../views/PricingOrderOverview'

type GeneralPricingProps = {
    additionalData: AdditionalDataType
}

const GeneralPricingDetail = ({ additionalData }: GeneralPricingProps) => {
    const { t } = useTranslation()
    return (
        <div className="rounded-md p-4 bg-white w-full">
            <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                {t('api.general_pricing')}
            </p>
            <div className="grid sm:grid-cols-2 bg-slate-100 p-2 gap-2  mt-4">
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="font-bold text-black">{t(`api.financing`)}:</span>
                    {additionalData?.financing}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="font-bold text-black">
                        {t('api.show_all_prices_separate')}:
                    </span>
                    {additionalData?.show_all_prices_separate}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="font-bold text-black">{t(`api.maintenance`)}:</span>
                    {additionalData?.maintenance?.split('_')?.map(unit => {
                        return `${String(unit).toLowerCase()} `
                    })}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="font-bold text-black">{t(`api.return`)}:</span>
                    {additionalData?.return ? 'yes' : 'no'}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="font-bold text-black">{t(`api.insurance`)}:</span>
                    {additionalData?.insurance ? 'yes' : 'no'}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="font-bold text-black">{t(`api.manual_enter`)}:</span>
                    {additionalData?.return_description || '-'}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="font-bold text-black">{t(`api.running_hours`)}:</span>
                    {additionalData?.running_hours}
                </p>
            </div>
        </div>
    )
}
export default GeneralPricingDetail
