import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { t } from 'i18next'
import Button from '../../../components/button'

/* eslint-disable react/no-unescaped-entities */
interface DuplicateModalProps {
    modal: boolean
    onConfirm: () => void
    closeModal: () => void
}

const DuplicateModal = (props: DuplicateModalProps) => {
    const { modal, closeModal, onConfirm } = props
    return (
        <Modal
            open={modal}
            onClose={closeModal}
            center
            showCloseIcon={false}
            classNames={{
                root: 'rounded-lg',
                modal: 'rounded-lg',
            }}
        >
            <div className="relative px-4 w-full max-w-md h-full md:h-auto">
                <div className="p-6 pt-0 text-center" onClick={() => closeModal()}>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {t('api.configuration_with_same_property_will_be_created')}
                    </h3>
                    <div className="flex justify-center gap-4">
                        <Button
                            label={t('api.yes')}
                            onClick={() => onConfirm()}
                            variant="primary"
                        />
                        <Button label={t('api.no')} onClick={() => closeModal()} variant="ghost" />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DuplicateModal
