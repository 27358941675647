/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unused-prop-types */
import { Checkbox, DatePicker, Form, Input, Select } from 'antd'
import { map } from 'lodash'
import moment from 'moment'
import { callAxios } from 'plugins/api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

/* eslint-disable @typescript-eslint/no-unused-vars */
type OrderQuoteFormProps = {
    formik: any
}

const roundedClass = 'rounded-lg flex-1'
const size = 'large'

const OrderQuoteForm = (props: OrderQuoteFormProps) => {
    const { formik } = props
    const [financeCompany, setFinanceCompany] = useState<any>([])
    const { t } = useTranslation()

    const financeCompanyFetching = useQuery(
        'financeCompany',
        () =>
            callAxios({
                method: 'get',
                url: 'getAllFinanceCompanies',
            }),
        {
            onSuccess: data => {
                const financeComapnyList = data.data.map(
                    (item: { finance_name: string; finance_id: string | number }) => {
                        return {
                            label: item.finance_name,
                            value: item.finance_id,
                        }
                    },
                )
                setFinanceCompany(financeComapnyList)
            },
        },
    )

    return (
        <div className="grid grid-cols-2 gap-x-8 mt-4">
            <Form.Item
                name="name"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.name
                            ? String(formik.errors.name)
                            : 'Required Order Data',
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.quote_made_by')}</h5>
                </div>
                <Input
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    placeholder={t('fields.name_of_dealer')}
                    size={size}
                    disabled
                    className={roundedClass}
                />
                {formik.errors && formik.errors.name && formik.touched.name && (
                    <p className="text-red-600 mt-1">{formik.errors.name}</p>
                )}
            </Form.Item>

            <Form.Item
                name="email"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.email
                            ? String(formik.errors.email)
                            : t('message.email_required'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.email')}</h5>
                </div>
                <Input
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    placeholder={t('fields.enter_email')}
                    type="email"
                    disabled
                    size={size}
                    className={roundedClass}
                />
                {formik.errors && formik.errors.email && formik.touched.email && (
                    <p className="text-red-600 mt-1">{formik.errors.email}</p>
                )}
            </Form.Item>

            <Form.Item
                name="orderBy"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors?.orderBy
                            ? String(formik.errors.orderBy)
                            : t('message.order_date_required'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.order_by')}</h5>
                </div>
                <Input
                    name="user.name"
                    value={formik.values.orderBy}
                    onChange={formik.handleChange}
                    placeholder={t('fields.enter_order_by')}
                    size={size}
                    disabled
                    className={roundedClass}
                />
                {formik.errors && formik.errors?.user?.name && formik.touched.orderBy && (
                    <p className="text-red-600 mt-1">{formik.errors.orderBy}</p>
                )}
            </Form.Item>
            <Form.Item
                name="orderByEmail"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors?.orderByEmail
                            ? String(formik.errors.orderByEmail)
                            : t('message.order_by_email_required'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.order_by_email')}</h5>
                </div>
                <Input
                    name="orderByEmail"
                    type="email"
                    value={formik.values.orderByEmail}
                    onChange={formik.handleChange}
                    placeholder={t('fields.enter_order_by_email')}
                    size={size}
                    disabled
                    className={roundedClass}
                />
                {formik.errors && formik.errors?.user?.email && formik.touched.orderByEmail && (
                    <p className="text-red-600 mt-1">{formik.errors.orderByEmail}</p>
                )}
            </Form.Item>

            <Form.Item
                name="finance_account_number"
                className="flex flex-col"
                rules={[
                    {
                        required: true,
                        message: formik.errors.finance_account_number
                            ? String(formik.errors.finance_account_number)
                            : t('message.finance_account_number_required'),
                    },
                ]}
            >
                <div className="mb-1">
                    <h5 className="capitalize">{t('fields.finance_account_number')}</h5>
                </div>
                <Input
                    name="finance_account_number"
                    value={formik.values.finance_account_number}
                    onChange={formik.handleChange}
                    placeholder="xxxxxx-xxxx-xxxx"
                    size={size}
                    className={roundedClass}
                />
                {formik.errors &&
                    formik.errors.finance_account_number &&
                    formik.touched.finance_account_number && (
                        <p className="text-red-600 mt-1">{formik.errors.finance_account_number}</p>
                    )}
            </Form.Item>

            <div className="flex flex-col">
                <Form.Item
                    name="finance_company_exists"
                    className="flex flex-row items-center w-[45%]"
                    rules={[
                        {
                            required: false,
                            message: formik.errors
                                ? String(formik.errors.finance_company_exists)
                                : t('message.required'),
                        },
                    ]}
                >
                    <div>
                        <h5 className="capitalize">{t('fields.finance_company_doesnt_exist')}</h5>
                    </div>
                    <Checkbox
                        name="finance_company_exists"
                        onChange={formik.handleChange}
                        value={formik.values.finance_company_exists}
                    />
                </Form.Item>
                {!formik.values.finance_company_exists ? (
                    <Form.Item
                        name="finance_company_name"
                        className="flex flex-col"
                        rules={[
                            {
                                required: true,
                                message: formik.errors.finance_company_name
                                    ? String(formik.errors.finance_company_name)
                                    : t('message.name_of_company_required'),
                            },
                        ]}
                    >
                        <div className="mb-1">
                            <h5 className="capitalize">{t('fields.finance_company_name')}</h5>
                        </div>
                        <Input
                            name="finance_company_name"
                            value={formik.values.finance_company_name}
                            onChange={formik.handleChange}
                            placeholder={t('fields.enter_finance_company_name')}
                            size={size}
                            className={roundedClass}
                        />
                        {formik.errors &&
                            formik.errors.finance_company_name &&
                            formik.touched.finance_company_name && (
                                <p className="text-red-600 mt-1">
                                    {formik.errors.finance_company_name}
                                </p>
                            )}
                    </Form.Item>
                ) : (
                    <Form.Item
                        name="exisiting_finance_company"
                        className="flex flex-col"
                        rules={[
                            {
                                required: true,
                                message: formik.errors.exisiting_finance_company
                                    ? String(formik.errors.exisiting_finance_company)
                                    : t('message.order_date_required'),
                            },
                        ]}
                    >
                        <div className="mb-1">
                            <h5 className="capitalize">{t('fields.choose_finance_company')}</h5>
                        </div>
                        <Select
                            defaultValue={
                                formik.values.exisiting_finance_company ||
                                t('fields.choose_your_option')
                            }
                            onChange={(value: string) =>
                                formik.setFieldValue('exisiting_finance_company', value)
                            }
                            loading={financeCompanyFetching.isLoading}
                            className="rounded-lg"
                            size={size}
                        >
                            {map(financeCompany || [], option => (
                                <Select.Option value={option.value}>{option.label}</Select.Option>
                            ))}
                        </Select>
                        {formik.errors &&
                            formik.errors.exisiting_finance_company &&
                            formik.touched.exisiting_finance_company && (
                                <p className="text-red-600 mt-1">
                                    {formik.errors.exisiting_finance_company}
                                </p>
                            )}
                    </Form.Item>
                )}
            </div>
            <div>
                <Form.Item
                    name="dealer"
                    className="flex flex-row items-center w-[45%]"
                    rules={[
                        {
                            required: false,
                            message: formik.errors
                                ? String(formik.errors.type)
                                : t('message.required'),
                        },
                    ]}
                >
                    <div>
                        <h5 className="capitalize">{t('fields.dealer')}</h5>
                    </div>
                    <Checkbox
                        name="type"
                        onChange={formik.handleChange}
                        checked={formik.values.type}
                    />
                </Form.Item>
                <Form.Item
                    name="order_date"
                    className="flex flex-col"
                    rules={[
                        {
                            required: true,
                            message: formik.errors
                                ? String(formik.errors.order_date)
                                : t('message.order_date_required'),
                        },
                    ]}
                >
                    <div className="mb-1">
                        <h5 className="capitalize">
                            {formik.values.type
                                ? t('fields.dealer_request_date')
                                : t('fields.customer_request_date')}
                        </h5>
                    </div>
                    <DatePicker
                        className={roundedClass}
                        size={size}
                        style={{
                            width: '100%',
                        }}
                        value={moment(formik.values.order_date, 'DD-MM-YYYY')}
                    />
                    {formik.errors && formik.errors.order_date && formik.touched.order_date && (
                        <p className="text-red-600 mt-1">{formik.errors.order_date}</p>
                    )}
                </Form.Item>
            </div>
            <br />
            <Form.Item
                name="finance_company_exists"
                className="flex flex-row items-center w-[45%]"
                rules={[
                    {
                        required: false,
                        message: formik.errors
                            ? String(formik.errors.payment_terms)
                            : t('message.payment_terms_required'),
                    },
                ]}
            >
                <div>
                    <h5 className="capitalize">{t('fields.payment_terms')}</h5>
                </div>
                <Checkbox
                    name="payment_terms"
                    onChange={formik.handleChange}
                    checked={formik.values.payment_terms}
                />
            </Form.Item>
        </div>
    )
}

export default OrderQuoteForm
