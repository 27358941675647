import { Collapse } from 'antd'
import Input from 'components/input'
import Table from 'components/table'
import PanelHeader from 'modules/quotes/components/panelHeader'
import { useTranslation } from 'react-i18next'
import { formatCurrency, getCurrencySymbol } from 'utils/currency/formatCurrency'
import { ORDER_TYPE } from 'utils/orderType'

interface IOptionPricingCOmponent {
    options: any[]
    totalOptions: any
    optionalAttachment: any[]
    optionalBatteries: any[]
    optionalBatteryAccessories: any[]
    optionalChargers: any[]
    optionalExSolution: any[]
    optionalPrepList: any[]
    optionalSpecial: any[]
    residualValue: any
}

const OptionTableViewComponent = (props: IOptionPricingCOmponent) => {
    const { t } = useTranslation()
    const { Panel } = Collapse

    const {
        options,
        totalOptions,
        optionalAttachment,
        optionalBatteries,
        optionalBatteryAccessories,
        optionalChargers,
        optionalExSolution,
        optionalPrepList,
        optionalSpecial,
        residualValue,
    } = props

    const tableRows: string[] =
        residualValue[0]?.leasePriceOptions &&
        residualValue[0]?.leasePriceOptions !== '' &&
        residualValue[0]?.leasePriceOptions !== 'null' &&
        residualValue[0]?.leasePriceOptions.length > 0
            ? typeof residualValue[0]?.leasePriceOptions === 'string'
                ? JSON.parse(residualValue[0]?.leasePriceOptions)?.map((item: any) => item.name)
                : residualValue[0]?.leasePriceOptions?.map((item: any) => item.name)
            : []

    const calculateTotalPrice = (extraItems: any[]) => {
        let totalPrice = 0
        if (extraItems && extraItems.length > 0) {
            extraItems.forEach(extraItem => {
                totalPrice += parseFloat(String(extraItem.total_price))
            })
        }
        return parseFloat(String(totalPrice))
    }

    const totalPrepList = calculateTotalPrice(optionalPrepList)
    const totalChargers = calculateTotalPrice(optionalChargers)
    const totalBatteries = calculateTotalPrice(optionalBatteries)
    const totalBatteryAccessories = calculateTotalPrice(optionalBatteryAccessories)
    const totalAttachments = calculateTotalPrice(optionalAttachment)
    const totalExSolutions = calculateTotalPrice(optionalExSolution)
    const totalSpecial = calculateTotalPrice(optionalSpecial)

    const optionalColumn: any = [
        {
            name: t('api.name'),
            selector: (row: any) => row.name,
            left: true,
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity || row.quantity,
            left: true,
            width: '124px',
        },
        {
            name: t('api.price'),
            selector: (row: any) => formatCurrency(String(row.price)),
            right: true,
            width: '144px',
        },
    ]

    const prepListColumn: any = [
        {
            name: t('api.component'),
            selector: (row: any) => row.prep_list[0].component,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.prep_list[0].description,
            left: true,
        },
        {
            name: t('api.position'),
            selector: (row: any) => row.prep_list[0].position,
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const chargerColumn: any = [
        {
            name: t('api.brand'),
            selector: (row: any) => row.charger[0].charger_brand,
            left: true,
        },
        {
            name: t('api.type'),
            selector: (row: any) => row.charger[0].charger_type,
            left: true,
        },
        {
            name: t('api.battery_type'),
            selector: (row: any) => row.charger[0].battery_type,
            left: true,
        },
        {
            name: t('api.grid_power'),
            selector: (row: any) => row.charger[0].grid_power,
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const batteryColumn: any = [
        {
            name: t('api.id'),
            selector: (row: any) => row.battery[0].id,
            left: true,
        },
        {
            name: t('api.type'),
            selector: (row: any) => row.battery[0].battery_type,
            left: true,
        },
        {
            name: t('api.volt'),
            selector: (row: any) => row.battery[0].volt,
            left: true,
        },
        {
            name: t('api.ah'),
            selector: (row: any) => row.battery[0].ah,
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const batteryAccessoriesColumn: any = [
        {
            name: t('api.group'),
            selector: (row: any) => row.battery_accessories[0].group,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.battery_accessories[0].description,
            left: true,
        },
        {
            name: t('api.status'),
            selector: (row: any) => row.status,
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const attachmentColumn: any = [
        {
            name: t('api.model'),
            selector: (row: any) => row.model,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.description,
            left: true,
        },
        {
            name: t('api.order_type'),
            selector: (row: any) =>
                row.order_type === ORDER_TYPE.COMPULSORY ? t('api.compulsory') : t('api.optional'),
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const exSolutionColumn: any = [
        {
            name: t('api.model'),
            selector: (row: any) => row.model,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.description,
            left: true,
        },
        {
            name: t('api.order_type'),
            selector: (row: any) =>
                row.order_type === ORDER_TYPE.COMPULSORY ? t('api.compulsory') : t('api.optional'),
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const specialColumn: any = [
        {
            name: t('api.model'),
            selector: (row: any) => row.model,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.description,
            left: true,
        },
        {
            name: t('api.order_type'),
            selector: (row: any) =>
                row.order_type === ORDER_TYPE.COMPULSORY ? t('api.compulsory') : t('api.optional'),
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    return (
        <div className="w-full flex flex-col gap-2">
            {/* <p className="ml-1 font-semibold">{t('api.optional_items')}</p> */}
            <Collapse ghost accordion expandIconPosition="end" className="bg-white w-full">
                {options && options.length > 0 && (
                    <Panel
                        className="bg-white extra-item-panel"
                        header={
                            <div className="flex w-11/12 justify-between">
                                <p>{t('api.options')}</p>
                                <p>{formatCurrency(totalOptions)}</p>
                            </div>
                        }
                        key={1}
                    >
                        <Table columns={optionalColumn} data={options} striped />
                    </Panel>
                )}
                {optionalPrepList.length > 0 && (
                    <Panel
                        className="bg-white extra-item-panel"
                        header={PanelHeader(
                            t('api.prep_lists'),
                            <p> {formatCurrency(totalPrepList)}</p>,
                        )}
                        key={2}
                    >
                        <Table columns={prepListColumn} data={optionalPrepList} striped />
                    </Panel>
                )}
                {optionalChargers.length > 0 && (
                    <Panel
                        className="bg-white extra-item-panel"
                        header={PanelHeader(
                            t('api.chargers'),
                            <p>{formatCurrency(totalChargers)}</p>,
                        )}
                        key={3}
                    >
                        <Table columns={chargerColumn} data={optionalChargers} striped />
                    </Panel>
                )}
                {optionalBatteries.length > 0 && (
                    <Panel
                        className="bg-white extra-item-panel"
                        header={PanelHeader(
                            t('api.batteries'),
                            <p>{formatCurrency(totalBatteries)}</p>,
                        )}
                        key={4}
                    >
                        <Table columns={batteryColumn} data={optionalBatteries} striped />
                    </Panel>
                )}
                {optionalBatteryAccessories.length > 0 && (
                    <Panel
                        className="bg-white extra-item-panel"
                        header={PanelHeader(
                            t('api.battery_accessories'),
                            <p>{formatCurrency(totalBatteryAccessories)}</p>,
                        )}
                        key={5}
                    >
                        <Table
                            columns={batteryAccessoriesColumn}
                            data={optionalBatteryAccessories}
                            striped
                        />
                    </Panel>
                )}
                {optionalSpecial.length > 0 && (
                    <Panel
                        className="bg-white extra-item-panel"
                        header={PanelHeader(
                            t('api.specials'),
                            <p>{formatCurrency(totalSpecial)}</p>,
                        )}
                        key={6}
                    >
                        <Table columns={specialColumn} data={optionalSpecial} striped />
                    </Panel>
                )}
                {optionalAttachment.length > 0 && (
                    <Panel
                        className="bg-white extra-item-panel"
                        header={PanelHeader(
                            t('api.attachments'),
                            <p>{formatCurrency(totalAttachments)}</p>,
                        )}
                        key={7}
                    >
                        <Table columns={attachmentColumn} data={optionalAttachment} striped />
                    </Panel>
                )}
                {optionalExSolution.length > 0 && (
                    <Panel
                        className="bg-white extra-item-panel"
                        header={PanelHeader(
                            t('api.ex_solutions'),
                            <p>{formatCurrency(totalExSolutions)}</p>,
                        )}
                        key={8}
                    >
                        <Table columns={exSolutionColumn} data={optionalExSolution} striped />
                    </Panel>
                )}
            </Collapse>
        </div>
    )
}

export default OptionTableViewComponent
