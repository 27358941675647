import React, { forwardRef } from 'react'

interface TextAreaProps {
    horizontal?: boolean
    placeholder?: string
    value: string | number | null
    label?: string
    name: string
    error?: string | null
    disabled?: boolean
    center?: boolean
    cols?: number
    rows?: number
    customInputStyles?: string
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props: TextAreaProps, ref) => {
    const {
        horizontal,
        placeholder,
        value,
        label,
        name,
        disabled,
        center,
        error,
        cols,
        rows,
        customInputStyles,
        onChange,
        onBlur,
    } = props
    const outlineTextArea = 'border-2 border-inputBorder placeholder-placeholderColor'
    const textAreaClass = 'rounded-lg flex items-center gap-2'

    return (
        <div
            className={`flex ${
                horizontal
                    ? 'flex-col md:flex-row md:items-center justify-between'
                    : 'flex-col justify-start'
            }   gap-1 w-full`}
        >
            <div className={`${horizontal ? 'w-[27%] mt-2' : 'flex justify-start'}`}>
                {label && (
                    <label htmlFor={name} className={`${horizontal ? 'text-md' : 'text-md'} px-1`}>
                        {label}
                    </label>
                )}
            </div>
            <div className={`${horizontal ? 'w-[70%]' : ''}`}>
                <div className={`${textAreaClass} ${outlineTextArea}`}>
                    <textarea
                        ref={ref}
                        placeholder={placeholder}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        cols={cols}
                        rows={rows}
                        name={name}
                        disabled={Boolean(disabled)}
                        className={`py-2 px-2 w-full focus:outline-none rounded-lg text-sm ${
                            center && 'text-center'
                        } ${customInputStyles}`}
                    />
                </div>
                {error && <span className="text-red-700 text-sm">{error}</span>}
            </div>
        </div>
    )
})

export default TextArea
