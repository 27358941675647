import { Modal } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddExtraQuote from '../views/AddExtraQuote'

interface QuoteExtraQuickViewProps {
    configuration: any
}

const QuoteExtraQuickView = (props: QuoteExtraQuickViewProps) => {
    const { configuration } = props
    const { t } = useTranslation()
    const [showExtraModal, setShowExtraModal] = useState<boolean>(false)

    return (
        // <Dropdown
        //     placement="bottomRight"
        //     arrow
        //     overlay={
        //         <div className="bg-white w-[300px] md:w-[600px] lg:w-[800px] h-auto max-h-[40vh] overflow-y-auto p-4 rounded-md drop-shadow-md">
        //             <AddExtraQuote configuration={configuration.id} viewOnly />
        //         </div>
        //     }
        //     trigger={['click', 'hover']}
        // >
        <>
            <button
                type="button"
                className="bg-[#00c543] rounded-md p-2 capitalize text-white text-center cursor-pointer w-fit"
                onClick={() => setShowExtraModal(true)}
            >
                {t('api.show_extra')}
            </button>
            <Modal
                title={t('api.extra')}
                open={showExtraModal}
                centered
                footer={null}
                width={1000}
                onCancel={() => setShowExtraModal(false)}
            >
                <div>
                    <AddExtraQuote configuration={configuration.id} viewOnly />
                </div>
            </Modal>
        </>
        // </Dropdown>
    )
}

export default QuoteExtraQuickView
