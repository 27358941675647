import React from 'react'
import { FormikProps } from 'formik'
import { DatePicker } from 'antd'
import moment from 'moment/moment'
import Input from '../../../components/input'
import { ISiteSurveyForm } from './AddSiteSurvey'
import useTranslationData from '../../translation/hooks/useTranslationData'

interface ISiteSurveyInformation {
    formik: FormikProps<ISiteSurveyForm>
}

const SiteSurveyInformation = (props: ISiteSurveyInformation) => {
    const { formik } = props
    const {
        translation: { t },
    } = useTranslationData()

    return (
        <div className="flex flex-col gap-4 w-full">
            <Input
                value={formik.values.customer}
                horizontal
                required
                name="customer"
                onChange={formik.handleChange}
                type="text"
                error={formik.touched.customer ? formik.errors.customer : undefined}
                label={t('fields.customer')}
            />
            <Input
                value={formik.values.contactPerson}
                name="contactPerson"
                horizontal
                required
                onChange={formik.handleChange}
                type="text"
                error={formik.touched.contactPerson ? formik.errors.contactPerson : undefined}
                label={t('fields.contact_person')}
            />
            <div className="flex flex-col md:flex-row gap-2 justify-between">
                <div className="w-[27%] mt-2">
                    <span className="text-md px-1">{t('fields.date')}</span>
                </div>
                <div className="w-[70%]">
                    <DatePicker
                        value={
                            formik.values.date
                                ? moment(formik.values.date, 'YYYY-MM-DD')
                                : moment(new Date(), 'YYYY-MM-DD')
                        }
                        onChange={(date: any) =>
                            formik.setFieldValue('date', moment(date).format('YYYY-MM-DD'))
                        }
                        className="rounded-lg flex-1 text-md"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                    />
                    {formik.touched.date && (
                        <span className="text-red-700 text-sm">{formik.errors.date}</span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SiteSurveyInformation
