import { callAxios } from 'plugins/api'

const updateNonHysterFreeText = (params: { configurationId: number; freeText: string }) =>
    callAxios({
        method: 'PUT',
        url: `novo/configurations/${params.configurationId}/non-hyster-free-text`,
        data: { non_hyster_free_text: params.freeText },
        isHeffiq: true,
    })

const getNonHysterFreeText = (configurationId: number) =>
    callAxios({
        method: 'GET',
        url: `novo/configurations/${configurationId}/non-hyster-free-text`,
        isHeffiq: true,
    })

const NonHysterFreeTextQueries = {
    updateNonHysterFreeText,
    getNonHysterFreeText,
}

export default NonHysterFreeTextQueries
