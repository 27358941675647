import * as Yup from 'yup'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import { LOGIN_ROUTE } from 'route/appRoutes'
import { useAuth } from '../context/useAuth'
import useTranslationData from '../../translation/hooks/useTranslationData'
import Input from '../../../components/input'
import Button from '../../../components/button'

interface LoginValues {
    email: string
}

const ForgotPasswordForm = () => {
    const { forgotPassword, loading } = useAuth()

    const {
        translation: { t },
    } = useTranslationData()

    const formik: FormikProps<LoginValues> = useFormik<LoginValues>({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required('Email is required'),
        }),
        onSubmit: async (values, { setSubmitting }: FormikHelpers<LoginValues>) => {
            await forgotPassword(values)
            setSubmitting(false)
        },
    })

    return (
        <form className="flex flex-col gap-4">
            <Input
                value={formik.values.email}
                onChange={formik.handleChange}
                name="email"
                placeholder={t('fields.email')}
                label={t('fields.email')}
            />
            {formik.errors.email && formik.errors.email && (
                <p className="text-validation-warning">{formik.errors.email}</p>
            )}
            <div className="text-right">
                <a href={LOGIN_ROUTE} className="link text-sm text-secondary underline">
                    {t('api.back_to_login')}
                </a>
            </div>
            <div className="w-full">
                <Button
                    label={t('api.reset_password')}
                    isLoading={Boolean(loading) || Boolean(formik.isSubmitting)}
                    onClick={() => formik.handleSubmit()}
                />
            </div>
        </form>
    )
}

export default ForgotPasswordForm
