/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { filter } from 'lodash'

export type StepType = {
    id: string | number
    step: number
    label: string
    hidden?: boolean
    disabled?: boolean
    component: React.ReactElement
}

interface StepperProps {
    steppers: StepType[]
    activeStep: StepType
    changeActiveStep?: (step: number) => void
}

const Stepper = (props: StepperProps) => {
    const { steppers, activeStep, changeActiveStep } = props
    return (
        <div className="flex flex-wrap justify-evenly items-center gap-8 bg-white rounded-xl p-4 w-full">
            {filter(steppers, item => {
                return !item.hidden
            }).map((item: StepType, index: number) => {
                const activeStepperClass = `h-10 w-10 rounded-full grid place-content-center ${
                    activeStep.id === item.id
                        ? 'bg-secondary text-white'
                        : 'bg-white  border-placeholderColor border-2 text-placeholderColor'
                }`
                const textColor = activeStep.id === item.id ? 'text-black' : 'text-placeholderColor'
                return (
                    <div
                        key={index}
                        className={`${
                            activeStep.id !== item.id ? 'hidden' : 'flex'
                        } lg:flex flex-col items-center gap-2 cursor-pointer hover:text-primary`}
                        onClick={() =>
                            changeActiveStep && Number(activeStep.id) > item.step && !item.disabled
                                ? changeActiveStep(item.step)
                                : null
                        }
                    >
                        <div className={activeStepperClass}>
                            <span className="text-lg font-extrabold">{item.step + 1}</span>
                        </div>
                        <div className="flex flex-col justify-start gap-1 md:block">
                            {/* <p className="text-placeholderColor text-xs">Step {item.step + 1}</p> */}
                            <h5
                                className={`leading-3 text-sm ${
                                    item?.disabled ? 'text-gray cursor-default' : textColor
                                } ${!item?.disabled && 'hover:text-primary'}`}
                            >
                                {item.label}
                            </h5>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Stepper
