import { t } from 'i18next'
import React from 'react'
import 'react-responsive-modal/styles.css'

import Table from '../../../components/table'
import { IAllRepliedNotificationUserName } from '../models/Discounts'

interface IProps {
    data: IAllRepliedNotificationUserName[]
}

const columns = [
    {
        name: t('api.requested_by'),
        cell: (row: { requested_by_name: string }) => {
            return <span className="font-bold">{row.requested_by_name}</span>
        },
        left: true,
    },
    {
        name: t('api.requested_discount_percentage'),
        cell: (row: { requested_discount_percentage: string }) => {
            return <span className="font-bold">{row.requested_discount_percentage}</span>
        },
        center: true,
    },
    {
        name: t('api.approved_discount_percentage'),
        cell: (row: { provided_discount: string }) => {
            return <span className="font-bold">{row.provided_discount}</span>
        },
        center: true,
    },
    {
        name: t('api.configuration_name'),
        cell: (row: { configuration_name: string }) => {
            return <span className="font-bold">{row.configuration_name}</span>
        },
        left: true,
    },
    {
        name: t('api.reply_comment'),
        cell: (row: { comment: string }) => {
            return <span className="font-bold">{row.comment}</span>
        },
        left: true,
    },
    {
        name: t('api.requested_at'),
        cell: (row: { requested_at: string }) => {
            return <span className="font-bold">{row.requested_at}</span>
        },
        center: true,
    },
    {
        name: t('api.requested_by'),
        cell: (row: { replied_by_name: string }) => {
            return <span className="font-bold">{row.replied_by_name}</span>
        },
        left: true,
    },
    {
        name: t('api.published_at'),
        cell: (row: { published_at: string }) => {
            return <span className="font-bold">{row.published_at}</span>
        },
        center: true,
    },
    {
        name: t('api.configuration_list'),
        cell: (row: { requested_configuration_id: number }) => {
            return (
                <a
                    href={`configuration/${row.requested_configuration_id}`}
                    className="flex items-center justify-start gap-3 w-full py-2 px-4 text-sm underline text-accent"
                >
                    <span>See Configuration</span>
                </a>
            )
        },
        center: true,
    },
]

const DiscountHistory = ({ data }: IProps) => {
    return (
        <div>
            <Table columns={columns} data={data || []} striped pagination totalRows={10} />
        </div>
    )
}

export default DiscountHistory
