import { useTranslation } from 'react-i18next'
// eslint-disable-next-line no-shadow
export enum PRICING_STATUS {
    NEW = 0,
    DRAFT = 1,
    REQUEST_FLEET,
    FLEET_ADJUSTED,
    REQUEST_APPROVAL_PENDING,
    APPROVED,
    REQUEST_WORKSHOP,
    WORKSHOP_ADJUSTED,
}

const textStyle = 'whitespace-normal text-center rounded-full px-4 text-white'

const GetPricingStatusText = (props: { pendingStatus: number }) => {
    const { pendingStatus } = props
    const { t } = useTranslation()

    switch (pendingStatus) {
        case PRICING_STATUS.NEW:
            return <div className={`bg-zinc-600 ${textStyle}`}>{t('api.new')}</div>
        case PRICING_STATUS.DRAFT:
            return <div className={`bg-blue-300 ${textStyle}`}>{t('api.draft')}</div>
        case PRICING_STATUS.REQUEST_FLEET:
            return <div className={`bg-primary ${textStyle}`}>{t('api.request_fleet')}</div>
        case PRICING_STATUS.FLEET_ADJUSTED:
            return <div className={`bg-successColor ${textStyle}`}>{t('api.fleet_adjusted')}</div>
        case PRICING_STATUS.REQUEST_APPROVAL_PENDING:
            return <div className={`bg-orange-300 ${textStyle}`}>{t('api.request_approval')}</div>
        case PRICING_STATUS.APPROVED:
            return <div className={`bg-successColor ${textStyle}`}>{t('api.approved')}</div>
        case PRICING_STATUS.REQUEST_WORKSHOP:
            return <div className={`bg-successColor ${textStyle}`}>{t('api.request_workshop')}</div>
        case PRICING_STATUS.WORKSHOP_ADJUSTED:
            return <div className={`bg-orange-300 ${textStyle}`}>{t('api.workshop_adjusted')}</div>
        default:
            return <div className={`bg-zinc-600 ${textStyle}`}>{t('api.new')}</div>
    }
}

export default GetPricingStatusText
