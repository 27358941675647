import Button from 'components/button'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
    dockShelterOrRampsOptions,
    floorConditionOption,
    floorOptions,
    workInShiftOptions,
    workingEnvironmentTemperatureOptions,
    FuelTypeOptions,
    tankOption,
    batteryBrandOption,
    batteryTypeOptions,
    eloOrHipOptions,
    chargingTimeOptions,
} from 'modules/siteSurvey/components/SiteSurveyOptions'
import {
    indoorOrOutdoorOptions,
    seasonalCrowdOption,
    palletSizeOptions,
    couplingOptions,
    plugTypeOption,
} from 'modules/siteSurvey/components/siteSurveyDropDown'
import { SiteSurveyData } from '../query/model'

interface ConfigurationSiteSurveyDetailprops {
    siteSurveyDetail: SiteSurveyData
    siteSurveyMenu: () => void
}

const removeUnderscore = (str: string) => {
    return str.replace(/_/g, ' ')
}

const SiteSurveyListItem = (props: { label: string; value?: string | null; unit?: string }) => {
    const { label, value, unit } = props

    if (!value) {
        return null
    }

    return (
        <div className="flex items-center justify-start gap-2 w-full">
            <p className="font-bold text-md capitalize">{label}</p>
            <p>:</p>
            <p className="w-auto">
                {value} {unit}
            </p>
        </div>
    )
}

const ConfigurationSiteSurveyDetail = (props: ConfigurationSiteSurveyDetailprops) => {
    const { siteSurveyDetail, siteSurveyMenu } = props
    const { t } = useTranslation()

    if (!siteSurveyDetail) {
        return (
            <div className="flex flex-col items-center justify-center gap-4">
                <p className="text-lg text-bold">{t('api.add_site_survey')}</p>
                <div className="w-2/4">
                    <Button
                        label={t('api.add_site_survey')}
                        variant="outline"
                        onClick={siteSurveyMenu}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4">
            <div className="flex md:items-end flex-col md:flex-row justify-between">
                <div>
                    <div className="flex items-center justify-start gap-2">
                        <p>{t('fields.customer')}:</p>
                        <p>{siteSurveyDetail?.customer}</p>
                    </div>
                    <div className="flex items-center justify-start gap-2">
                        <p>{t('fields.contact_person')}:</p>
                        <p>{siteSurveyDetail?.contact_person}</p>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-start gap-2">
                        <p>{moment(siteSurveyDetail?.date).format('MM-ddd-YYYY hh:mm A')}</p>
                    </div>
                    <div className="flex items-end justify-end gap-2">
                        <p>{siteSurveyDetail?.machine_type}</p>
                    </div>
                </div>
            </div>
            <hr />
            <div>
                <p>{siteSurveyDetail?.description}</p>
            </div>
            <hr />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <SiteSurveyListItem
                    label={t('fields.segment')}
                    value={siteSurveyDetail?.segments.name}
                />
                <SiteSurveyListItem
                    label={t('fields.series')}
                    value={siteSurveyDetail?.series.name}
                />
                <SiteSurveyListItem
                    label={t('fields.model')}
                    value={siteSurveyDetail?.models.name}
                />
                <SiteSurveyListItem
                    label={t('fields.location_and_department')}
                    value={siteSurveyDetail?.location_and_department}
                />
                <SiteSurveyListItem label="Branch" value={siteSurveyDetail?.branch} />
                <SiteSurveyListItem
                    label="Floor"
                    value={t(
                        floorOptions.find(floor => floor.value === siteSurveyDetail.floor)?.label ||
                            '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.floor_condition')}
                    value={t(
                        floorConditionOption.find(
                            floorCondition =>
                                floorCondition.value === siteSurveyDetail.floor_condition,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.indoor_outdoor')}
                    value={t(
                        indoorOrOutdoorOptions.find(
                            indoorOrOutDoor =>
                                indoorOrOutDoor.value === siteSurveyDetail.indoor_or_outdoor,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.working_environment_tempprature')}
                    value={t(
                        workingEnvironmentTemperatureOptions.find(
                            workingEnvironment =>
                                workingEnvironment.value ===
                                siteSurveyDetail.working_environment_temperature,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.fork_clearance')}
                    value={siteSurveyDetail?.fork_clearance}
                />
                <SiteSurveyListItem
                    label={t('fields.outlet_height_for_pallet')}
                    value={siteSurveyDetail?.outlet_height_for_pallet}
                />
                <SiteSurveyListItem
                    label={t('fields.corridor_width')}
                    value={siteSurveyDetail?.corridor_width}
                />
                <SiteSurveyListItem
                    label="Dock Shelters"
                    value={t(
                        dockShelterOrRampsOptions.find(
                            dockShelter => dockShelter.value === siteSurveyDetail.dock_shelters,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label="Ramps"
                    value={t(
                        dockShelterOrRampsOptions.find(
                            ramp => ramp.value === siteSurveyDetail.ramps,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.running_hours')}
                    value={siteSurveyDetail?.running_hours}
                    unit={t('api.hrs')}
                />
                <SiteSurveyListItem
                    label={t('fields.seasonal_crowds')}
                    value={t(
                        seasonalCrowdOption.find(
                            seasonalCrowd =>
                                seasonalCrowd.value === siteSurveyDetail.seasonal_crowds,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.work_in_shifts')}
                    value={t(
                        workInShiftOptions.find(
                            workInShift => workInShift.value === siteSurveyDetail.work_in_shifts,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.surrounding_additional_information')}
                    value={siteSurveyDetail?.surrounding_additional_information}
                />
                <SiteSurveyListItem
                    label="Pallet Size"
                    value={t(
                        palletSizeOptions.find(
                            palletSize => palletSize.value === siteSurveyDetail.pallet_size,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.load_dimension')}
                    value={siteSurveyDetail?.load_dimension}
                />
                <SiteSurveyListItem
                    label={t('fields.material')}
                    value={siteSurveyDetail?.material}
                />
                <SiteSurveyListItem
                    label={t('fields.weight')}
                    value={siteSurveyDetail?.weight}
                    unit="Kg"
                />
                <SiteSurveyListItem
                    label={t('fields.load_center')}
                    value={siteSurveyDetail?.load_center}
                />
                <SiteSurveyListItem
                    label={t('fields.stacking_package')}
                    value={siteSurveyDetail?.stacking_package}
                />
                <SiteSurveyListItem
                    label={t('fields.load_additional_information')}
                    value={siteSurveyDetail?.load_additional_information}
                />
                <SiteSurveyListItem
                    label={t('fields.fuel')}
                    value={t(
                        FuelTypeOptions.find(fuelType => fuelType.value === siteSurveyDetail.fuel)
                            ?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.coupling')}
                    value={t(
                        couplingOptions.find(
                            coupling => coupling.value === siteSurveyDetail.coupling,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.tank')}
                    value={t(
                        tankOption.find(tank => tank.value === siteSurveyDetail.tank)?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.battery_brand')}
                    value={t(
                        batteryBrandOption.find(
                            batteryBrand => batteryBrand.value === siteSurveyDetail.battery_brand,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.battery_type')}
                    value={t(
                        batteryTypeOptions.find(
                            batteryType => batteryType.value === siteSurveyDetail.battery_type,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.battery_capacity')}
                    value={siteSurveyDetail?.battery_capacity}
                    unit="Ah"
                />
                <SiteSurveyListItem
                    label={t('fields.voltage')}
                    value={siteSurveyDetail?.voltage}
                    unit="v"
                />
                <SiteSurveyListItem
                    label={t('fields.elo_or_hip')}
                    value={t(
                        eloOrHipOptions.find(
                            eloOrHip => eloOrHip.value === siteSurveyDetail.elo_or_hip,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.central_filling_system')}
                    value={siteSurveyDetail?.central_filling_system ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.charging_time')}
                    value={t(
                        chargingTimeOptions.find(
                            chargingTime => chargingTime.value === siteSurveyDetail.charging_time,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.plug_type')}
                    value={t(
                        plugTypeOption.find(
                            plugType => plugType.value === siteSurveyDetail.plug_type,
                        )?.label || '',
                    )}
                />
                <SiteSurveyListItem
                    label={t('fields.fork_dimension')}
                    value={siteSurveyDetail?.fork_dimension}
                />
                <SiteSurveyListItem
                    label={t('fields.measure_over_forks')}
                    value={siteSurveyDetail?.measure_over_forks}
                />
                <SiteSurveyListItem
                    label={t('fields.measure_between_fork')}
                    value={siteSurveyDetail?.measure_between_fork}
                />
                <SiteSurveyListItem
                    label={t('fields.fork_wheels')}
                    value={siteSurveyDetail?.fork_wheels}
                />
                <SiteSurveyListItem
                    label={t('fields.heavy_clamp')}
                    value={siteSurveyDetail?.heavy_clamp ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.attachments')}
                    value={siteSurveyDetail?.attachments}
                />
                <SiteSurveyListItem
                    label={t('fields.load_rack')}
                    value={siteSurveyDetail?.load_rack ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.lfl_or_ffl')}
                    value={siteSurveyDetail?.lfl_or_ffl}
                />
                <SiteSurveyListItem label={t('fields.mast')} value={siteSurveyDetail?.mast} />
                <SiteSurveyListItem
                    label={t('fields.platform_height')}
                    value={siteSurveyDetail?.platform_height}
                />
                <SiteSurveyListItem
                    label={t('fields.inductance_or_railconduction')}
                    value={siteSurveyDetail?.inductance_or_railconduction}
                />
                <SiteSurveyListItem label={t('fields.control')} value={siteSurveyDetail?.control} />
                <SiteSurveyListItem
                    label={t('fields.steering')}
                    value={siteSurveyDetail?.steering}
                />
                <SiteSurveyListItem
                    label={t('fields.roof_plate')}
                    value={siteSurveyDetail?.roof_plate ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.cabin')}
                    value={siteSurveyDetail?.cabin ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.heating_or_air_condition')}
                    value={siteSurveyDetail?.heating_or_air_condition ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.mirrors')}
                    value={siteSurveyDetail?.mirrors ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.radio')}
                    value={siteSurveyDetail?.radio ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem label={t('fields.safety')} value={siteSurveyDetail?.safety} />
                <SiteSurveyListItem
                    label={t('fields.lighting')}
                    value={siteSurveyDetail?.lighting}
                />
                <SiteSurveyListItem
                    label={t('fields.public_road')}
                    value={siteSurveyDetail?.public_road ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.work_lights')}
                    value={siteSurveyDetail?.work_lights ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.flashing_light')}
                    value={siteSurveyDetail?.flashing_Light ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem label={t('fields.pal')} value={siteSurveyDetail?.pal} />
                <SiteSurveyListItem
                    label={t('fields.type_banden')}
                    value={siteSurveyDetail?.type_banden}
                />
                <SiteSurveyListItem
                    label={t('fields.max_work_hour')}
                    value={`${siteSurveyDetail?.max_work_hour} hrs`}
                />
                <SiteSurveyListItem
                    label={t('fields.hyster_tracker')}
                    value={siteSurveyDetail?.hyster_tracker}
                />
                <SiteSurveyListItem
                    label={t('fields.truck_model_additional_information')}
                    value={siteSurveyDetail?.truck_model_additional_information}
                />
                <SiteSurveyListItem
                    label={t('fields.financing')}
                    value={siteSurveyDetail?.financing}
                />
                <SiteSurveyListItem
                    label={t('fields.alternative_financing')}
                    value={siteSurveyDetail?.alternative_financing}
                />
                <SiteSurveyListItem
                    label={t('fields.maintenance')}
                    value={siteSurveyDetail?.maintenance.replaceAll('_', ' ')}
                />
                <SiteSurveyListItem
                    label={t('fields.insurance')}
                    value={siteSurveyDetail?.insurance ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.return')}
                    value={siteSurveyDetail?.return ? t('api.yes') : t('api.no')}
                />
                <SiteSurveyListItem
                    label={t('fields.duration_of_contract')}
                    value={siteSurveyDetail?.duration_of_contract}
                    unit="months"
                />
                <SiteSurveyListItem
                    label={t('fields.offer_options_additional_information')}
                    value={siteSurveyDetail?.offer_options_additional_information}
                />
                <SiteSurveyListItem label={t('fields.comment')} value={siteSurveyDetail?.comment} />
            </div>
        </div>
    )
}

export default ConfigurationSiteSurveyDetail
