import Loading from 'components/loading'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { toast } from 'react-toastify'
import { getLeasePriceOptions } from '../queries/quotes'

const LeasePriceOptions = () => {
    const { t } = useTranslation()
    const { quoteId } = useParams()
    const [durationOfContractArray, setDurationOfContractArray] = useState<number[]>([])
    const [leasePriceOption, setLeasePriceOption] = useState<any[]>([])

    const { isLoading } = useQuery(
        'getLeasePriceOptions',
        () => getLeasePriceOptions(parseInt(String(quoteId), 10)),
        {
            onSuccess: data => {
                const extraLeasePriceOption = data.data.data.extra_lease_price_options
                const durationOfContract: number[] = []
                const tempLeasePricesOption: any[] = []
                data.data.data?.saved_lease_prices.forEach((lease: any) => {
                    if (!durationOfContract.includes(lease.duration_of_contract)) {
                        durationOfContract.push(lease.duration_of_contract)
                    }
                    const previousItemIndex = tempLeasePricesOption.findIndex(
                        (option: any) => option.id === lease.id,
                    )
                    if (previousItemIndex >= 0) {
                        const previousItem = tempLeasePricesOption[previousItemIndex]
                        const previousLeasePriceOption = previousItem.leasePriceOption
                        const leaseOption = JSON.parse(lease.lease_price_options)
                        previousLeasePriceOption.map((previousLease: any, index: number) => {
                            const leasePriceByName = extraLeasePriceOption.find(
                                (leasePrice: any) => leasePrice.name === previousLease.name,
                            )
                            return {
                                name: previousLease.name,
                                quantity: previousLease.quantity,
                                lease_price: [
                                    ...previousLease.lease_price,
                                    {
                                        duration: lease.duration_of_contract,
                                        value: leaseOption[index].lease_price,
                                        purchasePrice: leasePriceByName?.price,
                                        salesPrice: leasePriceByName?.selling_price,
                                        profit:
                                            parseFloat(String(leasePriceByName?.selling_price)) -
                                            parseFloat(String(leasePriceByName?.price)),
                                    },
                                ],
                            }
                        })
                        tempLeasePricesOption[previousItemIndex].leasePriceOption =
                            previousLeasePriceOption.map((previousLease: any, index: number) => {
                                const leasePriceByName = extraLeasePriceOption.find(
                                    (leasePrice: any) => leasePrice.name === previousLease.name,
                                )
                                return {
                                    name: previousLease.name,
                                    quantity: previousLease.quantity,
                                    lease_price: [
                                        ...previousLease.lease_price,
                                        {
                                            duration: lease.duration_of_contract,
                                            value: leaseOption[index].lease_price,
                                            purchasePrice: leasePriceByName?.price,
                                            salesPrice: leasePriceByName?.selling_price,
                                            profit:
                                                parseFloat(
                                                    String(leasePriceByName?.selling_price),
                                                ) - parseFloat(String(leasePriceByName?.price)),
                                        },
                                    ],
                                }
                            })
                    } else {
                        const leaseOption = JSON.parse(lease.lease_price_options)
                        tempLeasePricesOption.push({
                            id: lease.id,
                            model: lease.model,
                            leasePriceOption: leaseOption
                                ? leaseOption.map((l: any) => {
                                      const leasePriceByName = extraLeasePriceOption.find(
                                          (leasePrice: any) => leasePrice.name === l.name,
                                      )
                                      return {
                                          name: l.name,
                                          quantity: l.quantity,
                                          lease_price: [
                                              {
                                                  duration: lease.duration_of_contract,
                                                  value: l.lease_price,
                                                  purchasePrice: leasePriceByName?.price,
                                                  salesPrice: leasePriceByName?.selling_price,
                                                  profit:
                                                      parseFloat(
                                                          String(leasePriceByName?.selling_price),
                                                      ) -
                                                      parseFloat(String(leasePriceByName?.price)),
                                              },
                                          ],
                                      }
                                  })
                                : [],
                        })
                    }
                })
                setDurationOfContractArray(durationOfContract.sort((a, b) => a - b))
                setLeasePriceOption(tempLeasePricesOption)
            },
            onError: () => {
                toast.error('message.fetch_lease_price_options_failed')
            },
        },
    )

    if (isLoading) {
        return (
            <div className="h-full w-full flex justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="w-full h-full overflow-auto">
            <table className="border border-gray w-max">
                <thead>
                    <th className="border border-gray py-2 px-2">{t('api.id')}</th>
                    <th className="border border-gray py-2 px-2">{t('api.model')}</th>
                    <th className="border border-gray py-2 px-2 w-[18rem]">
                        {t('api.service_price_per_month_for_option')}
                    </th>
                    {durationOfContractArray.map(duration => (
                        <th key={duration} className="py-2 px-2 w-32 border border-gray">
                            {duration} {t('api.lease_months')}
                        </th>
                    ))}
                    <th className="py-2 px-2 w-32 border border-gray">{t('api.purchase_p/st')}</th>
                    <th className="py-2 px-2 w-32 border border-gray">{t('api.sales_p/st')}</th>
                    <th className="py-2 px-2 w-32 border border-gray">{t('api.profit_p/st')}</th>
                </thead>
                <tbody>
                    {leasePriceOption.map(lease => {
                        return (
                            <tr key={lease.id}>
                                <td className="py-1 px-2 border border-gray">{lease.id}</td>
                                <td className="py-1 px-2 border border-gray">{lease.model}</td>
                                <td
                                    className="w-fit p-0"
                                    colSpan={durationOfContractArray.length + 4}
                                >
                                    <table className="border border-gray w-fit">
                                        {lease.leasePriceOption.map((price: any, index: number) => (
                                            <tr key={index}>
                                                <td className="border border-gray w-[18rem] py-1 px-2">
                                                    {price.name}
                                                </td>
                                                {durationOfContractArray.map(duration => (
                                                    <td
                                                        key={duration}
                                                        className="border text-right border-gray py-1 px-2 w-32"
                                                    >
                                                        {formatCurrency(
                                                            price?.lease_price.find(
                                                                (lp: any) =>
                                                                    lp.duration === duration,
                                                            )?.value || 0,
                                                        )}
                                                    </td>
                                                ))}
                                                <td className="border text-right border-gray py-1 px-2 w-32">
                                                    {formatCurrency(
                                                        price?.lease_price[0].purchasePrice,
                                                    )}
                                                </td>
                                                <td className="border text-right border-gray py-1 px-2 w-32">
                                                    {formatCurrency(
                                                        price?.lease_price[0].salesPrice,
                                                    )}
                                                </td>
                                                <td className="border text-right border-gray py-1 px-2 w-32">
                                                    {formatCurrency(price?.lease_price[0].profit)}
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default LeasePriceOptions
