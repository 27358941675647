/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-unstable-nested-components */
import Button from 'components/button'
import Quantity from 'components/quantity'
import Table from 'components/table'
import { extend, find, includes, pick } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Fuse from 'fuse.js'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { useMutation, useQueryClient } from 'react-query'

import { addExtraItem } from 'modules/quotes/queries/quotes'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import AlertModal from 'components/modal/alertModal'
import Input from 'components/input'
import { sendRequestTs } from 'modules/quotes/queries/requestts'
import classNames from 'classnames'
import { getPillsColorForTS } from 'utils/pills'
import { ORDER_TYPE } from 'utils/orderType'
import RequestTsConfirmationModal from '../request_ts/RequestTsConfirmationModal'
import UpdateExtraItem from './UpdateExtraItem'

interface ExtraItemTableListProps {
    columns: any[]
    data: any[]
    searchQuery: string
    columnKey: string
    searchKeys: string[]
    onlyList?: boolean
    refetch?: any
    viewOnly?: boolean
}

const columnKeys: any = {
    charger: 'charger',
    battery: 'battery',
    'prep list': 'prep_list',
    'battery accessories': 'battery_accessories',
    'hyster battery list': 'hyster_batteries',
}

const STATUS: any = {
    1: 'api.requested',
    2: 'api.done',
    3: 'api.rejected',
    4: 'api.servicer',
}

const ExtraItemTableList = (props: ExtraItemTableListProps) => {
    const { columns, data, searchQuery, searchKeys, columnKey, onlyList, refetch, viewOnly } = props
    const { configurationId, quoteId } = useParams()
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const fuse = new Fuse(data, {
        shouldSort: true,
        threshold: 0.1,
        ignoreLocation: true,
        keys: searchKeys || [],
    })
    const results = fuse.search(searchQuery)
    const [selectedRow, setSelectedRow] = useState(
        {} as {
            id: number
        },
    )

    const extraItemData = searchQuery ? results.map(character => character.item) : data
    const itemsToPick = [
        'article',
        'description',
        'group',
        'group_extra',
        'price',
        'quantity',
        'netTotal',
        'order_type',
        'column',
        'id',
    ]

    const addExtrItemMutation = useMutation(
        'addExtraItem',
        (formData: any) => addExtraItem(String(configurationId) || '', formData),
        {
            onSuccess: () => {
                toast(t('message.add_extra_item_success'))
            },
        },
    )

    const handleAddExtraItemAsOptional = (record: any) => {
        setSelectedRow(record)
        const formData = pick(
            extend(record, {
                column: columnKeys[columnKey],
                order_type: ORDER_TYPE.OPTIONAL,
                quote_id: quoteId,
                novo_configuration_id: configurationId,
            }),
            itemsToPick,
        )
        addExtrItemMutation.mutate(formData)
    }

    const handleAddExtraItemAsCompulsory = (record: any) => {
        setSelectedRow(record)
        const formData = pick(
            extend(record, {
                column: columnKeys[columnKey],
                order_type: ORDER_TYPE.COMPULSORY,
                quote_id: quoteId,
                novo_configuration_id: configurationId,
            }),
            itemsToPick,
        )
        addExtrItemMutation.mutate(formData)
    }

    const conditionalRowStyles = [
        {
            when: (row: any) => row.order_type === ORDER_TYPE.OPTIONAL,
            style: {
                backgroundColor: 'rgb(44 128 61 / 34%)',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: (row: any) => row.order_type === ORDER_TYPE.COMPULSORY && !viewOnly && !onlyList,
            style: {
                backgroundColor: 'rgb(44 44 44 / 16%)',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ]

    return (
        <Table
            tableHeight="auto"
            conditionalRowStyles={conditionalRowStyles}
            columns={
                [
                    ...(columns || []),
                    {
                        name: t('api.price'),
                        left: true,
                        wrap: true,
                        width: '200px',
                        cell: (row: { price: number | string }) => {
                            return (
                                <div className="flex  justify-start items-center gap-2 py-2">
                                    <p className="text-black text-bold">
                                        {formatCurrency(row.price !== 0 ? row?.price : 'N.V.T')}
                                    </p>
                                </div>
                            )
                        },
                    },
                    {
                        name: t('api.status'),
                        center: true,
                        omit:
                            !onlyList ||
                            viewOnly ||
                            includes(
                                [
                                    'charger',
                                    'battery',
                                    'battery accessories',
                                    'hyster battery list',
                                ],
                                columnKey,
                            ),
                        wrap: true,
                        cell: (row: { status: number; status_remarks: string }) => {
                            const color = getPillsColorForTS(row.status)
                            return (
                                <div className="flex flex-col justify-start items-center gap-2 py-2">
                                    {row.status !== null ? (
                                        <div
                                            title={
                                                row.status !== null && row.status_remarks
                                                    ? row?.status_remarks
                                                    : ''
                                            }
                                            className={classNames(
                                                'capitalize cursor-pointer font-bold',
                                                color.bg,
                                                color.text,
                                                'rounded-full py-2 px-4 text-center',
                                            )}
                                        >
                                            {t(STATUS?.[row?.status || 1])}
                                        </div>
                                    ) : (
                                        <div
                                            className={classNames(
                                                'capitalize cursor-pointer font-bold',
                                                color.bg,
                                                color.text,
                                                'rounded-full py-2 px-4 text-center',
                                            )}
                                        >
                                            {t(STATUS?.[1])}
                                        </div>
                                    )}
                                </div>
                            )
                        },
                    },
                    {
                        name: (
                            <div className="flex items-center w-full gap-8">
                                <p className="w-[100px]">Quantity</p>
                                <p className="flex-1">Total</p>
                            </div>
                        ),
                        width: onlyList ? '200px' : '500px',
                        center: true,
                        cell: (row: { quantity: number; id: number; price: string }) => {
                            const [qty, setQty] = useState(0)
                            const [requestTsModal, setRequestTsModal] = useState(false)
                            const [notes, setNotes] = useState('')
                            const [price, setPrice] = useState<string>('')
                            const [priorityDate, setPriorityDate] = useState<string>('')

                            useEffect(() => {
                                setQty(row.quantity)
                            }, [row])

                            const handleChange = (value: string) => {
                                setQty(parseInt(value, 10))
                                extend(
                                    find(extraItemData, {
                                        id: row.id,
                                    }),
                                    {
                                        quantity: parseInt(value, 10),
                                        netTotal: parseInt(value, 10) * parseInt(row.price, 10),
                                    },
                                )
                            }
                            const handleRequestTsClose = () => {
                                setRequestTsModal(false)
                                setNotes('')
                            }
                            const totalPrice = qty * parseInt(row.price, 10)
                            const isRequestTS =
                                parseInt(String(row.price), 10) === 0 && columnKey === 'prep list'

                            const requestTsMutation = useMutation(
                                (obj: any) => sendRequestTs(obj),
                                {
                                    onSuccess: () => {
                                        toast(t('api.requested_success'))
                                        handleRequestTsClose()
                                        queryClient.invalidateQueries()
                                    },
                                },
                            )

                            const handleRequestTs = () => {
                                const requestVaribles = extend(row, {
                                    price: parseInt(price === '' ? '0' : price, 10),
                                    remarks: notes,
                                    priority: priorityDate,
                                    novo_configuration_id: configurationId,
                                    column: columnKeys[columnKey],
                                    order_type: ORDER_TYPE.COMPULSORY,
                                    quote_id: quoteId,
                                    update: false,
                                })
                                requestTsMutation.mutate(requestVaribles)
                            }

                            return (
                                <div className="flex items-center w-full gap-8 ">
                                    <div className="w-[100px]">
                                        <Quantity
                                            name="quantity"
                                            disabled={onlyList || viewOnly}
                                            quantity={qty}
                                            setQuantity={value => handleChange(String(value))}
                                        />
                                    </div>
                                    <div className="flex items-center gap-8 flex-1">
                                        <div className="flex-1 ">
                                            <p className="text-black text-bold">
                                                {formatCurrency(
                                                    totalPrice !== 0
                                                        ? totalPrice.toFixed(2)
                                                        : 'N.V.T',
                                                )}
                                            </p>
                                        </div>
                                        {!onlyList && !viewOnly && (
                                            <div>
                                                {isRequestTS ? (
                                                    <div className="flex items-center gap-2 p-2">
                                                        <Input
                                                            placeholder={t('api.price')}
                                                            type="number"
                                                            value={price}
                                                            name="price"
                                                            disabled={qty === 0}
                                                            onChange={e => {
                                                                setPrice(e.target.value)
                                                            }}
                                                        />
                                                        <Button
                                                            variant="primary"
                                                            customButtonClass="h-8 w-[150px]"
                                                            disabled={qty === 0}
                                                            label={t('api.request_for_ts')}
                                                            onClick={() => {
                                                                setRequestTsModal(true)
                                                            }}
                                                            isSmall
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-col items-start gap-2 p-2">
                                                        <Button
                                                            variant="outline"
                                                            customButtonClass="h-8"
                                                            label={t('api.add_as_compulsory')}
                                                            disabled={
                                                                qty === 0 ||
                                                                (addExtrItemMutation.isLoading &&
                                                                    selectedRow.id === row.id)
                                                            }
                                                            isSmall
                                                            onClick={() =>
                                                                handleAddExtraItemAsCompulsory(row)
                                                            }
                                                        />
                                                        <Button
                                                            variant="primary"
                                                            customButtonClass="h-8"
                                                            label={t('api.add_as_optional')}
                                                            disabled={
                                                                qty === 0 ||
                                                                (addExtrItemMutation.isLoading &&
                                                                    selectedRow.id === row.id)
                                                            }
                                                            isSmall
                                                            onClick={() =>
                                                                handleAddExtraItemAsOptional(row)
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <AlertModal
                                        show={requestTsModal}
                                        isSubmitting={requestTsMutation.isLoading}
                                        content={
                                            <RequestTsConfirmationModal
                                                notes={notes}
                                                setNotes={value => setNotes(value)}
                                                priorityDate={priorityDate}
                                                setPriorityDate={setPriorityDate}
                                            />
                                        }
                                        onConfirm={() => handleRequestTs()}
                                        onCancel={() => handleRequestTsClose()}
                                    />
                                </div>
                            )
                        },
                    },
                    {
                        name: t('api.action'),
                        left: true,
                        wrap: true,
                        omit: !onlyList || viewOnly,
                        width: '100px',
                        cell: (row: any) => (
                            <UpdateExtraItem
                                row={row}
                                columnKey={columnKeys[columnKey]}
                                refetch={refetch}
                            />
                        ),
                    },
                ] ?? []
            }
            data={extraItemData ?? []}
            pagination={false}
            striped={false}
        />
    )
}

export default ExtraItemTableList
