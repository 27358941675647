/* eslint-disable camelcase */
import { Collapse } from 'antd'
import Loading from 'components/loading'
import { Options } from 'components/select'
import { FormikProps, useFormik } from 'formik'
import { sortBy } from 'lodash'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { numberFormater } from 'utils/numberFormater'
import { ORDER_TYPE } from 'utils/orderType'
import GetPricingStatusText, { PRICING_STATUS } from 'utils/pricingStatus'
import AlertModal from 'components/modal/alertModal'
import Button from 'components/button'
// import GeneralPricing from '../components/GeneralPricing'
// import OptionPricingComponent from '../components/OptionPricingComponent'
// import PricingOverview from '../components/PricingOverview'
// import PurchasePriceCalculation from '../components/PurchasePriceCalculation'
// import SiteSurveyNotConnected from '../components/SiteSurveyNotConnectedForm'
import { IPricingForm, IResidualValue, initialResidualValue } from 'modules/quotes/views/Pricing'
import { useQuoteLayout } from 'modules/quotes/QuoteLayout'
import { ISavePricing, PricingQueries } from '../../quotes/queries/pricing'
import GeneralPricingDetail from '../component/GeneralPricingDetail'
import PurchasePriceCalculationDetail from '../component/PurchasePriceCalculationDetail'
import ExtraItemsDetail from '../component/ExtraItemsDetail'
import OptionalItemsDetail from '../component/OptionalItemsDetail'
import ContractDetails from '../component/ContractDetails'
import notFound from '../../../assets/svg/404.svg'
import { useOrderLayout } from '../OrderLayout'

export interface MonthlyConfigurationPrice {
    id: number
    duration_of_contract: number
    standard_residual_value: string
    total_residual_value: number
    lease_price_options: string
    lease_factor: number
    financial_part: number
    basic_truck: number
    service_extra_preplist: number
    service_extra_special: string
    service_extra_attachments: number
    service_extra_ex_solutions: number
    service_battery: number
    service_tyre: number
    total_service: number
    total_price_per_year_ex_bmwt: number
    total_price_per_year_inclusive_bmwt: number
    preventive_maintenance: number
    batteries_service_cost: number
    insurance_cost: number
    error_key: string
    errors: null
    configuration_price_id: number
    adjusted_by: null
    approved_by: null
    created_by: number
    updated_by: null
    created_at: Date
    updated_at: Date
    service_price_big_truck_all_in: number
    service_price_big_truck_pm: number
    service_big_truck_tyre_per_hour: number
    bmwt_battery: number
}

export interface IPricingInformation {
    id: number
    novo_list_price: number
    standard_discount_percentage: number
    special_price_request_percentage: number
    discount_amount: number
    bridging_machine_amount: number
    financial_support_amount: number
    total_extra: number
    total_options: number
    data_logger: number
    net_all_cost: number
    profit_percentage: number
    profit_amount: number
    novo_quote_price: number
    trade_in_value: number
    quote_minus_trade_in: number
    additional_residual_percentage: number
    min_investment_group: number
    running_hours: number
    add_tyre: boolean
    add_battery: boolean
    add_attachment: boolean
    add_specials: boolean
    add_ex_solutions: boolean
    battery_service: number
    bmwt: boolean
    category: number
    vpg_percentage: number
    vpg_amount: number
    machine_list_price: number
    total_net_value_parts: number
    total_options_selected: number
    discount_service_all_in: number
    discount_service_pm: number
    price_status: number
    investment_status: number
    discount_service_status: number
    additional_residual_status: number
    lease_factor_status: number
    margin_for_options: string
    status: number
    novo_configuration_id: number
    adjusted_by: null
    approved_by: null
    created_by: number
    updated_by: null
    created_at: Date
    updated_at: Date
    is_one_factor_less: boolean
    factor_status: boolean
    spr_status: number
    number_of_batteries: number
    tyre_banden: null
    type_banden: string
    manual_price?: number
    add_bmwt_battery: boolean
    selected_duration_of_contract: number
    monthly_configuration_price: MonthlyConfigurationPrice[]
}

export interface AdditionalDataType {
    id: number
    configuration_id?: number
    financing: string
    maintenance: string
    insurance: boolean
    show_all_prices_separate: string
    return: boolean
    return_description: null
    theoretical_category: string
    created_at?: Date
    updated_at?: Date
    running_hours: number
}

const PricingOrderOverview = () => {
    const { t } = useTranslation()
    const { setCurrentModelName } = useOrderLayout()
    const { configurationId, quoteId, orderId } = useParams()

    // const [searchParams, setSearchParams] = useSearchParams()
    const [isInitialFetched, setIsInitialFetched] = useState<boolean>(false)
    const [isPricingFetched, setIsPricingFetched] = useState<boolean>(false)
    const [isAdditionalDataFetched, setIsAdditionalDataFetched] = useState<boolean>(false)
    const [additionalData, setAdditionalData] = useState<AdditionalDataType>(
        {} as AdditionalDataType,
    )
    const [residualValue, setResidualValue] = useState([initialResidualValue])
    const [totalExtra, setTotalExtra] = useState<number>(0)
    // const { authorizationMatrix, userExtra } = useAuth()
    const [configurationPriceId, setConfigurationPriceId] = useState<number>(0)

    const [extraPricing, setExtraPricing] = useState<any>({
        attachment: [],
        batteries: [],
        batteryAccessories: [],
        chargers: [],
        exSolution: [],
        prepList: [],
        special: [],
    })
    const [options, setOptions] = useState<any[]>([])
    const [totalOptions, setTotalOptions] = useState(0)
    const [isBigTruck, setIsBigTruck] = useState<boolean>(false)
    const [investGroupOptions, setInvestmentGroupOptions] = useState<Options[]>([])
    const [optionalAttachment, setOptionalAttachment] = useState<any[]>([])
    const [optionalBatteries, setOptionalBatteries] = useState<any[]>([])
    const [optionalBatteryAccessories, setOptionalBatteryAccessories] = useState<any[]>([])
    const [optionalChargers, setOptionalChargers] = useState<any[]>([])
    const [optionalExSolution, setOptionalExSolution] = useState<any[]>([])
    const [optionalPrepList, setOptionalPrepList] = useState<any[]>([])
    const [optionalSpecial, setOptionalSpecial] = useState<any[]>([])
    const [monthlyConfigurationPrice, setInitialConfigurationMonths] = useState<any[]>([])
    // const [isRequestFleet, setIsRequestFleet] = useState<boolean>(false)

    useEffect(() => {
        return () => {
            setCurrentModelName(undefined)
        }
    }, [])

    const [companyRatesData, setCompanyRatesData] = useState<IPricingInformation>(
        {} as IPricingInformation,
    )

    const { refetch: refetchCalculateResidualValue } = useQuery(
        ['getFactorResidualValue', configurationId],
        () =>
            PricingQueries.getResidualAndFinancialPart(parseInt(String(configurationId), 10), {
                months: String(
                    companyRatesData?.monthly_configuration_price?.map(
                        (value: any) => value.duration_of_contract,
                    ),
                ),
                additional_residual_percentage: companyRatesData?.additional_residual_percentage,
                min_investment_group:
                    parseInt(String(companyRatesData?.min_investment_group), 10) || 0,
                quote_total: companyRatesData?.novo_quote_price,
                trade_in_amount: companyRatesData?.trade_in_value,
                service_discount_all_in: companyRatesData?.discount_service_all_in,
                service_discount_pm: companyRatesData?.discount_service_pm,
                include_service_tyre: companyRatesData?.add_tyre,
                include_service_battery: companyRatesData?.add_battery,
                include_service_attachment: companyRatesData?.add_attachment,
                include_service_specials: companyRatesData?.add_specials,
                number_of_batteries: companyRatesData?.number_of_batteries,
                include_service_exsolution: companyRatesData?.add_ex_solutions,
                quantity_battery_service_job: companyRatesData?.battery_service,
                bmwt_selected: companyRatesData?.bmwt,
                bmwt_battery_selected: companyRatesData?.add_bmwt_battery,
                tyre_type: companyRatesData?.type_banden || 'single_std',
                batteries_selected: companyRatesData?.add_battery,
                is_whe: false,
                margin_for_options: Number(companyRatesData?.margin_for_options),
            }),
        {
            refetchOnMount: false,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            retryOnMount: false,
            refetchOnWindowFocus: false,
            enabled: Boolean(
                !isBigTruck && configurationId && isInitialFetched && isPricingFetched,
            ),
            onSuccess: d => {
                if (d?.data?.data && !isBigTruck) {
                    const residualValueData = d?.data?.data
                    setResidualValue(
                        sortBy(residualValueData, 'month')?.map((value: any) => ({
                            durationOfContract: value.month,
                            standardResidualValue:
                                parseFloat(String(value.standard_residual_value)) || 0,
                            totalResidualValue: parseFloat(String(value.total_residual_value)) || 0,
                            factor: parseFloat(String(value.factor)) || 0,
                            financialPart: value.financial_part,
                            exSolutionServicePrice: value.service_extra_ex_solutions || 0,
                            insuranceCost: value.insurance_cost,
                            serviceBigTruckAllIn: value?.service_big_truck_all_in || 0,
                            serviceBigTruckPm: value?.service_big_truck_pm || 0,
                            serviceAllIn: value.service_all_in,
                            servicePm: value.service_pm,
                            filledInsuranceCost: value.filled_insurance_rate,
                            filledPreventiveMaintenance: value.filled_preventive_maintenance,
                            servicePriceTriesPerHour:
                                parseFloat(String(value.service_big_truck_tyre_per_hour)) || 0,
                            error: value.errors,
                            errorKey: value.error_key,
                            leasePriceOptions: value.lease_price_options,
                        })),
                    )
                }
            },
        },
    )

    const {
        isLoading: additionalDataLoading,
        refetch: refetchAdditionalData,
        isFetching: additionalDataFetching,
        isError: additionalDataError,
    } = useQuery(
        ['additionalData'],
        () => PricingQueries.getAdditionalConfigurationData(parseInt(String(configurationId), 10)),
        {
            enabled: !!isInitialFetched,
            onSuccess: data => {
                setIsAdditionalDataFetched(true)
                setAdditionalData(data?.data?.data)
            },
        },
    )

    const {
        data: initialPricingData,
        isLoading: initialPricingLoading,
        isFetching: initialPricingFetching,
        isError: initialPricingError,
        refetch: refetchInitialData,
    } = useQuery(
        ['getPricingInitialData'],
        () => PricingQueries.getInitialValueForPricing(parseInt(String(configurationId), 10)),
        {
            enabled: !!configurationId && !isInitialFetched,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            onSuccess: (d: any) => {
                const initialData = d.data.data
                setIsBigTruck(initialData.is_big_truck)
                const investGroupOption: Options[] = initialData.investment_group
                    ? initialData.investment_group.map((investmentGroup: any) => ({
                          label: `${numberFormater(investmentGroup?.min_value)} ${
                              investmentGroup?.max_value === 0
                                  ? ' +'
                                  : ` - ${numberFormater(investmentGroup?.max_value)}`
                          }`,
                          value: parseFloat(String(investmentGroup?.min_value)),
                      }))
                    : []
                setInvestmentGroupOptions(investGroupOption)
                setOptions([...initialData.parts_data, ...initialData.lease_price_options])
                let totalOption = 0
                initialData?.parts_data.forEach((option: any) => {
                    totalOption += option['Net Price Each']
                })
                initialData?.lease_price_options.forEach((option: any) => {
                    totalOption += parseFloat(String(option.price))
                })
                setOptionalAttachment(
                    initialData?.extra_service?.attachment?.filter(
                        (attachment: any) => attachment.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalBatteries(
                    initialData?.extra_service?.batteries?.filter(
                        (battery: any) => battery.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalBatteryAccessories(
                    initialData?.extra_service?.battery_accessories?.filter(
                        (batteryAccessory: any) =>
                            batteryAccessory.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalChargers(
                    initialData?.extra_service?.chargers?.filter(
                        (charger: any) => charger.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalExSolution(
                    initialData?.extra_service?.ex_solution?.filter(
                        (exSolution: any) => exSolution.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalPrepList(
                    initialData?.extra_service?.prep_lists?.filter(
                        (exSolution: any) => exSolution.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setOptionalSpecial(
                    initialData?.extra_service?.special?.filter(
                        (special: any) => special.order_type === ORDER_TYPE.OPTIONAL,
                    ),
                )
                setExtraPricing({
                    attachment: initialData?.extra_service?.attachment?.filter(
                        (attachment: any) => attachment.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    batteries: initialData?.extra_service?.batteries?.filter(
                        (battery: any) => battery.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    batteryAccessories: initialData?.extra_service?.battery_accessories?.filter(
                        (batteryAccessory: any) =>
                            batteryAccessory.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    chargers: initialData?.extra_service?.chargers?.filter(
                        (charger: any) => charger.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    exSolution: initialData?.extra_service?.ex_solution?.filter(
                        (exSolution: any) => exSolution.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    prepList: initialData?.extra_service?.prep_lists?.filter(
                        (prepList: any) => prepList.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                    special: initialData?.extra_service?.special?.filter(
                        (special: any) => special.order_type === ORDER_TYPE.COMPULSORY,
                    ),
                })
                setTotalOptions(totalOption)
                setIsInitialFetched(true)
                setCurrentModelName(initialData?.novo_configuration_site_survey?.model)
            },
        },
    )

    const { refetch: refetchCalculateResidualValueBigTruck } = useQuery(
        [
            'getFactorResidualValueTruck',
            configurationId,
            isInitialFetched,
            isPricingFetched,
            residualValue,
        ],
        () =>
            PricingQueries.getResidualAndFinancialPartBigTruck(
                parseInt(String(configurationId), 10),
                residualValue.map(rv => ({
                    months: companyRatesData?.selected_duration_of_contract,
                    standard_residual_value: rv.standardResidualValue,
                    additional_residual_percentage:
                        companyRatesData?.additional_residual_percentage,
                    factor: rv.factor,
                    service_price_all: rv.serviceAllIn,
                    service_price_pm: rv.servicePm,
                    min_investment_group:
                        parseInt(String(companyRatesData?.min_investment_group), 10) || 0,
                    quote_total: companyRatesData?.novo_quote_price,
                    trade_in_amount: companyRatesData?.trade_in_value,
                    service_big_truck_all_in: rv.serviceBigTruckAllIn,
                    ex_solutions_service_price: rv.exSolutionServicePrice,
                    filled_preventive_maintennce: rv.filledPreventiveMaintenance,
                    filled_insurance_rate: rv.filledInsuranceCost,
                    service_big_truck_pm: rv.serviceBigTruckPm,
                    service_discount_all_in: companyRatesData?.discount_service_all_in,
                    service_discount_pm: companyRatesData?.discount_service_pm,
                    include_service_tyre: companyRatesData?.add_tyre,
                    include_service_battery: companyRatesData?.add_battery,
                    service_big_truck_tyre_per_hour: rv.servicePriceTriesPerHour,
                    include_service_attachment: companyRatesData?.add_attachment,
                    include_service_specials: companyRatesData?.add_specials,
                    number_of_batteries: companyRatesData?.number_of_batteries,
                    include_service_exsolution: companyRatesData?.add_ex_solutions,
                    quantity_battery_service_job: companyRatesData?.battery_service,
                    bmwt_selected: companyRatesData?.bmwt,
                    bmwt_battery_selected: companyRatesData?.add_bmwt_battery,
                    tyre_type: companyRatesData?.type_banden || 'single_std',
                    batteries_selected: companyRatesData?.add_battery,
                    is_whe: false,
                    margin_for_options: Number(companyRatesData?.margin_for_options),
                })),
            ),
        {
            refetchOnMount: false,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            retryOnMount: false,
            refetchOnWindowFocus: false,
            enabled: Boolean(
                isBigTruck &&
                    configurationId &&
                    residualValue &&
                    (isInitialFetched || isPricingFetched) &&
                    !residualValue.some(item => item.durationOfContract === 0) &&
                    !residualValue.some(item => item.standardResidualValue === 0) &&
                    !residualValue.some(item => item.factor === 0) &&
                    parseFloat(String(companyRatesData.min_investment_group)) >= 0,
            ),
            onSuccess: d => {
                if (d?.data?.data && isBigTruck) {
                    const residualValueData = d?.data?.data
                    setResidualValue(
                        sortBy(residualValueData, 'month')?.map((value: any) => ({
                            durationOfContract: value.month,
                            standardResidualValue:
                                parseFloat(String(value.standard_residual_value)) || 0,
                            totalResidualValue: parseFloat(String(value.total_residual_value)) || 0,
                            factor: parseFloat(String(value.factor)) || 0,
                            financialPart: value.financial_part,
                            servicePriceTriesPerHour: parseFloat(
                                String(value.service_big_truck_tyre_per_hour),
                            ),
                            exSolutionServicePrice: value.service_extra_ex_solutions || 0,
                            insuranceCost: value.insurance_cost,
                            filledInsuranceCost: value.filled_insurance_rate,
                            filledPreventiveMaintenance: value.filled_preventive_maintenance,
                            serviceBigTruckAllIn: value?.service_big_truck_all_in || 0,
                            serviceBigTruckPm: value?.service_big_truck_pm || 0,
                            serviceAllIn: value.service_all_in,
                            servicePm: value.service_pm,
                            error: value.errors,
                            errorKey: value.error_key,
                            leasePriceOptions: value.lease_price_options,
                        })),
                    )
                }
            },
        },
    )
    const {
        isLoading: pricingByConfigurationIdLoading,
        isFetching: pricingByConfigurationIdFetching,
        isError: pricingByConfigurationIdError,
    } = useQuery(
        ['getCompanyRates'],
        () => PricingQueries.getPricingForConfigurationId(parseInt(String(configurationId), 10)),
        {
            enabled: !!configurationId && isInitialFetched && !isPricingFetched,
            onSuccess: async data => {
                setCompanyRatesData(data?.data?.data)
                const pricingData = data?.data?.data
                setConfigurationPriceId(pricingData.id)

                if (pricingData.monthly_configuration_price.length > 0) {
                    setInitialConfigurationMonths(pricingData.monthly_configuration_price)
                    const residualValueCompany: any = pricingData.monthly_configuration_price.map(
                        (value: any) => ({
                            durationOfContract: value.duration_of_contract,
                            standardResidualValue: parseFloat(
                                String(value.standard_residual_value),
                            ),
                            totalResidualValue: parseFloat(String(value.total_residual_value)),
                            serviceBigTruckAllIn: value.service_price_big_truck_all_in || 0,
                            serviceBigTruckPm: value.service_price_big_truck_pm || 0,
                            factor: value.lease_factor,
                            financialPart: value.financial_part,
                            insuranceCost: value.insurance_cost,
                            error: value.errors,
                            errorKey: value.error_key,
                            servicePriceTriesPerHour: parseFloat(
                                String(value.service_big_truck_tyre_per_hour),
                            ),
                            leasePriceOptions: JSON.parse(value.lease_price_options),
                            serviceAllIn: {
                                basic_truck: value.basic_truck,
                                service_extra_preplist: value.service_extra_preplist,
                                service_extra_attachments: value.service_extra_attachments,
                                service_tyre: value.service_tyre,
                                service_extra_special: parseFloat(
                                    String(value.service_extra_special),
                                ),
                                service_battery: value.service_battery,
                                service_ex_solutions: 0,
                                total_service: 0,
                            },
                            servicePm: {
                                batteries_service_cost: value.batteries_service_cost,
                                preventive_maintenance: value.preventive_maintenance,
                                total_price_per_year_ex_bmwt: value.total_price_per_year_ex_bmwt,
                                total_price_per_year_inclusive_bmwt:
                                    value.total_price_per_year_inclusive_bmwt,
                            },
                        }),
                    )

                    setResidualValue(residualValueCompany)
                }

                setIsPricingFetched(true)
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.get_pricing_failed'))
            },
        },
    )

    if (initialPricingFetching || additionalDataFetching || pricingByConfigurationIdFetching) {
        return (
            <div className="grid place-content-center w-full h-screen">
                <Loading />
            </div>
        )
    }

    if (initialPricingError || pricingByConfigurationIdError) {
        return (
            <div className="grid place-content-center text-center w-full h-[70vh]">
                <img className="w-80" src={notFound} alt="page not found" />
                <p className="text-lg font-bold">{t(`api.configuration_not_found`)}</p>
            </div>
        )
    }

    return (
        <div className="h-full w-full flex flex-col gap-4 bg-inputBorder">
            <GeneralPricingDetail
                additionalData={
                    initialPricingData?.data?.data?.novo_configuration_site_survey.site_survey?.id
                        ? {
                              id: initialPricingData?.data?.data?.novo_configuration_site_survey
                                  .site_survey?.id,
                              financing:
                                  initialPricingData?.data?.data?.novo_configuration_site_survey
                                      .site_survey?.financing,
                              maintenance:
                                  initialPricingData?.data?.data?.novo_configuration_site_survey
                                      .site_survey?.maintenance,
                              running_hours:
                                  initialPricingData?.data?.data?.novo_configuration_site_survey
                                      .site_survey?.running_hours,
                              insurance:
                                  initialPricingData?.data?.data?.novo_configuration_site_survey
                                      .site_survey?.insurance,
                              return: initialPricingData?.data?.data?.novo_configuration_site_survey
                                  .site_survey?.return,
                              return_description:
                                  initialPricingData?.data?.data?.novo_configuration_site_survey
                                      .site_survey?.return_description,
                              theoretical_category:
                                  initialPricingData?.data?.data?.novo_configuration_site_survey
                                      .site_survey?.theoretical_category,
                              show_all_prices_separate:
                                  initialPricingData?.data?.data?.novo_configuration_site_survey
                                      .site_survey?.show_all_prices_separate,
                          }
                        : additionalData
                }
            />
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                <PurchasePriceCalculationDetail
                    companyRatesData={companyRatesData}
                    totalExtra={totalExtra}
                    financing={
                        initialPricingData?.data?.data?.novo_configuration_site_survey.site_survey
                            ?.id
                            ? initialPricingData?.data?.data?.novo_configuration_site_survey
                                  .site_survey?.financing
                            : additionalData.financing
                    }
                    initialPrice={initialPricingData?.data?.data}
                />
                <ContractDetails
                    isBigTruck={isBigTruck}
                    companyRatesData={companyRatesData}
                    additionalData={
                        initialPricingData?.data?.data?.novo_configuration_site_survey.site_survey
                            ?.id
                            ? {
                                  id: initialPricingData?.data?.data?.novo_configuration_site_survey
                                      .site_survey?.id,
                                  financing:
                                      initialPricingData?.data?.data?.novo_configuration_site_survey
                                          .site_survey?.financing,
                                  maintenance:
                                      initialPricingData?.data?.data?.novo_configuration_site_survey
                                          .site_survey?.maintenance,
                                  running_hours:
                                      initialPricingData?.data?.data?.novo_configuration_site_survey
                                          .site_survey?.running_hours,
                                  insurance:
                                      initialPricingData?.data?.data?.novo_configuration_site_survey
                                          .site_survey?.insurance,
                                  return: initialPricingData?.data?.data
                                      ?.novo_configuration_site_survey.site_survey?.return,
                                  return_description:
                                      initialPricingData?.data?.data?.novo_configuration_site_survey
                                          .site_survey?.return_description,
                                  theoretical_category:
                                      initialPricingData?.data?.data?.novo_configuration_site_survey
                                          .site_survey?.theoretical_category,
                                  show_all_prices_separate:
                                      initialPricingData?.data?.data?.novo_configuration_site_survey
                                          .site_survey?.show_all_prices_separate,
                              }
                            : additionalData
                    }
                    initialPrice={initialPricingData?.data?.data}
                    residualValue={residualValue}
                />
            </div>
            {initialPricingData?.data?.data?.extra_service?.attachment?.length > 0 ||
            initialPricingData?.data?.data?.extra_service?.batteries?.length > 0 ||
            initialPricingData?.data?.data?.extra_service?.batteryAccessories?.length > 0 ||
            initialPricingData?.data?.data?.extra_service?.chargers?.length > 0 ||
            initialPricingData?.data?.data?.extra_service?.exSolution?.length > 0 ||
            initialPricingData?.data?.data?.extra_service?.prepList?.length > 0 ||
            initialPricingData?.data?.data?.extra_service?.special?.length > 0 ? (
                <ExtraItemsDetail
                    setTotalExtra={setTotalExtra}
                    initialPrice={initialPricingData?.data?.data}
                />
            ) : null}

            {initialPricingData?.data?.data?.lease_price_options?.length > 0 && (
                <OptionalItemsDetail
                    margin={companyRatesData?.margin_for_options}
                    totalOptions={totalOptions}
                    optionalAttachment={optionalAttachment}
                    optionalBatteries={optionalBatteries}
                    optionalBatteryAccessories={optionalBatteryAccessories}
                    optionalChargers={optionalChargers}
                    optionalExSolution={optionalExSolution}
                    optionalPrepList={optionalPrepList}
                    optionalSpecial={optionalSpecial}
                    options={options}
                    residualValue={residualValue}
                />
            )}
        </div>
    )
}
export default PricingOrderOverview
