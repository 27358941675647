import { Checkbox } from 'antd'
import Input from 'components/input'
import Select from 'components/select'
import { FormikProps } from 'formik'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { durationOfContractOptions } from 'modules/siteSurvey/components/siteSurveyDropDown'
import { useTranslation } from 'react-i18next'
import { AiFillPlusCircle } from 'react-icons/ai'
import { MdCancel } from 'react-icons/md'
import { useMutation, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCurrencySymbol } from 'utils/currency/formatCurrency'
import { PRICING_STATUS } from 'utils/pricingStatus'
import { PricingQueries } from '../queries/pricing'
import { IPricingForm, initialResidualValue } from '../views/Pricing'
import ServiceOverviewTable from './serviceOverviewTable'

interface IPurchasePriceCalculation {
    formik: FormikProps<IPricingForm>
    configurationPriceId?: number
    handleAddResidualValue: () => void
    updateDurationOfContract: (value: number, index: number) => void
    isBigTruck: boolean
    monthlyConfigurationPrice: any[]
    updateStandardResidualValue: (value: number, index: number) => void
    updateTotalResidualValue: (value: number, index: number) => void
    updateLeaseFactor: (value: number, index: number) => void
    updateServiceBigTruckAllIn: (value: number, index: number) => void
    updateServiceBigTruckPm: (value: number, index: number) => void
    updateServicePriceTyrePerHourBigTruck: (value: number, index: number) => void
    updateExSolutionServicePrice: (value: number, index: number) => void
    updatePreventiveMaintenance: (value: number, index: number) => void
    updateInsuranceCost: (value: number, index: number) => void
    referenceResidualValue: any
}

const PricingOverview = (props: IPurchasePriceCalculation) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const { userExtra, authorizationMatrix } = useAuth()
    const [searchParams, setSearchParams] = useSearchParams()
    const pricingStatus = searchParams.get('pricing_status')

    const {
        formik,
        handleAddResidualValue,
        configurationPriceId,
        updateDurationOfContract,
        updateLeaseFactor,
        updateStandardResidualValue,
        updateTotalResidualValue,
        updateServiceBigTruckAllIn,
        updateServiceBigTruckPm,
        updateServicePriceTyrePerHourBigTruck,
        updateExSolutionServicePrice,
        monthlyConfigurationPrice,
        updateInsuranceCost,
        updatePreventiveMaintenance,
        isBigTruck,
        referenceResidualValue,
    } = props

    const removeConfigurationMonth = useMutation(
        ['removeConfigurationMonth'],
        (configurationMonth: number) =>
            PricingQueries.removeConfigurationMonth(
                parseFloat(String(configurationPriceId)),
                configurationMonth,
            ),
        {
            onSuccess: data => {
                toast.success(t('message.configuration_month_deleted_success'))
                formik.setFieldValue(
                    'residualValue',
                    formik.values.residualValue.filter(
                        rv => rv.durationOfContract !== data.data.data,
                    ),
                )
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.configuration_month_deleted_failed'))
            },
        },
    )

    const enableOrDisableFields =
        isBigTruck || formik.values.residualValue.find((x: any) => x.error)
            ? !(userExtra.role_id === ROLE_TYPE.fleet || userExtra.role_id === ROLE_TYPE.servicer)
            : false

    const checkIfAdditionalResidualPercentageIsBeyondAccess: () => boolean = () => {
        const maxValue = authorizationMatrix?.find(
            (auth: any) => auth.authorization_condition === 'RESIDUAL_VALUE',
        )?.max_value
        if (formik.values.additionalResidualPercentage > parseFloat(maxValue)) {
            return true
        }
        return false
    }

    const checkIfMarginIsBeyondAccess: () => boolean = () => {
        const minProfitPercentage = parseFloat(
            authorizationMatrix?.find((auth: any) => auth.authorization_condition === 'MARGIN')
                ?.min_value,
        )
        if (formik.values.profitPercentage < minProfitPercentage) {
            return true
        }
        return false
    }

    return (
        <div className="px-4 flex flex-col gap-2">
            <div className="flex w-full flex-wrap gap-8 justify-start items-center">
                {formik.values.residualValue &&
                    formik.values.residualValue.map((residualValue: any, index: number) => (
                        <div
                            className={`flex gap-2 relative ${
                                index === 0 ? 'max-w-[32rem] w-fit' : ''
                            }`}
                            key={index}
                        >
                            <div className="flex flex-1 flex-col gap-2">
                                <Select
                                    horizontal
                                    options={durationOfContractOptions}
                                    label={`${t('fields.duration_of_contract')} ${t(
                                        'message.select_duration_of_contract',
                                    )}`}
                                    name="durationOfContract"
                                    showPlaceHolder
                                    leftContent={
                                        formik.values.residualValue.length > 1 ? (
                                            <Checkbox
                                                checked={
                                                    formik.values.selectedDurationOfContract ===
                                                    residualValue.durationOfContract
                                                }
                                                onChange={() => {
                                                    formik.setFieldValue(
                                                        'selectedDurationOfContract',
                                                        formik.values.selectedDurationOfContract !==
                                                            residualValue.durationOfContract
                                                            ? residualValue.durationOfContract
                                                            : undefined,
                                                    )
                                                }}
                                            />
                                        ) : undefined
                                    }
                                    noLabel={!!index}
                                    required
                                    rightContent={<p>{t('fields.months')}</p>}
                                    placeHolderOption={t('fields.select_duration_of_contract')}
                                    selectWidth="w-[14rem]"
                                    value={residualValue.durationOfContract}
                                    onChange={e =>
                                        updateDurationOfContract(
                                            Number(String(e.target.value)),
                                            index,
                                        )
                                    }
                                    error={
                                        residualValue?.error
                                            ? t(`message.${residualValue.error}`)
                                            : undefined
                                    }
                                />
                                <Input
                                    horizontal
                                    disabled={isBigTruck ? enableOrDisableFields : true}
                                    noLabel={!!index}
                                    label={t('fields.standard_residual_value')}
                                    name="standardResidualValue"
                                    required
                                    type="number"
                                    customInputStyles="p-0 text-right"
                                    inputWidth="w-[14rem]"
                                    rightContent={<p>%</p>}
                                    onChange={e =>
                                        updateStandardResidualValue(
                                            Number(String(e.target.value)),
                                            index,
                                        )
                                    }
                                    value={String(
                                        isBigTruck
                                            ? residualValue.standardResidualValue || 0
                                            : residualValue.standardResidualValue.toFixed(2),
                                    )}
                                    error={
                                        residualValue.errorKey === 'standard_residual_value'
                                            ? t(`message.${residualValue.error}`)
                                            : undefined
                                    }
                                />
                                <Input
                                    horizontal
                                    disabled
                                    noLabel={!!index}
                                    label={t('fields.total_residual_value')}
                                    name="totalResidualValue"
                                    type="number"
                                    customInputStyles="p-0 text-right"
                                    inputWidth="w-[14rem]"
                                    rightContent={<p>%</p>}
                                    onChange={e =>
                                        updateTotalResidualValue(
                                            Number(String(e.target.value)),
                                            index,
                                        )
                                    }
                                    value={residualValue.totalResidualValue.toFixed(2)}
                                    title={residualValue.error}
                                    error={
                                        residualValue.errorKey === 'total_residual_value'
                                            ? t(`message.${residualValue.error}`)
                                            : undefined
                                    }
                                />
                                <Input
                                    horizontal
                                    disabled
                                    noLabel={!!index}
                                    label={t('fields.residual_value')}
                                    name="residualValue"
                                    type="number"
                                    showOnlyCurrency
                                    customInputStyles="pr-5 text-right"
                                    inputWidth="w-[14rem]"
                                    leftContent={<p>{getCurrencySymbol()}</p>}
                                    value={(
                                        ((Number(residualValue.totalResidualValue.toFixed(2)) ||
                                            0) /
                                            100) *
                                        formik.values.quotePrice
                                    ).toFixed(0)}
                                />
                                <Input
                                    horizontal
                                    disabled={isBigTruck ? enableOrDisableFields : true}
                                    noLabel={!!index}
                                    label={t('fields.factor_value')}
                                    name="factor"
                                    type="number"
                                    required
                                    customInputStyles="p-0 text-right"
                                    onChange={e =>
                                        updateLeaseFactor(Number(String(e.target.value)), index)
                                    }
                                    inputWidth="w-[14rem]"
                                    value={
                                        isBigTruck
                                            ? residualValue.factor
                                            : residualValue.factor.toFixed(3)
                                    }
                                    title={residualValue.error}
                                    error={
                                        residualValue.errorKey === 'factor_value'
                                            ? t(`message.${residualValue.error}`)
                                            : undefined
                                    }
                                />
                                {isBigTruck &&
                                    (formik.values.maintenance === 'ALL_IN' ||
                                        formik.values.maintenance ===
                                            'ALL_IN_AND_PREVENTIVE_MAINTENANCE') && (
                                        <Input
                                            horizontal
                                            disabled={enableOrDisableFields}
                                            noLabel={!!index}
                                            label={t('fields.service_big_truck_all_in')}
                                            name="serviceBigTruckAllIn"
                                            type="number"
                                            customInputStyles="p-0 text-right"
                                            leftContent={<p>{getCurrencySymbol()}</p>}
                                            onChange={e =>
                                                updateServiceBigTruckAllIn(
                                                    Number(String(e.target.value)),
                                                    index,
                                                )
                                            }
                                            inputWidth="w-[14rem]"
                                            value={String(residualValue.serviceBigTruckAllIn)}
                                            title={residualValue.error}
                                            error={
                                                residualValue.errorKey ===
                                                'service_big_truck_all_in'
                                                    ? t(`message.${residualValue.error}`)
                                                    : undefined
                                            }
                                        />
                                    )}
                                {/* {isBigTruck &&
                                    (formik.values.maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                                        formik.values.maintenance ===
                                            'ALL_IN_AND_PREVENTIVE_MAINTENANCE') && (
                                        <Input
                                            horizontal
                                            disabled={enableOrDisableFields}
                                            noLabel={!!index}
                                            label={t('fields.service_big_truck_pm')}
                                            name="serviceBigTruckPm"
                                            type="number"
                                            customInputStyles="p-0 text-right"
                                            leftContent={<p>{getCurrencySymbol()}</p>}
                                            onChange={e =>
                                                updateServiceBigTruckPm(
                                                    Number(String(e.target.value)),
                                                    index,
                                                )
                                            }
                                            inputWidth="w-[14rem]"
                                            value={String(residualValue.serviceBigTruckPm)}
                                            title={residualValue.error}
                                            error={
                                                residualValue.errorKey === 'service_big_truck_pm'
                                                    ? t(`message.${residualValue.error}`)
                                                    : undefined
                                            }
                                        />
                                    )} */}
                                {isBigTruck && (
                                    <Input
                                        horizontal
                                        disabled={enableOrDisableFields}
                                        noLabel={!!index}
                                        label={t('fields.service_price_tires_per_hour_big_trucks')}
                                        name="servicePriceTriesPerHour"
                                        type="number"
                                        customInputStyles="p-0 text-right"
                                        leftContent={<p>{getCurrencySymbol()}</p>}
                                        onChange={e =>
                                            updateServicePriceTyrePerHourBigTruck(
                                                Number(String(e.target.value)),
                                                index,
                                            )
                                        }
                                        inputWidth="w-[14rem]"
                                        value={String(residualValue.servicePriceTriesPerHour)}
                                        title={residualValue.error}
                                        error={
                                            residualValue.errorKey === 'service_big_truck_pm'
                                                ? t(`message.${residualValue.error}`)
                                                : undefined
                                        }
                                    />
                                )}
                                {isBigTruck && (
                                    <Input
                                        horizontal
                                        disabled={enableOrDisableFields}
                                        noLabel={!!index}
                                        label={t('fields.ex_solution_service_price_per_hours')}
                                        name="exSolutionServicePrice"
                                        type="number"
                                        customInputStyles="p-0 text-right"
                                        leftContent={<p>{getCurrencySymbol()}</p>}
                                        onChange={e =>
                                            updateExSolutionServicePrice(
                                                Number(String(e.target.value)),
                                                index,
                                            )
                                        }
                                        inputWidth="w-[14rem]"
                                        value={String(residualValue.exSolutionServicePrice)}
                                        title={residualValue.error}
                                        error={
                                            residualValue.errorKey === 'service_big_truck_pm'
                                                ? t(`message.${residualValue.error}`)
                                                : undefined
                                        }
                                    />
                                )}
                                {(formik.values.maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                                    formik.values.maintenance ===
                                        'ALL_IN_AND_PREVENTIVE_MAINTENANCE') &&
                                    isBigTruck && (
                                        <Input
                                            horizontal
                                            disabled={enableOrDisableFields}
                                            noLabel={!!index}
                                            label={t('fields.pm_maintenance')}
                                            name="pm_maintenance"
                                            type="number"
                                            customInputStyles="p-0 text-right"
                                            leftContent={<p>{getCurrencySymbol()}</p>}
                                            onChange={e =>
                                                updatePreventiveMaintenance(
                                                    Number(String(e.target.value)),
                                                    index,
                                                )
                                            }
                                            inputWidth="w-[14rem]"
                                            value={String(
                                                residualValue.filledPreventiveMaintenance,
                                            )}
                                            title={residualValue.error}
                                            error={
                                                residualValue.errorKey === 'service_big_truck_pm'
                                                    ? t(`message.${residualValue.error}`)
                                                    : undefined
                                            }
                                        />
                                    )}
                                {formik.values.insurance && isBigTruck && (
                                    <Input
                                        horizontal
                                        disabled={enableOrDisableFields}
                                        noLabel={!!index}
                                        label={t('fields.insurance_cost_rate')}
                                        name="insurance_cost"
                                        type="number"
                                        customInputStyles="p-0 text-right"
                                        rightContent={<p>%</p>}
                                        onChange={e =>
                                            updateInsuranceCost(
                                                Number(String(e.target.value)),
                                                index,
                                            )
                                        }
                                        inputWidth="w-[14rem]"
                                        value={String(residualValue.filledInsuranceCost)}
                                        title={residualValue.error}
                                        error={
                                            residualValue.errorKey === 'service_big_truck_pm'
                                                ? t(`message.${residualValue.error}`)
                                                : undefined
                                        }
                                    />
                                )}
                            </div>
                            {index !== 0 && (
                                <button
                                    type="button"
                                    className="hover:brightness-105 text-red-700 hover:cursor-pointer absolute top-2 -right-6"
                                    onClick={() => {
                                        if (
                                            monthlyConfigurationPrice.find(
                                                cp =>
                                                    cp.duration_of_contract ===
                                                    residualValue.durationOfContract,
                                            )
                                        ) {
                                            removeConfigurationMonth.mutate(
                                                residualValue.durationOfContract,
                                            )
                                        } else {
                                            formik.setFieldValue(
                                                'residualValue',
                                                formik.values.residualValue.filter(
                                                    rv =>
                                                        rv.durationOfContract !==
                                                        residualValue.durationOfContract,
                                                ),
                                            )
                                        }
                                    }}
                                >
                                    <MdCancel size={24} />
                                </button>
                            )}
                            {formik.values.residualValue.length - 1 === index && index < 2 && (
                                <button
                                    type="button"
                                    className="hover:brightness-105 text-primary max-w-[8rem] hover:text-white h-full rounded-md hover:bg-primaryLight hover:opacity-80 p-8 border border-primary border-dashed flex flex-col justify-center items-center gap-4 hover:cursor-pointer absolute top-2 -right-40"
                                    onClick={handleAddResidualValue}
                                >
                                    <AiFillPlusCircle size={24} />
                                    <p>{t('api.add_new')}</p>
                                </button>
                            )}
                        </div>
                    ))}
            </div>
            {formik.values.residualValue &&
                formik.values.residualValue[0] !== initialResidualValue && (
                    <div className="py-2 flex justify-between items-end gap-8 w-full">
                        <ServiceOverviewTable
                            residualValue={formik.values.residualValue}
                            battery={formik.values.addBattery}
                            tires={formik.values.addTyre}
                            attachment={formik.values.addAttachment}
                            specials={formik.values.addSpecials}
                            exSolution={formik.values.addExSolution}
                            maintenance={formik.values.maintenance}
                            runningHours={formik.values.runningHours}
                            selectedDurationOfContract={formik.values.selectedDurationOfContract}
                        />
                        {referenceResidualValue && Number(formik.values.category) !== 3 && (
                            <div className="flex flex-col gap-2">
                                <p className="font-bold">{t('api.reference')}</p>
                                <ServiceOverviewTable
                                    residualValue={referenceResidualValue}
                                    battery={formik.values.addBattery}
                                    tires={formik.values.addTyre}
                                    attachment={formik.values.addAttachment}
                                    specials={formik.values.addSpecials}
                                    exSolution={formik.values.addExSolution}
                                    maintenance={formik.values.maintenance}
                                    runningHours={formik.values.runningHours}
                                    selectedDurationOfContract={
                                        formik.values.selectedDurationOfContract
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
        </div>
    )
}

export default PricingOverview
