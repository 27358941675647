import { useTranslation } from 'react-i18next'
import OptionTableViewComponent from './OptionTableViewComponent'

type OptionalItemsDetailProps = {
    margin: string
    options: any
    totalOptions: any
    optionalAttachment: any[]
    optionalBatteries: any[]
    optionalBatteryAccessories: any[]
    optionalChargers: any[]
    optionalExSolution: any[]
    optionalPrepList: any[]
    optionalSpecial: any[]
    residualValue: any
}

const OptionalItemsDetail = ({
    margin,
    options,
    totalOptions,
    optionalAttachment,
    optionalBatteries,
    optionalBatteryAccessories,
    optionalChargers,
    optionalExSolution,
    optionalPrepList,
    optionalSpecial,
    residualValue,
}: OptionalItemsDetailProps) => {
    const { t } = useTranslation()

    return (
        <div className="rounded-md p-4 bg-white">
            <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                {t('api.optional_items')}
            </p>
            <div className="grid grid-cols-2 bg-slate-100 p-2 gap-2  mt-4">
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.margin_for_overview`)}:</span>
                    {String(margin)}
                </p>
            </div>
            <OptionTableViewComponent
                options={options}
                totalOptions={totalOptions}
                optionalAttachment={optionalAttachment}
                optionalBatteries={optionalBatteries}
                optionalBatteryAccessories={optionalBatteryAccessories}
                optionalChargers={optionalChargers}
                optionalExSolution={optionalExSolution}
                optionalPrepList={optionalPrepList}
                optionalSpecial={optionalSpecial}
                residualValue={residualValue}
            />
            {/* <div className="w-full bg-slate-100 p-2 border-l-[3px] border-primary gap-2  mt-2">
                <table className="w-full">
                    <thead>
                        <tr className="text-left">
                            <th>{t(`api.name`)}</th>
                            <th>{t(`api.quantity`)}</th>
                            <th>{t(`api.price`)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {options?.map((option: any, index: number) => (
                            <tr className="" key={index}>
                                <td>{option.name}</td>
                                <td>{option.quantity}</td>
                                <td>{option.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> */}
        </div>
    )
}
export default OptionalItemsDetail
