/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from 'react'
import DataTable from 'react-data-table-component'
import Loading from './loading'
import './table.css'

interface DataTableProps {
    columns: any
    data: any
    totalRows?: number
    loading?: boolean
    striped?: boolean
    noHeader?: boolean
    pagination?: boolean
    expandableRows?: boolean
    ExpandedComponent?: any
    customStyles?: any
    tableHeight?: string
    selectableRows?: boolean
    onRowCLicked?: (item: any) => void
    conditionalRowStyles?: any
    noDataFound?: string | ReactElement
    onSelectedRowsChanged?: (selectedRows: any) => void
    rowSelectCriteria?: any
    selectableRowDisabled?: any
}

const Table = (props: DataTableProps) => {
    const {
        columns,
        data,
        totalRows,
        loading,
        noHeader,
        pagination,
        striped = true,
        expandableRows,
        ExpandedComponent,
        tableHeight,
        selectableRows,
        onRowCLicked,
        conditionalRowStyles,
        customStyles,
        noDataFound,
        onSelectedRowsChanged,
        rowSelectCriteria,
        selectableRowDisabled,
    } = props

    const defaultCustomStyle = {
        headRow: {
            style: {
                fontSize: '0.9rem',
                fontWeight: 'semi-bold',
            },
        },
    }

    return (
        <DataTable
            className="relative whitespace-nowrap"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination={!!pagination}
            paginationPerPage={totalRows}
            striped={striped}
            highlightOnHover
            persistTableHead
            noTableHead={noHeader}
            fixedHeader
            selectableRows={selectableRows}
            onRowClicked={onRowCLicked}
            fixedHeaderScrollHeight={tableHeight}
            expandableRows={expandableRows}
            expandableRowsComponent={ExpandedComponent}
            expandOnRowClicked={expandableRows}
            expandableRowExpanded={(row: any) => row.defaultExpanded}
            progressComponent={<Loading />}
            responsive
            customStyles={customStyles || defaultCustomStyle}
            noDataComponent={noDataFound}
            conditionalRowStyles={conditionalRowStyles}
            onSelectedRowsChange={onSelectedRowsChanged}
            selectableRowSelected={rowSelectCriteria}
            selectableRowDisabled={selectableRowDisabled}
        />
    )
}

export default Table
