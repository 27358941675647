import { callAxios } from 'plugins/api'

const downloadDocument = (quoteId: string) =>
    callAxios({
        url: `quote/${quoteId}/document/download`,
        method: 'POST',
        isHeffiq: true,
        headers: {
            'Content-Disposition': 'attachment',
            'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
    })

const getSalesSupportList = () =>
    callAxios({
        url: 'quote/salesSupport/list',
        method: 'GET',
        isHeffiq: true,
    })

const updateQuoteDocumentDetail = (quoteId: string, params: any) =>
    callAxios({
        url: `quote/${quoteId}/document`,
        method: 'PUT',
        data: params,
        isHeffiq: true,
    })

const getQuoteDocumentDetail = (quoteId: string) =>
    callAxios({
        url: `quote/${quoteId}/document`,
        method: 'GET',
        isHeffiq: true,
    })

export const DownloadQueries = {
    downloadDocument,
    getSalesSupportList,
    updateQuoteDocumentDetail,
    getQuoteDocumentDetail,
}
