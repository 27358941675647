const userInfo = JSON.parse(localStorage.getItem('CPQ_LOGGED_IN_USER_EXTRA_INFO') || '{}')

export type UserRole = 'manager' | 'dealer' | 'admin' | 'user' | 'sales-support'

export const getUserRole = (): UserRole => {
    if (userInfo.is_support === 1) {
        return 'sales-support'
    }
    if (userInfo.is_manager === 1) {
        return 'manager'
    }
    if (userInfo.is_dealer === 1) {
        return 'dealer'
    }
    if (userInfo.is_support === 1 && userInfo.is_manager === 1 && userInfo.is_dealer === 1) {
        return 'admin'
    }
    return 'user'
}
