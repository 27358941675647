/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import { useAuth } from 'modules/auth/context/useAuth'
import { addThousandSeparator } from 'utils/formatNumber'
import currencySymbolMap from './curreny.json'

const currencySymbols: any = currencySymbolMap

export const formatCurrency = (value: string | number): string => {
    const { country } = useAuth()
    const code = String(country.currency || country.country_code || '').toUpperCase()
    const symbol = String(currencySymbols[code])
    const price = parseFloat(String(value)).toFixed(2)

    if (_.isNaN(price) || price === 'NaN') {
        return 'N.V.T'
    }

    return `${symbol || '€'} ${addThousandSeparator(Number(price))}`
}

export const getCurrencySymbol = (): string => {
    const { country } = useAuth()
    const code = String(country.currency || country.country_code || '').toUpperCase()
    const symbol = String(currencySymbols[code])
    if (symbol) {
        return symbol
    }
    return '€'
}
