/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unstable-nested-components */

import Table from 'components/table'
import { Collapse } from 'antd'
import { t } from 'i18next'
import appJson from '../../../../app.json'
import ConfigureDetail from './configureDetail'
import PanelHeader from '../panelHeader'

interface ConfigureProps {
    configuration: any
    orderId?: string
    hideImage?: boolean
    isOrder?: boolean
}

const partsColumns: any = [
    {
        id: 'group',
        name: t('api,model_number'),
        left: true,
        cell: (row: {
            machine_part_model_number: number
            machine_category_model_number: number
        }) => {
            return (
                <div className="flex align-center items-center gap-2">
                    <h1 className="text-semibold mb-2 text-md text-center">
                        {row.machine_part_model_number
                            ? row.machine_part_model_number
                            : row.machine_category_model_number}
                    </h1>
                </div>
            )
        },
        width: '168px',
    },
    {
        id: 'description',
        name: t('api.description'),
        left: true,
        width: '256px',
        selector: (row: { machine_part_name: string; machine_category: string }) => (
            <p title={row.machine_part_name} className="cursor-pointer text-md text-left">
                {row.machine_part_name ? row.machine_part_name : row.machine_category}
            </p>
        ),
    },
    {
        id: 'remarks',
        name: t('api.remark'),
        left: true,
        width: '168px',
        selector: (row: { machine_part_remarks: string }) => {
            return <p className="text-md"> {row.machine_part_remarks} </p>
        },
    },
    {
        id: 'qty',
        name: t('api.quantity'),
        center: true,
        selector: (row: any) => {
            return <p className="text-md">{row.quantity ? row.quantity : 0}</p>
        },
    },
    {
        id: 'price',
        name: t('api.price'),
        right: true,
        selector: (row: { price: number; is_included: boolean }) => {
            const { is_included } = row
            if (is_included) {
                return (
                    <p className="text-md">
                        {appJson.companyCurrency}{' '}
                        {row.price ? parseFloat(String(row.price)).toFixed(2) : 0} (Included)
                    </p>
                )
            }
            return (
                <p className="text-md">
                    {appJson.companyCurrency}{' '}
                    {row.price ? parseFloat(String(row.price)).toFixed(2) : 0}
                </p>
            )
        },
    },
    {
        id: 'total_price',
        name: t('api.total_price'),
        right: true,
        selector: (row: { is_included: boolean; price: number; quantity: number }) => {
            const { is_included } = row
            if (is_included) {
                return (
                    <p className="text-md">
                        {appJson.companyCurrency}{' '}
                        {row.price && row.quantity
                            ? (
                                  parseFloat(String(row.price)) * parseFloat(String(row.quantity))
                              ).toFixed(2)
                            : 0}{' '}
                        (Included)
                    </p>
                )
            }
            return (
                <p className="text-md">
                    {appJson.companyCurrency}{' '}
                    {row.price && row.quantity
                        ? (
                              parseFloat(String(row.price)) * parseFloat(String(row.quantity))
                          ).toFixed(2)
                        : 0}
                </p>
            )
        },
    },
]

const columns = [
    {
        id: 'group',
        name: t('api.model_number'),
        left: true,
        cell: (row: {
            machine_part_model_number: number
            machine_category_model_number: number
        }) => {
            return (
                <div className="flex align-center items-center gap-2">
                    <h1 className="text-semibold mb-2 text-md text-center">
                        {row.machine_part_model_number
                            ? row.machine_part_model_number
                            : row.machine_category_model_number}
                    </h1>
                </div>
            )
        },
        width: '168px',
    },
    {
        id: 'description',
        name: t('api.description'),
        left: true,
        width: '256px',
        selector: (row: { machine_part_name: string; machine_category: string }) => (
            <p title={row.machine_part_name} className="cursor-pointer text-md text-left">
                {row.machine_part_name ? row.machine_part_name : row.machine_category}
            </p>
        ),
    },
    {
        id: 'remarks',
        name: t('api.remarks'),
        left: true,
        width: '168px',
        selector: (row: { remarks: string }) => {
            return <p className="text-md"> {row.remarks} </p>
        },
    },
    {
        id: 'qty',
        name: t('api.quantity'),
        center: true,
        selector: () => {
            return <p className="text-md">1</p>
        },
    },
    {
        id: 'price',
        name: t('api.price'),
        right: true,
        selector: (row: { price: number }) => (
            <p className="text-md">
                {appJson.companyCurrency} {row.price ? parseFloat(String(row.price)).toFixed(2) : 0}
            </p>
        ),
    },
    {
        id: 'total_price',
        name: t('api.total_price'),
        right: true,
        selector: (row: { price: number; quantity: number }) => (
            <p className="text-md">
                {appJson.companyCurrency}{' '}
                {row.price && row.quantity ? (parseFloat(String(row.price)) * 1).toFixed(2) : 0}
            </p>
        ),
    },
    // {
    //     id: 'note',
    //     name: 'Note',
    //     selector: (row: any) => <p className="text-md"> {row.name} </p>,
    // },
]

const ManualAdjustmentColumns: any = [
    {
        id: 'group',
        name: t('api.model_number'),
        left: true,
        cell: (row: { manual_adjustment_model_number: number }) => {
            return (
                <div className="flex align-center items-center gap-2">
                    <h1 className="text-semibold mb-2 text-md text-center">
                        {row.manual_adjustment_model_number}
                    </h1>
                </div>
            )
        },
        width: '168px',
    },
    {
        id: 'description',
        name: t('api.description'),
        left: true,
        width: '256px',
        selector: (row: { manual_adjustment_name: string }) => (
            <p className="cursor-pointer text-md text-left">{row.manual_adjustment_name}</p>
        ),
    },
    {
        id: 'remarks',
        name: t('api.remarks'),
        left: true,
        width: '168px',
        selector: (row: { remarks: string }) => {
            return <p className="text-md"> {row.remarks} </p>
        },
    },
    {
        id: 'qty',
        name: t('api.quantity'),
        center: true,
        selector: (row: { manual_adjustment_quantity: number }) => (
            <p className="text-md">
                {parseFloat(String(row.manual_adjustment_quantity)).toFixed(2)}
            </p>
        ),
    },
    {
        id: 'price',
        name: t('api.price'),
        right: true,
        selector: (row: { manual_adjustment_price: number }) => (
            <p className="text-md">
                {appJson.companyCurrency}{' '}
                {parseFloat(String(row.manual_adjustment_price)).toFixed(2)}
            </p>
        ),
    },
    {
        id: 'total_price',
        name: t('api.total_price'),
        right: true,
        selector: (row: any) => (
            <p className="text-md">
                {appJson.companyCurrency}{' '}
                {(
                    parseFloat(String(row.manual_adjustment_price)) &&
                    parseFloat(String(row.manual_adjustment_quantity)) &&
                    parseFloat(String(row.manual_adjustment_price)) *
                        parseFloat(String(row.manual_adjustment_quantity))
                ).toFixed(2)}
            </p>
        ),
    },
    // {
    //     id: 'note',
    //     name: 'Note',
    //     selector: (row: any) => <p className="text-md"> {row.manual_adjustment_note} </p>,
    // },
]

const Configure = (props: ConfigureProps) => {
    const { configuration, orderId, isOrder } = props
    const { Panel } = Collapse

    return (
        <div className="flex flex-col gap-8 mt-4 mr-4">
            <ConfigureDetail
                machine={configuration.machine}
                orderId={Boolean(orderId)}
                hideImage={false}
            />
            <Collapse style={{ backgroundColor: 'transparent' }} defaultActiveKey={[1, 2, 3]}>
                <Panel key={1} header={PanelHeader({ title: t('api.compoent') })}>
                    <Table
                        columns={columns}
                        data={[configuration.machine]}
                        pagination={false}
                        striped={false}
                    />
                </Panel>
                {configuration.parts.length > 0 && (
                    <Panel key={2} header={PanelHeader({ title: t('api.compitable_parts') })}>
                        <Table
                            columns={partsColumns}
                            data={configuration.parts}
                            pagination={false}
                            noHeader
                            striped={false}
                        />
                    </Panel>
                )}
                {configuration.adjustment.length > 0 && (
                    <Panel key={3} header={PanelHeader({ title: t('api.manual_adjustment') })}>
                        <Table
                            columns={ManualAdjustmentColumns}
                            data={configuration.adjustment}
                            pagination={false}
                            noHeader
                            striped={false}
                        />
                    </Panel>
                )}
            </Collapse>
        </div>
    )
}

export default Configure
