/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import { find, isEmpty, map } from 'lodash'
import Button from 'components/button'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { successToast } from 'utils/toasterUtil'
import { useTranslation } from 'react-i18next'
import { LeaseType } from '../LeaseCalculation'
import LeaseTable from './LeaseTable'
import { updateAddLeaseForConfiguration } from '../queries/lease'

interface LeaseCalculatorProps {
    resudialPercentage: any
    companyRates: any
    machine: any
    leaseConfiguration: any
    configurationId: string
}

const LeaseCalculator = (props: LeaseCalculatorProps) => {
    const { resudialPercentage, machine, companyRates, leaseConfiguration, configurationId } = props
    const [leases, setLeases] = useState<LeaseType[]>([])
    const [leaseData, setLeaseData] = useState<LeaseType[]>([])
    const [isRefresh, setIsRefresh] = useState<boolean>(false)
    const { t } = useTranslation()
    const leaseCalcualtionFormula = (
        net_total: number,
        residual_value: number,
        rate: number,
        time: number,
    ) => {
        const monthly_payment: number =
            ((-net_total * (1 + rate / 1200) ** time + residual_value) * rate) /
            1200 /
            (1 - (1 + rate / 1200) ** time)
        return monthly_payment.toFixed(2)
    }

    const getRateValue = (rateName: string) => {
        const rate = find(companyRates, { rate_name: rateName })
        return rate?.rate_value || 0
    }

    const calculateLease = () => {
        if (leaseConfiguration?.length > 0) {
            setLeases(leaseConfiguration)
        }
        const leaseWithResidualPercentage: LeaseType[] = map(
            resudialPercentage || [],
            (resudial: any) => {
                const netTotal: number =
                    machine.machine_total_price -
                    (machine.discount_percentage * machine.machine_total_price) / 100 -
                    machine.discount_amount
                const residualValue: number = (netTotal * (resudial?.percentage || 0)) / 100
                return {
                    configuration_id: configurationId,
                    lease_duration: resudial.year || 0,
                    configuration_quantity: machine?.quantity || 0,
                    net_total: parseFloat(String(netTotal)).toFixed(2),
                    interest_rate: getRateValue('Interest Rate'),
                    residual_value: parseFloat(String(residualValue)).toFixed(2),
                    service_cost: getRateValue('Service Cost'),
                    price_per_month: leaseCalcualtionFormula(
                        netTotal,
                        residualValue,
                        getRateValue('Interest Rate'),
                        resudial.year,
                    ),
                }
            },
        )
        setIsRefresh(true)
        setLeases(leaseWithResidualPercentage || [])
    }

    const handleTextChange = (value: string, name: string, i: any) => {
        switch (name) {
            case 'residual_value':
                leases[i].residual_value = value || '0'
                break

            case 'service_cost':
                leases[i].service_cost = parseInt(value || '0', 10)
                break

            case 'interest_rate':
                leases[i].interest_rate = parseInt(value || '0', 10)
                break

            default:
                break
        }
        leases[i].price_per_month = leaseCalcualtionFormula(
            parseInt(leases[i].net_total, 10),
            parseInt(leases[i].residual_value, 10),
            parseFloat(String(leases[i].interest_rate)),
            parseInt(String(leases[i].lease_duration), 10),
        )
        setLeaseData(leases)
        setIsRefresh(true)
    }

    const handleSelectChange = (value: string, i: any) => {
        leases[i].lease_duration = parseInt(value, 10)
        leases[i].residual_value = String(
            (parseInt(leases[i].net_total, 10) *
                resudialPercentage.filter(
                    (lease1: { year: number }) => lease1.year === parseInt(value, 10),
                )[0].percentage) /
                100,
        )
        leases[i].price_per_month = leaseCalcualtionFormula(
            parseInt(leases[i].net_total, 10),
            parseInt(leases[i].residual_value, 10),
            parseFloat(String(leases[i].interest_rate)),
            parseInt(String(leases[i].lease_duration), 10),
        )
        setLeaseData(leases)
        setIsRefresh(true)
    }

    const addUpdateLease = useMutation(
        ['addUpdateLeaseMutation', configurationId],
        () =>
            updateAddLeaseForConfiguration(
                {
                    lease_data: leases,
                },
                configurationId,
            ),
        {
            onSuccess: () => {
                toast(t('message.update_lease_success'), successToast)
            },
            onError: (error: { message: string }) => {
                toast(error?.message || t('message.update_lease_failed'), successToast)
            },
        },
    )

    useEffect(() => {
        calculateLease()
    }, [resudialPercentage && leaseConfiguration && machine && companyRates])

    useEffect(() => {
        if (isRefresh === true) {
            setIsRefresh(false)
            setLeases(isEmpty(leaseData) ? leases : leaseData)
        }
    }, [isRefresh])

    return (
        <div className="flex flex-col gap-4">
            <div>
                <p className="text-2xl">{t('api.lease_calculation')}</p>
            </div>
            <div className="flex flex-col gap-4">
                <LeaseTable
                    leases={leases}
                    handleSelectChange={handleSelectChange}
                    resudialPercentage={resudialPercentage}
                    handleTextChange={handleTextChange}
                />
                <div className="flex justify-center items-center">
                    <div>
                        <Button
                            isLoading={addUpdateLease.isLoading}
                            disabled={addUpdateLease.isLoading}
                            label={t('api.update_add_lease')}
                            onClick={() => addUpdateLease.mutate()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaseCalculator
