/* eslint-disable react/button-has-type */
import React from 'react'
import ButtonLoader from './buttonLoader'

interface ButtonProps {
    id?: string
    label?: string
    title?: string
    content?: React.ReactElement
    variant?: 'primary' | 'outline' | 'ghost' | 'error'
    type?: 'button' | 'submit' | 'reset'
    dataDropdownToggle?: string
    customButtonClass?: string
    isLoading?: boolean
    leftIcon?: React.ReactElement
    rightIcon?: React.ReactElement
    disabled?: boolean
    isSmall?: boolean
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button = (props: ButtonProps) => {
    const {
        id,
        type = 'button',
        label,
        onClick,
        content,
        leftIcon,
        rightIcon,
        variant,
        isLoading,
        dataDropdownToggle,
        disabled,
        customButtonClass,
        isSmall,
        title,
        ...rest
    } = props

    const primaryButton = `bg-secondary text-white hover:text-secondary hover:bg-primary hover:border-black border-2`
    const errorButton = `bg-red-700 text-white hover:bg-red-900 hover:border-black border-2`
    const outlineButton = `bg-accent hover:border-black hover:bg-secondary hover:text-white border-2 border-accent text-white `
    const ghostButton = `text-primary bg-none hover:text-black`

    const btnClass = `block font-bold py-[0.6rem] px-3 w-full rounded-lg flex items-center justify-center gap-3 whitespace-nowrap`

    const getButtonClass = () => {
        switch (variant) {
            case 'primary':
                return primaryButton
            case 'outline':
                return outlineButton
            case 'ghost':
                return ghostButton
            case 'error':
                return errorButton
            default:
                return primaryButton
        }
    }

    if (isLoading) {
        return (
            <button
                id={id}
                data-dropdown-toggle={dataDropdownToggle}
                type={type || 'button'}
                disabled
                className={`${btnClass} ${getButtonClass()} ${customButtonClass} ${
                    isSmall ? 'btn-xs' : ''
                }`}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                    onClick ? onClick(e) : null
                }
                {...rest}
            >
                <ButtonLoader />
                {!content && label}
                {content && content}
            </button>
        )
    }
    return (
        <button
            title={title}
            id={id}
            data-dropdown-toggle={dataDropdownToggle}
            type={type || 'button'}
            className={`${btnClass} ${getButtonClass()} ${customButtonClass} ${
                disabled ? 'opacity-50 cursor-not-allowed' : ''
            } }`}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                onClick ? onClick(e) : null
            }
            disabled={disabled}
            {...rest}
        >
            {leftIcon && leftIcon}
            {!content && label}
            {content && content}
            {rightIcon && rightIcon}
        </button>
    )
}

export default Button
