/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from 'antd'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { orderBy } from 'lodash'
import moment from 'moment'

import NovoDetail from 'modules/novo-configuration/views/novoDetail'
import { getNovoConfigurationHistory } from 'modules/novo-configuration/query'
import Loading from '../../../components/loading'
import useTranslationData from '../../translation/hooks/useTranslationData'

const cardClass = 'bg-white px-4 py-4 rounded-xl h-fit'

const Logs = () => {
    const { configurationId } = useParams()
    const {
        translation: { t },
    } = useTranslationData()
    const [selectedVersion, setSelectedVersion] = useState('')
    const [versions, setVersions] = useState<
        {
            label: string
            value: string
            updated_at: string
        }[]
    >([])

    const { isLoading } = useQuery([], () => getNovoConfigurationHistory(configurationId || ''), {
        onSuccess: (data: any) => {
            const novoVersions = data?.data?.data.map(
                (item: { id: string; version_number: string; updated_at: string }) => ({
                    label: item.version_number,
                    value: item.id,
                    updated_at: item.updated_at,
                }),
            )
            setVersions(novoVersions)
            setSelectedVersion(novoVersions[0].value)
        },
    })

    const onChangeVersion = (versionId: string) => {
        setSelectedVersion(versionId)
    }

    if (isLoading) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 overflow-hidden">
            <div className={classNames('flex items-center justify-between', cardClass)}>
                <Select
                    defaultValue={selectedVersion}
                    placeholder="Select Version"
                    size="large"
                    className="rounded-md min-w-[10vw]"
                    onChange={onChangeVersion}
                >
                    {orderBy(versions, ['value'], ['asc']).map(item => (
                        <Select.Option value={item.value}>
                            <span className="flex items-center gap-2">
                                <span>Version: {item.label}</span>
                                <span>|</span>
                                <span>{moment(item.updated_at).format('MMM-DD-YYYY')}</span>
                            </span>
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div className="overflow-y-scroll">
                <NovoDetail novoVersionId={selectedVersion} novoIdProp={configurationId} hideBack />
            </div>
        </div>
    )
}
export default Logs
