import DetailConfiguartion from 'modules/configuration/views/detailConfiguration'
import { useTranslation } from 'react-i18next'

const QuoteInfo = () => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col gap-4">
            <div>
                <div>
                    <p className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                        {t('api.configuration_detail')}
                    </p>
                    <hr className="mt-4 text-black border-[#E0E0E0]" />
                </div>
            </div>
            <DetailConfiguartion hideImage hideBack />
        </div>
    )
}

export default QuoteInfo
