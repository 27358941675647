import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const liClass = `text-bold cursor-pointer hover:border-gray-300 w-full md:w-auto border-none md:border-b-2 md:border`

export type TabType = {
    name: string
    value: string
}

const Tabs = ({
    leftContent,
    tabs,
    getActiveTab,
    initialTab,
}: {
    tabs: TabType[]
    leftContent?: React.ReactElement
    getActiveTab: (item: TabType) => void
    initialTab?: TabType
}) => {
    const [activeTab, setActiveTab] = useState<TabType>(initialTab || tabs[0])
    const { t } = useTranslation()

    const handleTabChange = (tab: TabType) => {
        setActiveTab(tab)
        getActiveTab(tab)
    }

    return (
        <div className="flex w-full items-center justify-between flex-wrap gap-4 mb-4">
            <div>
                <ul className="flex flex-wrap gap-8 -mb-px">
                    {tabs.map((tab: TabType) => (
                        <li
                            key={tab.name}
                            className={`${
                                activeTab.name === tab.name
                                    ? 'text-secondary border-primary font-medium underline underline-offset-8 decoration-4 decoration-primary'
                                    : 'text-placeholderColor border-transparent'
                            } ${liClass}`}
                            onClick={() => handleTabChange(tab)}
                        >
                            {t(`${tab.name}`)}
                        </li>
                    ))}
                </ul>
            </div>
            <div>{leftContent}</div>
        </div>
    )
}

export default Tabs
