import Button from 'components/button'
import { FormikProps, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loading from 'components/loading'
import { TextEditor } from 'components/TextEditor'
import { createFreeText, getFreeTextByQuoteId, updateFreeText } from '../queries/quotes'

interface IFreeTextForm {
    id?: number
    freeText: string
}

const FreeText = () => {
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const FreeTextSaveMutation = useMutation(
        ['addFreeText'],
        (params: { quote_id: number; free_text: string }) => createFreeText(params),
        {
            onSuccess: () => {
                toast.success(t('message.requirement_matrix_save_success'))
            },
            onError: (error: Error) => {
                toast.error(error?.message || t('message.requirement_matrix_save_failed'))
            },
        },
    )

    const FreeTextUpdateMutation = useMutation(
        ['addFreeText'],
        (params: { id: number; quote_id: number; free_text: string }) =>
            updateFreeText(params.id, { free_text: params.free_text, quote_id: params.quote_id }),
        {
            onSuccess: () => {
                toast.success(t('message.requirement_matrix_update_success'))
            },
            onError: (error: Error) => {
                toast.error(error?.message || t('message.requirement_matrix_update_failed'))
            },
        },
    )

    const formik: FormikProps<IFreeTextForm> = useFormik<IFreeTextForm>({
        initialValues: {
            freeText: '',
        },
        validationSchema: Yup.object({
            freeText: Yup.string().required(t('api.free_text_required')),
        }),
        onSubmit: values => {
            if (!quoteId) {
                toast.error(t('api.quote_id_required'))
                return
            }
            if (values.id) {
                FreeTextUpdateMutation.mutate({
                    id: values.id,
                    quote_id: parseFloat(quoteId),
                    free_text: values.freeText,
                })
            } else {
                FreeTextSaveMutation.mutate(
                    {
                        quote_id: parseFloat(quoteId),
                        free_text: values.freeText,
                    },
                    {
                        onSuccess: data => {
                            formik.setFieldValue('id', data.data.data.id)
                        },
                    },
                )
            }
        },
    })

    const { isLoading } = useQuery(
        ['getFreeText'],
        () => getFreeTextByQuoteId(parseFloat(quoteId || '')),
        {
            enabled: !!quoteId,
            onSuccess: (d: any) => {
                if (d.data.data) {
                    formik.setFieldValue('id', d.data.data.id)
                    formik.setFieldValue('freeText', d.data.data.free_text)
                }
            },
            onError: () => {
                toast.error(t('api.fetch_question_failed'))
            },
        },
    )

    if (isLoading || FreeTextSaveMutation.isLoading || FreeTextUpdateMutation.isLoading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="h-full w-full items-end flex flex-col gap-4">
            <div className="flex w-full justify-between items-center">
                <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                    {t('api.free_text')}
                </p>
            </div>
            <div className="flex w-full flex-col flex-1">
                <TextEditor
                    value={formik.values.freeText}
                    onChange={(value: string) => {
                        formik.setFieldValue('freeText', value)
                    }}
                    errorMessage={
                        formik.touched.freeText && formik.errors.freeText
                            ? String(formik.errors.freeText)
                            : ''
                    }
                />
            </div>
            <div className="w-1/5">
                <Button
                    variant="primary"
                    type="submit"
                    label={t('api.save')}
                    isLoading={false}
                    onClick={() => formik.handleSubmit()}
                />
            </div>
        </div>
    )
}

export default FreeText
