import Tabs, { TabType } from 'components/tabs'
import DetailConfiguartion from 'modules/configuration/views/detailConfiguration'
import { useState } from 'react'
import Logs from './Logs'
import Summary from './Summary'
import PriceLogs from './PriceLogs'
import GeneralLogs from './GeneralLogs'
import ExtraLogs from './ExtraLogs'

const tabs: TabType[] = [
    {
        name: 'General Logs',
        value: 'api.general_logs',
    },
    {
        name: 'Price Logs',
        value: 'api.price_logs',
    },
    {
        name: 'Extra Logs',
        value: 'api.extra_logs',
    },
    {
        name: 'Change Summary',
        value: 'api.summary',
    },
    {
        name: 'Current',
        value: 'api.machine',
    },
    {
        name: 'Logs',
        value: 'api.logs',
    },
]

const HistoryTab = () => {
    const getScreen = (screen: string) => {
        switch (screen) {
            case 'api.general_logs':
                return <GeneralLogs />
            case 'api.price_logs':
                return <PriceLogs />
            case 'api.extra_logs':
                return <ExtraLogs />
            case 'api.summary':
                return <Summary />
            case 'api.machine':
                return <DetailConfiguartion hideBack />
            case 'api.logs':
                return <Logs />
            default:
                return <p>404 Not Found</p>
        }
    }

    const [activeTab, setActiveTab] = useState<TabType>(tabs[0])
    return (
        <div>
            <Tabs tabs={tabs} getActiveTab={setActiveTab} />
            <hr className="my-4 border-1 border-gray-400" />
            <div>{getScreen(activeTab.value)}</div>
        </div>
    )
}

export default HistoryTab
