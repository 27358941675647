import { FormikProps, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { PricingQueries } from 'modules/quotes/queries/pricing'
import Stepper, { StepType } from '../../../components/stepper'
import SiteSurveyInformation from './SiteSurveyInformation'
import Button from '../../../components/button'
import MachineDescriptions from './MachineDescriptions'
import ImageUploadSiteSurvey from './ImageUploadSiteSurvey'
import { SiteSurveyQueries } from '../queries/siteSurveyQueries'
import Loading from '../../../components/loading'
import { Options } from '../../../components/select'
import SiteSurveyOverview from './SiteSurveyOverview'
import TruckSpecification from './TruckSpecification'
import { attachmentOption, palletSizeOptions } from './SiteSurveyOptions'
import useTranslationData from '../../translation/hooks/useTranslationData'

export interface ISiteSurveyForm {
    customer: string
    contactPerson: string
    date: string
    segment: string
    segmentId: number
    series: string
    seriesId: number
    model: string
    modelId: number
    locationAndDepartment: string
    branch: string
    branchOther: string
    fuel: string
    // Omgeving
    floor: string
    floorCondition: string
    indoorOrOutDoor: string
    forkClearance: string
    outletHeightForPallet: string
    corridorWidth: string
    dockShelters: string
    ramps: string
    runningHours: number
    seasonalCrowds: boolean
    workInShifts: string
    surroundingAdditionalInformation: string
    // Last
    palletSize: string
    palletSizeOther: string
    loadDimension: string
    material: string
    weight: string
    loadCenter: string
    overBuildEquipmentOnSite: boolean
    overBuildEquipmentOnSiteRemark: string
    stackingPackage: string
    loadAdditionalInformation: string
    // truckModel Electric
    tank: string
    coupling: string
    batteryBrand: string
    batteryType: string
    batteryCapacity: string
    eloOrHip: string
    voltage: string
    centralFillingSystem: boolean
    chargingTime: string
    plugType: string
    // forkPositioner: boolean
    forkDimension: string
    measureOverForks: string
    measureBetweenForks: string
    forkWheels: string
    heavyClamp: boolean
    attachments: string
    attachmentsOther: string
    loadRack: boolean
    lflOrFfl: string
    platformHeight: string
    inductanceOrRailConduction: string
    control: string
    steering: string
    roofPlate: boolean
    cabin: boolean
    heatingOrAirCondition: boolean
    mirrors: boolean
    radio: boolean
    safety: string
    lighting: string
    publicRoad: boolean
    drivingLight: boolean
    flashingLight: boolean
    drivingDirection: string
    pal: string
    typeBanden: string
    maxWorkHour: number
    mast: string
    hysterTracker: string
    truckModelAdditionalInformation: string
    workingEnvironmentTemperature: string
    // lease
    financing: string
    alternativeFinancing: string
    maintenance: string
    insurance: boolean
    return: boolean
    replacementMachine: string
    showAllPricesSeparate: string
    durationOfContract: number
    offerAdditionalInformation: string
    comment: string
    images: []
    uploadedImages: []
}

const AddSiteSurvey = () => {
    const {
        translation: { t },
    } = useTranslationData()
    const navigate = useNavigate()
    const [step, setStep] = useState<number>(0)
    const { siteSurveyId, copySiteSurveyId } = useParams()
    const [savedSiteSurveyId, setSavedSiteSurveyId] = useState<number>()
    const [theoriticalMaxWorkHour, setTheoriticalMaxWorkHour] = useState<number>()
    const [calculatedCategory, setCalculatedCategory] = useState<string>('')
    const [thirdFormCollapseActiveKey, setThirdFormCollapseActiveKey] = useState<any>(['1'])
    const [secondFormCollapseActiveKey, setSecondFormCollapseActiveKey] = useState<any>(['1'])
    const [drive, setDrive] = useState<string>('')
    const [batteryCapacityOptions, setBatteryCapacityOptions] = useState<Options[]>([])
    const [searchParams] = useSearchParams()
    const [isPmPlusAvailable, setIsPmPlusAvailable] = useState<boolean>(false)

    const configurationId = searchParams.get('configuration-id')
    const surrounding = searchParams.get('surrounding') === 'true'
    const load = searchParams.get('load') === 'true'
    const truckModel = searchParams.get('truck-model') === 'true'
    const offerOptions = searchParams.get('offer-options') === 'true'
    const hideNonRequiredFields = searchParams.get('hide-non-required-fields') === 'true'
    const copyOnlyRequiredFields = searchParams.get('copy-only-required-fields') === 'true'

    const validationSchemaList = [
        Yup.object().shape({
            customer: Yup.string().required(`${t('fields.customer_name')} ${t('fields.required')}`),
            contactPerson: Yup.string().required(
                `${t('fields.contact_person')} ${t('fields.required')}`,
            ),
            // fuel: Yup.string().when('machineType', {
            //     is: (machineType: 'Warehouse' | 'Counter balance') =>
            //         machineType === 'Counter balance',
            //     then: Yup.string().required('Fuel is required'),
            // }),
        }),
        Yup.object().shape({
            locationAndDepartment: Yup.string().required(
                `${t('fields.location')} ${t('fields.and')} ${t('fields.department')} 
                ${t('fields.required')}`,
            ),
            branch: Yup.string().required(`${t('fields.branch')} ${t('fields.required')}`),
            floor: Yup.string().required(`${t('fields.floor')} ${t('fields.required')}`),
            floorCondition: Yup.string().required(
                `${t('fields.floor_condition')} ${t('fields.required')}`,
            ),
            forkClearance: Yup.string().required(
                `${t('fields.fork_clearance')} ${t('fields.required')}`,
            ),
            outletHeightForPallet: Yup.string().required(
                `${t('fields.outlet_height_for_pallet')} ${t('fields.required')}`,
            ),
            dockShelters: Yup.string().required(
                `${t('fields.dock_shelters')} ${t('fields.required')}`,
            ),
            ramps: Yup.string().required(`${t('fields.ramp')} ${t('fields.required')}`),
            runningHours: Yup.number()
                .required(`${t('fields.running_hours')} ${t('fields.required')}`)
                .positive(`${t('fields.running_hours')} ${t('fields.must_be_greater_than_zero')}`),
            workInShifts: Yup.string().required(
                `${t('fields.work_in_shifts')} ${t('fields.required')}`,
            ),

            weight: Yup.string().required(`${t('fields.weight')} ${t('fields.required')}`),
            loadCenter: Yup.string().required(`${t('fields.load_center')} ${t('fields.required')}`),
            segmentId: Yup.number().required(`${t('fields.segment')} ${t('fields.required')}`),
            seriesId: Yup.string().required(`${t('fields.series')} ${t('fields.required')}`),
            modelId: Yup.string().required(`${t('fields.model')} ${t('fields.required')}`),
        }),
        Yup.object().shape({
            batteryType: Yup.string().when([], {
                is: () => drive === 'Elektrisch',
                then: Yup.string()
                    .nullable(false)
                    .required(`${t('fields.battery_type')} ${t('fields.required')}`),
                otherwise: Yup.string().nullable().notRequired(),
            }),
            batteryCapacity: Yup.string().when(['batteryType'], {
                is: (batteryType: string) => batteryType === 'Loodzuur',
                then: Yup.string()
                    .nullable(false)
                    .required(`${t('fields.battery_capacity')} ${t('fields.required')}`),
                otherwise: Yup.string().nullable().notRequired(),
            }),
            eloOrHip: Yup.string().when([], {
                is: () => drive === 'Elektrisch',
                then: Yup.string().required(`${t('fields.elo_or_hip')} ${t('fields.required')}`),
                otherwise: Yup.string().nullable().notRequired(),
            }),
            maxWorkHour: Yup.number().when([], {
                is: () => drive === 'Elektrisch',
                then: Yup.number()
                    .required(`${t('fields.max_effective_working_hours')} ${t('fields.required')}`)
                    .positive(
                        `${t('fields.max_effective_working_hours')} ${t(
                            'fields.must_be_greater_than_zero',
                        )}`,
                    ),
                otherwise: Yup.number().nullable().notRequired(),
            }),
            overBuildEquipmentOnSite: Yup.boolean().required(
                `${t('fields.overbuild_equipment_on_site')} ${t('fields.required')}`,
            ),
            financing: Yup.string().required(`${t('fields.financing')} ${t('fields.required')}`),
            maintenance: Yup.string().required(
                `${t('fields.maintenance')} ${t('fields.required')}`,
            ),
            durationOfContract: Yup.number().when('maintenance', {
                is: (maintenance: string) => maintenance === 'ALL_IN',
                then: Yup.number()
                    .required(`${t('fields.duration_of_contract')} ${t('fields.required')}`)
                    .positive(
                        `${t('fields.duration_of_contract')} ${t(
                            'fields.must_be_greater_than_zero',
                        )}`,
                    ),
            }),
        }),
    ]

    const { isLoading: isSegmentLoading, data: segmentData } = useQuery(
        'getAllSegment',
        () => SiteSurveyQueries.getALlSegment(),
        {
            onError: () => {
                toast.error(t('message.something_went_wrong_try_again'))
            },
        },
    )

    const createSiteSurveyForm = useMutation(
        'createSiteSurvey',
        (createSiteSurveyFormParams: any) =>
            SiteSurveyQueries.createSiteSurvey(createSiteSurveyFormParams),
        {
            onSuccess: data => {
                setTheoriticalMaxWorkHour(data.data.calculated_deployment)
                setCalculatedCategory(data.data.calculated_category)
                setSavedSiteSurveyId(data.data.data.id)
                toast.success(`${t('api.site_survey')} ${t('message.created')}`)
                setStep(step + 1)
            },
            onError: () => {
                toast.error(t('message.something_went_wrong_try_again'))
            },
        },
    )

    const updateSiteSurveyForm = useMutation(
        ['updateSiteSurvey'],
        (updateSiteSurveyParams: any) =>
            SiteSurveyQueries.updateSiteSurvey({
                data: updateSiteSurveyParams,
                siteSurveyId: savedSiteSurveyId || parseInt(String(siteSurveyId), 10),
            }),
        {
            onSuccess: (data: any) => {
                setTheoriticalMaxWorkHour(data.data.calculated_deployment)
                setCalculatedCategory(`api.category_${data.data.calculated_category}`)
                setSavedSiteSurveyId(data.data.data.id)
                toast.success(`${t('message.site_survey_updated_success')}`)
                setStep(step + 1)
            },
            onError: () => {
                toast.error(t('message.something_went_wrong_try_again'))
            },
        },
    )

    const uploadImageForSiteSurvey = useMutation(
        ['uploadImageForSurvey'],
        (data: any) =>
            SiteSurveyQueries.uploadImageForSiteSurvey({
                siteSurveyId: savedSiteSurveyId || 0,
                data,
            }),
        {
            onSuccess: () => {
                toast.success(`${t('message.image_updated_success')}`)
                navigate(`/site-survey`)
            },
            onError: () => {
                toast.error(t('message.something_went_wrong_try_again'))
            },
        },
    )

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    const formik: FormikProps<ISiteSurveyForm> = useFormik<ISiteSurveyForm>({
        initialValues: {
            customer: '',
            contactPerson: '',
            date: moment().format('YYYY-MM-DD'),
            segment: '',
            segmentId: 0,
            locationAndDepartment: '',
            branch: '',
            branchOther: '',
            series: '',
            seriesId: 0,
            model: '',
            modelId: 0,
            fuel: '',
            // surrounding
            floor: '',
            floorCondition: '',
            indoorOrOutDoor: '',
            forkClearance: '',
            outletHeightForPallet: '',
            corridorWidth: '',
            dockShelters: '',
            ramps: '',
            surroundingAdditionalInformation: '',
            runningHours: 0,
            seasonalCrowds: false,
            tank: '',
            workInShifts: '',
            // Last
            palletSize: '',
            palletSizeOther: '',
            loadDimension: '',
            material: '',
            weight: '',
            loadCenter: '',
            overBuildEquipmentOnSite: false,
            overBuildEquipmentOnSiteRemark: '',
            stackingPackage: '',
            loadAdditionalInformation: '',
            // truckModel Electric
            batteryBrand: '',
            batteryType: '',
            batteryCapacity: '',
            voltage: '',
            eloOrHip: 'hip',
            centralFillingSystem: false,
            chargingTime: '',
            plugType: '',
            forkDimension: '',
            // forkPositioner: false,
            heavyClamp: false,
            maxWorkHour: 0,
            attachments: '',
            attachmentsOther: '',
            measureBetweenForks: '',
            measureOverForks: '',
            loadRack: false,
            lflOrFfl: '',
            mast: '',
            platformHeight: '',
            inductanceOrRailConduction: '',
            control: '',
            steering: '',
            pal: '',
            lighting: '',
            forkWheels: '',
            publicRoad: false,
            hysterTracker: '',
            truckModelAdditionalInformation: '',
            workingEnvironmentTemperature: '',
            drivingDirection: '',
            // Combustion
            coupling: '',
            cabin: false,
            roofPlate: false,
            heatingOrAirCondition: false,
            mirrors: false,
            radio: false,
            safety: '',
            drivingLight: false,
            flashingLight: false,
            typeBanden: '',
            // finance
            financing: '',
            alternativeFinancing: '',
            insurance: false,
            maintenance: '',
            return: false,
            replacementMachine: '',
            durationOfContract: 0,
            showAllPricesSeparate: '',
            offerAdditionalInformation: '',
            comment: '',
            images: [],
            uploadedImages: [],
        },
        validationSchema: validationSchemaList[step],
        onSubmit: values => {
            const createSiteSurveyFormParams = {
                customer: values.customer,
                contact_person: values.contactPerson,
                date: values.date,
                segment: values.segment,
                segment_id: values.segmentId,
                series: values.series,
                serie_id: values.seriesId,
                location_and_department: values.locationAndDepartment,
                branch: values.branch,
                branchOther: values.branchOther,
                model: values.model,
                model_id: values.modelId,
                fuel: formik.values.segment === 'Verbrandings heftruck' ? values.fuel : null,
                // Omgeving
                floor: values.floor,
                floor_condition: values.floorCondition,
                indoor_or_out_door: values.indoorOrOutDoor,
                fork_clearance: values.forkClearance,
                outlet_height_for_pallet: values.outletHeightForPallet,
                corridor_width: values.corridorWidth,
                dock_shelters: values.dockShelters,
                ramps: values.ramps,
                running_hours: values.runningHours,
                seasonal_crowds: values.seasonalCrowds,
                work_in_shifts: values.workInShifts,
                surrounding_additional_information: values.surroundingAdditionalInformation,
                // Last
                pallet_size:
                    values.palletSize === 'other' ? values.palletSizeOther : values.palletSize,
                load_dimension: values.loadDimension,
                material: values.material,
                weight: values.weight,
                load_center: values.loadCenter,
                overbuild_equipment_on_site: values.overBuildEquipmentOnSite,
                overbuild_equipment_on_site_remark: values.overBuildEquipmentOnSiteRemark,
                stacking_package: values.stackingPackage,
                load_additional_information: values.loadAdditionalInformation,
                // truckModel Electric
                tank: values.tank,
                battery_brand: values.batteryBrand,
                battery_type: values.batteryType,
                battery_capacity: values.batteryCapacity,
                elo_or_hip: drive === 'Elektrisch' ? values.eloOrHip : null,
                voltage: values.voltage,
                central_filling_system: values.centralFillingSystem,
                coupling: values.coupling,
                charging_time: values.chargingTime,
                plug_type: values.plugType,
                // fork_positioner: values.forkPositioner,
                fork_dimension: values.forkDimension,
                measure_over_forks: values.measureOverForks,
                measure_between_forks: values.measureBetweenForks,
                fork_wheels: values.forkWheels,
                heavy_clamp: values.heavyClamp,
                driving_direction: values.drivingDirection,
                attachments:
                    values.attachments === 'other' ? values.attachmentsOther : values.attachments,
                load_rack: values.loadRack,
                lfl_or_ffl: values.lflOrFfl,
                platform_height: values.platformHeight,
                inductance_or_rail_conduction: values.inductanceOrRailConduction,
                control: values.control,
                steering: values.steering,
                roof_plate: values.roofPlate,
                cabin: values.cabin,
                heating_or_air_condition: values.heatingOrAirCondition,
                mirrors: values.mirrors,
                radio: values.radio,
                safety: values.safety,
                lighting: values.lighting,
                public_road: values.publicRoad,
                driving_light: values.drivingLight,
                flashing_light: values.flashingLight,
                pal: values.pal,
                type_banden: values.typeBanden,
                max_work_hour: values.maxWorkHour,
                mast: values.mast,
                hyster_tracker: values.hysterTracker,
                truck_model_additional_information: values.truckModelAdditionalInformation,
                working_environment_temperature: values.workingEnvironmentTemperature,
                // lease
                financing: values.financing,
                alternative_financing: values.alternativeFinancing,
                maintenance: values.maintenance,
                show_all_prices_separate: values.showAllPricesSeparate,
                insurance: values.insurance,
                return: values.return,
                replacement_machine: values.replacementMachine,
                duration_of_contract: values.durationOfContract,
                offer_options_additional_information: values.offerAdditionalInformation,
                comment: values.comment,
            }
            if (step === 2) {
                if (siteSurveyId) {
                    updateSiteSurveyForm.mutate(createSiteSurveyFormParams)
                } else {
                    createSiteSurveyForm.mutate(createSiteSurveyFormParams)
                }
            } else if (step === 3) {
                updateSiteSurveyForm.mutate(createSiteSurveyFormParams)
            } else if (step === 4) {
                const formData = new FormData()

                formik.values.images.forEach((image: any, index) => {
                    formData.append(`images[${index}]`, image)
                })

                uploadImageForSiteSurvey.mutate(formData)
            } else {
                setStep(step + 1)
            }
            formik.setTouched({})
        },
    })

    const checkIfPmPlusAvailable = useMutation(
        ['checkIfPmPlusAvailable'],
        (seriesCode: string) => PricingQueries.isPmPlusAvailable(seriesCode),
        {
            onSuccess: data => {
                if (data?.data?.data) {
                    setIsPmPlusAvailable(true)
                }
            },
            onError: error => {
                toast.error(t('message.check_pm_plus_failed'))
            },
        },
    )

    const openNotValidatedFields = () => {
        if (
            formik.errors.locationAndDepartment ||
            formik.errors.runningHours ||
            formik.errors.floor ||
            formik.errors.floorCondition ||
            formik.errors.forkClearance ||
            formik.errors.outletHeightForPallet ||
            formik.errors.dockShelters ||
            formik.errors.ramps ||
            formik.errors.workInShifts ||
            formik.errors.branch
        ) {
            setSecondFormCollapseActiveKey(['1'])
        } else if (formik.errors.weight || formik.errors.loadCenter) {
            setSecondFormCollapseActiveKey(['2'])
        } else {
            setSecondFormCollapseActiveKey(['3'])
        }
    }

    const openNotValidatedFieldsStepThird = () => {
        if (
            formik.errors.batteryType ||
            formik.errors.eloOrHip ||
            formik.errors.maxWorkHour ||
            formik.errors.overBuildEquipmentOnSite
        ) {
            setThirdFormCollapseActiveKey(['1'])
        } else {
            setThirdFormCollapseActiveKey(['2'])
        }
    }

    const [machineSegmentOption, setMachineSegmentOption] = useState<Options[]>([])
    const [machineSeriesOption, setMachineSeriesOption] = useState<Options[]>([])
    const [machineSeriesList, setMachineSeriesList] = useState<any[]>([])
    const [machineModelOption, setMachineModelOption] = useState<Options[]>([])
    const [machineExtraFields, setMachineExtraFields] = useState<string[]>([])

    useQuery(
        ['getModelByConfigurationId', configurationId],
        () => SiteSurveyQueries.getModelFromConfigurationId(Number(configurationId)),
        {
            enabled: !!configurationId,
            onSuccess: data => {
                const values = data?.data?.data
                formik.setFieldValue('segment', values.segment)
                formik.setFieldValue('segmentId', values.segment_id)
                formik.setFieldValue('series', values.series)
                formik.setFieldValue('seriesId', values.series_id)
                formik.setFieldValue('model', values.model)
                formik.setFieldValue('modelId', values.model_id)
            },
        },
    )

    useQuery(
        ['getBatteryCapacityByModelId', formik.values.modelId],
        () => SiteSurveyQueries.getBatteryCapacityByModelId(formik.values.modelId),
        {
            enabled: !!formik.values.modelId,
            onSuccess: data => {
                setBatteryCapacityOptions(
                    data?.data.data.map((capacity: any) => ({ label: capacity, value: capacity })),
                )
            },
            onError: () => {
                toast.error('Someting went wrong while fetching model')
            },
        },
    )

    useEffect(() => {
        if (segmentData?.data) {
            setMachineSegmentOption(
                segmentData?.data.map((segment: any) => ({
                    label: segment.name,
                    value: segment.id,
                })),
            )
        }
    }, [segmentData?.data])

    useEffect(() => {
        const tempSegment = segmentData?.data.find(
            (segment: any) => String(segment.id) === String(formik.values.segmentId),
        )
        if (tempSegment && tempSegment.series) {
            setMachineExtraFields(tempSegment.extra_field.split(','))
            setMachineSeriesOption(
                tempSegment.series.length > 0
                    ? tempSegment.series.map((series: any) => ({
                          label: series.name,
                          value: series.id,
                      }))
                    : [],
            )
            setMachineSeriesList(tempSegment.series)
        }
    }, [formik.values.segmentId, segmentData])

    useEffect(() => {
        const tempSeries = machineSeriesList.find(
            (series: any) => String(series.id) === String(formik.values.seriesId),
        )
        if (tempSeries && tempSeries.models) {
            setDrive(tempSeries.drive)
            setMachineModelOption(
                tempSeries.models.length > 0
                    ? tempSeries.models.map((series: any) => ({
                          label: series.name,
                          value: series.id,
                      }))
                    : [],
            )
        }
    }, [formik.values.seriesId, machineSeriesList])

    useEffect(() => {
        const series = machineSeriesList.find((s: any) => s.id === Number(formik.values.seriesId))
        if (series) {
            checkIfPmPlusAvailable.mutate(series.code)
        }
    }, [formik.values.seriesId, machineSeriesList])

    const { isLoading: siteSurveyLoading } = useQuery(
        ['getSiteSurveyById'],
        () =>
            SiteSurveyQueries.getSiteSurveyById(
                parseInt(String(siteSurveyId || copySiteSurveyId), 10),
            ),
        {
            enabled: !!(siteSurveyId || copySiteSurveyId),
            onError: () => {
                toast.error(t('message.something_went_wrong_try_again'))
            },
            onSuccess: data => {
                const fetchedSiteSurveyData = data.data
                if (siteSurveyId) {
                    formik.setFieldValue('date', fetchedSiteSurveyData.date)
                    formik.setFieldValue('comment', fetchedSiteSurveyData.comment)
                }
                if (siteSurveyId || copySiteSurveyId) {
                    formik.setFieldValue('customer', fetchedSiteSurveyData.customer)
                    formik.setFieldValue('contactPerson', fetchedSiteSurveyData.contact_person)
                    formik.setFieldValue('uploadedImages', fetchedSiteSurveyData.images)
                }
                if (siteSurveyId || (copySiteSurveyId && surrounding)) {
                    formik.setFieldValue('floor', fetchedSiteSurveyData.floor)
                    formik.setFieldValue('floorCondition', fetchedSiteSurveyData.floor_condition)
                    formik.setFieldValue('indoorOrOutDoor', fetchedSiteSurveyData.indoor_or_outdoor)
                    formik.setFieldValue('forkClearance', fetchedSiteSurveyData.fork_clearance)
                    formik.setFieldValue(
                        'outletHeightForPallet',
                        fetchedSiteSurveyData.outlet_height_for_pallet,
                    )
                    formik.setFieldValue(
                        'locationAndDepartment',
                        fetchedSiteSurveyData.location_and_department,
                    )
                    formik.setFieldValue('branch', fetchedSiteSurveyData.branch)
                    formik.setFieldValue('branchOther', fetchedSiteSurveyData.branchOther)
                    formik.setFieldValue('corridorWidth', fetchedSiteSurveyData.corridor_width)
                    formik.setFieldValue('dockShelters', fetchedSiteSurveyData.dock_shelters)
                    formik.setFieldValue('ramps', fetchedSiteSurveyData.ramps)
                    formik.setFieldValue('runningHours', fetchedSiteSurveyData.running_hours)
                    formik.setFieldValue('seasonalCrowds', fetchedSiteSurveyData.seasonal_crowds)
                    formik.setFieldValue('workInShifts', fetchedSiteSurveyData.work_in_shifts)
                    formik.setFieldValue(
                        'surroundingAdditionalInformation',
                        fetchedSiteSurveyData.surrounding_additional_information,
                    )
                }
                if (siteSurveyId || (copySiteSurveyId && load)) {
                    if (fetchedSiteSurveyData.pallet_size) {
                        formik.setFieldValue(
                            'palletSize',
                            palletSizeOptions.find(
                                pallet =>
                                    pallet.value?.toString().toLowerCase() ===
                                    fetchedSiteSurveyData.pallet_size.toLowerCase(),
                            )
                                ? fetchedSiteSurveyData.pallet_size
                                : 'other',
                        )
                        formik.setFieldValue(
                            'palletSizeOther',
                            palletSizeOptions.find(
                                pallet =>
                                    pallet.value?.toString().toLowerCase() ===
                                    fetchedSiteSurveyData.pallet_size.toLowerCase(),
                            )
                                ? ''
                                : fetchedSiteSurveyData.pallet_size,
                        )
                    }
                    formik.setFieldValue('loadDimension', fetchedSiteSurveyData.load_dimension)
                    formik.setFieldValue('material', fetchedSiteSurveyData.material)
                    formik.setFieldValue('weight', fetchedSiteSurveyData.weight)
                    formik.setFieldValue('loadCenter', fetchedSiteSurveyData.load_center)
                    formik.setFieldValue(
                        'overBuildEquipmentOnSite',
                        Boolean(fetchedSiteSurveyData.overBuildEquipmentOnSite),
                    )
                    formik.setFieldValue(
                        'overBuildEquipmentOnSiteRemark',
                        fetchedSiteSurveyData.overBuildEquipmentOnSiteRemark,
                    )
                    formik.setFieldValue('stackingPackage', fetchedSiteSurveyData.stacking_package)
                    formik.setFieldValue(
                        'loadAdditionalInformation',
                        fetchedSiteSurveyData.load_additional_information,
                    )
                }
                if (siteSurveyId || (copySiteSurveyId && truckModel)) {
                    formik.setFieldValue('segment', fetchedSiteSurveyData.segments.name)
                    formik.setFieldValue('segmentId', fetchedSiteSurveyData.segment_id)
                    formik.setFieldValue('series', fetchedSiteSurveyData.series.name)
                    formik.setFieldValue('seriesId', fetchedSiteSurveyData.serie_id)
                    formik.setFieldValue('model', fetchedSiteSurveyData.models.name)
                    formik.setFieldValue('modelId', fetchedSiteSurveyData.model_id)
                    formik.setFieldValue('fuel', fetchedSiteSurveyData.fuel)
                    formik.setFieldValue('coupling', fetchedSiteSurveyData.coupling)
                    formik.setFieldValue('tank', fetchedSiteSurveyData.tank)
                    formik.setFieldValue('batteryBrand', fetchedSiteSurveyData.battery_brand)
                    formik.setFieldValue('batteryType', fetchedSiteSurveyData.battery_type)
                    formik.setFieldValue('batteryCapacity', fetchedSiteSurveyData.battery_capacity)
                    formik.setFieldValue('voltage', fetchedSiteSurveyData.voltage)
                    formik.setFieldValue(
                        'centralFillingSystem',
                        fetchedSiteSurveyData.central_filling_system,
                    )
                    formik.setFieldValue('chargingTime', fetchedSiteSurveyData.charging_time)
                    formik.setFieldValue('plugType', fetchedSiteSurveyData.plug_type)
                    // formik.setFieldValue('forkPositioner', fetchedSiteSurveyData.fork_positioner)
                    formik.setFieldValue('forkDimension', fetchedSiteSurveyData.fork_dimension)
                    formik.setFieldValue(
                        'measureOverForks',
                        fetchedSiteSurveyData.measure_over_forks,
                    )
                    formik.setFieldValue(
                        'measureBetweenForks',
                        fetchedSiteSurveyData.measure_between_forks,
                    )
                    formik.setFieldValue(
                        'workingEnvironmentTemperature',
                        fetchedSiteSurveyData.working_environment_temperature,
                    )
                    formik.setFieldValue('eloOrHip', fetchedSiteSurveyData.elo_or_hip)
                    formik.setFieldValue('forkWheels', fetchedSiteSurveyData.fork_wheels)
                    formik.setFieldValue('heavyClamp', fetchedSiteSurveyData.heavy_clamp)
                    if (fetchedSiteSurveyData.pallet_size) {
                        formik.setFieldValue(
                            'attachments',
                            attachmentOption.find(
                                attachment =>
                                    attachment.value?.toString().toLowerCase() ===
                                    String(fetchedSiteSurveyData.attachments).toLowerCase(),
                            )
                                ? fetchedSiteSurveyData.attachments
                                : 'other',
                        )
                        formik.setFieldValue(
                            'attachmentsOther',
                            attachmentOption.find(
                                attachment =>
                                    attachment.value?.toString().toLowerCase() ===
                                    String(fetchedSiteSurveyData.attachments).toLowerCase(),
                            )
                                ? ''
                                : fetchedSiteSurveyData.attachments,
                        )
                    }
                    formik.setFieldValue('attachments', fetchedSiteSurveyData.attachments)
                    formik.setFieldValue('loadRack', fetchedSiteSurveyData.load_rack)
                    formik.setFieldValue('lflOrFfl', fetchedSiteSurveyData.lfl_or_ffl)
                    formik.setFieldValue('platformHeight', fetchedSiteSurveyData.platform_height)
                    formik.setFieldValue(
                        'inductanceOrRailConduction',
                        fetchedSiteSurveyData.inductance_or_railconduction,
                    )
                    formik.setFieldValue('control', fetchedSiteSurveyData.control)
                    formik.setFieldValue('steering', fetchedSiteSurveyData.steering)
                    formik.setFieldValue('roofPlate', fetchedSiteSurveyData.roof_plate)
                    formik.setFieldValue('cabin', fetchedSiteSurveyData.cabin)
                    formik.setFieldValue(
                        'heatingOrAirCondition',
                        fetchedSiteSurveyData.heating_or_air_condition,
                    )
                    formik.setFieldValue('mirrors', fetchedSiteSurveyData.mirrors)
                    formik.setFieldValue('radio', fetchedSiteSurveyData.radio)
                    formik.setFieldValue('safety', fetchedSiteSurveyData.safety)
                    formik.setFieldValue('lighting', fetchedSiteSurveyData.lighting)
                    formik.setFieldValue('publicRoad', fetchedSiteSurveyData.public_road)
                    formik.setFieldValue('drivingLight', fetchedSiteSurveyData.driving_light)
                    formik.setFieldValue('flashingLight', fetchedSiteSurveyData.flashing_light)
                    formik.setFieldValue('pal', fetchedSiteSurveyData.pal)
                    formik.setFieldValue('type_banden', fetchedSiteSurveyData.type_banden)
                    formik.setFieldValue('maxWorkHour', fetchedSiteSurveyData.max_work_hour)
                    formik.setFieldValue(
                        'drivingDirection',
                        fetchedSiteSurveyData.driving_direction,
                    )
                    formik.setFieldValue(
                        'theoreticalRunningHour',
                        fetchedSiteSurveyData.theoretical_running_hour,
                    )
                    formik.setFieldValue('mast', fetchedSiteSurveyData.mast)
                    formik.setFieldValue('hysterTracker', fetchedSiteSurveyData.hyster_tracker)
                    formik.setFieldValue(
                        'truckModelAdditionalInformation',
                        fetchedSiteSurveyData.truck_model_additional_information,
                    )
                }
                if (siteSurveyId || (copySiteSurveyId && offerOptions)) {
                    formik.setFieldValue('financing', fetchedSiteSurveyData.financing)
                    formik.setFieldValue(
                        'alternativeFinancing',
                        fetchedSiteSurveyData.alternative_financing,
                    )
                    formik.setFieldValue('maintenance', fetchedSiteSurveyData.maintenance)
                    formik.setFieldValue(
                        'showAllPricesSeparate',
                        fetchedSiteSurveyData.show_all_prices_separate,
                    )
                    formik.setFieldValue('insurance', fetchedSiteSurveyData.insurance)
                    formik.setFieldValue('return', fetchedSiteSurveyData.return)
                    formik.setFieldValue(
                        'replacementMachine',
                        fetchedSiteSurveyData.replacement_machine,
                    )
                    formik.setFieldValue(
                        'durationOfContract',
                        fetchedSiteSurveyData.duration_of_contract,
                    )
                    formik.setFieldValue(
                        'offerAdditionalInformation',
                        fetchedSiteSurveyData.offer_options_additional_information,
                    )
                }
                if (siteSurveyId || (copySiteSurveyId && copyOnlyRequiredFields)) {
                    formik.setFieldValue('customer', fetchedSiteSurveyData.customer)
                    formik.setFieldValue('contactPerson', fetchedSiteSurveyData.contact_person)
                    formik.setFieldValue(
                        'locationAndDepartment',
                        fetchedSiteSurveyData.location_and_department,
                    )
                    formik.setFieldValue('branch', fetchedSiteSurveyData.branch)
                    formik.setFieldValue('floor', fetchedSiteSurveyData.floor)
                    formik.setFieldValue('floorCondition', fetchedSiteSurveyData.floor_condition)
                    formik.setFieldValue('forkClearance', fetchedSiteSurveyData.fork_clearance)
                    formik.setFieldValue(
                        'outletHeightForPallet',
                        fetchedSiteSurveyData.outlet_height_for_pallet,
                    )
                    formik.setFieldValue('dockShelters', fetchedSiteSurveyData.dock_shelters)
                    formik.setFieldValue('ramps', fetchedSiteSurveyData.ramps)
                    formik.setFieldValue('runningHours', fetchedSiteSurveyData.running_hours)
                    formik.setFieldValue('workInShifts', fetchedSiteSurveyData.work_in_shifts)
                    formik.setFieldValue('weight', fetchedSiteSurveyData.weight)
                    formik.setFieldValue('loadCenter', fetchedSiteSurveyData.load_center)
                    formik.setFieldValue('segment', fetchedSiteSurveyData.segments.name)
                    formik.setFieldValue('segmentId', fetchedSiteSurveyData.segment_id)
                    formik.setFieldValue('series', fetchedSiteSurveyData.series.name)
                    formik.setFieldValue('seriesId', fetchedSiteSurveyData.serie_id)
                    formik.setFieldValue('models', fetchedSiteSurveyData.models.name)
                    formik.setFieldValue('modelId', fetchedSiteSurveyData.model_id)
                    formik.setFieldValue('batteryType', fetchedSiteSurveyData.battery_type)
                    formik.setFieldValue('batteryCapacity', fetchedSiteSurveyData.battery_capacity)
                    formik.setFieldValue('eloOrHip', fetchedSiteSurveyData.elo_or_hip)
                    formik.setFieldValue('maxWorkHour', fetchedSiteSurveyData.max_work_hour)
                    formik.setFieldValue(
                        'overBuildEquipmentOnSite',
                        fetchedSiteSurveyData.overbuild_equipment_on_site,
                    )
                    formik.setFieldValue('financing', fetchedSiteSurveyData.financing)
                    formik.setFieldValue('maintenance', fetchedSiteSurveyData.maintenance)
                    formik.setFieldValue(
                        'durationOfContract',
                        fetchedSiteSurveyData.duration_of_contract,
                    )
                }
            },
        },
    )

    const steppers: StepType[] = [
        {
            id: 0,
            step: 0,
            label: t('api.site_survey_info'),
            component: <SiteSurveyInformation formik={formik} />,
        },
        {
            id: 1,
            step: 1,
            label: t('api.machine_description'),
            component: (
                <MachineDescriptions
                    formik={formik}
                    setIsPmPlusAvailable={setIsPmPlusAvailable}
                    machineSegmentOption={machineSegmentOption}
                    machineSeriesOption={machineSeriesOption}
                    machineModelOption={machineModelOption}
                    showNonRequiredFields={!hideNonRequiredFields}
                    secondFormCollapseActiveKey={secondFormCollapseActiveKey}
                    setSecondFormCollapseActiveKey={setSecondFormCollapseActiveKey}
                    machineSeriesList={machineSeriesList}
                />
            ),
        },
        {
            id: 2,
            step: 2,
            label: t('api.truck_specification_and_lease_purchase'),
            component: (
                <TruckSpecification
                    isPmPlusAvailable={isPmPlusAvailable}
                    drive={drive}
                    formik={formik}
                    batteryCapacityOptions={batteryCapacityOptions}
                    machineExtraFields={machineExtraFields}
                    showNonRequiredFields={!hideNonRequiredFields}
                    setThirdFormCollapseActiveKey={setThirdFormCollapseActiveKey}
                    thirdFormCollapseActiveKey={thirdFormCollapseActiveKey}
                />
            ),
        },
        {
            id: 3,
            step: 3,
            label: t('api.site_survey_overview'),
            component: (
                <SiteSurveyOverview
                    drive={drive}
                    formik={formik}
                    batteryCapacityOption={batteryCapacityOptions}
                    theoriticalMaxWorkHour={theoriticalMaxWorkHour || 0}
                    calculatedCategory={calculatedCategory || ''}
                />
            ),
        },
        {
            id: 4,
            step: 4,
            label: t('api.image_upload'),
            component: <ImageUploadSiteSurvey formik={formik} />,
        },
    ]

    const cardClass = 'h-full w-full bg-white p-4 rounded-md flex-1'

    return (
        <div className={cardClass}>
            <div className="bg-white h-full rounded-xl flex flex-col gap-4">
                <div className="flex w-full justify-between items-center">
                    <Stepper steppers={steppers} activeStep={steppers[step]} />
                </div>
                <div className="h-full overflow-auto flex flex-col justify-between">
                    <div className="p-4 border border-gray rounded-md overflow-auto">
                        {siteSurveyLoading || isSegmentLoading ? (
                            <div className="h-full w-full flex justify-center items-center">
                                <Loading />
                            </div>
                        ) : (
                            steppers[step]?.component || <p>{t('message.error_stepper')}</p>
                        )}
                    </div>
                    <div
                        className={`flex mt-4 ${
                            step > 0 ? 'justify-between' : 'justify-end'
                        } align-center flex-wrap md:flex-nowrap`}
                    >
                        {step > 0 && (
                            <div className="md:w-48">
                                <Button
                                    label={t('api.back')}
                                    variant="primary"
                                    onClick={() => setStep(step - 1)}
                                />
                            </div>
                        )}
                        <div className="flex justify-end gap-8 flex-wrap md:flex-nowrap">
                            <div className="md:w-48">
                                <Button
                                    label={t('api.cancel')}
                                    variant="ghost"
                                    onClick={() => {
                                        navigate(-1)
                                    }}
                                />
                            </div>
                            {step !== 4 ? (
                                <div className="md:w-48">
                                    <Button
                                        label={t('api.next')}
                                        isLoading={
                                            createSiteSurveyForm.isLoading ||
                                            updateSiteSurveyForm.isLoading
                                        }
                                        onClick={() => {
                                            formik.handleSubmit()
                                            if (step === 1) {
                                                openNotValidatedFields()
                                            }
                                            if (step === 2) {
                                                openNotValidatedFieldsStepThird()
                                            }
                                        }}
                                        variant="outline"
                                    />
                                </div>
                            ) : (
                                <div className="md:w-48">
                                    <Button
                                        label={t('api.submit')}
                                        isLoading={uploadImageForSiteSurvey.isLoading}
                                        onClick={() => formik.handleSubmit()}
                                        variant="outline"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddSiteSurvey
