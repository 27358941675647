import { callAxios } from 'plugins/api'

const getRentalContract = (quoteId: string) =>
    callAxios({
        url: `rentalContract/${quoteId}`,
        method: 'GET',
        isHeffiq: true,
    })

const updateOrCreateRentalContract = (quoteId: string, payload: any) =>
    callAxios({
        url: `rentalContract/${quoteId}`,
        method: 'POST',
        data: payload,
        isHeffiq: true,
    })

export const RentalContractQueries = {
    getRentalContract,
    updateOrCreateRentalContract,
}
