import FileUpload from 'components/FileUpload'
import Button from 'components/button'
import Loading from 'components/loading'
import Table from 'components/table'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { BiTrash } from 'react-icons/bi'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import DeleteModal from '../../../components/modal/deleteModal'
import { errorToast, successToast } from '../../../utils/toasterUtil'
import {
    downloadImage,
    removeQuoteDocument,
    saveQuoteDocument,
    showQuoteDocuments,
} from '../queries/quotes'

const UploadDocument = () => {
    const { quoteId } = useParams()
    const { t } = useTranslation()
    const [deleteModal, setDeleteModal] = useState<boolean>(false)

    const handleModal = () => {
        setDeleteModal(!deleteModal)
    }

    const {
        data: quoteDocuments,
        isLoading,
        isFetching,
        refetch,
    } = useQuery(['getQuoteDocuments'], () => showQuoteDocuments(String(quoteId)))

    const addQuoteDocuments = useMutation(
        ['addQuoteDocuments'],
        (params: { quoteId: any; data: any }) => {
            return saveQuoteDocument(params.quoteId, params.data)
        },
        {
            onSuccess: (data: any) => {
                refetch()
                toast.success(data?.message || t('message.add_success'), successToast)
            },
            onError: (error: any) => {
                toast(error?.message || t('message.fail'), errorToast)
            },
        },
    )

    const deleteQuoteDocument = useMutation(
        ['removeQuoteDocument'],
        (params: { quoteId: any; id: any }) => {
            return removeQuoteDocument(params.quoteId, params.id)
        },
        {
            onSuccess: (data: any) => {
                refetch()
                toast.success(data?.message || t('message.remove_success'), successToast)
            },
            onError: (error: any) => {
                toast(error?.message || t('message.fail'), errorToast)
            },
        },
    )

    const uploadFileFormik = useFormik<any>({
        initialValues: {
            files: [],
        },
        validationSchema: Yup.object().shape({
            files: Yup.array().min(1, t('message.upload_atleast_one')),
        }),
        onSubmit: async (values, { resetForm }) => {
            const fd = new FormData()
            values.files.forEach((file: File) => {
                fd.append('files[]', file)
            })

            await addQuoteDocuments.mutate({
                quoteId,
                data: fd,
            })

            resetForm()
        },
    })

    const columns = [
        {
            name: t('api.sn'),
            left: true,
            cell: (row: any, index: number) => (
                <h1 className="text-black text-semibold text-base">{index + 1 || 'unknown'}</h1>
            ),
            sortable: true,
            width: '100px',
        },
        {
            name: t('api.file_name'),
            left: true,
            cell: (row: any) => (
                <div className="flex items-center text-md">
                    <p>{row?.name || ''}</p>
                </div>
            ),
            sortable: true,
        },
        {
            name: t('api.action'),
            left: true,
            width: '100px',
            cell: (row: any) => (
                <div className="flex items-between text-md font-bold cursor-pointer">
                    <span
                        onClick={() => downloadImage(row)}
                        className="hover:text-yellow-400 pr-2"
                        title={t('api.download_file')}
                    >
                        <AiOutlineCloudDownload size={20} />
                    </span>
                    <span
                        onClick={() => {
                            handleModal()
                        }}
                        className="hover:text-red-400"
                        title={t('api.delete')}
                    >
                        <BiTrash size={20} />
                    </span>
                    <DeleteModal
                        modal={deleteModal}
                        closeModal={() => handleModal()}
                        onConfirm={() =>
                            deleteQuoteDocument.mutate({ quoteId: row.quote_id, id: row.id })
                        }
                    />
                </div>
            ),
            sortable: true,
        },
    ]
    if (isLoading || isFetching) {
        return (
            <div className="flex justify-center w-full">
                <Loading />
            </div>
        )
    }
    return (
        <div className="flex flex-col gap-4">
            <p className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                {String(t(`api.quote_configuration`))}
            </p>
            <div className="flex flex-col items-end gap-4">
                <FileUpload
                    files={uploadFileFormik.values.files}
                    setFiles={files => uploadFileFormik.setFieldValue('files', files)}
                    max={3}
                    error={
                        uploadFileFormik.errors.files && uploadFileFormik.touched.files
                            ? String(uploadFileFormik.errors.files)
                            : ''
                    }
                />
                <div>
                    <Button
                        id="file_submit"
                        label={t('api.upload_files')}
                        variant="outline"
                        type="button"
                        isLoading={addQuoteDocuments.isLoading || deleteQuoteDocument.isLoading}
                        onClick={() => uploadFileFormik.handleSubmit()}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                data={quoteDocuments?.data || []}
                striped
                pagination
                totalRows={25}
                tableHeight="90%"
            />
        </div>
    )
}

export default UploadDocument
