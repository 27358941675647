import { useTranslation } from 'react-i18next'
import { MdOutlineCheck } from 'react-icons/md'
import { getCurrencySymbol } from 'utils/currency/formatCurrency'
import { addThousandSeparator } from 'utils/formatNumber'
import { omit } from 'lodash'

interface IServiceOverviewTable {
    runningHours: number
    residualValue: any
    battery: boolean
    attachment: boolean
    tires: boolean
    specials: boolean
    exSolution: boolean
    maintenance: string
    selectedDurationOfContract?: number
    overview?: boolean
}

const ServiceOverviewTable = (props: IServiceOverviewTable) => {
    const {
        residualValue,
        battery,
        attachment,
        tires,
        specials,
        maintenance,
        exSolution,
        runningHours,
        selectedDurationOfContract,
        overview,
    } = props
    const { t } = useTranslation()
    // service all in
    const serviceAllIn = Object.keys(omit(residualValue[0].serviceAllIn, 'basic_truck_gross_price'))
    // service pm
    const servicePm = Object.keys(residualValue[0].servicePm)
    const currencySymbol = getCurrencySymbol()

    return (
        <div
            className={` ${
                overview ? 'w-full bg-slate-100 p-2 gap-2  mt-2' : 'flex flex-col w-fit'
            }`}
        >
            <table className="border border-gray w-full">
                <thead>
                    <tr>
                        <th className="border border-gray text-left w-64">{t('api.period')}</th>
                        {residualValue.map((value: any, index: number) => (
                            <th className="w-36 border border-gray text-right" key={index}>
                                {value.durationOfContract} {t('api.lease_months')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-left border border-gray">
                            {t('api.selected_duration_of_contract')}
                        </td>
                        {residualValue.map((value: any, index: number) => (
                            <td className="text-right border border-gray" key={index}>
                                {selectedDurationOfContract === value.durationOfContract && (
                                    <div className="flex justify-end">
                                        <MdOutlineCheck />
                                    </div>
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="text-left border border-gray">{t('api.financial_part')}</td>
                        {residualValue.map((value: any, index: number) => (
                            <td className="text-right border border-gray" key={index}>
                                {currencySymbol}{' '}
                                {addThousandSeparator(Number(value.financialPart.toFixed(2)))}
                            </td>
                        ))}
                    </tr>
                    {(maintenance === 'ALL_IN' ||
                        maintenance === 'PREVENTIVE_MAINTENANCE_PLUS' ||
                        maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE') && (
                        <>
                            <tr>
                                <td className="text-left font-bold border border-gray">
                                    {maintenance === 'PREVENTIVE_MAINTENANCE_PLUS'
                                        ? t('api.service_pm_plus')
                                        : t('api.service_all_in')}
                                </td>
                            </tr>
                            {serviceAllIn.map((service, index: number) => {
                                if (
                                    (service === 'service_extra_attachments' && !attachment) ||
                                    (service === 'service_tyre' && !tires) ||
                                    (service === 'service_extra_special' && !specials) ||
                                    (service === 'service_battery' && !battery) ||
                                    (service === 'service_ex_solutions' && !exSolution)
                                ) {
                                    return null
                                }
                                return (
                                    <tr key={index}>
                                        <td
                                            className={`border border-gray ${
                                                service === 'total_service'
                                                    ? 'text-left font-bold'
                                                    : 'text-right'
                                            }`}
                                        >
                                            {t(`api.${service}`)}
                                        </td>
                                        {residualValue.map((value: any, i: number) => {
                                            return (
                                                <td
                                                    key={i}
                                                    className="text-right border border-gray"
                                                >
                                                    {currencySymbol}{' '}
                                                    {addThousandSeparator(
                                                        Number(
                                                            Number(
                                                                value.serviceAllIn[service],
                                                            ).toFixed(2),
                                                        ),
                                                    )}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </>
                    )}
                    {(maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                        maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE') && (
                        <>
                            <tr>
                                <td className="text-left border font-bold border-gray">
                                    {t('api.service_pm')}
                                </td>
                            </tr>
                            {servicePm.map(
                                (service, index: number) =>
                                    service !== 'preventive_maintenance' &&
                                    service !== 'batteries_service_cost' &&
                                    service !== 'bmwt_battery' &&
                                    service !== 'total_price_per_year_ex_bmwt' &&
                                    service !== 'total_price_per_year_inclusive_bmwt' && (
                                        <tr key={index}>
                                            <td className="text-left border capitalize text-right border-gray">
                                                {service
                                                    .replaceAll('_', ' ')
                                                    .replace('Service', '')}
                                            </td>
                                            {residualValue.map((value: any, i: number) => {
                                                return (
                                                    <td
                                                        key={i}
                                                        className="text-right border border-gray"
                                                    >
                                                        {currencySymbol}{' '}
                                                        {value?.servicePm
                                                            ? addThousandSeparator(
                                                                  Number(
                                                                      parseFloat(
                                                                          String(
                                                                              value.servicePm[
                                                                                  service
                                                                              ],
                                                                          ),
                                                                      )?.toFixed(2),
                                                                  ),
                                                              )
                                                            : 0}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    ),
                            )}
                            <tr>
                                <td className="text-left border font-bold border-gray">
                                    {t('api.total_service')}
                                </td>
                                {residualValue.map((value: any, i: number) => {
                                    const totalPm = parseFloat(
                                        value.servicePm.batteries_service_cost,
                                    )
                                    return (
                                        <td
                                            key={i}
                                            className="text-right font-bold border border-gray"
                                        >
                                            {currencySymbol}{' '}
                                            {addThousandSeparator(
                                                Number(parseFloat(String(totalPm))?.toFixed(2)),
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        </>
                    )}
                    {/* <tr>
                <td className="text-right border border-gray">{t('api.battery')}</td>
                {residualValue.map((value: any, index: number) => (
                    <td key={index} className="text-center border border-gray">
                        {battery ? t('api.yes') : t('api.no')}
                    </td>
                ))}
            </tr>
            <tr>
                <td className="text-right border border-gray">{t('api.attachments')}</td>
                {residualValue.map((value: any, index: number) => (
                    <td key={index} className="text-center border border-gray">
                        {attachment ? t('api.yes') : t('api.no')}
                    </td>
                ))}
            </tr>
            <tr>
                <td className="text-right border border-gray">{t('api.tires')}</td>
                {residualValue.map((value: any, index: number) => (
                    <td key={index} className="text-center border border-gray">
                        {tires ? t('api.yes') : t('api.no')}
                    </td>
                ))}
            </tr>
            <tr>
                <td className="text-right border border-gray">{t('api.specials')}</td>
                {residualValue.map((value: any, index: number) => (
                    <td key={index} className="text-center border border-gray">
                        {specials ? t('api.yes') : t('api.no')}
                    </td>
                ))}
            </tr> */}
                    {/* <tr>
                <td className="text-left font-bold border border-gray">{t('api.total_service')}</td>
                {residualValue.map((value: any, index: number) => (
                    <td key={index} className="text-center border border-gray">
                        {value.serviceAllIn.total_service}
                    </td>
                ))}
            </tr> */}
                    <tr>
                        <td className="text-left font-bold border border-gray">
                            {t('api.insurance_cost')}
                        </td>
                        {residualValue.map((value: any, index: number) => (
                            <td key={index} className="text-right font-bold border border-gray">
                                {currencySymbol}{' '}
                                {addThousandSeparator(Number(value.insuranceCost.toFixed(2) || 0))}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td className="text-left font-bold border border-gray">
                            {t('api.total_all')}
                        </td>
                        {residualValue.map((value: any, index: number) => {
                            const totalAllIn = value.serviceAllIn.total_service
                            const totalPm = parseFloat(value.servicePm.batteries_service_cost)
                            const serviceCost =
                                maintenance === 'PREVENTIVE_MAINTAINENCE' ? totalPm : totalAllIn
                            const totalCost =
                                parseFloat(serviceCost) +
                                parseFloat(value.insuranceCost) +
                                parseFloat(value.financialPart)

                            return (
                                <td key={index} className="text-right font-bold border border-gray">
                                    {currencySymbol} {parseFloat(String(totalCost))?.toFixed(2)}
                                </td>
                            )
                        })}
                    </tr>
                    {(maintenance === 'ALL_IN' ||
                        maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE') && (
                        <tr>
                            <td className="text-left font-bold border border-gray">
                                {t('api.basic_service_gross_price_per_hour')}
                            </td>
                            {/* formulae for basic service per hour = basic_truck * 12 / running hours */}
                            {residualValue.map((value: any, i: number) => {
                                return (
                                    <td key={i} className="text-right border border-gray">
                                        {currencySymbol}{' '}
                                        {runningHours
                                            ? addThousandSeparator(
                                                  Number(
                                                      parseFloat(
                                                          String(
                                                              Number(
                                                                  (parseFloat(
                                                                      value.serviceAllIn
                                                                          .basic_truck_gross_price,
                                                                  ) *
                                                                      12) /
                                                                      runningHours,
                                                              ) || 0,
                                                          ),
                                                      ).toFixed(2),
                                                  ),
                                              )
                                            : t('message.please_set_running_hours')}
                                    </td>
                                )
                            })}
                        </tr>
                    )}
                    {(maintenance === 'ALL_IN' ||
                        maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE') && (
                        <tr>
                            <td className="text-left font-bold border border-gray">
                                {t('api.basic_service_per_hour')}
                            </td>
                            {/* formulae for basic service per hour = basic_truck * 12 / running hours */}
                            {residualValue.map((value: any, i: number) => {
                                return (
                                    <td key={i} className="text-right border border-gray">
                                        {currencySymbol}{' '}
                                        {runningHours
                                            ? addThousandSeparator(
                                                  Number(
                                                      parseFloat(
                                                          String(
                                                              (parseFloat(
                                                                  value.serviceAllIn.basic_truck,
                                                              ) *
                                                                  12) /
                                                                  runningHours,
                                                          ),
                                                      ).toFixed(2),
                                                  ),
                                              )
                                            : t('message.please_set_running_hours')}
                                    </td>
                                )
                            })}
                        </tr>
                    )}
                </tbody>
            </table>
            {(maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE') && (
                <>
                    <p className="font-bold text-sm mt-4">{t('api.yearly_maintenance_cost')}</p>
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="border border-gray text-left w-64">
                                    {t('api.period')}
                                </th>
                                {residualValue.map((value: any, index: number) => (
                                    <th className="w-36 border border-gray text-right" key={index}>
                                        {value.durationOfContract} {t('api.lease_months')}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {servicePm.map(
                                (service, index: number) =>
                                    (service === 'preventive_maintenance' ||
                                        service === 'bmwt_battery' ||
                                        service === 'batteries_service_cost' ||
                                        service === 'total_price_per_year_inclusive_bmwt') && (
                                        <tr key={index}>
                                            <td className="border text-right border-gray">
                                                {t(`api.${service}`)}
                                            </td>
                                            {residualValue.map((value: any, i: number) => {
                                                return (
                                                    <td
                                                        key={i}
                                                        className="text-right border border-gray"
                                                    >
                                                        {currencySymbol}{' '}
                                                        {service ===
                                                        'total_price_per_year_inclusive_bmwt'
                                                            ? value?.servicePm
                                                                ? addThousandSeparator(
                                                                      Number(
                                                                          parseFloat(
                                                                              String(
                                                                                  value.servicePm[
                                                                                      service
                                                                                  ] -
                                                                                      Number(
                                                                                          value
                                                                                              .servicePm
                                                                                              .total_price_per_year_ex_bmwt,
                                                                                      ),
                                                                              ),
                                                                          )?.toFixed(2),
                                                                      ),
                                                                  )
                                                                : 0
                                                            : value?.servicePm
                                                            ? addThousandSeparator(
                                                                  Number(
                                                                      parseFloat(
                                                                          String(
                                                                              value.servicePm[
                                                                                  service
                                                                              ],
                                                                          ),
                                                                      )?.toFixed(2),
                                                                  ),
                                                              )
                                                            : 0}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    ),
                            )}
                            <tr>
                                <td className="text-left font-bold border border-gray">
                                    {t('api.total_all')}
                                </td>
                                {residualValue.map((value: any, index: number) => {
                                    const bmwtBattery = Number(value?.servicePm?.bmwt_battery || 0)
                                    const totalPricePerYearInclusiveBmwt = parseFloat(
                                        value.servicePm.total_price_per_year_inclusive_bmwt,
                                    )
                                    const totalCost = Number(
                                        totalPricePerYearInclusiveBmwt + bmwtBattery,
                                    )
                                    const numberOfJobs = Number(value?.servicePm?.number_of_jobs)
                                    return (
                                        <td
                                            key={index}
                                            className="text-right font-bold border border-gray"
                                        >
                                            {currencySymbol}{' '}
                                            {addThousandSeparator(
                                                Number(parseFloat(String(totalCost))?.toFixed(2)),
                                            )}{' '}
                                            {numberOfJobs > 1
                                                ? `(${numberOfJobs} ${t('api.jobs')})`
                                                : ''}
                                        </td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </table>
                </>
            )}
        </div>
    )
}

export default ServiceOverviewTable
