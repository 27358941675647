import { TextEditor } from 'components/TextEditor'
import Button from 'components/button'
import Loading from 'components/loading'
import { FormikProps, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import NonHysterFreeTextQueries from '../queries/nonHysterFreeText'

const NonHysterFreeText = () => {
    const { t } = useTranslation()
    const { configurationId } = useParams()

    const nonHysterFreeTextMutation = useMutation(
        ['addNonHysterFreeText'],
        (freeText: string) =>
            NonHysterFreeTextQueries.updateNonHysterFreeText({
                configurationId: Number(configurationId),
                freeText,
            }),
        {
            onSuccess: () => {
                toast.success(t('message.non_hyster_free_text_update_success'))
            },
            onError: (error: Error) => {
                toast.error(error?.message || t('message.non_hyster_free_text_update_failed'))
            },
        },
    )

    const formik: FormikProps<any> = useFormik({
        initialValues: {
            freeText: '',
        },
        validationSchema: Yup.object({
            freeText: Yup.string().required(t('api.free_text_required')),
        }),
        onSubmit: values => {
            nonHysterFreeTextMutation.mutate(values.freeText)
        },
    })

    const { isLoading } = useQuery(
        ['getNonHysterFreeText'],
        () => NonHysterFreeTextQueries.getNonHysterFreeText(Number(configurationId)),
        {
            onSuccess: (data: any) => {
                formik.setFieldValue('freeText', data?.data?.data || '')
            },
            onError: (error: Error) => {
                toast.error(error?.message || t('message.non_hyster_free_text_get_failed'))
            },
        },
    )

    if (isLoading || nonHysterFreeTextMutation.isLoading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="h-full w-full items-end flex flex-col gap-4">
            <div className="flex w-full justify-between items-center">
                <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                    {t('api.non_hyster_free_text')}
                </p>
            </div>
            <div className="flex w-full flex-col flex-1">
                <TextEditor
                    value={formik.values.freeText}
                    onChange={(value: string) => {
                        formik.setFieldValue('freeText', value)
                    }}
                    errorMessage={
                        formik.touched.freeText && formik.errors.freeText
                            ? String(formik.errors.freeText)
                            : ''
                    }
                />
            </div>
            <div className="w-1/5">
                <Button
                    variant="primary"
                    type="submit"
                    label={t('api.save')}
                    isLoading={false}
                    onClick={() => formik.handleSubmit()}
                />
            </div>
        </div>
    )
}

export default NonHysterFreeText
