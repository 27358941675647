/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/rules-of-hooks */
import { Tooltip } from 'antd'
import { extend, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import {
    getBatteryListByModel,
    getChargerListByModel,
    getPrepListByModel,
    getBatteryAccessoriesListByModel,
    exportChargerData,
    getHysterBatteryListByModel,
    getHysterPartNumber,
} from '../queries/quotes'

const imageUrl = `${process.env.REACT_APP_S3_IMAGE_URL}`

type ExtraItemHooksProps = {
    configurationId?: string
}

const extraItemHooks = ({ configurationId }: ExtraItemHooksProps) => {
    const { t } = useTranslation()

    const { data: chargerListData, refetch: chargerListFunc } = useQuery(
        ['getChargerListByModal'],
        () => getChargerListByModel(configurationId || ''),
        {
            enabled: false,
            refetchOnWindowFocus: false,
        },
    )

    const { data: batteryListData, refetch: batteryListFunc } = useQuery(
        ['getBatteryListByModal'],
        () => getBatteryListByModel(configurationId || ''),
        {
            enabled: false,
            refetchOnWindowFocus: false,
        },
    )

    const { data: prepListData, refetch: prepListFunc } = useQuery(
        ['getPrepListByModal'],
        () => getPrepListByModel(configurationId || ''),
        {
            enabled: false,
            refetchOnWindowFocus: false,
        },
    )

    const { data: batteryAccessoriesListData, refetch: batteryAccessoriesListFunc } = useQuery(
        ['getBatteryAccessoriesListByModal'],
        () => getBatteryAccessoriesListByModel(),
        {
            enabled: false,
            refetchOnWindowFocus: false,
        },
    )

    const { data: hysterBatteryList, refetch: hysterBatteryListFunc } = useQuery(
        ['getHysterBatteryListFunc'],
        () => getHysterBatteryListByModel(configurationId || ''),
        {
            enabled: false,
            refetchOnWindowFocus: false,
        },
    )

    // export function

    const extraItemsObject: any = {
        charger: {
            columns: [
                {
                    name: t('api.id'),
                    width: '5rem',
                    selector: (row: any) => row.id,
                },
                {
                    name: t('api.description'),
                    left: true,
                    wrap: true,
                    selector: (row: any) => row.description,
                },
                {
                    name: t('api.battery_type'),
                    center: true,
                    width: '12rem',
                    cell: (row: { battery_type: string }) => {
                        return <p>{row.battery_type}</p>
                    },
                },
                {
                    name: t('api.model'),
                    center: true,
                    omit: true,
                    cell: (row: { model: string; load_time: string; grid_power: string }) => {
                        const ChargerExtra = () => {
                            return (
                                <div className="h-[8vh] gap-4 flex flex-col justify-start items-start bg-white">
                                    <div className="flex items-center gap-3">
                                        <p>Grid</p>
                                        <p>{row.grid_power}</p>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <p>Grid</p>
                                        <p>{row.load_time}</p>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <Tooltip
                                title={<ChargerExtra />}
                                color="white"
                                className="cursor-pointer"
                            >
                                {row.model}
                            </Tooltip>
                        )
                    },
                },
                // {
                //     name: t('api.grid_power'),
                //     center: true,
                //     cell: (row: { grid_power: string }) => {
                //         return (
                //             <div>
                //                 <p>{row.grid_power}</p>
                //             </div>
                //         )
                //     },
                // },
                // {
                //     name: t('api.load_time'),
                //     center: true,
                //     cell: (row: { load_time: string }) => {
                //         return (
                //             <div>
                //                 <p>{row.load_time}</p>
                //             </div>
                //         )
                //     },
                // },
                {
                    name: t('api.plug_type'),
                    left: true,
                    cell: (row: { plug_type: string; plug_type_image_url: string[] }) => {
                        const PlugImage = () => {
                            return (
                                <div className="h-[8vh] bg-white">
                                    {!row?.plug_type_image_url ? (
                                        <div className="flex h-full justify-center items-center">
                                            <p className="text-primary">
                                                {t('api.no_image_available')}
                                            </p>
                                        </div>
                                    ) : (
                                        <img
                                            src={`${imageUrl}${
                                                (row?.plug_type_image_url &&
                                                    row?.plug_type_image_url[0]) ||
                                                ''
                                            }`}
                                            alt={row.plug_type}
                                        />
                                    )}
                                </div>
                            )
                        }
                        return (
                            <Tooltip title={<PlugImage />} color="white" className="cursor-pointer">
                                {row.plug_type}
                            </Tooltip>
                        )
                    },
                },
            ],
            data:
                map(chargerListData?.data?.data, item =>
                    extend(item, {
                        quantity: 0,
                        netTotal: item?.price * (item?.quantity || 0),
                    }),
                ) || [],
            listFunction: chargerListFunc,
            searchKeys: ['charger_brand', 'charger_type', 'grid_power', 'plug_type'],
            exportFunction: () => exportChargerData('charger'),
        },
        battery: {
            columns: [
                {
                    name: t('api.serie_code'),
                    left: true,
                    omit: true,
                    cell: (row: { serie_code: string }) => {
                        return (
                            <div>
                                <p>{row.serie_code}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.model'),
                    left: true,
                    omit: true,
                    cell: (row: { model: string }) => {
                        return (
                            <div>
                                <p>{row.model}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.id'),
                    width: '5rem',
                    cell: (row: { id: string }) => {
                        return (
                            <div>
                                <p>{row.id}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.description'),
                    left: true,
                    wrap: true,
                    selector: (row: any) => row.description,
                },
            ],
            data:
                map(batteryListData?.data?.data, item =>
                    extend(item, {
                        quantity: 0,
                        netTotal: item?.price * (item?.quantity || 0),
                    }),
                ) || [],
            listFunction: batteryListFunc,
            searchKeys: ['battery_type', 'volt', 'ah'],
            exportFunction: () => exportChargerData('battery'),
        },
        'battery accessories': {
            isGroup: true,
            columns: [
                {
                    name: t('api.group'),
                    left: true,
                    wrap: true,
                    selector: (row: { group: string }) => {
                        return (
                            <div>
                                <p>{row.group}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.article'),
                    left: true,
                    omit: true,
                    wrap: true,
                    selector: (row: { article: string }) => {
                        return (
                            <div>
                                <p>{row.article}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.description'),
                    left: true,
                    wrap: true,
                    selector: (row: { description: string; article: string }) => {
                        return (
                            <div title={row.article}>
                                <p>{row.description}</p>
                            </div>
                        )
                    },
                },
            ],
            data:
                map(batteryAccessoriesListData?.data?.data, item => {
                    return extend(item, {
                        quantity: 0,
                        netTotal: item?.price * (item?.quantity || 0),
                    })
                }) || [],
            searchKeys: ['group', 'article', 'description'],
            listFunction: batteryAccessoriesListFunc,
            exportFunction: () => exportChargerData('battery-accessories'),
        },
        'hyster battery list': {
            columns: [
                {
                    name: t('api.part_number'),
                    left: true,
                    wrap: true,
                    width: '12rem',
                    selector: (row: { part_number: string; hyster_number: string }) => {
                        return (
                            <div title={row.hyster_number}>
                                <p>{row.part_number}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.description'),
                    wrap: true,
                    left: true,
                    minWidth: '24rem',
                    selector: (row: { hyster_description: string }) => {
                        return (
                            <div title={row.hyster_description}>
                                <p>{row.hyster_description}</p>
                            </div>
                        )
                    },
                },
            ],
            data:
                map(hysterBatteryList?.data?.data, item =>
                    extend(item, {
                        hyster_description: `${t('api.battery_type')}: ${item.battery_type} ${
                            item.volt
                        } ${t('api.volt')} ${item.ah} ${t('api.ah')}`,
                        quantity: 0,
                        netTotal: item?.price * (item?.quantity || 0),
                    }),
                ) || [],
            listFunction: hysterBatteryListFunc,
            searchKeys: ['type', 'volt', 'ah'],
            exportFunction: () => exportChargerData('hyster battery list'),
        },
        'prep list': {
            columns: [
                {
                    name: t('api.heffiq_article'),
                    left: true,
                    omit: true,
                    cell: (row: { heffiq_article: string }) => {
                        return (
                            <div>
                                <p>{row.heffiq_article}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.serie_code'),
                    left: true,
                    omit: true,
                    cell: (row: { serie_code: string }) => {
                        return (
                            <div>
                                <p>{row.serie_code}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.component'),
                    left: true,
                    width: '200px',
                    wrap: true,
                    selector: (row: { component: string; heffiq_article: string }) => (
                        <p title={row.heffiq_article} className="cursor-pointer">
                            {row.component}
                        </p>
                    ),
                },
                {
                    name: t('api.description'),
                    left: true,
                    wrap: true,
                    selector: (row: { description: string }) => (
                        <p className="py-2">{row.description}</p>
                    ),
                },
                {
                    name: t('api.position'),
                    left: true,
                    wrap: true,
                    selector: (row: { position: string }) => row.position,
                },
                {
                    name: t('api.extra_needed'),
                    left: true,
                    wrap: true,
                    selector: (row: { extra: string }) => row.extra,
                },
            ],
            data:
                map(prepListData?.data?.data, item =>
                    extend(item, {
                        quantity: 0,
                        netTotal: item?.price * (item?.quantity || 0),
                    }),
                ) || [],
            listFunction: prepListFunc,
            searchKeys: ['description', 'position', 'component'],
            exportFunction: () => exportChargerData('preplist'),
        },
        attachment: {
            columns: [
                {
                    name: t('api.brand'),
                    left: true,
                    cell: (row: { brand: string }) => {
                        return (
                            <div>
                                <p>{row.brand}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.model'),
                    left: true,
                    cell: (row: { model: string }) => {
                        return (
                            <div>
                                <p>{row.model}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.description'),
                    left: true,
                    cell: (row: { description: string }) => {
                        return (
                            <div>
                                <p>{row.description}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.gros_price'),
                    left: true,
                    cell: (row: { gross_price: string }) => {
                        return (
                            <div>
                                <p>{row.gross_price}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.discount'),
                    left: true,
                    cell: (row: { discount: string }) => {
                        return (
                            <div>
                                <p>{row.discount}</p>
                            </div>
                        )
                    },
                },
            ],
            data:
                map(chargerListData?.data?.data, item =>
                    extend(item, {
                        quantity: 0,
                        netTotal: item?.price * (item?.quantity || 0),
                    }),
                ) || [],
            listFunction: () => null,
            exportFunction: () => exportChargerData('charger'),
        },
        'ex-solution': {
            columns: [
                {
                    name: t('api.model'),
                    left: true,
                    cell: (row: { model: string }) => {
                        return (
                            <div>
                                <p>{row.model}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.description'),
                    left: true,
                    cell: (row: { description: string }) => {
                        return (
                            <div>
                                <p>{row.description}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.note'),
                    left: true,
                    cell: (row: { note: string }) => {
                        return (
                            <div>
                                <p>{row.note}</p>
                            </div>
                        )
                    },
                },
                {
                    name: t('api.price'),
                    left: true,
                    cell: (row: { price: string }) => {
                        return (
                            <div>
                                <p>{row.price}</p>
                            </div>
                        )
                    },
                },
            ],
            data:
                map(chargerListData?.data?.data, item =>
                    extend(item, {
                        quantity: 0,
                        netTotal: item?.price * (item?.quantity || 0),
                    }),
                ) || [],
            listFunction: () => null,
        },
        special: {
            columns: [],
            data: [],
            listFunction: () => null,
            exportFunction: () => exportChargerData('charger'),
        },
    }

    return {
        extraItemsObject,
    }
}

export default extraItemHooks
