/* eslint-disable no-unsafe-optional-chaining */
import { Switch } from 'antd'
import Quantity from 'components/quantity'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { ORDER_TYPE } from 'utils/orderType'

interface EditExtraItemDataProps {
    totalPrice: number
    qty: number
    orderType: number
    setOrderType: (value: number) => void
    setQty: (value: number) => void
}

const EditExtraItemData = (props: EditExtraItemDataProps) => {
    const { setQty, qty, totalPrice, orderType, setOrderType } = props
    const { t } = useTranslation()

    return (
        <div className="flex flex-col justify-center items-center gap-8">
            <div>
                <p>{t('api.update_extra_item')}</p>
            </div>
            <div className="flex flex-col gap-4 flex-start">
                <div className="flex justify-between items-center gap-4">
                    <p className="w-2/3 text-left">{t('api.change_order_type')}</p>
                    <Switch
                        className="bg-green-700"
                        defaultChecked={orderType === ORDER_TYPE.OPTIONAL}
                        checkedChildren={
                            <span className="text-md font-bold">{t('api.optional')}</span>
                        }
                        unCheckedChildren={
                            <span className="text-md font-bold">{t('api.compulsory')}</span>
                        }
                        onChange={e => {
                            setOrderType(e === true ? 1 : 2)
                        }}
                    />
                </div>
                <div className="flex justify-between items-center gap-4">
                    <p className="w-2/3 text-left">{t('api.quantity')}</p>
                    <Quantity name="quantity" quantity={qty} setQuantity={value => setQty(value)} />
                </div>
                <div className="flex justify-between items-center gap-4">
                    <p className="w-2/3 text-left">{t('api.total_price')}</p>
                    <p className="text-black text-bold text-right">
                        {formatCurrency(totalPrice !== 0 ? totalPrice : 'N.V.T')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default EditExtraItemData
