/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import Button from 'components/button'
import Input from 'components/input'
import { t } from 'i18next'
import { BiSearch } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useAuth } from '../../../auth/context/useAuth'

const ConfigureTabs = ({
    activeTab,
    setActiveTab,
    pattern,
    setPattern,
    userList,
    selectedUser,
    setSelectedUser,
}: {
    activeTab: number
    setActiveTab: (index: number) => void
    pattern: string
    setPattern: (value: string) => void
    userList: any
    selectedUser: any
    setSelectedUser: any
}) => {
    const liClass = `text-bold cursor-pointer hover:border-gray-300 w-full md:w-auto border-none md:border-b-2 md:border`
    const navigate = useNavigate()
    const { userExtra } = useAuth()

    return (
        <div className="flex items-center justify-between flex-wrap">
            <div>
                <ul className="flex flex-wrap gap-8 -mb-px">
                    <li
                        className={`${
                            activeTab === 1
                                ? 'text-primary border-primary'
                                : 'text-placeholderColor border-transparent'
                        } ${liClass}`}
                        onClick={() => setActiveTab(1)}
                    >
                        {t('api.my_configuration')}
                    </li>
                    {/* <li */}
                    {/*    className={`${ */}
                    {/*        activeTab === 2 */}
                    {/*            ? 'text-primary border-primary' */}
                    {/*            : 'text-placeholderColor border-transparent' */}
                    {/*    } ${liClass}`} */}
                    {/*    onClick={() => setActiveTab(2)} */}
                    {/* > */}
                    {/*    {t('api.my_library')} */}
                    {/* </li> */}
                    {userExtra.is_manager > 0 && (
                        <li
                            className={`${
                                activeTab === 3
                                    ? 'text-primary border-primary'
                                    : 'text-placeholderColor border-transparent'
                            } ${liClass}`}
                            onClick={() => setActiveTab(3)}
                        >
                            {t('api.team_configuration')}
                        </li>
                    )}
                    {/* {!userExtra.is_manager && ( */}
                    {/*    <li */}
                    {/*        className={`${ */}
                    {/*            activeTab === 4 */}
                    {/*                ? 'text-primary border-primary' */}
                    {/*                : 'text-placeholderColor border-transparent' */}
                    {/*        } ${liClass}`} */}
                    {/*        onClick={() => setActiveTab(4)} */}
                    {/*    > */}
                    {/*        {t('api.team_library')} */}
                    {/*    </li> */}
                    {/* )} */}
                </ul>
            </div>
            <div className="flex justify-center items-center flex-wrap md:flex-nowrap gap-4 mt-4 lg:mt-0">
                {activeTab === 3 && (
                    <div className="flex flex-wrap items-center gap-8 z-50 ">
                        <div className="w-full lg:w-[12vw] z-[99999999] ">
                            <Select
                                options={userList}
                                value={selectedUser}
                                onChange={(value: any) => setSelectedUser(value)}
                            />
                        </div>
                    </div>
                )}
                <div className="w-full md:w-auto">
                    <Input
                        type="text"
                        value={pattern}
                        name="search"
                        leftContent={<BiSearch />}
                        onChange={event => {
                            setPattern(event.target.value)
                        }}
                    />
                </div>
                <div className="w-full md:w-auto">
                    <Button
                        label={t('api.create_configuration')}
                        variant="primary"
                        onClick={() => navigate('/configuration/create')}
                    />
                </div>
            </div>
        </div>
    )
}

export default ConfigureTabs
