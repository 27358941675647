import { Select } from 'antd'
import AlertModal from 'components/modal/alertModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { errorToast, successToast } from 'utils/toasterUtil'
import { updateQuoteOwnership } from '../queries/quotes'

type OrderOpportunityType = {
    label: number | string
    value: number | string
}

const UpdateOwnershipAction = (props: {
    quoteId: number
    ownerId: number
    refetch: any
    userList: any
}) => {
    const { quoteId, ownerId, refetch, userList } = props
    const { t } = useTranslation()
    const [showUpdateOwnershipModal, setShowUpdateOwnershipModal] = useState(false)
    const [selectedUserId, setSelectedUserId] = useState<number>()

    const updateQuoteOwnershipMutation = useMutation(
        'updateQuoteOwnership',
        (payload: { user_id: number; quote_id: number }) => updateQuoteOwnership(payload),
        {
            onSuccess: () => {
                refetch()
                toast(t('message.ownership_update_success'), successToast)
            },
            onError: (error: any) => {
                toast(error?.message || t('message.ownership_update_fail'), errorToast)
            },
            onSettled: () => {
                setShowUpdateOwnershipModal(false)
            },
        },
    )

    return (
        <>
            <Select
                defaultValue={ownerId}
                size="large"
                className="w-48 rounded-md uppercase"
                dropdownStyle={{ textTransform: 'uppercase' }}
                placeholder={t('api.user')}
                onChange={value => {
                    setSelectedUserId(Number(value))
                    setShowUpdateOwnershipModal(true)
                }}
            >
                {userList
                    .filter((x: any) => x.value !== 'all')
                    .map((item: OrderOpportunityType) => (
                        <Select.Option value={item.value}>{item.label}</Select.Option>
                    ))}
            </Select>
            <AlertModal
                show={showUpdateOwnershipModal}
                content={<div className="text-xl">{t('message.update_ownership')}</div>}
                isSubmitting={updateQuoteOwnershipMutation.isLoading}
                onConfirm={() => {
                    if (selectedUserId) {
                        updateQuoteOwnershipMutation.mutate({
                            user_id: selectedUserId,
                            quote_id: Number(quoteId),
                        })
                    }
                }}
                onCancel={() => setShowUpdateOwnershipModal(false)}
            />
        </>
    )
}

export default UpdateOwnershipAction
