/* eslint-disable camelcase */
import { Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'utils/currency/formatCurrency'

interface IConnectExtraToConfigurationsModal {
    id: number
    netTotal: number
    order_type: number
    price: number
    quantity: number
    configurationList: any[]
    setConfigurationList: any
}

const ConnectExtraToConfigurationsModal = (props: IConnectExtraToConfigurationsModal) => {
    const { id, netTotal, order_type, price, quantity, configurationList, setConfigurationList } =
        props

    const { t } = useTranslation()

    return (
        <div className="flex flex-col gap-2 text-sm">
            <p className="text-lg">{t('api.connect_extra_to_configurations')}</p>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                    <span>{t('fields.id')}</span>
                    <span>{id}</span>
                </div>
                <div className="flex justify-between">
                    <span>{t('fields.order_type')}</span>
                    <span>{order_type === 2 ? t('api.compulsory') : t('api.optional')}</span>
                </div>
                <div className="flex justify-between">
                    <span>{t('fields.price')}</span>
                    <span>{formatCurrency(price)}</span>
                </div>
                <div className="flex justify-between">
                    <span>{t('fields.quantity')}</span>
                    <span>{quantity}</span>
                </div>
                <div className="flex justify-between">
                    <span>{t('fields.net_total')}</span>
                    <span>{formatCurrency(netTotal)}</span>
                </div>
            </div>
            <div className="flex flex-col items-start gap-2">
                <p className="api.text-left w-full">{t('api.select_configurations')}</p>
                {configurationList.length === 0 && <p>{t('api.no_configuration_available')}</p>}
                {configurationList.length > 0 &&
                    configurationList?.map((item: any) => (
                        <div className="flex w-full items-center justify-between" key={item.id}>
                            <p>
                                {t('api.id')} : {item.id} {item.label}
                            </p>
                            <Checkbox
                                key={item.id}
                                checked={item.checked}
                                onChange={() => {
                                    const tempConfigurationList = configurationList
                                    const index = tempConfigurationList.findIndex(
                                        c => c.id === item.id,
                                    )
                                    tempConfigurationList[index].checked =
                                        !tempConfigurationList[index].checked
                                    setConfigurationList([...tempConfigurationList])
                                }}
                            />
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default ConnectExtraToConfigurationsModal
