import { callAxios } from 'plugins/api'

const getQuestions = () =>
    callAxios({
        url: 'requirement-matrix-question',
        method: 'GET',
        isHeffiq: true,
    })

const getDemoAnswerByQuestionId = (questionId: number) =>
    callAxios({
        url: `requirement-matrix-demo-answer/question/${questionId}`,
        method: 'GET',
        isHeffiq: true,
    })

const findRequirementMatrixByQuoteId = (quoteId: number) =>
    callAxios({
        url: `requirement-matrix/${quoteId}`,
        method: 'GET',
        isHeffiq: true,
    })

const addRequirementMatrixToQuote = (formData: {
    answer: string
    quote_id: number
    question_id: number
    subject_id: number
}) =>
    callAxios({
        url: `requirement-matrix`,
        method: 'POST',
        isHeffiq: true,
        data: formData,
    })

const updateRequirementMatrixAnswer = (quoteId: number, formData: { answer: string }) =>
    callAxios({
        url: `requirement-matrix/${quoteId}`,
        method: 'PUT',
        isHeffiq: true,
        data: formData,
    })

const addSubject = (params: { quote_id: number; subject: string }) =>
    callAxios({
        url: `requirement-matrix-subject/addSubjects`,
        method: 'POST',
        isHeffiq: true,
        data: params,
    })

const getSubject = (quoteId: number) =>
    callAxios({
        url: `requirement-matrix-subject/${quoteId}`,
        method: 'GET',
        isHeffiq: true,
    })

const deleteRequirementMatrix = (requirementMatrixId: number) =>
    callAxios({
        url: `requirement-matrix-subject/delete/${requirementMatrixId}`,
        method: 'DELETE',
        isHeffiq: true,
    })

const RequirementMatrixQueries = {
    getQuestions,
    getDemoAnswerByQuestionId,
    findRequirementMatrixByQuoteId,
    addRequirementMatrixToQuote,
    updateRequirementMatrixAnswer,
    addSubject,
    getSubject,
    deleteRequirementMatrix,
}

export default RequirementMatrixQueries
