import { Options } from '../../../components/select'

const FLOOR_OPTION_LESS_THAN_25_PERCENT_UNEVEN = 1
const FLOOR_OPTION_50_PERCENT_SMOOTH = 2
const FLOOR_OPTION_GREATER_THAN_75_UNEVEN = 3

const COUPLING_OPTION_KLIK_KOPPELING_RECHT = 1
const COUPLING_OPTION_SCHROEF_KOPPELING_RECHT = 2
const COUPLING_OPTION_EASY_CLIP_KOPPELING_RECHT = 3
const COUPLING_OPTION_KLIK_KOPPELING_45_GRADEN = 4
const COUPLING_OPTION_SCHROEF_KOPPELING_45_GRADEN = 5
const COUPLING_OPTION_EASY_CLIP_KOPPELING_45_GRADEN = 6
const COUPLING_OPTION_KLIK_KOPPELING_90_GRADEN = 7
const COUPLING_OPTION_SCHROEF_KOPPELING_90_GRADEN = 8
const COUPLING_OPTION_EASY_CLIP_KOPPELING_90_GRADEN = 9
const COUPLING_OPTION_NVT = 10

export const couplingOptions: Options[] = [
    {
        label: 'Klik koppeling recht',
        value: COUPLING_OPTION_KLIK_KOPPELING_RECHT,
    },
    {
        label: 'Schroef koppeling recht',
        value: COUPLING_OPTION_SCHROEF_KOPPELING_RECHT,
    },
    {
        label: 'Easy-clip koppeling recht',
        value: COUPLING_OPTION_EASY_CLIP_KOPPELING_RECHT,
    },
    {
        label: 'Klik koppeling 45 graden',
        value: COUPLING_OPTION_KLIK_KOPPELING_45_GRADEN,
    },
    {
        label: 'Schroef koppeling 45 graden',
        value: COUPLING_OPTION_SCHROEF_KOPPELING_45_GRADEN,
    },
    {
        label: 'Easy-clip koppeling 45 graden',
        value: COUPLING_OPTION_EASY_CLIP_KOPPELING_45_GRADEN,
    },
    {
        label: 'Klik koppeling 90 graden',
        value: COUPLING_OPTION_KLIK_KOPPELING_90_GRADEN,
    },
    {
        label: 'Schroef koppeling 90 graden',
        value: COUPLING_OPTION_SCHROEF_KOPPELING_90_GRADEN,
    },
    {
        label: 'Easy-clip koppeling 90 graden',
        value: COUPLING_OPTION_EASY_CLIP_KOPPELING_90_GRADEN,
    },
    {
        label: 'N.v.t',
        value: COUPLING_OPTION_NVT,
    },
]

const INDOOR_ONLY = 1
const OUTDOOR_ONLY = 2
const SPECIAL_OPTION_FOR_INDOOR_INCLUDED = 3
const INDOOR_AND_OUTDOOR = 4
const SPECIAL_OPION_FOR_OUTDOOR_INCLUDED = 5

export const indoorOrOutdoorOptions: Options[] = [
    {
        label: 'Indoor Only',
        value: INDOOR_ONLY,
    },
    {
        label: 'Outdoor Only',
        value: OUTDOOR_ONLY,
    },
    {
        label: 'Special option for indoors included',
        value: SPECIAL_OPTION_FOR_INDOOR_INCLUDED,
    },
    {
        label: 'Indoors and Outdoors',
        value: INDOOR_AND_OUTDOOR,
    },
    {
        label: 'Special option for outdoors included',
        value: SPECIAL_OPION_FOR_OUTDOOR_INCLUDED,
    },
]

const DOCK_OPTION_NONE = 1
const DOCK_OPTION_SOMETIMES = 2
const DOCK_OPTION_ALWAYS = 3

export const dockShelterOrRampsOptions: Options[] = [
    {
        label: 'none',
        value: DOCK_OPTION_NONE,
    },
    {
        label: 'sometimes (+/- 50% of the times)',
        value: DOCK_OPTION_SOMETIMES,
    },
    {
        label: 'always',
        value: DOCK_OPTION_ALWAYS,
    },
]

const SEASONAL_CROWD_OPTION_NO = 1
const SEASONAL_CROWD_OPTION_YES = 2

export const seasonalCrowdOption: Options[] = [
    {
        label: 'api.no',
        value: 'no',
    },
    {
        label: 'api.yes',
        value: 'yes',
    },
]

const WORK_IN_SHIFT_NOT_APPLICABLE = 1
const WORK_IN_SHIFT_2_SHIFT = 2
const WORK_IN_SHIFT_3_SHIFT = 3
const WORK_IN_SHIFT_24HR_7DAYS = 4
const WORK_IN__SHIFT_24HR_5DAYS = 5

export const workInShiftOptions: Options[] = [
    {
        label: 'Not Applicable',
        value: WORK_IN_SHIFT_NOT_APPLICABLE,
    },
    {
        label: '2 shifts',
        value: WORK_IN_SHIFT_2_SHIFT,
    },
    {
        label: '3 shifts',
        value: WORK_IN_SHIFT_3_SHIFT,
    },
    {
        label: '24hr/7days',
        value: WORK_IN_SHIFT_24HR_7DAYS,
    },
    {
        label: '24hr/5days',
        value: WORK_IN__SHIFT_24HR_5DAYS,
    },
]

const BATTERY_TYPE_PZS_345 = 1
const BATTERY_TYPE_PZB_375 = 2
const BATTERY_TYPE_PZB_450 = 3
const BATTERY_TYPE_PZS_460 = 4
const BATTERY_TYPE_PZS_500 = 5
const BATTERY_TYPE_PZB_525 = 6
const BATTERY_TYPE_PZS_560 = 7
const BATTERY_TYPE_PZS_575 = 8
const BATTERY_TYPE_PZB_600 = 9
const BATTERY_TYPE_PZS_620 = 10
const BATTERY_TYPE_PZS_625 = 11
const BATTERY_TYPE_PZS_630 = 12
const BATTERY_TYPE_PZB_675 = 12
const BATTERY_TYPE_PZS_690 = 13
const BATTERY_TYPE_PZB_690 = 14
const BATTERY_TYPE_PZS_700 = 15
const BATTERY_TYPE_PZS_735 = 16
const BATTERY_TYPE_PZS_750 = 17
const BATTERY_TYPE_PZB_750 = 18
const BATTERY_TYPE_PZS_775 = 19
const BATTERY_TYPE_PZB_800 = 20
const BATTERY_TYPE_PZS_800 = 21
const BATTERY_TYPE_PZS_840 = 22
const BATTERY_TYPE_PZS_875 = 23
const BATTERY_TYPE_PZS_920 = 24
const BATTERY_TYPE_PZS_1000 = 25

export const batteryTypeOptions: Options[] = [
    {
        label: 'PzS 345',
        value: BATTERY_TYPE_PZS_345,
    },
    {
        label: 'PzB 375',
        value: BATTERY_TYPE_PZB_375,
    },
    {
        label: 'PzB 450',
        value: BATTERY_TYPE_PZB_450,
    },
    {
        label: 'PzS 460',
        value: BATTERY_TYPE_PZS_460,
    },
    {
        label: 'PzS 500',
        value: BATTERY_TYPE_PZS_500,
    },
    {
        label: 'PzB 525',
        value: BATTERY_TYPE_PZB_525,
    },
    {
        label: 'PzS 560',
        value: BATTERY_TYPE_PZS_560,
    },
    {
        label: 'PzS 575',
        value: BATTERY_TYPE_PZS_575,
    },
    {
        label: 'PzB 600',
        value: BATTERY_TYPE_PZB_600,
    },
    {
        label: 'PzS 620',
        value: BATTERY_TYPE_PZS_620,
    },
    {
        label: 'PzS 625',
        value: BATTERY_TYPE_PZS_625,
    },
    {
        label: 'PzS 630',
        value: BATTERY_TYPE_PZS_630,
    },
    {
        label: 'PzB 675',
        value: BATTERY_TYPE_PZB_675,
    },
    {
        label: 'PzS 690',
        value: BATTERY_TYPE_PZS_690,
    },
    {
        label: 'PzB 690',
        value: BATTERY_TYPE_PZB_690,
    },
    {
        label: 'PzS 700',
        value: BATTERY_TYPE_PZS_700,
    },
    {
        label: 'PzS 735',
        value: BATTERY_TYPE_PZS_735,
    },
    {
        label: 'PzS 750',
        value: BATTERY_TYPE_PZS_750,
    },
    {
        label: 'PzB 750',
        value: BATTERY_TYPE_PZB_750,
    },
    {
        label: 'PzS 775',
        value: BATTERY_TYPE_PZS_775,
    },
    {
        label: 'PzB 800',
        value: BATTERY_TYPE_PZB_800,
    },
    {
        label: 'PzS 800',
        value: BATTERY_TYPE_PZS_800,
    },
    {
        label: 'PzS 840',
        value: BATTERY_TYPE_PZS_840,
    },
    {
        label: 'PzS 875',
        value: BATTERY_TYPE_PZS_875,
    },
    {
        label: 'PzS 920',
        value: BATTERY_TYPE_PZS_920,
    },
    {
        label: 'PzS 1000',
        value: BATTERY_TYPE_PZS_1000,
    },
]

export const batteryCapacityStaticOption: Options[] = [
    {
        label: '345',
        value: '345',
    },
    {
        label: '375',
        value: '375',
    },
    {
        label: '450',
        value: '450',
    },
    {
        label: '460',
        value: '460',
    },
    {
        label: '500',
        value: '500',
    },
    {
        label: '525',
        value: '525',
    },
    {
        label: '560',
        value: '560',
    },
    {
        label: '600',
        value: '600',
    },
    {
        label: '625',
        value: '625',
    },
    {
        label: '630',
        value: '630',
    },
    {
        label: '675',
        value: '675',
    },
    {
        label: '690',
        value: '690',
    },
    {
        label: '700',
        value: '700',
    },
    {
        label: '735',
        value: '735',
    },
    {
        label: '750',
        value: '750',
    },
    {
        label: '775',
        value: '775',
    },
    {
        label: '800',
        value: '800',
    },
    {
        label: '840',
        value: '840',
    },
    {
        label: '875',
        value: '875',
    },
    {
        label: '920',
        value: '920',
    },
    {
        label: '930',
        value: '930',
    },
    {
        label: '1000',
        value: '1000',
    },
]

export const yesNoOptions: Options[] = [
    {
        label: 'api.yes',
        value: 'yes',
    },
    {
        label: 'api.no',
        value: 'no',
    },
]

export const tyreTypeOption: Options[] = [
    {
        label: 'fields.dual_nm',
        value: 'dual_nm',
    },
    {
        label: 'fields.dual_std',
        value: 'dual_std',
    },
    {
        label: 'fields.single_nm',
        value: 'single_nm',
    },
    {
        label: 'fields.single_std',
        value: 'single_std',
    },
]

export const maintenanceOptions: Options[] = [
    {
        label: 'api.preventive_maintenance',
        value: 'PREVENTIVE_MAINTAINENCE',
    },
    {
        label: 'api.all_in',
        value: 'ALL_IN',
    },
    {
        label: 'api.all_in_and_preventive_maintenance',
        value: 'ALL_IN_AND_PREVENTIVE_MAINTENANCE',
    },
    {
        label: 'api.preventive_maintenance_plus',
        value: 'PREVENTIVE_MAINTENANCE_PLUS',
    },
    {
        label: 'api.nvt',
        value: 'N.V.T',
    },
]

export const durationOfContractOptions: Options[] = [
    {
        label: '12',
        value: '12',
    },
    {
        label: '24',
        value: '24',
    },
    {
        label: '36',
        value: '36',
    },
    {
        label: '48',
        value: '48',
    },
    {
        label: '60',
        value: '60',
    },
    {
        label: '72',
        value: '72',
    },
    {
        label: '84',
        value: '84',
    },
    {
        label: '96',
        value: '96',
    },
]

export const batteryServiceOptions: Options[] = [
    {
        label: 'fields.1_times',
        value: 1,
    },
    {
        label: 'fields.2_times',
        value: 2,
    },
    {
        label: 'fields.3_times',
        value: 3,
    },
    {
        label: 'fields.4_times',
        value: 4,
    },
]

export const typeBandanOptions: Options[] = [
    {
        label: 'fields.standard',
        value: 'Standard',
    },
    {
        label: 'fields.non_marking',
        value: 'Non Marking',
    },
    {
        label: 'fields.dual',
        value: 'Dual',
    },
    {
        label: 'fields.non_marking_dual',
        value: 'Non Marking Dual',
    },
]

export const safetyOptions: Options[] = [
    {
        label: 'fields.streambelt',
        value: 'Streambelt',
    },
    {
        label: 'fields.safety_doors',
        value: 'Safety doors',
    },
]

export const loadStrainOptions: Options[] = [
    {
        label: 'fields.increased',
        value: 'increased',
    },
    {
        label: 'fields.decreased',
        value: 'decreased',
    },
]

export const palletSizeOptions: Options[] = [
    {
        label: 'fields.europallet_80_120_cm',
        value: 'Europallet 80 x 120 cm',
    },
    {
        label: 'fields.blokpallet_100_120_cm',
        value: 'Blokpallet 100 x 120 cm (meest voorkomend)',
    },
    {
        label: 'fields.display_60_80_cm_of_40_60_cm',
        value: 'Display 60 x 80 cm of 40 x 60cm',
    },
    {
        label: 'fields.amerikaanse_pallet_102_122_cm',
        value: 'Amerikaanse pallet 102 x 122 cm',
    },
    {
        label: 'fields.düsseldorf_pallets_60_80_cm',
        value: 'Düsseldorf pallets 60 x 80 cm',
    },
    {
        label: 'fields.other',
        value: 'other',
    },
]

export const lightingOption: Options[] = [
    {
        label: 'fields.lighting_halo',
        value: 'Lighting Halo',
    },
    {
        label: 'fields.led_beacon',
        value: 'LED beacon',
    },
]

export const loadRackOption: Options[] = [
    {
        label: 'fields.raised',
        value: 'Raised',
    },
    {
        label: 'fields.not_raised',
        value: 'Not Raised',
    },
]

export const lflOrFFlOption: Options[] = [
    {
        label: 'fields.lfl',
        value: 'LFL',
    },
    {
        label: 'fields.ffl',
        value: 'FFL',
    },
]

export const palOption: Options[] = [
    {
        label: 'feilds.front',
        value: 'Front',
    },
    {
        label: 'fields.rear',
        value: 'Rear',
    },
]

export const forkWheelOption: Options[] = [
    {
        label: 'fields.single',
        value: 'Single',
    },
    {
        label: 'fields.double',
        value: 'Double',
    },
]

export const mastOptions: Options[] = [
    {
        label: 'fields.ffl',
        value: 'FFL',
    },
    {
        label: 'fields.ffl_2_piece',
        value: 'FFL 2 piece',
    },
    {
        label: 'fields.ffl_3_piece',
        value: 'FFL 3 piece',
    },
]

export const inductanceOrRailConductionOptions: Options[] = [
    {
        label: 'fields.induction',
        value: 'Induction',
    },
    {
        label: 'fields.rail_condution',
        value: 'Rail conduction',
    },
]

export const controlOptions: Options[] = [
    {
        label: 'fields.level',
        value: 'Level',
    },
    {
        label: 'fields.joystick',
        value: 'Joystick',
    },
    {
        label: 'fields.dual_side',
        value: 'Dual side',
    },
]

export const steeringOptions: Options[] = [
    {
        label: '180',
        value: '180',
    },
    {
        label: '360',
        value: '360',
    },
]

export const plugTypeOption: Options[] = [
    {
        label: 'fields.schiko_230_v_stekker',
        value: 'Schiko 230V stekker',
    },
    {
        label: 'fields.5p_16a_400_v_stekker',
        value: '5P/16A 400V stekker',
    },
    {
        label: 'fields.5p_32a_400_v_stekker',
        value: '5P/32a 400v stekker',
    },
    {
        label: 'fields.63a_li)ion',
        value: '63A (Li-ion)',
    },
    {
        label: 'fields.125_a_li_ion',
        value: '125A (Li-ion)',
    },
]

export const financeOptions: Options[] = [
    {
        label: 'fields.purchase',
        value: 'Purchase',
    },
    {
        label: 'fields.operational_lease',
        value: 'Operational Lease',
    },
    {
        label: 'fields.financial_lease',
        value: 'Financial Lease',
    },
]
