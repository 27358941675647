/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import classNames from 'classnames'
import Button from 'components/button'
import Loading from 'components/loading'
import Table from 'components/table'
import { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { useMutation, useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { errorToast, successToast } from 'utils/toasterUtil'
import { useTranslation } from 'react-i18next'
import {
    attachSiteSurvey,
    AttchedSiteSurvey,
    getAllUnattachedSiteSurvey,
    getConnectSiteSurvey,
} from '../query'
import ConfigurationSiteSurveyDetail from './ConfigurationSiteSurveyDetail'

interface ConfigurationSiteSurveyProps {
    closeModal: () => void
    isNovo: boolean
    configurationId?: string
}

const ConfigurationSiteSurvey = (props: ConfigurationSiteSurveyProps) => {
    const { t } = useTranslation()
    const { closeModal, configurationId, isNovo } = props
    const { novoId, orderId } = useParams()
    const [addSurvey, setAddSurvey] = useState<boolean>(false)
    const [deAttachSurvey, setDeattachSurvey] = useState<boolean>(false)
    const [selectedSurvey, setSelectedSurvey] = useState<any>({})

    const {
        isLoading: connectedSiteSurveyLoading,
        isFetching: connectedSiteSurveyFetching,
        data: connectedSiteSurvey,
        refetch,
    } = useQuery(
        'getConnectSiteSurvey',
        () => getConnectSiteSurvey(configurationId || novoId || ''),
        {
            enabled: !addSurvey,
            onSuccess: res => {
                if (!res?.data?.data) {
                    setAddSurvey(true)
                }
            },
        },
    )

    const { isLoading, data } = useQuery(
        ['siteSurvey'],
        () => getAllUnattachedSiteSurvey(configurationId || ''),
        {
            enabled: addSurvey,
        },
    )

    const attachSiteSurveyMutation = useMutation(
        ['attachSiteSurvey'],
        (payload: AttchedSiteSurvey) => attachSiteSurvey(configurationId || novoId || '', payload),
        {
            onSuccess: (res: any) => {
                toast(
                    res?.data?.message || `${t('api.site_survey')} ${t('message.connected')} `,
                    successToast,
                )
                setAddSurvey(false)
                setDeattachSurvey(false)
                setSelectedSurvey({})
                refetch()
            },
            onError: (error: { message: string }) => {
                toast(
                    error?.message || `${t('api.site_survey')} ${t('message.not_connected')} `,
                    errorToast,
                )
                setSelectedSurvey({})
            },
        },
    )

    const columns = [
        {
            name: t('api.customer'),
            width: '200px',
            selector: (row: { customer: number }) => row.customer,
        },
        {
            name: t('api.contact_person'),
            width: '200px',
            selector: (row: { contact_person: number }) => row.contact_person,
        },
        {
            name: t('api.models'),
            width: '200px',
            selector: (row: { models: { name: string } }) => row.models.name,
        },
        {
            name: t('api.action'),
            center: true,
            cell: (row: { id: number }) => (
                <div className="py-2">
                    <Button
                        isSmall
                        label={addSurvey ? t('api.attach') : t('api.remove')}
                        variant="outline"
                        isLoading={
                            selectedSurvey?.id === row.id && attachSiteSurveyMutation.isLoading
                        }
                        disabled={
                            selectedSurvey?.id === row.id && attachSiteSurveyMutation.isLoading
                        }
                        onClick={() => {
                            attachSiteSurveyMutation.mutate({
                                site_survey_id: row.id,
                                is_novo: isNovo,
                            })
                            setSelectedSurvey(row)
                        }}
                    />
                </div>
            ),
        },
    ]

    if (connectedSiteSurveyLoading || connectedSiteSurveyFetching) {
        return (
            <div className="flex w-full h-[70vh] justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 px-3">
            <div className="flex items-center justify-between">
                <p className="text-secondary border-primary md:text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {deAttachSurvey ? t('api.remove') : t('api.configuration')}{' '}
                    {t('api.site_survey')}
                </p>
                <div className="flex gap-4 items-center">
                    {!orderId &&
                        connectedSiteSurvey?.data?.data &&
                        connectedSiteSurvey?.data?.data?.length > 0 &&
                        addSurvey && (
                            <Button
                                label={addSurvey ? t('api.back') : t('api.attached_site_survey')}
                                variant="outline"
                                onClick={() => {
                                    setAddSurvey(!addSurvey)
                                }}
                            />
                        )}
                    {!orderId &&
                        !addSurvey &&
                        connectedSiteSurvey?.data?.data &&
                        connectedSiteSurvey?.data?.data.length !== 0 && (
                            <Button
                                label={
                                    deAttachSurvey ? t('api.cancel') : t('api.dettach_site_survey')
                                }
                                variant={deAttachSurvey ? 'outline' : 'error'}
                                onClick={() => {
                                    setDeattachSurvey(!deAttachSurvey)
                                }}
                            />
                        )}
                    <Button
                        leftIcon={<IoMdClose size={22} />}
                        variant="primary"
                        onClick={() => closeModal()}
                    />
                </div>
            </div>
            <div className={classNames(deAttachSurvey ? 'h-fit' : 'h-[70vh]')}>
                {deAttachSurvey ? (
                    <div className="h-full w-full grid place-content-center py-8">
                        <div className="">
                            <div
                                className="p-6 pt-0 text-center flex flex-col justify-center items-center"
                                onClick={() => closeModal()}
                            >
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 w-2/3 text-center">
                                    {t('message.remove_site_survey_from_configuration')}
                                </h3>
                                <div className="flex justify-center gap-4">
                                    <Button
                                        label={t('message.yes_i_am_sure')}
                                        onClick={() =>
                                            attachSiteSurveyMutation.mutate({
                                                site_survey_id: null,
                                                is_novo: isNovo,
                                            })
                                        }
                                        variant="error"
                                        isLoading={attachSiteSurveyMutation.isLoading}
                                        disabled={attachSiteSurveyMutation.isLoading}
                                    />
                                    <Button
                                        label={t('message.no_cancel')}
                                        onClick={() => {
                                            setDeattachSurvey(false)
                                        }}
                                        variant="ghost"
                                        isLoading={attachSiteSurveyMutation.isLoading}
                                        disabled={attachSiteSurveyMutation.isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : addSurvey ? (
                    <>
                        <p className="text-md">
                            {addSurvey
                                ? t('api.attach_new_site_survey')
                                : t('message.list_site_survey')}
                        </p>
                        <Table
                            columns={columns}
                            data={data?.data?.data || []}
                            loading={isLoading}
                            striped
                            pagination
                            noDataFound={
                                <div className="flex flex-col gap-2 items-center justify-center">
                                    <Link
                                        to={`/site-survey/create?configuration-id=${configurationId}`}
                                        className="underline"
                                    >
                                        <p className="py-4">
                                            {t('message.all_site_survey_connected_make_new_one')}
                                        </p>
                                    </Link>
                                    <Link
                                        to={`/site-survey/create?configuration-id=${configurationId}&&hide-non-required-fields=true`}
                                        className="underline"
                                    >
                                        <p className="py-4">
                                            {t(
                                                'message.all_site_survey_connected_make_new_one_with_only_required_fields',
                                            )}
                                        </p>
                                    </Link>
                                </div>
                            }
                        />
                    </>
                ) : (
                    <ConfigurationSiteSurveyDetail
                        siteSurveyMenu={() => setAddSurvey(!addSurvey)}
                        siteSurveyDetail={
                            connectedSiteSurvey?.data?.data && connectedSiteSurvey?.data?.data[0]
                        }
                    />
                )}
            </div>
        </div>
    )
}

export default ConfigurationSiteSurvey
