import { useTranslation } from 'react-i18next'
import ServiceOverviewTable from 'modules/quotes/components/serviceOverviewTable'
import { IResidualValue } from 'modules/quotes/views/Pricing'
import { MdOutlineCheck } from 'react-icons/md'
import { AdditionalDataType, IPricingInformation } from '../views/PricingOrderOverview'

type ContractDetailsProps = {
    isBigTruck: boolean
    companyRatesData: IPricingInformation
    additionalData: AdditionalDataType
    initialPrice: any
    residualValue: IResidualValue[]
}

const ContractDetails = ({
    isBigTruck,
    companyRatesData,
    initialPrice,
    additionalData,
    residualValue,
}: ContractDetailsProps) => {
    const { t } = useTranslation()
    const filteredResidualValue =
        residualValue && residualValue?.length > 1
            ? residualValue?.filter(
                  x => x.durationOfContract === companyRatesData?.selected_duration_of_contract,
              )
            : residualValue

    return (
        <div className="rounded-md p-4 bg-white">
            <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full">
                {t('api.contract_details')}
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 bg-slate-100 p-2 gap-2  mt-4">
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.additional_residual_percentage`)}:</span>
                    {String(companyRatesData?.additional_residual_percentage)}%
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.investment_group`)}:</span>
                    {String(
                        `
                            ${
                                initialPrice?.investment_group[
                                    companyRatesData?.min_investment_group === 0 ? 0 : 1
                                ].min_value
                            }
                            -${
                                initialPrice?.investment_group[
                                    companyRatesData?.min_investment_group === 0 ? 0 : 1
                                ].max_value
                            }`,
                    )}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.discount_service_all_in`)}:</span>
                    {String(companyRatesData?.discount_service_all_in)}%
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.discount_service_pm`)}:</span>
                    {String(companyRatesData?.discount_service_pm)}%
                </p>
                {companyRatesData?.add_battery && (
                    <>
                        <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                            <span className="">{t(`api.add_battery`)}:</span>
                            {companyRatesData?.add_battery && <MdOutlineCheck />}
                        </p>
                        <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                            <span className="">{t(`api.battery_service`)}:</span>
                            {String(companyRatesData?.battery_service)}
                        </p>
                        <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                            <span className="">{t(`api.no_of_batteries`)}:</span>
                            {String(companyRatesData?.number_of_batteries)}
                        </p>
                    </>
                )}
                {companyRatesData?.add_attachment && (
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.add_attachment`)}:</span>
                        {companyRatesData?.add_attachment && <MdOutlineCheck />}
                    </p>
                )}
                {companyRatesData?.bmwt && (
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.bmwt`)}:</span>
                        {companyRatesData?.bmwt && <MdOutlineCheck />}
                    </p>
                )}
                {companyRatesData?.add_tyre && (
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.add_tyre`)}:</span>
                        {companyRatesData?.add_tyre && <MdOutlineCheck />}
                    </p>
                )}
                {companyRatesData?.add_specials && (
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.add_specials`)}:</span>
                        {companyRatesData?.add_specials && <MdOutlineCheck />}
                    </p>
                )}
                {companyRatesData?.add_ex_solutions && (
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.add_ex_solutions`)}:</span>
                        {companyRatesData?.add_ex_solutions && <MdOutlineCheck />}
                    </p>
                )}
                {companyRatesData?.add_bmwt_battery && (
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.add_bmwt_battery`)}:</span>
                        {companyRatesData?.add_bmwt_battery && <MdOutlineCheck />}
                    </p>
                )}
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.category`)}:</span>
                    {() => {
                        switch (String(companyRatesData?.category)) {
                            case '1':
                                return t('api.light')
                            case '2':
                                return t('api.medium')
                            case '3':
                                return t('api.heavy')
                            default:
                                return t('api.light')
                        }
                    }}
                </p>
                {/* <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.is_whe`)}:</span>
                    {String(initialPrice?.is_whe)}
                </p> */}
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.tyre_type`)}:</span>
                    {String(companyRatesData?.type_banden)}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.duration_of_contract`)}:</span>
                    {String(companyRatesData?.selected_duration_of_contract)} months
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.standard_residual_value`)}:</span>
                    {String(filteredResidualValue[0]?.standardResidualValue)}%
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.total_residual_value`)}:</span>
                    {String(filteredResidualValue[0]?.totalResidualValue)}%
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.factor_value`)}:</span>
                    {String(filteredResidualValue[0]?.factor?.toFixed(2))}
                </p>
                {isBigTruck && (
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.service_big_truck_all_in`)}:</span>€{' '}
                        {String(filteredResidualValue[0]?.serviceBigTruckAllIn)}
                    </p>
                )}
                {isBigTruck && (
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.service_big_truck_pm`)}:</span>€{' '}
                        {String(filteredResidualValue[0]?.serviceBigTruckPm)}
                    </p>
                )}
                {isBigTruck && (
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">
                            {t(`api.service_price_tires_per_hour_big_trucks`)}:
                        </span>
                        € {String(filteredResidualValue[0]?.servicePriceTriesPerHour)}
                    </p>
                )}
            </div>
            {residualValue && residualValue?.length > 0 && (
                <ServiceOverviewTable
                    residualValue={filteredResidualValue || []}
                    battery={companyRatesData?.add_battery}
                    tires={companyRatesData?.add_tyre}
                    attachment={companyRatesData?.add_attachment}
                    specials={companyRatesData?.add_specials}
                    exSolution={companyRatesData?.add_ex_solutions}
                    maintenance={
                        additionalData?.maintenance ||
                        initialPrice?.novo_configuration_site_survey.site_survey?.maintenance
                    }
                    runningHours={
                        additionalData?.running_hours ||
                        initialPrice?.novo_configuration_site_survey.site_survey?.running_hours
                    }
                    selectedDurationOfContract={companyRatesData?.selected_duration_of_contract}
                    overview
                />
            )}
            {/* <div className="grid grid-cols-1 bg-slate-100 p-2 gap-2  mt-2">
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.financial_part`)}:</span>
                    {String('2822.66')}
                </p>
                <p className="font-bold">{t('api.service_all_in')}</p>
                <div className="pl-2">
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.basic_truck`)}:</span>
                        {String('228.35')}
                    </p>
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.service_extra_preplist`)}:</span>
                        {String('0.35')}
                    </p>
                    <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                        <span className="">{t(`api.service_battery`)}:</span>
                        {String('0.35')}
                    </p>
                </div>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.total_service`)}:</span>
                    {String('228.35')}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.insurance_cost`)}:</span>
                    {String('215.50')}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.total_all`)}:</span>
                    {String('3266.51')}
                </p>
                <p className="flex justify-between items-center pr-2 border-r-[3px] border-white">
                    <span className="">{t(`api.basic_service_per_hour`)}:</span>
                    {String('1.83')}
                </p>
            </div> */}
        </div>
    )
}
export default ContractDetails
