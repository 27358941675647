import { extend } from 'lodash'
import { callAxios } from 'plugins/api'

export const getAllOrder = (userId: number, isSupport?: boolean) => {
    if (isSupport) {
        return callAxios({
            url: `sales-support/getAllOrdersSalesSupport/${userId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `order/user/${userId}`,
        method: 'GET',
    })
}

export const getAllTeamOrder = (userId: number) => {
    return callAxios({
        url: `user-hierarchy/showChildrenOrders/${userId}`,
        method: 'GET',
    })
}

export const getOrderDetail = ({
    orderId,
    userId,
    isSupport,
}: {
    orderId: string
    userId: number
    isSupport?: boolean
}) => {
    if (isSupport) {
        return callAxios({
            url: `sales-support/getSingleOrderSalesSupport/${userId}/${orderId}`,
            method: 'GET',
            isHeffiq: true,
        })
    }
    return callAxios({
        url: `order/user/${userId}/${orderId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const deleteOrder = (orderId: string) => {
    return callAxios({
        url: `order/deleteOrderFormData/${orderId}`,
        method: 'GET',
    })
}

export const submitOrder = (orderId: string) => {
    return callAxios({
        url: `v2/order/submit/${orderId}`,
        method: 'PATCH',
    })
}

export const reOrder = (orderId: string, userId: number, isSupport?: boolean) => {
    if (isSupport) {
        return callAxios({
            url: `sales-support/reorderOrderSalesSupport/${userId}/${orderId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `order/reorder/${orderId}/${userId}`,
        method: 'GET',
    })
}

export const cancelOrder = (orderId: string, userId: number, isSupport?: boolean) => {
    if (isSupport) {
        return callAxios({
            url: `sales-support/cancelOrderSalesSupport/${userId}/${orderId}`,
            method: 'GET',
        })
    }
    return callAxios({
        url: `order/cancel/${orderId}/${userId}`,
        method: 'GET',
    })
}

export const orderFormData = (orderId: string) => {
    return callAxios({
        url: `order/getOrderFormData/${orderId}`,
        method: 'GET',
    })
}

export const updateOrderFormData = (orderId: string, data: any, userId: number) => {
    return callAxios({
        url: `order/updateOrderFormData/${orderId}`,
        method: 'POST',
        data: extend(data, {
            user_id: userId,
        }),
    })
}

export const saveOrderFormData = (orderId: string, data: any, userId: number) => {
    return callAxios({
        url: `order/saveOrderFormData/${orderId}`,
        method: 'POST',
        data: extend(data, {
            user_id: userId,
        }),
    })
}
