import { callAxios } from 'plugins/api'

export const parseExcelData = (payload: { file: any; is_non_hyster: boolean }) => {
    const formData = new FormData()
    formData.append('excel_file', payload.file)
    formData.append('is_non_hyster', payload.is_non_hyster ? 'true' : 'false')
    return callAxios({
        url: 'novo/file-upload',
        method: 'POST',
        data: formData,
        isHeffiq: true,
    })
}

export const getNovoConfigurationById = (configurationId: number) => {
    return callAxios({
        url: `novo/configurations/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getNovoConfigurationListByUser = () => {
    return callAxios({
        url: 'novo/my-configurations',
        method: 'GET',
        isHeffiq: true,
    })
}

export const updateNovoConfiguration = (configurationId: string, data: any) => {
    return callAxios({
        url: `novo/configurations/data/${configurationId}`,
        method: 'PUT',
        data,
        isHeffiq: true,
    })
}

export const getNovoConfigurationHistory = (configurationId: string) => {
    return callAxios({
        url: `novo/configurations/${configurationId}/versions`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getNovoConfigurationVersionDetail = (versionId: number, configurationId: number) => {
    return callAxios({
        url: `novo/configurations/${versionId}/versions/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getConnectSiteSurvey = (configurationId: string) => {
    return callAxios({
        url: `siteSurvey/connected/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getAllUnattachedSiteSurvey = (configurationId: string) => {
    return callAxios({
        url: `siteSurvey/match-configuration/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getConfigurationImage = (configurationId: number) => {
    return callAxios({
        url: `novo/configurations/${configurationId}/images`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const deleteNovoConfiguration = (configurationId: string) => {
    return callAxios({
        url: `novo/configurations/${configurationId}`,
        method: 'DELETE',
        isHeffiq: true,
    })
}

export const changeNovoOrNonNovoStatus = (configurationId: string) =>
    callAxios({
        url: `novo/configurations/${configurationId}/change-novo-status`,
        method: 'PUT',
        isHeffiq: true,
    })

export type AttchedSiteSurvey = {
    site_survey_id: number | null
    is_novo: boolean
}

export const attachSiteSurvey = (configurationId: string, data: AttchedSiteSurvey) => {
    return callAxios({
        url: `novo/configurations/${configurationId}/connect-site-survey`,
        method: 'PUT',
        data,
        isHeffiq: true,
    })
}
