import Table from 'components/table'
import { useTranslation } from 'react-i18next'
import { formatCurrency, getCurrencySymbol } from 'utils/currency/formatCurrency'
import { Collapse } from 'antd'
import Input from 'components/input'
import { useEffect } from 'react'
import { ORDER_TYPE } from 'utils/orderType'
import PanelHeader from './panelHeader'

export interface IExtraPricingComponent {
    attachment: any[]
    batteries: any[]
    batteryAccessories: any[]
    chargers: any[]
    exSolution: any[]
    prepList: any[]
    special: any[]
    hysterBatteries: any[]
    formik?: any
    totalPreplist: number
    totalChargers: number
    totalBatteries: number
    totalBatteryAccessories: number
    totalAttachments: number
    totalExSolutions: number
    totalSpecial: number
    totalExtra: number
    viewOnly?: boolean
    totalHysterBattery: number
    hideOptions?: boolean
}

const ExtraPricingComponent = (props: IExtraPricingComponent) => {
    const { t } = useTranslation()
    const { Panel } = Collapse
    const {
        attachment,
        batteries,
        batteryAccessories,
        chargers,
        exSolution,
        prepList,
        special,
        hysterBatteries,
        formik,
        totalAttachments,
        totalBatteries,
        totalBatteryAccessories,
        totalChargers,
        totalExSolutions,
        totalExtra,
        totalPreplist,
        totalSpecial,
        totalHysterBattery,
        viewOnly,
        hideOptions,
    } = props

    useEffect(() => {
        if (totalExtra && !viewOnly) {
            formik.setFieldValue('totalExtra', totalExtra)
        }
    }, [totalExtra])

    const prepListColumn: any = [
        {
            name: t('api.component'),
            selector: (row: any) => row.prep_list[0].component,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.prep_list[0].description,
            left: true,
        },
        {
            name: t('api.position'),
            selector: (row: any) => row.prep_list[0].position,
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const chargerColumn: any = [
        {
            name: t('api.brand'),
            selector: (row: any) => row.charger[0].charger_brand,
            left: true,
        },
        {
            name: t('api.type'),
            selector: (row: any) => row.charger[0].charger_type,
            left: true,
        },
        {
            name: t('api.battery_type'),
            selector: (row: any) => row.charger[0].battery_type,
            left: true,
        },
        {
            name: t('api.grid_power'),
            selector: (row: any) => row.charger[0].grid_power,
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const batteryColumn: any = [
        {
            name: t('api.id'),
            selector: (row: any) => row.battery[0].id,
            left: true,
        },
        {
            name: t('api.type'),
            selector: (row: any) => row.battery[0].battery_type,
            left: true,
        },
        {
            name: t('api.volt'),
            selector: (row: any) => row.battery[0].volt,
            left: true,
        },
        {
            name: t('api.ah'),
            selector: (row: any) => row.battery[0].ah,
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const batteryAccessoriesColumn: any = [
        {
            name: t('api.group'),
            selector: (row: any) => row.battery_accessories[0].group,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.battery_accessories[0].description,
            left: true,
        },
        {
            name: t('api.status'),
            selector: (row: any) => row.status,
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const hysterBatteryColumn: any = [
        {
            name: t('api.battery_type'),
            selector: (row: any) => row.hyster_batteries[0].battery_type,
            left: true,
        },
        {
            name: t('api.capacity'),
            selector: (row: any) =>
                `${row.hyster_batteries[0].volt} ${t('api.volt')} ${row.hyster_batteries[0].ah} ${t(
                    'api.ah',
                )}`,
            left: true,
        },
        {
            name: t('api.order_type'),
            selector: (row: any) =>
                row.order_type === ORDER_TYPE.COMPULSORY ? t('api.compulsory') : t('api.optional'),
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const attachmentColumn: any = [
        {
            name: t('api.model'),
            selector: (row: any) => row.model,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.description,
            left: true,
        },
        {
            name: t('api.order_type'),
            selector: (row: any) =>
                row.order_type === ORDER_TYPE.COMPULSORY ? t('api.compulsory') : t('api.optional'),
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const exSolutionColumn: any = [
        {
            name: t('api.model'),
            selector: (row: any) => row.model,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.description,
            left: true,
        },
        {
            name: t('api.order_type'),
            selector: (row: any) =>
                row.order_type === ORDER_TYPE.COMPULSORY ? t('api.compulsory') : t('api.optional'),
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    const specialColumn: any = [
        {
            name: t('api.model'),
            selector: (row: any) => row.model,
            left: true,
        },
        {
            name: t('api.description'),
            selector: (row: any) => row.description,
            left: true,
        },
        {
            name: t('api.order_type'),
            selector: (row: any) =>
                row.order_type === ORDER_TYPE.COMPULSORY ? t('api.compulsory') : t('api.optional'),
            left: true,
        },
        {
            name: t('api.price'),
            selector: (row: any) => row.rate,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.rate))),
        },
        {
            name: t('api.quantity'),
            selector: (row: any) => row.quantity,
            right: true,
        },
        {
            name: t('api.total_price'),
            selector: (row: any) => row.total_price,
            right: true,
            cell: (row: any) => formatCurrency(parseFloat(String(row.total_price))),
        },
    ]

    return (
        <div className={`w-full flex flex-col gap-2 ${viewOnly && 'mt-4'}`}>
            {!viewOnly && <p className="ml-1 font-semibold">{t('api.extra_items')}</p>}
            {attachment?.length === 0 &&
                batteries?.length === 0 &&
                batteryAccessories?.length === 0 &&
                chargers?.length === 0 &&
                exSolution?.length === 0 &&
                prepList?.length === 0 &&
                special?.length === 0 &&
                special?.length === 0 &&
                hysterBatteries?.length === 0 && (
                    <p className="p-2">{t('api.no_extra_item_selected')}</p>
                )}
            {!hideOptions && (
                <Collapse
                    defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8]}
                    ghost
                    expandIconPosition="end"
                    className="bg-white w-full"
                >
                    {prepList?.length > 0 && (
                        <Panel
                            className="bg-white extra-item-panel"
                            header={PanelHeader(
                                t('api.prep_lists'),
                                <p> {formatCurrency(totalPreplist)}</p>,
                            )}
                            key={1}
                        >
                            <Table columns={prepListColumn} data={prepList} striped />
                        </Panel>
                    )}
                    {chargers?.length > 0 && (
                        <Panel
                            className="bg-white extra-item-panel"
                            header={PanelHeader(
                                t('api.chargers'),
                                <p>{formatCurrency(totalChargers)}</p>,
                            )}
                            key={2}
                        >
                            <Table columns={chargerColumn} data={chargers} striped />
                        </Panel>
                    )}
                    {batteries?.length > 0 && (
                        <Panel
                            className="bg-white extra-item-panel"
                            header={PanelHeader(
                                t('api.batteries'),
                                <p>{formatCurrency(totalBatteries)}</p>,
                            )}
                            key={3}
                        >
                            <Table columns={batteryColumn} data={batteries} striped />
                        </Panel>
                    )}
                    {batteryAccessories?.length > 0 && (
                        <Panel
                            className="bg-white extra-item-panel"
                            header={PanelHeader(
                                t('api.battery_accessories'),
                                <p>{formatCurrency(totalBatteryAccessories)}</p>,
                            )}
                            key={4}
                        >
                            <Table
                                columns={batteryAccessoriesColumn}
                                data={batteryAccessories}
                                striped
                            />
                        </Panel>
                    )}
                    {hysterBatteries?.length > 0 && (
                        <Panel
                            className="bg-white extra-item-panel"
                            header={PanelHeader(
                                t('api.hyster_batteries'),
                                <p>{formatCurrency(totalHysterBattery)}</p>,
                            )}
                            key={5}
                        >
                            <Table columns={hysterBatteryColumn} data={hysterBatteries} striped />
                        </Panel>
                    )}
                    {special?.length > 0 && (
                        <Panel
                            className="bg-white extra-item-panel"
                            header={PanelHeader(
                                t('api.specials'),
                                <p>{formatCurrency(totalSpecial)}</p>,
                            )}
                            key={6}
                        >
                            <Table columns={specialColumn} data={special} striped />
                        </Panel>
                    )}
                    {attachment?.length > 0 && (
                        <Panel
                            className="bg-white extra-item-panel"
                            header={PanelHeader(
                                t('api.attachments'),
                                <p>{formatCurrency(totalAttachments)}</p>,
                            )}
                            key={7}
                        >
                            <Table columns={attachmentColumn} data={attachment} striped />
                        </Panel>
                    )}
                    {exSolution?.length > 0 && (
                        <Panel
                            className="bg-white extra-item-panel"
                            header={PanelHeader(
                                t('api.ex_solutions'),
                                <p>{formatCurrency(totalExSolutions)}</p>,
                            )}
                            key={8}
                        >
                            <Table columns={exSolutionColumn} data={exSolution} striped />
                        </Panel>
                    )}
                </Collapse>
            )}
            {!viewOnly && (
                <Input
                    horizontal
                    name="totalExtra"
                    disabled
                    type="number"
                    label={t('fields.net_additional_parts')}
                    customInputStyles="p-0 text-right"
                    inputWidth="w-[14rem]"
                    showOnlyCurrency
                    value={totalExtra.toFixed(0)}
                    leftContent={<p>{getCurrencySymbol()}</p>}
                />
            )}
        </div>
    )
}

export default ExtraPricingComponent
