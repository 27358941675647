import Loading from 'components/loading'
import Select, { Options } from 'components/select'
import moment from 'moment'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { ORDER_TYPE } from 'utils/orderType'
import { getExtraLogByDate, getExtraVersionTimeByConfigurationId } from '../queries/log'

const ExtraLogs = () => {
    const { t } = useTranslation()
    const { configurationId } = useParams()
    const [timeStamp, setTimeStamp] = useState<Options[]>([])
    const [leftDateDropdown, setLeftDateDropdown] = useState<string>()
    const [rightDateDropdown, setRightDateDropdown] = useState<string>()
    const [leftData, setLeftData] = useState<any>()
    const [rightData, setRightData] = useState<any>()

    const getLogData = useMutation(
        ['configurationLogs'],
        (params: { date: string; direction: 'left' | 'right' }) =>
            getExtraLogByDate(String(configurationId), params.date),
        {
            onSuccess(data, variables) {
                if (variables.direction === 'left') {
                    setLeftData(data.data[0])
                } else {
                    setRightData(data.data[0])
                }
            },
            onError: (error: Error) => {
                toast.error(String(t(error.message)) || t('message.error'))
            },
        },
    )

    const { isLoading: timeLoading } = useQuery(
        ['configurationLogs'],
        () => getExtraVersionTimeByConfigurationId(String(configurationId)),
        {
            enabled: !!configurationId,
            onSuccess: data => {
                if (data?.data) {
                    setTimeStamp(
                        data.data.map((x: string) => ({
                            label: moment(x).format('YYYY-MM-DD hh:mm:ss A'),
                            value: x,
                        })),
                    )
                }
                if (data?.data && data.data.length > 0) {
                    setLeftDateDropdown(data.data[0])
                    getLogData.mutate({
                        date: data.data[0],
                        direction: 'left',
                    })
                }
                if (data?.data && data.data.length > 1) {
                    setRightDateDropdown(data.data[1])
                    getLogData.mutate({
                        date: data.data[1],
                        direction: 'right',
                    })
                }
            },
            onError: (error: Error) => {
                toast.error(String(t(error.message)) || t('message.error'))
            },
        },
    )

    const handleLeftDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setLeftDateDropdown(e.target.value)
        getLogData.mutate({
            date: e.target.value,
            direction: 'left',
        })
    }

    const handleRightDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setRightDateDropdown(e.target.value)
        getLogData.mutate({
            date: e.target.value,
            direction: 'right',
        })
    }

    if (getLogData.isLoading || timeLoading) {
        return (
            <tr>
                <td colSpan={3} className="flex justify-center items-center">
                    <Loading />
                </td>
            </tr>
        )
    }

    return (
        <div className="flex flex-col gap-2">
            <table className="border border-gray border border-gray-collapse rounded min-w-[600px] max-w-4xl">
                <thead className="bg-gray-100">
                    <tr>
                        <th className="border border-gray p-2 text-start w-[520px]">
                            {t('api.attributes')}
                        </th>
                        <th className="border border-gray p-2">
                            <div className="flex items-center flex-col w-full">
                                <Select
                                    name="leftDateDropdown"
                                    options={timeStamp}
                                    showPlaceHolder
                                    placeHolderOption={t('api.select_date')}
                                    // isLoading={timeLoading || isRefetching}
                                    value={leftDateDropdown}
                                    onChange={handleLeftDateChange}
                                />
                                <div className="flex w-full">
                                    <div className="flex-1 border-gray p-2">{t('api.price')}</div>
                                    <div className="w-24 border-gray p-2 text-center">
                                        {t('api.quantity')}
                                    </div>
                                    <div className="flex-1 border-gray p-2">
                                        {t('api.total_price')}
                                    </div>
                                    <div className="flex-1 p-2 w-32 text-center">
                                        {t('api.optional')}
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th className="border border-gray p-2">
                            <div className="flex items-center flex-col w-full">
                                <Select
                                    options={timeStamp}
                                    // isLoading={timeLoading || isRefetching}
                                    value={rightDateDropdown}
                                    name="rightDateDropdown"
                                    showPlaceHolder
                                    placeHolderOption={t('api.select_date')}
                                    onChange={handleRightDateChange}
                                />
                                <div className="flex w-full">
                                    <div className="flex-1 border-gray p-2">{t('api.price')}</div>
                                    <div className="w-24 border-gray p-2 text-center">
                                        {t('api.quantity')}
                                    </div>
                                    <div className="flex-1 border-gray p-2">
                                        {t('api.total_price')}
                                    </div>
                                    <div className="flex-1 border border-gray p-2 w-32 text-center">
                                        {t('api.optional')}
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {leftData?.chargers?.length > 0 && (
                        <>
                            <tr>
                                <td className="border border-gray p-2 font-bold" colSpan={3}>
                                    {t('api.charger')}
                                </td>
                            </tr>
                            {leftData.chargers.map((x: any) => {
                                const rightDataItem = rightData?.chargers.find(
                                    (y: any) => y.id === x.id,
                                )
                                return (
                                    <tr key={x.id}>
                                        <td className="border border-gray">
                                            {x.charger[0].description}
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.rate || 0)}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {x?.quantity || 0}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.total_price || 0)}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {x.order_type === ORDER_TYPE.COMPULSORY
                                                        ? t('api.compulsory')
                                                        : t('api.optional')}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.rate
                                                        ? formatCurrency(rightDataItem.rate)
                                                        : '-'}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {rightDataItem?.quantity
                                                        ? rightDataItem.quantity
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.total_price
                                                        ? formatCurrency(rightDataItem.total_price)
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {rightDataItem?.order_type ===
                                                    ORDER_TYPE.COMPULSORY
                                                        ? t('api.compulsory')
                                                        : t('api.optional')}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    )}
                    {leftData?.batteries?.length > 0 && (
                        <>
                            <tr>
                                <td className="border border-gray p-2 font-bold" colSpan={3}>
                                    {t('api.battery')}
                                </td>
                            </tr>
                            {leftData.batteries.map((x: any) => {
                                const rightDataItem = rightData?.batteries.find(
                                    (y: any) => y.id === x.id,
                                )
                                return (
                                    <tr key={x.id}>
                                        <td className="border border-gray">
                                            {x.battery[0].description}
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.rate || 0)}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {x?.quantity || 0}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.total_price || 0)}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {x.order_type === ORDER_TYPE.COMPULSORY
                                                        ? t('api.compulsory')
                                                        : t('api.optional')}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.rate
                                                        ? formatCurrency(rightDataItem.rate)
                                                        : '-'}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {rightDataItem?.quantity
                                                        ? rightDataItem.quantity
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.total_price
                                                        ? formatCurrency(rightDataItem.total_price)
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {rightDataItem?.order_type
                                                        ? rightDataItem.order_type ===
                                                          ORDER_TYPE.COMPULSORY
                                                            ? t('api.compulsory')
                                                            : t('api.optional')
                                                        : ''}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    )}
                    {leftData?.battery_accessories?.length > 0 && (
                        <>
                            <tr>
                                <td className="border border-gray p-2 font-bold" colSpan={3}>
                                    {t('api.battery_accessory')}
                                </td>
                            </tr>
                            {leftData.battery_accessories.map((x: any) => {
                                const rightDataItem = rightData?.battery_accessories.find(
                                    (y: any) => y.id === x.id,
                                )
                                return (
                                    <tr key={x.id}>
                                        <td className="border border-gray">
                                            {x.battery_accessories[0].description}
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.rate || 0)}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {x?.quantity || 0}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.total_price || 0)}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {x.order_type === ORDER_TYPE.COMPULSORY
                                                        ? t('api.compulsory')
                                                        : t('api.optional')}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.rate
                                                        ? formatCurrency(rightDataItem.rate)
                                                        : '-'}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {rightDataItem?.quantity
                                                        ? rightDataItem.quantity
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.total_price
                                                        ? formatCurrency(rightDataItem.total_price)
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {rightDataItem
                                                        ? rightDataItem.order_type ===
                                                          ORDER_TYPE.COMPULSORY
                                                            ? t('api.compulsory')
                                                            : t('api.optional')
                                                        : '-'}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    )}
                    {leftData?.hyster_batteries?.length > 0 && (
                        <>
                            <tr>
                                <td className="border border-gray p-2 font-bold" colSpan={3}>
                                    {t('api.hyster_battery')}
                                </td>
                            </tr>
                            {leftData.prep_lists.map((x: any) => {
                                const rightDataItem = rightData?.hyster_batteries.find(
                                    (y: any) => y.id === x.id,
                                )
                                return (
                                    <tr key={x.id}>
                                        <td className="border border-gray p-2">
                                            {`${x.hyster_batteries[0].battery_type} ${x.hyster_batteries[0].volt} ${x.hyster_batteries[0].ah}`}
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.rate || 0)}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {x?.quantity || 0}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.total_price || 0)}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {x.order_type === ORDER_TYPE.COMPULSORY
                                                        ? t('api.compulsory')
                                                        : t('api.optional')}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.rate
                                                        ? formatCurrency(rightDataItem.rate)
                                                        : '-'}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {rightDataItem?.quantity
                                                        ? rightDataItem.quantity
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.total_price
                                                        ? formatCurrency(rightDataItem.total_price)
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {rightDataItem
                                                        ? rightDataItem.order_type ===
                                                          ORDER_TYPE.COMPULSORY
                                                            ? t('api.compulsory')
                                                            : t('api.optional')
                                                        : '-'}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    )}
                    {leftData?.prep_lists?.length > 0 && (
                        <>
                            <tr>
                                <td className="border border-gray p-2 font-bold" colSpan={3}>
                                    {t('api.preplist')}
                                </td>
                            </tr>
                            {leftData.prep_lists.map((x: any) => {
                                const rightDataItem = rightData?.prep_lists?.find(
                                    (y: any) => y.id === x.id,
                                )
                                return (
                                    <tr key={x.id}>
                                        <td className="border border-gray p-2">
                                            {x.prep_list[0].component}
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.rate || 0)}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {x?.quantity || 0}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.total_price || 0)}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {x?.order_type
                                                        ? x.order_type === ORDER_TYPE.COMPULSORY
                                                            ? t('api.compulsory')
                                                            : t('api.optional')
                                                        : '-'}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.rate
                                                        ? formatCurrency(rightDataItem.rate)
                                                        : '-'}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {rightDataItem?.quantity
                                                        ? rightDataItem.quantity
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.total_price
                                                        ? formatCurrency(rightDataItem.total_price)
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {rightDataItem
                                                        ? rightDataItem.order_type ===
                                                          ORDER_TYPE.COMPULSORY
                                                            ? t('api.compulsory')
                                                            : t('api.optional')
                                                        : '-'}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    )}
                    {leftData?.attachment?.length > 0 && (
                        <>
                            <tr>
                                <td className="border border-gray p-2 font-bold" colSpan={3}>
                                    {t('api.attachments')}
                                </td>
                            </tr>
                            {leftData.attachment.map((x: any) => {
                                const rightDataItem = rightData?.attachment?.find(
                                    (y: any) => y.id === x.id,
                                )
                                return (
                                    <tr key={x.id}>
                                        <td className="border border-gray p-2">
                                            {`${x.model} - ${x.description}`}
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.rate || 0)}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {x?.quantity || 0}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.total_price || 0)}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {x.order_type === ORDER_TYPE.COMPULSORY
                                                        ? t('api.compulsory')
                                                        : t('api.optional')}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.rate
                                                        ? formatCurrency(rightDataItem.rate)
                                                        : '-'}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {rightDataItem?.quantity
                                                        ? rightDataItem.quantity
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.total_price
                                                        ? formatCurrency(rightDataItem.total_price)
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {rightDataItem
                                                        ? rightDataItem.order_type ===
                                                          ORDER_TYPE.COMPULSORY
                                                            ? t('api.compulsory')
                                                            : t('api.optional')
                                                        : '-'}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    )}
                    {leftData?.ex_solution?.length > 0 && (
                        <>
                            <tr>
                                <td className="border border-gray p-2 font-bold" colSpan={3}>
                                    {t('api.ex_solutions')}
                                </td>
                            </tr>
                            {leftData.ex_solution.map((x: any) => {
                                const rightDataItem = rightData?.ex_solution.find(
                                    (y: any) => y.id === x.id,
                                )

                                return (
                                    <tr key={x.id}>
                                        <td className="border border-gray p-2">
                                            {`${x.model} - ${x.description}`}
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.rate || 0)}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {x?.quantity || 0}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.total_price || 0)}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {x.order_type === ORDER_TYPE.COMPULSORY
                                                        ? t('api.compulsory')
                                                        : t('api.optional')}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.rate
                                                        ? formatCurrency(rightDataItem.rate)
                                                        : '-'}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {rightDataItem?.quantity
                                                        ? rightDataItem.quantity
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.total_price
                                                        ? formatCurrency(rightDataItem.total_price)
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {rightDataItem
                                                        ? rightDataItem.order_type ===
                                                          ORDER_TYPE.COMPULSORY
                                                            ? t('api.compulsory')
                                                            : t('api.optional')
                                                        : '-'}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    )}
                    {leftData?.special?.length > 0 && (
                        <>
                            <tr>
                                <td className="border border-gray p-2 font-bold" colSpan={3}>
                                    {t('api.specials')}
                                </td>
                            </tr>
                            {leftData.special.map((x: any) => {
                                const rightDataItem = rightData?.special.find(
                                    (y: any) => y.id === x.id,
                                )

                                return (
                                    <tr key={x.id}>
                                        <td className="border border-gray p-2">
                                            {`${x.model} - ${x.description}`}
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.rate || 0)}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {x?.quantity || 0}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {formatCurrency(x?.total_price || 0)}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {x.order_type === ORDER_TYPE.COMPULSORY
                                                        ? t('api.compulsory')
                                                        : t('api.optional')}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex w-full">
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.rate
                                                        ? formatCurrency(rightDataItem.rate)
                                                        : '-'}
                                                </div>
                                                <div className="w-24 border border-gray p-2 text-center">
                                                    {rightDataItem?.quantity
                                                        ? rightDataItem.quantity
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 text-right">
                                                    {rightDataItem?.total_price
                                                        ? formatCurrency(rightDataItem.total_price)
                                                        : '-'}
                                                </div>
                                                <div className="flex-1 border border-gray p-2 w-32 text-center">
                                                    {rightDataItem
                                                        ? rightDataItem.order_type ===
                                                          ORDER_TYPE.COMPULSORY
                                                            ? t('api.compulsory')
                                                            : t('api.optional')
                                                        : '-'}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default ExtraLogs
