/* eslint-disable */
import { useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { Collapse } from 'antd'
import { useQuery } from 'react-query'
import { CreateConfigurationType, ManualAdjustmentType } from '../../views/createConfiguration'
import ConfigurationCard from '../details/configurationCard'
import Table from '../../../../components/table'
import PanelHeader from '../panelHeader'
import ManualAdjustment from './manualAdjustment'
import { ConfigurationQueries } from '../../queries/configuration'
import { useAuth } from '../../../auth/context/useAuth'
import Button from '../../../../components/button'
import { toast } from 'react-toastify'
import { errorToast } from '../../../../utils/toasterUtil'
import { getCurrencySymbol } from '../../../../utils/currency/formatCurrency'
import { useTranslation } from 'react-i18next'

interface ProductionSelectionProps {
    formik: FormikProps<CreateConfigurationType>
    showDealerFields: boolean
}

const calculateRetailPrice = (
    isOnlyPart: boolean,
    componentPrice: number,
    manualAdjustment: ManualAdjustmentType[],
    compatibleMachineParts: any,
): number => {
    let totalPrice = 0
    manualAdjustment.forEach((manualAdjustmentItem: ManualAdjustmentType) => {
        totalPrice +=
            manualAdjustmentItem.manual_adjustment_quantity *
            manualAdjustmentItem.manual_adjustment_price
    })
    compatibleMachineParts.forEach((compatibleMachine: any) => {
        totalPrice += compatibleMachine.price * compatibleMachine.quantity
    })
    if (!isOnlyPart) {
        totalPrice += parseFloat(String(componentPrice))
    }
    return totalPrice
}

const calculateTotalCostPrice = (
    companyRatesData: any,
    isOnlyPart: boolean,
    componentCostPrice: number,
    manualAdjustment: ManualAdjustmentType[],
    compatibleMachineParts: any,
): number => {
    let totalCostPrice = 0
    let handlingCost = 0
    manualAdjustment.forEach((manualAdjustmentItem: ManualAdjustmentType) => {
        totalCostPrice +=
            manualAdjustmentItem.manual_adjustment_quantity *
            manualAdjustmentItem.manual_adjustment_price
    })
    compatibleMachineParts.forEach((compatibleMachine: any) => {
        totalCostPrice += compatibleMachine.cost_price * compatibleMachine.quantity
    })
    if (!isOnlyPart) {
        totalCostPrice += componentCostPrice
    }
    handlingCost =
        companyRatesData?.data.find((rate: any) => rate.rate_name === 'Handling Cost')
            ?.rate_value || 0
    totalCostPrice += (handlingCost * totalCostPrice) / 100
    return totalCostPrice || 0
}

const calculateLandedCost = (companyDataRates: any): number => {
    return (
        companyDataRates?.data.find((rate: any) => rate.rate_name === 'Landed Cost')?.rate_value ||
        0
    )
}

const calculateMaxDiscountAvailable = (
    userMaxDiscount: number,
    maxDiscountCategory: number,
    totalPrice: number,
) => {
    let maxDiscount = 0
    if (maxDiscountCategory) {
        if (userMaxDiscount < maxDiscountCategory) {
            maxDiscount = (userMaxDiscount * totalPrice) / 100
        } else {
            maxDiscount = (maxDiscountCategory * totalPrice) / 100
        }
    }
    return maxDiscount
}

const ReviewConfiguration = (props: ProductionSelectionProps) => {
    const { formik, showDealerFields } = props
    const { Panel } = Collapse

    const { user, userExtra } = useAuth()
    const [panelIndex, setPanelIndex] = useState<number[]>([])
    const { t } = useTranslation()

    const isCostPriceVisible = !!userExtra.cost_price_visible
    const isProfitMarginVisible = !!userExtra.profit_margin_visible
    const isOemEditable = !!userExtra.oem_editable
    const isExtraDiscountVisible = !!userExtra.extra_discount_visible
    const isExtraDiscountVisibleAndEditable = !!userExtra.extra_discount_visible_and_editable
    const isExtraExtraDiscountEditable = !!userExtra.extra_extra_discount_editable
    const isSalesMarginVisible = !!userExtra.sales_margin_visible
    const isCustomerDiscountVisible = !!userExtra.customer_discount_visible
    const [totalCostPrice, setTotalCostPrice] = useState<number>(0)
    const [totalCostPriceAllMachine, setTotalCostPriceAllMachine] = useState<number>(0)
    const [maximumAvailableDiscount, setMaximumAvailableDiscount] = useState<number>(0)
    const [netTotal, setNetTotal] = useState<number>(0)
    const [netNetPurchasePrice, setNetNetPurchasePrice] = useState<number>(0)
    const [totalDiscount, setTotalDiscount] = useState<number>(0)
    const [maxDiscountCategory, setMaxDiscountCategory] = useState<number>(0)
    const [discountPercentageAmount, setDiscountPercentageAmount] = useState<number>(0)
    const [customerDiscountPercentage, setCustomerDiscountPercentage] = useState<number>(0)
    const [extraDiscountPercentageAmount, setExtraDiscountPercentageAmount] = useState<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [totalMargin, setTotalMargin] = useState<number>(0)
    const [retailMargin, setRetailMargin] = useState<number>(0)
    const [activePanelIndex, setActivePanelIndex] = useState<number[]>([])

    useEffect(() => {
        setActivePanelIndex([1, ...panelIndex])
    }, [panelIndex])

    const handleChangeActiveIndex = (index: number[]) => {
        setActivePanelIndex(index)
    }
    const handeSetPanelIndex = () => {
        const tempIndex = []
        for (let i = 0; i < formik.values.totalTypes.length; i++) {
            tempIndex.push(i + 2)
        }
        setPanelIndex(tempIndex)
    }

    useEffect(() => {
        handeSetPanelIndex()
    }, [formik.values.totalTypes])

    const handleSetDiscountPercentage = (amount: number) => {
        if (isNaN(amount)) {
            amount = 0
        }
        if (amount <= userExtra.max_discount && amount <= maxDiscountCategory) {
            if (
                (amount * formik.values.retailPrice) / 100 + formik.values.discountAmount <=
                maximumAvailableDiscount
            ) {
                formik.setFieldValue('discountPercentage', amount)
            } else {
                toast(
                    `${t(
                        'message.discount_percentage_cant_be_more_than',
                    )} ${maximumAvailableDiscount}`,
                    errorToast,
                )
            }
        } else {
            toast(
                `${t('message.discount_percentage_cant_be_more_than')} ${
                    userExtra.max_discount < maxDiscountCategory
                        ? userExtra.max_discount
                        : maxDiscountCategory
                }`,
                errorToast,
            )
        }
    }

    useEffect(() => {
        formik.setFieldValue(
            'retailPrice',
            calculateRetailPrice(
                formik.values.onlyParts,
                formik.values.machineConfigurationDetailData[0].price,
                formik.values.manualAdjustments,
                formik.values.selectedParts,
            ),
        )
        if (userExtra.is_dealer) {
            formik.setFieldValue('discountPercentage', userExtra.max_discount)
        }
    }, [])

    useEffect(() => {
        if (formik.values.retailPrice && formik.values.discountPercentage) {
            setDiscountPercentageAmount(
                (formik.values.retailPrice * formik.values.discountPercentage) / 100,
            )
        }
    }, [formik.values.retailPrice, formik.values.discountPercentage])

    useEffect(() => {
        const tempNetTotal =
            formik.values.retailPrice -
            discountPercentageAmount -
            formik.values.discountAmount -
            formik.values.dealerDiscountAmount
        setNetTotal(tempNetTotal)
    }, [
        formik.values.retailPrice,
        discountPercentageAmount,
        formik.values.discountAmount,
        formik.values.dealerDiscountAmount,
    ])

    useEffect(() => {
        const tempNetNetPurchasePrice = netTotal - formik.values.oemDiscount
        setNetNetPurchasePrice(tempNetNetPurchasePrice)
    }, [netTotal, formik.values.oemDiscount])

    useEffect(() => {
        const tempExtraDiscountPercentageAmount =
            (formik.values.extraDiscountPercentage * netNetPurchasePrice) / 100
        setExtraDiscountPercentageAmount(tempExtraDiscountPercentageAmount)
    }, [formik.values.extraDiscountPercentage, netNetPurchasePrice])

    useEffect(() => {
        const tempNetNetNetPurchasePrice = netNetPurchasePrice - extraDiscountPercentageAmount
        formik.setFieldValue('netNetNetPurchasePrice', tempNetNetNetPurchasePrice)
    }, [netNetPurchasePrice, extraDiscountPercentageAmount])

    useEffect(() => {
        if (formik.values.endUserSalesPrice) {
            const tempSalesMarginAmount =
                formik.values.endUserSalesPrice - formik.values.netNetNetPurchasePrice
            formik.setFieldValue('salesMarginAmount', tempSalesMarginAmount)
        }
    }, [formik.values.netNetNetPurchasePrice, formik.values.endUserSalesPrice])

    useEffect(() => {
        if (formik.values.salesMarginAmount) {
            const tempSalesMarginPercentage =
                (formik.values.salesMarginAmount / formik.values.netNetNetPurchasePrice) * 100
            formik.setFieldValue('salesMarginPercentage', tempSalesMarginPercentage)
        }
    }, [formik.values.netNetNetPurchasePrice, formik.values.salesMarginAmount])

    useEffect(() => {
        const tempCustomerDiscountPercentage =
            (Math.abs(
                formik.values.retailPrice -
                    (formik.values.netNetNetPurchasePrice + formik.values.salesMarginAmount),
            ) /
                formik.values.retailPrice) *
            100
        setCustomerDiscountPercentage(tempCustomerDiscountPercentage)
    }, [
        formik.values.netNetNetPurchasePrice,
        extraDiscountPercentageAmount,
        formik.values.salesMarginAmount,
        formik.values.retailPrice,
    ])

    useEffect(() => {
        setMaximumAvailableDiscount(
            calculateMaxDiscountAvailable(
                userExtra.max_discount,
                maxDiscountCategory,
                formik.values.retailPrice,
            ),
        )
    }, [maxDiscountCategory])

    useEffect(() => {
        const tempTotalCostPriceAllMachine = formik.values.quantity * totalCostPrice
        setTotalCostPriceAllMachine(tempTotalCostPriceAllMachine)
    }, [formik.values.netNetNetPurchasePrice, formik.values.quantity, totalCostPrice])

    const handelDealerDiscountChange = (amount: number) => {
        formik.setFieldValue('dealerDiscountAmount', amount || 0)
    }

    const handleDiscountAmountChange = (amount: number) => {
        if (Number.isNaN(amount)) {
            formik.setFieldValue('discountAmount', 0)
        } else {
            formik.setFieldValue('discountAmount', amount)
        }
    }

    const handleOemDiscountAmountChange = (amount: number) => {
        if (Number.isNaN(amount)) {
            formik.setFieldValue('oemDiscount', 0)
        } else {
            formik.setFieldValue('oemDiscount', amount)
        }
    }

    const handleExtraDiscountChanges = (amount: number) => {
        if (Number.isNaN(amount)) {
            formik.setFieldValue('extraDiscountPercentage', 0)
        } else {
            formik.setFieldValue('extraDiscountPercentage', amount || 0)
        }
    }

    const handleEndCustomerPurchasePriceChanges = (amount: number) => {
        if (Number.isNaN(amount)) {
            formik.setFieldValue('endUserSalesPrice', 0)
        } else {
            formik.setFieldValue('endUserSalesPrice', amount)
        }
    }

    const { isLoading: companyRatesLoading, data: companyRatesData } = useQuery(
        ['getCompanyRates'],
        () => ConfigurationQueries.getCompanyRates(),
        {
            refetchOnMount: false,
            refetchIntervalInBackground: false,
            refetchInterval: false,
            retryOnMount: false,
            refetchOnWindowFocus: false,
        },
    )

    useEffect(() => {
        setTotalCostPrice(
            calculateTotalCostPrice(
                companyRatesData,
                formik.values.onlyParts,
                formik.values.machineConfigurationDetailData[0].cost_price,
                formik.values.manualAdjustments,
                formik.values.selectedParts,
            ),
        )
        formik.setFieldValue('landedCost', calculateLandedCost(companyRatesData))
    }, [companyRatesData])

    useEffect(() => {
        const tempTotalPrice = formik.values.quantity * formik.values.netNetNetPurchasePrice
        setTotalPrice(tempTotalPrice)
    }, [
        formik.values.retailPrice,
        formik.values.discountAmount,
        discountPercentageAmount,
        formik.values.quantity,
        formik.values.netNetNetPurchasePrice,
    ])

    useEffect(() => {
        const tempTotalMargin = ((totalPrice - totalCostPriceAllMachine) / totalPrice) * 100
        setTotalMargin(tempTotalMargin || 0)
    }, [
        formik.values.retailPrice,
        formik.values.discountAmount,
        discountPercentageAmount,
        formik.values.quantity,
        totalCostPrice,
        totalCostPriceAllMachine,
        totalPrice,
        totalCostPriceAllMachine,
    ])

    useEffect(() => {
        let tempRetailMargin =
            ((formik.values.netNetNetPurchasePrice -
                totalCostPrice -
                (totalCostPrice * formik.values.landedCost) / 100) /
                formik.values.netNetNetPurchasePrice) *
            100
        setRetailMargin(tempRetailMargin || 0)
    }, [
        formik.values.retailPrice,
        formik.values.discountAmount,
        discountPercentageAmount,
        formik.values.quantity,
        totalCostPrice,
        formik.values.netNetNetPurchasePrice,
        formik.values.landedCost,
    ])

    const { isLoading: maxDiscountByRoleLoading, data: maxDiscountByRoleData } = useQuery(
        ['getMaxDiscountByRole', formik.values.productId, userExtra.role_id],
        () =>
            ConfigurationQueries.getMaxDiscountByRoleId({
                roleId: userExtra.role_id,
                machineCategoryNumber: formik.values.productId || 0,
            }),
        {
            enabled: !!formik.values.productId && !!userExtra.role_id,
            onSuccess: data => {
                setMaxDiscountCategory(data.data[0].max_discount_category)
            },
        },
    )

    const columns: any = [
        {
            id: 'machine_part_model_number',
            name: t('api.machine_part_model_number'),
            center: true,
            selector: (row: any) => {
                return (
                    <div className="flex align-center items-center gap-2">
                        <h1 className="text-semibold mb-2 text-primary text-sm text-center">
                            {row.machine_part_model_number
                                ? row.machine_part_model_number
                                : row.machine_category_model_number}
                        </h1>
                    </div>
                )
            },
        },
        {
            id: 'machine_part_name',
            name: t('api.machine_part_name'),
            selector: (row: any) => {
                return (
                    <p className="text-sm">
                        {' '}
                        {row.machine_part_name ? row.machine_part_name : row.machine_category}
                    </p>
                )
            },
        },
        {
            id: 'machine_part_remarks',
            name: t('api.machine_part_remarks'),
            selector: (row: any) => {
                return <p className="text-sm">{row.machine_part_remarks}</p>
            },
        },
        {
            id: 'machine_part_note',
            name: t('api.machine_part_note'),
            selector: (row: any) => {
                return <p className="text-sm">{row.machine_part_note}</p>
            },
        },
        {
            id: 'cost_price',
            name: t('api.cost_price'),
            omit: Boolean(userExtra?.cost_price_visible === 0),
            selector: (row: any) => {
                return <p className="text-sm">{parseFloat(row.cost_price).toFixed(2)}</p>
            },
        },
        {
            id: 'price',
            name: t('api.price'),
            selector: (row: any) => {
                return (
                    <p className="text-sm">
                        {getCurrencySymbol()} {parseFloat(row.price).toFixed(2)}{' '}
                        {row.is_included ? '(included)' : null}
                    </p>
                )
            },
        },
        {
            id: 'quantity',
            name: t('api.quantity'),
            center: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: any) => {
                return (
                    <div className="text-sm">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {row.quantity !== undefined
                            ? row.quantity
                            : formik.values.onlyParts
                            ? 0
                            : 1}
                    </div>
                )
            },
        },
        {
            id: 'total_price',
            name: t('api.total_price'),
            cell: (row: any) => {
                return (
                    <p className="text-sm">
                        {getCurrencySymbol()}{' '}
                        {row.total_price
                            ? parseFloat(row.total_price).toFixed(2)
                            : formik.values.onlyParts
                            ? (0).toFixed(2)
                            : (row.price * (row.quantity ? row.quantity : 1)).toFixed(2)}{' '}
                        {row.is_included ? '(included)' : null}
                    </p>
                )
            },
        },
    ]

    return (
        <div className="flex flex-col justify-between">
            <ConfigurationCard
                userRole={userExtra.role}
                showDealerView={showDealerFields}
                dealerDiscountAmount={formik.values.dealerDiscountAmount}
                handelDealerDiscountChange={handelDealerDiscountChange}
                currencySymbol={formik.values.currency}
                discountPercentage={formik.values.discountPercentage}
                discountPercentageAmount={discountPercentageAmount}
                netTotal={netTotal || 0}
                netNetPurchasePrice={netNetPurchasePrice}
                netNetNetPurchasePrice={formik.values.netNetNetPurchasePrice}
                configurationName={formik.values.productConfigurationName}
                productCategory={formik.values.productCategory}
                retailPrice={formik.values.retailPrice}
                discountAmount={formik.values.discountAmount}
                handleDiscountAmountChange={handleDiscountAmountChange}
                oemDiscountAmount={formik.values.oemDiscount}
                setDiscountPercentage={handleSetDiscountPercentage}
                handleOemDiscountAmountChange={handleOemDiscountAmountChange}
                extraDiscount={formik.values.extraDiscountPercentage}
                extraExtraDiscountPercentageAmount={extraDiscountPercentageAmount}
                handleExtraDiscountChanges={handleExtraDiscountChanges}
                customerDiscountPercentage={customerDiscountPercentage}
                endCustomerPurchasePrice={formik.values.endUserSalesPrice}
                handleEndCustomerPurchasePriceChanges={handleEndCustomerPurchasePriceChanges}
                salesMarginAmount={formik.values.salesMarginAmount}
                salesMarginPercentage={formik.values.salesMarginPercentage}
                configurationQuantity={formik.values.quantity}
                setConfigurationQuantity={formik.setFieldValue}
                totalCostPriceAllMachine={totalCostPriceAllMachine}
                totalPrice={totalPrice}
                totalMargin={totalMargin}
                retailMargin={retailMargin}
                // isExtraDiscountVisible={isExtraDiscountVisible}
                // isExtraExtraDiscountVisible={isExtraExtraDiscountEditable}
                // isExtraDiscountVisibleAndEditable={isExtraExtraDiscountEditable}
                isExtraExtraDiscountEditable={isExtraExtraDiscountEditable}
                isCostPriceVisible={isCostPriceVisible}
                isOemEditable={isOemEditable}
                isProfitMarginVisible={isProfitMarginVisible}
                isSalesMarginVisible={isSalesMarginVisible}
                isCustomerDiscountVisible={isCustomerDiscountVisible}
                isLibrary={formik.values.isLibrary}
                setIsLibrary={formik.setFieldValue}
                showIsLibrary={!window.location.href.includes('edit')}
                landedCost={formik.values.landedCost}
                tradeInValue={formik?.values?.trade_in_value || 0}
                setTradeInValue={(value: number) => formik.setFieldValue('trade_in_value', value)}
            />
            <div className="w-full">
                <Collapse
                    activeKey={activePanelIndex}
                    onChange={(keys: any) => handleChangeActiveIndex(keys)}
                >
                    <Panel header={PanelHeader({ title: t('api.component') })} key={1}>
                        <Table
                            columns={columns}
                            data={formik.values.machineConfigurationDetailData}
                            pagination={false}
                            striped={false}
                        />
                    </Panel>
                    {formik.values.totalTypes.length &&
                        formik.values.totalTypes.map(
                            (sequence: string, index: number) =>
                                formik.values.selectedParts &&
                                formik.values.selectedParts.filter(
                                    (machine: any) =>
                                        machine.machine_part_type.toUpperCase() ===
                                        sequence.toUpperCase(),
                                ).length && (
                                    <Panel
                                        header={PanelHeader({
                                            title: sequence,
                                            stat: `(${
                                                formik.values.selectedParts?.filter(
                                                    (machine: any) =>
                                                        machine.machine_part_type.toUpperCase() ===
                                                        sequence.toUpperCase(),
                                                ).length
                                            } ${t('api.total_parts')})`,
                                        })}
                                        key={index + 2}
                                    >
                                        <Table
                                            columns={columns}
                                            data={formik.values.selectedParts?.filter(
                                                (machine: any) =>
                                                    machine.machine_part_type.toUpperCase() ===
                                                    sequence.toUpperCase(),
                                            )}
                                            tableHeight="fit"
                                            noHeader
                                            pagination={false}
                                            striped={false}
                                        />
                                    </Panel>
                                ),
                        )}
                </Collapse>
            </div>
            {formik.values.manualAdjustments.length > 0 && (
                <div className="w-full">
                    <ManualAdjustment editable={false} formik={formik} />
                </div>
            )}
        </div>
    )
}

export default ReviewConfiguration
