import { callAxios } from 'plugins/api'

const configurationLog = 'configuration'

export const getLogsConfiguration = (configurationId: string) => {
    return callAxios({
        url: `${configurationLog}/getLogsForConfiguration/${configurationId}`,
        method: 'GET',
    })
}

export const getLogsChanges = (configurationId: string) => {
    return callAxios({
        url: `configuration/${configurationId}/log`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getPriceLogVersionTimeByConfigurationId = (configurationId: string) => {
    return callAxios({
        url: `change-log/price/date-list/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getGeneralLogVersionTimeByConfigurationId = (configurationId: string) => {
    return callAxios({
        url: `change-log/general/date-list/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getGeneralLogByDate = (configurationId: string, date: string) => {
    return callAxios({
        url: `change-log/general/${configurationId}`,
        method: 'POST',
        isHeffiq: true,
        data: {
            date,
        },
    })
}

export const getPriceLogByDate = (configurationId: string, date: string) => {
    return callAxios({
        url: `change-log/price/${configurationId}`,
        method: 'POST',
        isHeffiq: true,
        data: {
            date,
        },
    })
}

export const getExtraVersionTimeByConfigurationId = (configurationId: string) => {
    return callAxios({
        url: `change-log/extra/date-list/${configurationId}`,
        method: 'GET',
        isHeffiq: true,
    })
}

export const getExtraLogByDate = (configurationId: string, date: string) => {
    return callAxios({
        url: `change-log/extra/${configurationId}`,
        method: 'POST',
        isHeffiq: true,
        data: {
            date,
        },
    })
}
