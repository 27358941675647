/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Select } from 'antd'
import { useQuery } from 'react-query'
import { Link, useLocation } from 'react-router-dom'

import classNames from 'classnames'
import Loading from 'components/loading'
import Table from 'components/table'
import { useAuth } from 'modules/auth/context/useAuth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { getPillsColorForTS } from 'utils/pills'
import { getRequestTs } from '../query/request_ts'

const STATUS: any = {
    1: 'api.requested',
    2: 'api.done',
    3: 'api.rejected',
    4: 'api.servicer',
}

const OPTIONAL: any = {
    2: 'api.compulsory',
    1: 'api.optional',
}

const RequestTs = () => {
    const [filter, setFilter] = useState('all')
    const { state } = useLocation()
    const { t } = useTranslation()

    const {
        userExtra: { role_id },
    } = useAuth()

    const { isLoading, data } = useQuery(
        ['getRequestTs', state?.isRefetch || null],
        () => getRequestTs(role_id),
        {
            onError: (error: any) => {
                toast(error?.message || 'Something wend wrong')
            },
        },
    )

    const getRequestData = () => {
        if (data?.data === 'No Request Found' || !data?.data || data?.data === null) {
            return []
        }
        if (filter === 'all') {
            return data?.data?.data || []
        }
        return data?.data?.data.filter((item: any) => {
            return String(item?.status) === filter
        })
    }

    const options = [
        {
            label: t('api.all'),
            value: 'all',
        },
        { value: '1', label: t('api.requested') },
        {
            value: '2',
            label: t('api.done'),
        },
        {
            value: '3',
            label: t('api.rejected'),
        },
        {
            value: '4',
            label: t('api.servicer'),
        },
    ]

    if (isLoading) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    const columns = [
        {
            name: t('api.quote_number'),
            width: '200px',
            selector: (row: { quote_number: string }) => row.quote_number,
        },
        {
            name: t('api.extra'),
            left: true,
            selector: (row: { extra_type: string }) => (
                <span className="capitalize">{row.extra_type}</span>
            ),
        },
        {
            name: t('api.request_item_detail'),
            width: '160px',
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                const key = row.extra_type
                const rowData: any = row[key] && row[key][0]
                return <p>{rowData?.component || rowData?.description || row.extra_description}</p>
            },
        },
        {
            name: t('api.request_item_price'),
            width: '160px',
            center: true,
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                return (
                    <p>
                        {formatCurrency(
                            row?.extra_rate || row?.total_price
                                ? Number(row?.total_price) / Number(row?.quantity)
                                : '-',
                        )}
                    </p>
                )
            },
        },
        {
            name: t('api.total_request_item_price'),
            width: '160px',
            center: true,
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => {
                const key = row.extra_type
                const rowData: any = row[key] && row[key][0]
                return (
                    <p>
                        {rowData?.total_price ||
                            row.extra_rate ||
                            row.total_price / row.quantity ||
                            '-'}
                    </p>
                )
            },
        },
        {
            name: t('api.optional'),
            center: true,
            selector: (row: { type: string }) => (
                <span className="capitalize">{t(OPTIONAL[row.type])}</span>
            ),
        },
        {
            name: t('api.is_priority'),
            center: true,
            width: '10rem',
            selector: (row: { priority: string }) => {
                return row.priority ? (
                    <p className="text-bold">{String(row.priority)}</p>
                ) : (
                    <p>{t('api.no_priority')}</p>
                )
            },
        },
        {
            name: t('api.status'),
            center: true,
            width: '10rem',
            selector: (row: { status: any }) => {
                const color = getPillsColorForTS(row.status)
                return (
                    <div
                        className={classNames(
                            'capitalize font-bold',
                            color.bg,
                            color.text,
                            'rounded-full py-2 px-4 text-center',
                        )}
                    >
                        {t(STATUS?.[row?.status || 1])}
                    </div>
                )
            },
        },
        {
            name: t('api.action'),
            center: true,
            selector: (row: { id: number }) => (
                <Link to={`${row.id}/detail`} className="text-primary hover:text-black">
                    View Detail
                </Link>
            ),
        },
    ]

    // if (allowedRole) {
    //     if (!allowedRole.includes(role)) {
    //         return (
    //             <div className="h-[84vh] flex w-full justify-center items-center">
    //                 <p className="text-2xl">{t('api.you_are_not_allowed_to_access_this_page')}</p>
    //             </div>
    //         )
    //     }
    // }

    return (
        <div className="flex flex-col gap-4 bg-white h-screen w-full p-4 rounded-md overscroll-auto overflow-auto">
            <div className="flex justify-between items-center">
                <h1 className="text-secondary border-primary font-medium underline underline-offset-8 decoration-4 decoration-primary capitalize text-center">
                    {t('api.request_for_price')}
                </h1>
                <div className="md:w-[200px]">
                    <Select
                        className="w-fit md:w-full capitalize placeholder:capitalize"
                        defaultValue={filter}
                        onChange={(e: string) => setFilter(e)}
                    >
                        {options.map((option, index) => (
                            <Select.Option key={index} value={option.value} className="capitalize">
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="border rounded-md border-gray overflow-auto">
                <Table
                    columns={columns || []}
                    data={getRequestData() || []}
                    loading={isLoading}
                    striped
                    tableHeight="90%"
                    pagination
                    totalRows={15}
                />
            </div>
        </div>
    )
}

export default RequestTs
