/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import { Switch } from 'antd'
import Button from 'components/button'
import Input from 'components/input'
import AlertModal from 'components/modal/alertModal'
import DeleteModal from 'components/modal/deleteModal'
import Quantity from 'components/quantity'
import Table from 'components/table'
import TextArea from 'components/textArea'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { extend, remove } from 'lodash'
import {
    addExtraItemForAttachment,
    deleteManualExtraItemById,
    getConfigurationListByQuoteId,
    updateManualExtraItemById,
} from 'modules/quotes/queries/quotes'
import { sendRequestTs } from 'modules/quotes/queries/requestts'
import { useEffect, useState } from 'react'
import { BiTrashAlt } from 'react-icons/bi'
import { FiTrash } from 'react-icons/fi'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatCurrency, getCurrencySymbol } from 'utils/currency/formatCurrency'
import { getPillsColorForTS } from 'utils/pills'
import classNames from 'classnames'
import { successToast } from 'utils/toasterUtil'
import { ORDER_TYPE } from 'utils/orderType'
import { TbPlugConnected } from 'react-icons/tb'
import RequestTsConfirmationModal from '../request_ts/RequestTsConfirmationModal'
import ConnectExtraToConfigurationsModal from './ConnectExtraToConfigurationsModal'

interface AttachmentViewProps {
    columnKey: string
    initialValues?: any
    refetch?: () => void
    viewOnly?: boolean
    isAttachment?: boolean
}

const KeysInPurlarForColumns: any = {
    batteries: 'battery',
    chargers: 'charger',
    battery_accessories: 'battery accessories',
    prep_lists: 'prep list',
    attachment: 'attachment',
    ex_solution: 'ex_solution',
    'ex-solution': 'ex_solution',
    special: 'special',
    hyster_battery_list: 'hyster battery list',
}

const STATUS: any = {
    1: 'api.requested',
    2: 'api.done',
    3: 'api.rejected',
    4: 'api.servicer',
}

const AttachmentView = (props: AttachmentViewProps) => {
    const { columnKey, initialValues, refetch, viewOnly, isAttachment } = props
    const { configurationId, quoteId } = useParams()
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const [configurationList, setConfigurationList] = useState<any[]>([])

    const initialValue = {
        model: '',
        description: '',
        quantity: 0,
        price: 0,
        netTotal: 0,
        gross_price: 0,
        order_type: ORDER_TYPE.COMPULSORY,
        note: '',
        id: '',
        column: KeysInPurlarForColumns[columnKey] || columnKey,
        novo_configuration_id: configurationId,
        quote_id: quoteId,
        status: null,
        status_remarks: '',
        total_price: 0,
    }

    const updateQuantityExtraItem = useMutation(
        ['updateQuantityExtraItem'],
        (formData: number) => updateManualExtraItemById(formData),
        {
            onSuccess: () => {
                toast(t('message.update_extra_item_success'), successToast)
                refetch && refetch()
                queryClient.invalidateQueries(['extraItemData'])
            },
        },
    )

    const addExtraItemForAttachmentMutation = useMutation(
        'addExtraItem',
        (formData: any) =>
            addExtraItemForAttachment(
                formData[0].addToOther
                    ? formData[0].novo_configuration_id
                    : String(configurationId) || '',
                formData,
            ),
        {
            onSuccess: () => {
                toast(t('message.add_item_success'), successToast)
                refetch && refetch()
                queryClient.invalidateQueries(['extraItemData'])
            },
        },
    )

    const extraAttachmentFormik: any = useFormik({
        initialValues: {
            attachment: initialValues ? [...initialValues] : [initialValue],
        },
        validationSchema: Yup.object().shape({
            attachment: Yup.array().of(
                Yup.object().shape({
                    model: Yup.string().required(t('message.model_required')),
                    description: Yup.string().required(t('message.description_required')),
                    // quantity: Yup.number()
                    //     .positive(t('messgae.enter_quantity'))
                    //     .required(t('message.quantity_required')),
                }),
            ),
        }),
        onSubmit: (values: any) => {
            if (initialValues) {
                updateQuantityExtraItem.mutate(values.attachment)
            } else {
                addExtraItemForAttachmentMutation.mutate(values.attachment)
            }
        },
    })

    useEffect(() => {
        if (initialValues) {
            extraAttachmentFormik.setFieldValue('attachment', initialValues)
        }
    }, [initialValues])

    const conditionalRowStyles = [
        {
            when: (row: any) => row.order_type === ORDER_TYPE.OPTIONAL,
            style: {
                backgroundColor: 'rgb(44 128 61 / 34%)',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: (row: any) => row.order_type === ORDER_TYPE.COMPULSORY && !viewOnly,
            style: {
                backgroundColor: 'rgb(44 44 44 / 16%)',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ]

    return (
        <div className="w-full py-2">
            <div className="  flex items-end justify-end gap-4">
                {!viewOnly && (
                    <div>
                        <Button
                            onClick={() => extraAttachmentFormik.handleSubmit()}
                            label={initialValues ? t('api.update') : t('api.save_all')}
                            variant="outline"
                            isSmall
                        />
                    </div>
                )}

                {!initialValues && !viewOnly && (
                    <div>
                        <Button
                            onClick={() => {
                                extraAttachmentFormik.setFieldValue('attachment', [
                                    ...extraAttachmentFormik.values.attachment,
                                    initialValue,
                                ])
                            }}
                            label={t('api.add_more')}
                            variant="outline"
                            isSmall
                        />
                    </div>
                )}
            </div>
            <Table
                columns={[
                    {
                        name: t('api.model'),
                        left: true,
                        with: '120px',
                        cell: (row: { model: string; status: number }, index: number) => {
                            return (
                                <div>
                                    <Input
                                        placeholder={t('api.model')}
                                        value={row?.model}
                                        disabled={STATUS?.[row?.status] === 'rejected' || viewOnly}
                                        name={`attachment.${index}.model`}
                                        onChange={extraAttachmentFormik.handleChange}
                                    />
                                    {extraAttachmentFormik?.errors &&
                                        extraAttachmentFormik?.errors?.attachment &&
                                        extraAttachmentFormik?.errors?.attachment[index] &&
                                        extraAttachmentFormik?.errors?.attachment[index]?.model && (
                                            <p className="text-red-600 mt-1">
                                                {
                                                    extraAttachmentFormik?.errors?.attachment?.[
                                                        index
                                                    ]?.model
                                                }
                                            </p>
                                        )}
                                </div>
                            )
                        },
                    },
                    {
                        name: t('api.description'),
                        left: true,
                        width: '200px',
                        cell: (row: { description: string; status: number }, index: number) => {
                            return (
                                // <Input
                                // placeholder="Description"
                                // value={row?.description}
                                // name={`attachment.${index}.description`}
                                // onChange={extraAttachmentFormik.handleChange}
                                // />
                                <div className="py-1 w-full">
                                    <TextArea
                                        placeholder={t('message.description')}
                                        value={row?.description}
                                        disabled={STATUS?.[row?.status] === 'rejected' || viewOnly}
                                        name={`attachment.${index}.description`}
                                        onChange={extraAttachmentFormik.handleChange}
                                    />
                                    {extraAttachmentFormik?.errors &&
                                        extraAttachmentFormik?.errors?.attachment &&
                                        extraAttachmentFormik?.errors?.attachment[index] &&
                                        extraAttachmentFormik?.errors?.attachment[index]
                                            ?.description && (
                                            <p className="text-red-600 mt-1">
                                                {
                                                    extraAttachmentFormik?.errors?.attachment?.[
                                                        index
                                                    ]?.description
                                                }
                                            </p>
                                        )}
                                </div>
                            )
                        },
                    },
                    // {
                    //     name: 'Notes',
                    //     left: true,initialValues.length
                    //     cell: (row: { notes: string }, index: number) => {
                    //         return (
                    //             <Input
                    //                 placeholder="Notes"
                    //                 value={row?.notes}
                    //                 name={`attachment.${index}.notes`}
                    //                 onChange={extraAttachmentFormik.handleChange}
                    //             />
                    //         )
                    //     },
                    // },
                    {
                        name: t('api.quantity'),
                        center: true,
                        width: '120px',
                        cell: (
                            row: { quantity: number; price: number; status: number },
                            index: number,
                        ) => {
                            return (
                                <div className="flex flex-col justify-center items-center">
                                    <Quantity
                                        name={`attachment.${index}.quantity`}
                                        quantity={row.quantity}
                                        disabled={STATUS?.[row?.status] === 'rejected' || viewOnly}
                                        setQuantity={value => {
                                            extraAttachmentFormik?.setFieldValue(
                                                `attachment.${index}.quantity`,
                                                value,
                                            )
                                            extraAttachmentFormik?.setFieldValue(
                                                `attachment.${index}.netTotal`,
                                                value * row.price,
                                            )
                                        }}
                                    />
                                    {extraAttachmentFormik?.errors &&
                                        extraAttachmentFormik?.errors?.attachment &&
                                        extraAttachmentFormik?.errors?.attachment[index] &&
                                        extraAttachmentFormik?.errors?.attachment[index]
                                            ?.quantity && (
                                            <p className="text-red-600 mt-1">
                                                {
                                                    extraAttachmentFormik?.errors?.attachment?.[
                                                        index
                                                    ]?.quantity
                                                }
                                            </p>
                                        )}
                                </div>
                            )
                        },
                    },
                    {
                        name: t('api.gross_price'),
                        center: true,
                        width: '170px',
                        omit: !isAttachment,
                        cell: (
                            row: {
                                price: number
                                gross_price: string
                                quantity: number
                                status: number
                            },
                            index: number,
                        ) => {
                            return (
                                <div className="flex items-center justify-center gap-2 ">
                                    <div className="flex items-center justify-center gap-2">
                                        <p className="font-bold tex-lg">{getCurrencySymbol()}</p>
                                        <Input
                                            placeholder={t('api.gross_price')}
                                            type="number"
                                            // disabled={
                                            //     (Boolean(initialValues) &&
                                            //         columnKey !== 'attachment') ||
                                            //     STATUS?.[row?.status] === 'rejected'
                                            // }
                                            customInputStyles="w-32"
                                            disabled={viewOnly}
                                            value={row?.gross_price || row?.gross_price}
                                            name={`attachment.${index}.gross_price`}
                                            onChange={e => {
                                                const { value } = e.target
                                                extraAttachmentFormik?.setFieldValue(
                                                    `attachment.${index}.gross_price`,
                                                    value,
                                                )
                                            }}
                                        />
                                        {extraAttachmentFormik?.errors &&
                                            extraAttachmentFormik?.errors?.attachment &&
                                            extraAttachmentFormik?.errors?.attachment[index] &&
                                            extraAttachmentFormik?.errors?.attachment[index]
                                                ?.gross_price && (
                                                <p className="text-red-600 mt-1">
                                                    {
                                                        extraAttachmentFormik?.errors?.attachment?.[
                                                            index
                                                        ]?.gross_price
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </div>
                            )
                        },
                    },
                    {
                        name: t('api.price'),
                        center: true,
                        width: '170px',
                        cell: (
                            row: { price: number; rate: string; quantity: number; status: number },
                            index: number,
                        ) => {
                            return (
                                <div className="flex items-center justify-center gap-2 ">
                                    <div className="flex items-center justify-center gap-2">
                                        <p className="font-bold tex-lg">{getCurrencySymbol()}</p>
                                        <Input
                                            placeholder={t('api.price')}
                                            type="number"
                                            // disabled={
                                            //     (Boolean(initialValues) &&
                                            //         columnKey !== 'attachment') ||
                                            //     STATUS?.[row?.status] === 'rejected'
                                            // }
                                            disabled={viewOnly}
                                            customInputStyles="w-32"
                                            value={row?.price || row?.rate}
                                            name={`attachment.${index}.price`}
                                            onChange={e => {
                                                const { value } = e.target
                                                extraAttachmentFormik?.setFieldValue(
                                                    `attachment.${index}.price`,
                                                    value,
                                                )
                                                extraAttachmentFormik?.setFieldValue(
                                                    `attachment.${index}.netTotal`,
                                                    parseInt(value, 10) * row.quantity,
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        },
                    },
                    {
                        name: t('api.status'),
                        center: true,
                        wrap: true,
                        cell: (row: { status: number; status_remarks: string }, index: number) => {
                            const color = getPillsColorForTS(row.status)
                            const [requestTsModal, setRequestTsModal] = useState(false)
                            const [notes, setNotes] = useState('')
                            const [priorityDate, setPriorityDate] = useState<string>('')
                            const [descError, setDescError] = useState<boolean>(false)
                            const [dateError, setDateError] = useState<boolean>(false)

                            const handleRequestTsClose = () => {
                                setRequestTsModal(false)
                                setNotes('')
                            }
                            const requestTsMutation = useMutation(
                                (obj: any) => sendRequestTs(obj),
                                {
                                    onSuccess: () => {
                                        toast(t('message.requested_success'))
                                        setRequestTsModal(false)
                                        handleRequestTsClose()
                                        refetch && refetch()
                                        queryClient.invalidateQueries()
                                    },
                                },
                            )

                            const handleRequestAction = () => {
                                if (!priorityDate) {
                                    setDateError(true)
                                }
                                if (!notes) {
                                    setDescError(true)
                                }
                                const requestVaribles = extend(row, {
                                    remarks: notes,
                                    priority: priorityDate,
                                    novo_configuration_id: configurationId,
                                    quote_id: quoteId,
                                    update: true,
                                })
                                if (priorityDate && notes) {
                                    requestTsMutation.mutate(requestVaribles)
                                } else {
                                    setDateError(true)
                                    setDescError(true)
                                }
                            }
                            return (
                                <div className="flex  justify-start items-center gap-2 py-2">
                                    {row.status !== null ? (
                                        <div
                                            title={
                                                row.status !== null && row.status_remarks
                                                    ? row?.status_remarks
                                                    : ''
                                            }
                                            className={classNames(
                                                'capitalize cursor-pointer font-bold',
                                                color.bg,
                                                color.text,
                                                'rounded-full py-2 px-4 text-center',
                                            )}
                                        >
                                            {t(STATUS?.[row?.status || 1])}
                                        </div>
                                    ) : (
                                        <div>
                                            {columnKey !== 'attachment' ? (
                                                <div className="flex flex-col items-start gap-2">
                                                    <Button
                                                        variant="primary"
                                                        customButtonClass="h-8"
                                                        disabled={viewOnly}
                                                        label={t('api.request_for_ts')}
                                                        onClick={async () => {
                                                            const validationForm: any =
                                                                await extraAttachmentFormik.validateForm()
                                                            if (
                                                                validationForm.attachment?.[
                                                                    index
                                                                ] !== undefined
                                                            ) {
                                                                setRequestTsModal(false)
                                                            } else {
                                                                setRequestTsModal(true)
                                                            }
                                                        }}
                                                    />
                                                    <AlertModal
                                                        show={requestTsModal}
                                                        isSubmitting={requestTsMutation.isLoading}
                                                        content={
                                                            <RequestTsConfirmationModal
                                                                priorityDate={priorityDate}
                                                                setPriorityDate={value => {
                                                                    setPriorityDate(value)
                                                                    setDateError(false)
                                                                }}
                                                                notes={notes}
                                                                setNotes={value => {
                                                                    setNotes(value)
                                                                    setDescError(false)
                                                                }}
                                                                dateError={dateError}
                                                                descError={descError}
                                                            />
                                                        }
                                                        onConfirm={() => handleRequestAction()}
                                                        onCancel={() => handleRequestTsClose()}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            )
                        },
                    },
                    {
                        name: t('api.order_type'),
                        center: true,
                        cell: (row: { order_type: ORDER_TYPE; status: number }, index: number) => {
                            return (
                                <div>
                                    <Switch
                                        className="bg-green-700"
                                        // disabled={STATUS?.[row?.status] === 'rejected'}
                                        // eslint-disable-next-line no-unneeded-ternary
                                        defaultChecked={row.order_type !== ORDER_TYPE.COMPULSORY}
                                        checkedChildren={
                                            <span className="text-md font-bold">
                                                {t('api.yes')}
                                            </span>
                                        }
                                        disabled={viewOnly}
                                        unCheckedChildren={
                                            <span className="text-md font-bold">{t('api.no')}</span>
                                        }
                                        onChange={(checked: boolean) => {
                                            extraAttachmentFormik?.setFieldValue(
                                                `attachment[${index}].order_type`,
                                                checked
                                                    ? ORDER_TYPE.OPTIONAL
                                                    : ORDER_TYPE.COMPULSORY,
                                            )
                                        }}
                                    />
                                </div>
                            )
                        },
                    },
                    {
                        name: t('api.total'),
                        left: true,
                        width: '100px',
                        cell: (row: {
                            netTotal: string
                            totalPrice: number
                            total_price: number
                        }) => {
                            return (
                                <p className="text-black text-bold">
                                    {formatCurrency(
                                        row?.netTotal ||
                                            row?.totalPrice ||
                                            row?.total_price ||
                                            'N.V.T',
                                    )}
                                </p>
                            )
                        },
                    },
                    {
                        name: t('api.action'),
                        center: true,
                        omit: viewOnly,
                        cell: (row: any, index: number) => {
                            const [deleteExtraItem, setDeleteExtraItem] = useState(false)
                            const [showConnectExtraModel, setShowConnectExtraModel] =
                                useState<boolean>(false)
                            const deleteExtraItemMutation = useMutation(
                                () => deleteManualExtraItemById(row.id),
                                {
                                    onSuccess: () => {
                                        toast('Deleted extra item successfully')
                                        setDeleteExtraItem(false)
                                        refetch && refetch()
                                        queryClient.invalidateQueries(['extraItemData'])
                                    },
                                },
                            )

                            const configurationListMutation = useMutation(
                                [
                                    'getConfigurationsListByQuoteId',
                                    columnKey,
                                    row.model,
                                    row.serie_code,
                                ],
                                () =>
                                    getConfigurationListByQuoteId({
                                        column: columnKey,
                                        model: row.model,
                                        series: row.serie_code,
                                        quote_id: Number(quoteId),
                                        item_id: row.id,
                                    }),
                                {
                                    onSuccess: (data: any) => {
                                        setConfigurationList(
                                            data?.data?.data.map((configuration: any) => ({
                                                label: `${configuration.novo_quote_number} ${t(
                                                    'api.model',
                                                )}:${configuration.model} ${t('api.series')}:${
                                                    configuration.series
                                                }`,
                                                id: configuration.id,
                                                checked: false,
                                            })),
                                        )
                                    },
                                    onError: () => {
                                        toast.error(t('message.fetch_configuration_error'))
                                    },
                                },
                            )

                            return (
                                <div
                                    className="flex items-center justify-center w-full gap-4"
                                    key={row.id}
                                >
                                    <button
                                        type="button"
                                        title={t('api.connect_to_other_configurations')}
                                        className="cursor-pointer hover:text-blue-700"
                                        onClick={() => {
                                            configurationListMutation.mutate()
                                            setShowConnectExtraModel(true)
                                        }}
                                    >
                                        <TbPlugConnected size={20} />
                                    </button>
                                    {initialValues && initialValues.length !== 0 ? (
                                        <span
                                            className="cursor-pointer hover:text-blue-700"
                                            onClick={() => setDeleteExtraItem(true)}
                                        >
                                            <FiTrash size={20} />
                                        </span>
                                    ) : (
                                        <Button
                                            onClick={() => {
                                                const itemsRemaining = remove(
                                                    extraAttachmentFormik.values.attachment,
                                                    item => {
                                                        return (
                                                            extraAttachmentFormik.values.attachment.indexOf(
                                                                item,
                                                            ) !== index
                                                        )
                                                    },
                                                )
                                                extraAttachmentFormik.setFieldValue(
                                                    'attachment',
                                                    itemsRemaining,
                                                )
                                            }}
                                            variant="ghost"
                                            isSmall
                                            leftIcon={<BiTrashAlt size={20} />}
                                        />
                                    )}
                                    <AlertModal
                                        show={showConnectExtraModel}
                                        key={row.id}
                                        content={
                                            <ConnectExtraToConfigurationsModal
                                                id={row.id}
                                                netTotal={
                                                    Number(row.total_price) ||
                                                    Number(row.price) * Number(row.quantity)
                                                }
                                                order_type={row.order_type}
                                                price={row.price}
                                                quantity={row.quantity}
                                                configurationList={configurationList}
                                                setConfigurationList={setConfigurationList}
                                            />
                                        }
                                        isSubmitting={addExtraItemForAttachmentMutation.isLoading}
                                        onConfirm={() => {
                                            configurationList.forEach(c => {
                                                if (c.checked) {
                                                    addExtraItemForAttachmentMutation.mutate([
                                                        {
                                                            column: row.column,
                                                            description: row.description,
                                                            gross_price: row.gross_price || 0,
                                                            id: row.id,
                                                            model: row.model,
                                                            netTotal: row.total_price,
                                                            note: row.note,
                                                            novo_configuration_id: c.id,
                                                            order_type: row.order_type,
                                                            price: row.price,
                                                            quantity: row.quantity,
                                                            quote_id: quoteId,
                                                            status: row.status,
                                                            status_remark: row.status_remark || '',
                                                            addToOther: true,
                                                            total_price: row.total_price || 0,
                                                        },
                                                    ])
                                                }
                                            })
                                            setShowConnectExtraModel(true)
                                        }}
                                        onCancel={() => setShowConnectExtraModel(false)}
                                    />
                                    <DeleteModal
                                        title={t('api.delete_extra_item_title')}
                                        label={t('api.delete_extra_item_body')}
                                        modal={deleteExtraItem}
                                        onConfirm={() => deleteExtraItemMutation.mutate()}
                                        closeModal={() => setDeleteExtraItem(false)}
                                    />
                                </div>
                            )
                        },
                    },
                ]}
                data={extraAttachmentFormik?.values.attachment || []}
                pagination={false}
                striped={false}
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    )
}

export default AttachmentView
