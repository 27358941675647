/* eslint-disable react/no-array-index-key */
import { useAuth } from 'modules/auth/context/useAuth'
import { useQuery } from 'react-query'
import {
    MdOutlineBookmarkAdded,
    MdOutlineDocumentScanner,
    MdOutlineFormatListBulleted,
    MdOutlineLocationOn,
    MdOutlinePayments,
    MdOutlineSettingsSuggest,
} from 'react-icons/md'
import { Link } from 'react-router-dom'
import { DashboardQueries } from '../queries/dashboard'
import Loading from '../../../components/loading'
import useTranslationData from '../../translation/hooks/useTranslationData'

const dashboardCardMenu = [
    {
        label: 'site_survey',
        icons: <MdOutlineLocationOn size={48} />,
        key: 'site-survey',
        link: '/site-survey',
    },
    {
        label: 'novo_configurations',
        icons: <MdOutlineSettingsSuggest size={48} />,
        key: 'novo-configuration',
        link: '/novo-configuration',
    },
    {
        label: 'non_novo_configurations',
        icons: <MdOutlineSettingsSuggest size={48} />,
        key: 'non-novo-configuration',
        link: '/non-novo-configuration',
    },
    {
        label: 'quotes',
        icons: <MdOutlinePayments size={48} />,
        key: 'quote',
        link: '/quotes',
    },
    {
        label: 'orders',
        icons: <MdOutlineBookmarkAdded size={48} />,
        key: 'order',
        link: '/order',
    },

    {
        label: 'bulletins',
        icons: <MdOutlineFormatListBulleted size={48} />,
        key: 'bulletins',
        link: '/bulletin',
    },
    {
        label: 'policy_documents',
        icons: <MdOutlineDocumentScanner size={48} />,
        key: 'policy',
        link: '/policy',
    },
]

const Dashboard = () => {
    const {
        user: { id },
    } = useAuth()
    const {
        translation: { t },
    } = useTranslationData()
    const {
        data: dashboardCounts,
        isLoading,
        isFetching,
    } = useQuery(['getDiscountCount'], () => DashboardQueries.getDashboardCount({ userId: id }))

    const getCountValue = (type: string) => {
        switch (type) {
            case 'configuration':
                return dashboardCounts?.data?.configuration || 0
            case 'novo-configuration':
                return dashboardCounts?.data?.novo_configuration || 0
            case 'non-novo-configuration':
                return dashboardCounts?.data?.non_novo_configuration || 0
            case 'quote':
                return dashboardCounts?.data?.quote || 0
            case 'order':
                return dashboardCounts?.data?.order || 0
            case 'bulletins':
                return dashboardCounts?.data?.bulletin || 0
            case 'policy':
                return dashboardCounts?.data?.policy_document || 0
            case 'site-survey':
                return dashboardCounts?.data?.site_survey || 0
            default:
                return '0'
        }
    }

    if (isLoading || isFetching) {
        return (
            <div className="flex justify-center items-center h-full">
                <Loading />
            </div>
        )
    }

    return (
        <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {dashboardCardMenu.map(({ label, icons, key, link }) => (
                <li
                    className="bg-primaryLight border border-primary rounded-md w-full flex flex-col justify-between hover:shadow-md"
                    key={key}
                >
                    <Link to={link} className=" ">
                        <h3 className="font-medium text-xl border-b-2 border-primary overflow-hidden p-4">
                            {t(`api.${label}`)}
                        </h3>
                        <div className="flex justify-between h-full p-4">
                            <div className="h-8">{icons}</div>
                            <h3 className="text-5xl">{getCountValue(key)}</h3>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default Dashboard
