import { Select } from 'antd'
import Input from 'components/input'
import classNames from 'classnames'
import Fuse from 'fuse.js'

import Button from 'components/button'
import Loading from 'components/loading'
import Tabs, { TabType } from 'components/tabs'
import { ChangeEvent, useState } from 'react'
import { useQuery } from 'react-query'
import { orderBy } from 'lodash'
import { BiSearch } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import ExcelTable from './components/ExcelTable'
import { getNovoConfigurationListByUser } from './query'
import useTranslationData from '../translation/hooks/useTranslationData'

const cardClass = 'bg-white p-4 rounded-md flex flex-col h-full'
const tabs: TabType[] = [
    {
        name: 'api.novo_configuration',
        value: 'novo_configuration',
    },
]
const NovoConfiguration = () => {
    const {
        translation: { t },
    } = useTranslationData()
    const [status, setStatus] = useState('all')
    const [connectedStatus, setConnectedStatus] = useState('not-connected')
    const [query, setSearchQuery] = useState<string>('')
    const [, setActiveTab] = useState(tabs[0])

    const { isLoading, data, refetch } = useQuery(
        ['getNovoConfigurationList'],
        getNovoConfigurationListByUser,
    )

    const onChangeStatus = (value: string) => {
        setStatus(value)
    }

    const fuse = new Fuse(data ? data?.data?.data : [], {
        shouldSort: true,
        threshold: 0.3,
        keys: [
            'description',
            'created_by.name',
            'updated_by.name',
            'novo_quote_number',
            'part_number',
            'model',
        ],
    })

    const results = fuse.search(query)
    const novoConfigurationResults = query
        ? results.map(novoConfiguration => novoConfiguration.item)
        : data?.data?.data

    const getFilterData = () => {
        let tempDataList = novoConfigurationResults
        if (status !== 'all' && status === 'lithium_battery') {
            tempDataList = tempDataList.filter(
                (item: { lithium_battery: boolean }) => item.lithium_battery,
            )
        }
        if (status !== 'all' && status === 'deleted') {
            tempDataList = tempDataList.filter((item: { is_deleted: boolean }) => item.is_deleted)
        }

        if (connectedStatus !== 'all' && connectedStatus === 'connected') {
            tempDataList = tempDataList.filter(
                (item: { quote_id: number }) => item.quote_id !== null,
            )
        }

        if (connectedStatus !== 'all' && connectedStatus === 'not-connected') {
            tempDataList = tempDataList.filter(
                (item: { quote_id: number }) => item.quote_id === null || !item.quote_id,
            )
        }

        return orderBy(tempDataList, ['id'], ['desc'])
    }

    if (isLoading) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className={classNames(cardClass)}>
            <Tabs
                tabs={tabs || []}
                getActiveTab={(tab: TabType) => {
                    setActiveTab(tab)
                    refetch()
                }}
                leftContent={
                    <div className="flex gap-4 justify-end items-center">
                        <div className="flex flex-wrap md:justify-center justify-end items-center md:flex-nowrap gap-4">
                            <Input
                                placeholder={t('api.search')}
                                name="search"
                                type="text"
                                leftContent={<BiSearch />}
                                value={query}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setSearchQuery(e.target.value)
                                }}
                            />
                            {/* <Select
                                defaultValue="all"
                                placeholder={`${t('api.select')} ${t('api.status')}`}
                                size="large"
                                className="rounded-md w-fit"
                                onChange={onChangeStatus}
                            >
                                {[
                                    {
                                        value: 'all',
                                        label: t('api.all'),
                                    },
                                    {
                                        value: 'lithium_battery',
                                        label: t('api.lithium_battery'),
                                    },
                                    {
                                        value: 'deleted',
                                        label: t('api.deleted'),
                                    },
                                ].map(item => (
                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                ))}
                            </Select> */}
                            <Select
                                defaultValue="not-connected"
                                style={{ width: 240 }}
                                size="large"
                                className="rounded-md"
                                onChange={value => setConnectedStatus(value)}
                            >
                                {[
                                    {
                                        value: 'all',
                                        label: t('api.all'),
                                    },
                                    {
                                        value: 'connected',
                                        label: t('api.connected'),
                                    },
                                    {
                                        value: 'not-connected',
                                        label: t('api.not_connected'),
                                    },
                                ].map(item => (
                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                ))}
                            </Select>
                            <Link to="/novo-configuration/import">
                                <Button
                                    id="file_submit"
                                    label={t('api.upload')}
                                    variant="outline"
                                    type="button"
                                />
                            </Link>
                        </div>
                    </div>
                }
            />
            <div className="border rounded-md border-gray overflow-auto">
                <ExcelTable excelData={getFilterData() || []} isLoading={false} refetch={refetch} />
            </div>
        </div>
    )
}

export default NovoConfiguration
