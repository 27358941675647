/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'antd'
import { differenceWith, isEqual, map, omit, orderBy } from 'lodash'

import moment from 'moment'
import PanelHeader from 'modules/configuration/components/panelHeader'
import { PRICING_STATUS } from 'utils/pricingStatus'
import { CALCULATION_STATUS } from 'utils/calculationStatus'
import { ORDER_TYPE } from 'utils/orderType'
import { getCurrencySymbol } from 'utils/currency/formatCurrency'
import { getLogsChanges } from '../queries/log'
import Loading from '../../../components/loading'

const { Panel } = Collapse

const hasDecimalPlaces = (num: number) => {
    return num % 1 !== 0
}

const Summary = () => {
    const { configurationId } = useParams()
    const [summary, setSummary] = useState<any>([])
    const { t } = useTranslation()

    const { isLoading, isFetching } = useQuery(
        ['logResult'],
        () => getLogsChanges(configurationId || ''),
        {
            onSuccess: (data: any) => {
                const configurationPriceLog =
                    data?.data?.data?.configuration_price_log &&
                    data?.data?.data?.configuration_price_log.length
                        ? data?.data?.data?.configuration_price_log
                        : []
                const extraConfigLog =
                    data?.data?.data?.extra_config_log && data?.data?.data?.extra_config_log.length
                        ? data?.data?.data?.extra_config_log
                        : []
                const extraItemLog =
                    data?.data?.data?.extra_item_log && data?.data?.data?.extra_item_log.length
                        ? data?.data?.data?.extra_item_log
                        : []
                const extraMonthlyConfigurationPriceLog =
                    data?.data?.data?.monthly_configuration_price_log &&
                    data?.data?.data?.monthly_configuration_price_log.length
                        ? data?.data?.data?.monthly_configuration_price_log
                        : []
                if (data?.data) {
                    setSummary([
                        ...configurationPriceLog,
                        ...extraMonthlyConfigurationPriceLog,
                        ...extraConfigLog,
                        ...extraItemLog,
                    ])
                } else {
                    setSummary([])
                }
            },
        },
    )

    if (isLoading || isFetching) {
        return (
            <div className="flex justify-center items-center bg-white px-8 py-4 rounded-xl gap-4 max-h-[84vh] min-h-[84vh]">
                <Loading />
            </div>
        )
    }

    const calculateValue = (value: string, tag: string): string => {
        const isStatus = tag === 'status'
        const isPriceStatus = tag === 'price_status'
        const isSPRStatus = tag === 'spr_status'
        const isResidualValueStatus = tag === 'additional_residual_status'
        const isInvestmentStatus = tag === 'investment_status'
        const isDiscountStatus = tag === 'discount_service_status'
        const isDurationOfContract = tag === 'duration_of_contract'
        const isOrderType = tag === 'order_type'
        const isLeasePriceOption = tag === 'lease_price_options'

        let tempValue = value

        if (isLeasePriceOption) {
            tempValue = JSON.stringify(
                JSON.parse(value).map((item: any) => ({
                    ...item,
                    lease_price: parseFloat(String(item.lease_price)).toFixed(2),
                })),
            )
        }

        if (isStatus) {
            switch (parseFloat(value)) {
                case PRICING_STATUS.NEW:
                    tempValue = t('api.new')
                    break
                case PRICING_STATUS.DRAFT:
                    tempValue = t('api.draft')
                    break
                case PRICING_STATUS.REQUEST_FLEET:
                    tempValue = t('api.request_fleet')
                    break
                case PRICING_STATUS.FLEET_ADJUSTED:
                    tempValue = t('api.fleet_adjusted')
                    break
                case PRICING_STATUS.REQUEST_APPROVAL_PENDING:
                    tempValue = t('api.request_approval')
                    break
                case PRICING_STATUS.APPROVED:
                    tempValue = t('api.approved')
                    break
                case PRICING_STATUS.REQUEST_WORKSHOP:
                    tempValue = t('api.request_workshop')
                    break
                case PRICING_STATUS.WORKSHOP_ADJUSTED:
                    tempValue = t('api.workshop_adjusted')
                    break
                default:
                    break
            }
        }
        if (
            isPriceStatus ||
            isSPRStatus ||
            isResidualValueStatus ||
            isInvestmentStatus ||
            isDiscountStatus
        ) {
            switch (parseFloat(value)) {
                case CALCULATION_STATUS.DEFAULT:
                    tempValue = t('api.default')
                    break
                case CALCULATION_STATUS.ADJUSTED:
                    tempValue = t('api.adjusted')
                    break
                case CALCULATION_STATUS.APPROVED:
                    tempValue = t('api.approved')
                    break
                case CALCULATION_STATUS.RECALCULATE:
                    tempValue = t('api.recalculate')
                    break
                default:
                    break
            }
        }

        if (isOrderType) {
            switch (parseFloat(value)) {
                case ORDER_TYPE.OPTIONAL:
                    tempValue = t('api.optional')
                    break
                case ORDER_TYPE.COMPULSORY:
                    tempValue = t('api.compulsory')
                    break
                default:
                    break
            }
        }

        let oldValue = !isNaN(parseFloat(tempValue))
            ? hasDecimalPlaces(parseFloat(tempValue))
                ? parseFloat(tempValue).toFixed(2)
                : parseFloat(tempValue)
            : tempValue

        if (isDurationOfContract) {
            oldValue += ` ${t('api.months')}`
        }

        return String(oldValue)
    }

    const renderLeasePriceOption = (oldValue: any, newValue: any) => {
        const tempOldValue = differenceWith(
            JSON.parse(oldValue || '[]'),
            JSON.parse(newValue || '[]'),
            isEqual,
        )
        const tempNewValue = differenceWith(
            JSON.parse(newValue || '[]'),
            JSON.parse(oldValue || '[]'),
            isEqual,
        )

        return (
            <table className="w-full">
                <thead>
                    <th className="w-[12rem] text-center">{t('api.name')}</th>
                    <th className="w-[12rem] text-center">{t('api.lease_price')}</th>
                    <th className="w-[12rem] text-center">{t('api.quantity')}</th>
                    <th className="w-[12rem] text-center">{t('api.price')}</th>
                </thead>
                <tbody>
                    {tempNewValue.map((item: any) => {
                        const old = tempOldValue.find(oldvalue => oldvalue.name === item.name)
                        return (
                            <tr>
                                <td>{item.name}</td>
                                <td className="text-right px-4">
                                    {old && old.lease_price !== item.lease_price
                                        ? `${t('api.from')} ${parseFloat(
                                              String(old.lease_price),
                                          ).toFixed(2)} ${getCurrencySymbol()} ${t(
                                              'api.to',
                                          )}  ${getCurrencySymbol()}  ${parseFloat(
                                              String(item.lease_price),
                                          ).toFixed(2)}`
                                        : `${getCurrencySymbol()} ${parseFloat(
                                              String(item.lease_price),
                                          ).toFixed(2)}`}
                                </td>
                                <td className="text-right px-4">
                                    {old && old.quantity !== item.quantity
                                        ? `${t('api.from')} ${old.quantity} ${t('api.to')} ${
                                              item.quantity
                                          }`
                                        : item.quantity}
                                </td>
                                <td className="text-right px-4">
                                    {old && old.price !== item.price
                                        ? `${t('api.from')} ${getCurrencySymbol()} ${parseFloat(
                                              String(old.price),
                                          ).toFixed(2)} ${t(
                                              'api.to',
                                          )} ${getCurrencySymbol()} ${parseFloat(
                                              String(item.price),
                                          ).toFixed(2)}`
                                        : ` ${getCurrencySymbol()} ${parseFloat(
                                              String(item.price),
                                          ).toFixed(2)}`}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    return (
        <Collapse
            className="w-full"
            expandIconPosition="end"
            collapsible="icon"
            // defaultActiveKey={summary.map((log: any) => log.id)}
        >
            {orderBy(summary, ['updated_at'], ['desc']).map((log: any) => (
                <Panel
                    collapsible="icon"
                    className="w-full h-full bg-base"
                    header={PanelHeader({
                        title: String(moment(log?.updated_at).format('MMM, DD, YYYY h:mm A')),
                    })}
                    key={log.id}
                >
                    <table className="table-auto w-full">
                        <thead>
                            <tr>
                                <th className="p-2 whitespace-nowrap text-left text-md w-[200px]">
                                    Changes Field
                                </th>
                                {/* <th className="p-2 whitespace-nowrap text-center text-md w-[200px]">
                                    Version
                                </th> */}
                                <th className="p-2 whitespace-nowrap text-left text-md w-[200px]">
                                    Modifications
                                </th>
                                <th className="p-2 whitespace-nowrap text-left text-md w-[200px]">
                                    Modified By
                                </th>
                                <th className="p-2 whitespace-nowrap text-left text-md w-[200px]">
                                    Date Modified
                                </th>
                            </tr>
                        </thead>

                        <tbody className="text-sm divide-y divide-gray-100">
                            {map(
                                omit(log?.new_values, [
                                    'id',
                                    'configuration_price_id',
                                    'novo_config_id',
                                    'novo_configuration_id',
                                    'quote_id',
                                    'configuration_id',
                                    'updated_at',
                                    'created_by',
                                    'created_at',
                                ]),
                                (v, k) => {
                                    const oldValue = log?.old_values[k]
                                    return (
                                        <tr>
                                            <td className="p-2 whitespace-nowrap text-left text-md text-black w-[200px]">
                                                {t(`fields.${k}`)}
                                            </td>
                                            {/* <td className="p-2 whitespace-nowrap text-center text-md text-black w-[200px]">
                                            0
                                        </td> */}
                                            <td className="p-2 whitespace-nowrap text-left text-md text-black w-[200px]">
                                                {k === 'lease_price_options'
                                                    ? renderLeasePriceOption(oldValue, v)
                                                    : oldValue
                                                    ? `${t('api.from')} ${calculateValue(
                                                          oldValue,
                                                          k,
                                                      )} ${t('api.to')} ${calculateValue(v, k)}`
                                                    : `${t('api.set_to')} ${calculateValue(v, k)}`}
                                            </td>
                                            <td className="p-2 whitespace-nowrap text-left text-md text-black w-[200px]">
                                                {log?.user_name}
                                            </td>
                                            <td className="p-2 whitespace-nowrap text-left text-md text-black w-[200px]">
                                                {String(
                                                    moment(log?.updated_at).format(
                                                        'MMM, DD, YYYY h:mm A',
                                                    ),
                                                )}
                                            </td>
                                        </tr>
                                    )
                                },
                            )}
                        </tbody>
                    </table>
                </Panel>
            ))}
        </Collapse>
    )
}

export default Summary
