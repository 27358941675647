/* eslint-disable react/no-array-index-key */
import appConfig from '../../../app.json'
import useTranslationData from '../../translation/hooks/useTranslationData'

interface Props {
    hasOverlay?: boolean
}

const Footer = ({ hasOverlay = false }: Props) => {
    const { companyName } = appConfig
    const {
        translation: { t },
    } = useTranslationData()

    const someQuickMenu = [
        { name: 'disclaimer', link: '/disclaimer' },
        { name: 'privacy_statement', link: '/privacy' },
    ]

    const currentTime = new Date()

    return (
        <footer className={hasOverlay ? 'bg-blackWithTransparency' : ''}>
            <div
                className={
                    hasOverlay
                        ? 'text-[0.5rem] md:text-base flex flex-row flex-wrap justify-center items-center text-white'
                        : ' text-[0.5rem] md:text-base flex flex-row justify-center items-center flex-wrap '
                }
            >
                <div>
                    <p className="whitespace-nowrap text-sm">
                        &copy; {`${currentTime.getFullYear()} ${t('api.copyright')}`}
                    </p>
                </div>
                <div className="separator mx-2">|</div>
                {someQuickMenu.map(({ name, link }, index) => (
                    <div className="flex whitespace-nowrap items-center" key={name + index}>
                        <a href={link} className="hover:text-primary text-sm">
                            {t(`api.${name}`)}
                        </a>
                        {index < someQuickMenu.length - 1 && (
                            <div className="separator mx-2">|</div>
                        )}
                    </div>
                ))}
            </div>
        </footer>
    )
}

export default Footer
