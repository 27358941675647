import { ReactElement } from 'react'

const PanelHeader = (title: string, rightContent: ReactElement) => (
    <div className="flex w-11/12 justify-between">
        <p>{title}</p>
        <p>{rightContent}</p>
    </div>
)

export default PanelHeader
