/* eslint-disable react/no-danger */
import React from 'react'
import Splash from '../../../components/splash'

interface Props {
    isLoading: boolean
    isFetching: boolean
    title: string
    description: string
}

const LongDescription = ({ isLoading, isFetching, title, description }: Props) => {
    if (isLoading || isFetching) {
        return <Splash />
    }

    return (
        <div className="bg-white px-8 py-4 flex flex-col items-center gap-8">
            <h1 className="text-2xl font-bold capitalize text-center h-5">{title}</h1>
            <div
                className="container h-95 overflow-scroll px-20 text-lg py-5 tracking-wide break-words text-justify"
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    )
}

export default LongDescription
