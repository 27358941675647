/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { FeedbackRequest, FeedbackType, FormikFeedback } from '../models/Feedback'
import Select, { Options } from '../../../components/select'
import { FeedbackQueries } from '../queries/feedback'
import Button from '../../../components/button'
import { errorToast, successToast } from '../../../utils/toasterUtil'
import Input from '../../../components/input'
import { useAuth } from '../../auth/context/useAuth'
import Loading from '../../../components/loading'
import useTranslationData from '../../translation/hooks/useTranslationData'

const CustomerFeedback = () => {
    const { user } = useAuth()
    const {
        translation: { t },
    } = useTranslationData()
    const [options, setOptions] = useState<Options[] | []>([])
    const { isLoading } = useQuery(['getFeedbackType'], () => FeedbackQueries.getFeedbackType(), {
        onSuccess: (data: any) => {
            setOptions(
                data.data.map((item: FeedbackType) => {
                    return {
                        label: item.type,
                        value: item.id,
                    }
                }),
            )
        },
    })

    const saveFeedbackMutation = useMutation(
        ['saveFeedback'],
        (feedbackRequest: FeedbackRequest) => FeedbackQueries.addFeedback(feedbackRequest),
        {
            onSuccess: data => {
                toast(`${t('message.successfully_added')} ${t('api.feedback')}`, successToast)
            },
            onError: (error: any) => {
                toast(
                    error?.message || `${t('message.fail_adding) ${t(api.feedback)')}`,
                    errorToast,
                )
            },
        },
    )

    const formik: FormikProps<FormikFeedback> = useFormik<FormikFeedback>({
        initialValues: {
            username: user?.name || '',
            email: user?.email || '',
            files: null,
            feedback: '',
            feedback_type_id: '',
        },
        validationSchema: Yup.object().shape({
            feedback_type_id: Yup.string().required(
                `${t('api.feedback_type')} ${t('api.required')}`,
            ),
            feedback: Yup.string().required(`${t('api.feedback')} ${t('api.required')}`),
        }),
        onSubmit: async (values, { resetForm }: FormikHelpers<FormikFeedback>) => {
            await saveFeedbackMutation.mutateAsync({
                file_attachments: values?.files,
                problem_description: values.feedback,
                type_id: values.feedback_type_id,
            })
            resetForm()
        },
    })

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-3">
            <h1 className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full md:w-auto border-none md:border-b-2 md:border">
                {t('api.feedback_form')}
            </h1>
            <div className="w-full pt-5">
                <label className="block mb-1">{t('fields.name')}</label>
                <Input
                    value={formik.values.username || ''}
                    type="text"
                    name="username"
                    customInputStyles="w-full bg-white cursor-not-allowed"
                    disabled
                    onChange={formik.handleChange}
                />
            </div>
            <div className="w-full">
                <label className="block mb-1">{t('fields.email')}</label>
                <Input
                    value={formik.values.email || ''}
                    type="text"
                    name="email"
                    customInputStyles="w-full bg-white cursor-not-allowed"
                    disabled
                    onChange={formik.handleChange}
                />
            </div>
            <div className="w-full">
                <label className="block mb-1">{t('fields.feedback_type')}</label>
                <Select
                    options={options}
                    showPlaceHolder
                    placeHolderOption={t('fields.feedback_type_placeholder')}
                    customStyle="w-full"
                    OptionStyle="text-left"
                    name="feedback_type_id"
                    value={formik.values.feedback_type_id}
                    onChange={formik.handleChange}
                    disabled={
                        Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                    }
                />
                {formik.errors.feedback_type_id && (
                    <p className="text-validation-warning pt-1">{formik.errors.feedback_type_id}</p>
                )}
            </div>
            <div className="w-full">
                <label className="block mb-1">{t('fields.attachment')}</label>
                <div className="flex flex-col justify-start gap-1 w-ful border-2 border-inputBorder placeholder-placeholderColor rounded-lg">
                    <input
                        type="file"
                        name="files"
                        className="w-96 text-center py-2 px-2 w-full focus:outline-none rounded-lg"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(event: any) => {
                            formik.setFieldValue('files', event.currentTarget.files[0])
                        }}
                        disabled={
                            Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                        }
                    />
                </div>
            </div>
            <div className="w-full">
                <label className="block mb-1">{t('fields.problem_description')}</label>
                <textarea
                    rows={6}
                    name="feedback"
                    className="w-full border-2 border-inputBorder rounded-lg p-2"
                    value={formik.values.feedback}
                    onChange={formik.handleChange}
                    disabled={
                        Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                    }
                />
                {formik.errors.feedback && formik.touched.feedback && (
                    <p className="text-validation-warning pt-1">{formik.errors.feedback}</p>
                )}
            </div>
            <div className="w-full mt-4 flex justify-end">
                <div className="w-1/5">
                    <Button
                        label={t('api.submit')}
                        variant="primary"
                        disabled={
                            Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                        }
                        isLoading={
                            Boolean(formik.isSubmitting) || Boolean(saveFeedbackMutation.isLoading)
                        }
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomerFeedback
