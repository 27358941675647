import { FaMinus, FaPlus } from 'react-icons/fa'
import React from 'react'
import { toast } from 'react-toastify'
import Input from './input'
import { errorToast } from '../utils/toasterUtil'

interface QuantityProps {
    name: string
    quantity: number
    setQuantity: (quantity: number) => void
    disabled?: boolean
    min?: number
    max?: number
}

const formatQuantity = (quantity: number) => {
    if (quantity < 10) {
        return `0${quantity}`
    }
    return quantity
}

const Quantity = (props: QuantityProps) => {
    const { name, disabled, quantity, setQuantity, min, max } = props

    const increment = () => {
        if (!max) {
            setQuantity(quantity + 1)
            return
        }
        if (quantity < max) {
            setQuantity(quantity + 1)
        } else {
            toast('Quantity amount exceeded', errorToast)
        }
    }
    const decrement = () => {
        if (quantity > (min || 0)) {
            setQuantity(quantity - 1)
        }
    }

    return (
        <div className="flex gap-2 justify-start items-center ">
            {disabled ? (
                <p>{formatQuantity(quantity)}</p>
            ) : (
                <div className="flex gap-1 justify-end items-center">
                    <button
                        className={`${
                            quantity > (min || 0)
                                ? 'bg-primary hover:bg-black text-white'
                                : 'bg-gray'
                        } text-white p-2 rounded-full `}
                        type="button"
                        onClick={() => decrement()}
                    >
                        <FaMinus size={8} />
                    </button>
                    <div className="w-auto">
                        <Input
                            value={formatQuantity(quantity)}
                            type="text"
                            name={name}
                            customInputStyles="w-12 text-center"
                            center
                            onChange={e => Number(e.target.value)}
                        />
                    </div>
                    <button
                        className="bg-primary text-white p-2 rounded-full hover:bg-black hover:text-white"
                        type="button"
                        onClick={() => increment()}
                    >
                        <FaPlus size={8} />
                    </button>
                </div>
            )}
        </div>
    )
}

export default Quantity
