import { Collapse, Select } from 'antd'
import { useTranslation } from 'react-i18next'

import Input from 'components/input'

import Loading from 'components/loading'
import { filter, includes, map, pick, uniqBy, extend } from 'lodash'
import PanelHeader from 'modules/configuration/components/panelHeader'
import { getExtraItemData } from 'modules/quotes/queries/quotes'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { ORDER_TYPE } from 'utils/orderType'
import AttachmentView from './AttachmentView'
import ExtraItemTableList from './ExtraItemTableList'
import ExtraItemHooks from '../../hooks/extraItemHooks'

const { Panel } = Collapse

const KeysInPurlar: any = {
    batteries: 'battery',
    chargers: 'charger',
    battery_accessories: 'battery_accessories',
    prep_lists: 'prep_list',
    attachment: 'attachment',
    ex_solution: 'ex-solution',
    special: 'special',
    hyster_batteries: 'hyster battery list',
}

const KeysInPurlarForColumns: any = {
    batteries: 'battery',
    chargers: 'charger',
    battery_accessories: 'battery accessories',
    prep_lists: 'prep list',
    attachment: 'attachment',
    ex_solution: 'ex_solution',
    special: 'special',
    hyster_batteries: 'hyster battery list',
}

interface ExtraItemDataViewProps {
    configurationId: string
    viewOnly?: boolean
}

const ExtraItemDataView = (props: ExtraItemDataViewProps) => {
    const { configurationId, viewOnly } = props
    const { extraItemsObject } = ExtraItemHooks({
        configurationId,
    })

    const { t } = useTranslation()

    // const [extraItemsData, setExtraItemsData] = useState<any>([])
    const [filterQuery, setFilterQuery] = useState<string>('all')
    const [searchQuery, setSearchQuery] = useState<any>({
        charger: '',
        'prep list': '',
    })
    const [isEmptyExtraItems, setIsEmptyExtraItems] = useState<boolean>(false)

    const handleSearchChange = (value: string, key: string) => {
        setSearchQuery({
            ...searchQuery,
            [key]: value,
        })
    }

    const { isLoading, data, refetch } = useQuery(
        ['extraItemData', configurationId],
        () => getExtraItemData(parseInt(String(configurationId || 0), 10)),
        {
            onSuccess: extraItems => {
                // setExtraItemsData(extraItems?.data?.data)
                setIsEmptyExtraItems(
                    map(extraItems?.data?.data, (v, k) => {
                        if (v && v.length === 0) {
                            return 1
                        }
                        return 0
                    }).every(element => element === 1),
                )
            },
        },
    )
    if (isLoading) {
        return (
            <div>
                <Loading />
            </div>
        )
    }

    if (isEmptyExtraItems) {
        return (
            <div>
                <p className="text-md text-center font-bold">{t('api.no_extra_item')}</p>
            </div>
        )
    }

    const getComponents = (columnKey: string) => {
        const extraItemsData = data?.data?.data
        const mappedKey = KeysInPurlar[columnKey] ? KeysInPurlar[columnKey] : columnKey
        const displayKey = KeysInPurlarForColumns[columnKey]

        const getData = () => {
            const originalData = map(extraItemsData[columnKey], obj => {
                return extend(obj[mappedKey] && obj[mappedKey][0], {
                    id: obj.id,
                    quantity: obj.quantity,
                    netTotal: obj.total_price,
                    price: obj?.rate || obj?.price,
                    status: obj.status,
                    order_type: obj?.order_type || ORDER_TYPE.COMPULSORY,
                    status_remarks: obj?.request?.latest_status[0]?.remark || null,
                    part_number:
                        obj?.hyster_batteries?.length > 0 && obj?.hyster_batteries[0]?.part_number,
                    battery_type:
                        obj?.hyster_batteries?.length > 0 && obj?.hyster_batteries[0]?.battery_type,
                    volt: obj?.hyster_batteries?.length > 0 && obj?.hyster_batteries[0]?.volt,
                    ah: obj?.hyster_batteries?.length > 0 && obj?.hyster_batteries[0]?.ah,
                    hyster_description:
                        obj?.hyster_batteries?.length > 0 &&
                        `${t('api.battery_type')}: ${obj?.hyster_batteries[0]?.battery_type} ${
                            obj?.hyster_batteries[0]?.volt
                        } ${t('api.volt')} ${obj?.hyster_batteries[0]?.ah} ${t('api.ah')}`,
                    charger_id: obj?.charger_id,
                    battery_id: obj?.battery_id,
                    battery_acessories_id: obj?.battery_acessories_id,
                    hyster_battery_id: obj?.hyster_battery_id,
                    preplist_id: obj?.preplist_id,
                })
            })
            if (mappedKey === 'charger' && filterQuery !== 'all') {
                return filter(originalData, columnObject => {
                    return columnObject.load_time === filterQuery
                })
            }
            return originalData
        }

        switch (mappedKey.toLocaleLowerCase()) {
            case 'attachment':
                return (
                    <AttachmentView
                        refetch={refetch}
                        columnKey={columnKey}
                        viewOnly={viewOnly}
                        isAttachment
                        initialValues={
                            map(extraItemsData[columnKey], attachment => {
                                return extend(
                                    pick(attachment, [
                                        'description',
                                        'model',
                                        'quantity',
                                        'price',
                                        'rate',
                                        'totalPrice',
                                        'total_price',
                                        'gross_price',
                                        'order_type',
                                        'note',
                                        'id',
                                        'status',
                                    ]),
                                    {
                                        column: columnKey,
                                        price: attachment?.rate || attachment?.price || 0,
                                        status_remarks:
                                            attachment?.request?.latest_status[0]?.remark || null,
                                    },
                                )
                            }) || []
                        }
                    />
                )
            case 'ex-solution':
                return (
                    <AttachmentView
                        refetch={refetch}
                        columnKey={columnKey}
                        viewOnly={viewOnly}
                        initialValues={
                            map(extraItemsData[columnKey], attachment => {
                                return extend(
                                    pick(attachment, [
                                        'description',
                                        'model',
                                        'quantity',
                                        'price',
                                        'totalPrice',
                                        'total_price',
                                        'order_type',
                                        'note',
                                        'id',
                                        'status',
                                    ]),
                                    {
                                        column: columnKey,
                                        price: attachment?.rate || attachment?.price || 0,
                                        status_remarks:
                                            attachment?.request?.latest_status[0]?.remark || null,
                                    },
                                )
                            }) || []
                        }
                    />
                )
            case 'special':
                return (
                    <AttachmentView
                        refetch={refetch}
                        columnKey={columnKey}
                        viewOnly={viewOnly}
                        initialValues={
                            map(extraItemsData[columnKey], attachment => {
                                return extend(
                                    pick(attachment, [
                                        'description',
                                        'model',
                                        'quantity',
                                        'price',
                                        'totalPrice',
                                        'total_price',
                                        'order_type',
                                        'note',
                                        'id',
                                        'status',
                                    ]),
                                    {
                                        column: columnKey,
                                        status_remarks:
                                            attachment?.request?.latest_status[0]?.remark || null,
                                        price: attachment?.rate || attachment?.price || 0,
                                    },
                                )
                            }) || []
                        }
                    />
                )
            default:
                return (
                    <ExtraItemTableList
                        refetch={refetch}
                        onlyList
                        columnKey={KeysInPurlarForColumns[columnKey]}
                        searchKeys={extraItemsObject[displayKey]?.searchKeys}
                        columns={(extraItemsObject && extraItemsObject[displayKey]?.columns) || []}
                        searchQuery={searchQuery[displayKey] || ''}
                        data={getData() || []}
                        viewOnly={viewOnly}
                    />
                )
        }
    }

    return (
        <Collapse
            style={{ backgroundColor: 'transparent' }}
            className="w-full"
            defaultActiveKey={map(data?.data?.data, (k, v) => {
                return v
            })}
            expandIconPosition="end"
            collapsible="icon"
        >
            {map(data?.data?.data || [], (v, item: any) =>
                v.length ? (
                    <Panel
                        key={item}
                        collapsible="icon"
                        className="w-full bg-base"
                        header={PanelHeader({
                            title: KeysInPurlarForColumns[item],
                            content: (
                                <div className="flex justify-center items-center gap-2">
                                    {!includes(['attachment', 'ex-solution', 'special'], item) && (
                                        <div className="flex items-center -mt-1 gap-2">
                                            {item === 'chargers' && (
                                                <Select
                                                    value={`Load Time - ${filterQuery}`}
                                                    style={{
                                                        minWidth: '250px',
                                                    }}
                                                    onChange={value => setFilterQuery(value)}
                                                    className="rounded-md mt-1 capitalize"
                                                    size="large"
                                                    options={uniqBy(
                                                        [
                                                            {
                                                                label: t('api.all'),
                                                                value: 'all',
                                                            },
                                                            ...map(
                                                                data?.data &&
                                                                    data?.data?.data[item],
                                                                (load: { charger: any[] }) => {
                                                                    const charger = load.charger[0]
                                                                    return {
                                                                        label:
                                                                            charger?.load_time ||
                                                                            '',
                                                                        value:
                                                                            charger?.load_time ||
                                                                            '',
                                                                    }
                                                                },
                                                            ),
                                                        ],
                                                        'value',
                                                    )}
                                                />
                                            )}
                                            <Input
                                                placeholder={t('api.search')}
                                                value={searchQuery[item] || ''}
                                                name={`searchQuery-${item}`}
                                                onChange={e => {
                                                    e.stopPropagation()
                                                    handleSearchChange(e.target.value, item)
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            ),
                        })}
                    >
                        {getComponents(item)}
                    </Panel>
                ) : null,
            )}
        </Collapse>
    )
}

export default ExtraItemDataView
