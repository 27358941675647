/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import classNames from 'classnames'
import {
    Link,
    Outlet,
    useLocation,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom'

import { FiDownloadCloud, FiSend, FiUploadCloud } from 'react-icons/fi'
import { BsTrashFill } from 'react-icons/bs'
import { TiCancel } from 'react-icons/ti'
import { SiMatrix } from 'react-icons/si'
import { GrOverview } from 'react-icons/gr'
import { MdOutlineSwipe } from 'react-icons/md'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { GiHamburgerMenu } from 'react-icons/gi'
import { RiCloseFill } from 'react-icons/ri'
import { IoIosArrowBack } from 'react-icons/io'

import { getPillsColor } from 'utils/pills'
import { errorToast, successToast } from 'utils/toasterUtil'
import DeleteModal from 'components/modal/deleteModal'
import ButtonLoader from 'components/buttonLoader'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import useOutsideClick from 'hooks/useOutsideClick'
import { useTranslation } from 'react-i18next'
import { getQuoteDetail } from 'modules/quotes/queries/quotes'
import SubmenuHeading from 'components/submenuHeading'
import { IoNewspaperOutline } from 'react-icons/io5'
import { BiText } from 'react-icons/bi'
import { cancelOrder, deleteOrder, getOrderDetail, reOrder, submitOrder } from './queries/order'
import Loading from '../../components/loading'

const cardBox = 'bg-white drop-shadow-md rounded-md py-6 px-6 flex flex-col gap-4 overflow-y-auto'

type ContextType = {
    orderConfigurations: [] | null
    quoteDetailFromOrder: any
    isLoading: boolean
    currentConfiguration: any
    setCurrentModelName: (value?: string) => void
}

const OrderLayout = () => {
    const queryClient = useQueryClient()
    const sideMenuRef = useRef<any>()
    const location = useLocation()
    const { userExtra } = useAuth()
    const { t } = useTranslation()
    const [orderDetail, setOrderDetail] = useState<any>({})
    const [orderConfigurations, setOrderConfigurations] = useState([])
    const [currentModelName, setCurrentModelName] = useState<string>()
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const [color, setColor] = useState<any>({})
    const [quoteDetail, setQuoteDetail] = useState<any>({})
    const {
        user,
        userExtra: {
            order_form_visibility,
            project_overview_visible,
            cancel_order,
            submit_order_form,
        },
    } = useAuth()
    const { orderId } = useParams()
    const navigate = useNavigate()

    const handleBack = () => {
        if (
            window.location.href.indexOf('pricing') !== -1 ||
            window.location.href.indexOf('add-extra') !== -1 ||
            window.location.href.indexOf('is_novo') !== -1 ||
            window.location.href.indexOf('history') !== -1 ||
            window.location.href.indexOf('overview') !== -1 ||
            window.location.href.indexOf('translate-parts') !== -1
        ) {
            navigate(`/order/${orderId}/${quoteDetail.id}`)
        } else {
            navigate('/order')
        }
    }

    const { isLoading, refetch } = useQuery(
        ['orderDetail', orderId],
        () =>
            getOrderDetail({
                orderId: orderId || '',
                userId: user.id,
                isSupport: userExtra.role_id === ROLE_TYPE.technical_support,
            }),
        {
            onSuccess: data => {
                setOrderConfigurations(data?.data?.configurations || [])
                setOrderDetail(data?.data?.order[0] || null)
                setColor(getPillsColor(data?.data?.order[0].status))
            },
        },
    )

    useQuery(
        ['quoteDetail', orderDetail.quote_id],
        () =>
            getQuoteDetail(
                orderDetail.quote_id || '',
                user.id,
                userExtra.role_id === ROLE_TYPE.technical_support,
            ),
        {
            enabled: !!orderDetail?.quote_id,
            refetchOnMount: true,
            onSuccess: data => {
                setQuoteDetail(data?.data?.data?.quote || {})
            },
        },
    )

    const deleteOrderAction = useMutation(
        ['deleteOrder'],
        () => deleteOrder(orderDetail.order_id),
        {
            onSuccess: data => {
                if (data.data.message === 'Order Deleted successfully') {
                    toast(
                        data.data.message || `Failed to delete order ${orderDetail.order_name}`,
                        successToast,
                    )
                    navigate(-1)
                } else {
                    toast(
                        data.data.message || `Failed to delete order ${orderDetail.order_name}`,
                        errorToast,
                    )
                }
            },
            onError: (error: any) => {
                toast(
                    error?.message || `Failed to delete order ${orderDetail.order_name}`,
                    errorToast,
                )
            },
        },
    )

    const submitOrderAction = useMutation(
        ['submitOrder'],
        () => submitOrder(orderDetail.order_id),
        {
            onSuccess: data => {
                toast(
                    data.data.message || `Success to submit order ${orderDetail.order_name}`,
                    successToast,
                )
                queryClient.invalidateQueries(['orderList'])
                navigate('..')
            },
            onError: (error: any) => {
                toast(
                    error?.message || `Failed to delete order ${orderDetail.order_name}`,
                    errorToast,
                )
            },
        },
    )

    const handleDelete = () => {
        deleteOrderAction.mutate()
        setDeleteModal(false)
    }

    const handleReOrder = useMutation(
        'reOrder',
        () =>
            reOrder(orderDetail.order_id, user.id, userExtra.role === ROLE_TYPE.technical_support),
        {
            onSuccess: () => {
                toast.success(
                    `${t('api.order')} ${orderDetail.order_name} ${t('message.made_success')}`,
                )
                refetch()
            },
            onError: (error: any) => {
                toast(
                    error?.message || `${t('message.failed_to_order')} ${orderDetail.order_name}`,
                    errorToast,
                )
            },
        },
    )

    const handleCancelOrder = useMutation(
        'reOrder',
        () =>
            cancelOrder(
                orderDetail.order_id,
                user.id,
                userExtra.role === ROLE_TYPE.technical_support,
            ),
        {
            onSuccess: data => {
                toast.success(
                    `${t('api.order')} ${orderDetail.order_name} ${t(
                        'message.mark_cancel_success',
                    )}`,
                )
                refetch()
            },
            onError: (error: any) => {
                toast(
                    error?.message ||
                        `${t('message.failed_to_cancel_order')} ${orderDetail.order_name}`,
                    errorToast,
                )
            },
        },
    )

    const closeMenu = () => {
        setOpenMenu(false)
    }

    useOutsideClick(sideMenuRef, () => {
        if (openMenu) {
            setOpenMenu(false)
        }
    })

    if (isLoading) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    if (!isLoading && !orderDetail) {
        return (
            <div className="container my-5 text-center">
                <strong>{t('message.order_not_found_or_not_authorized')}</strong>
            </div>
        )
    }

    const getStatusPill = () => {
        const scolor = getPillsColor(
            orderDetail.status === 'pending order' ? 'pending' : 'submitted',
        )
        const baseType =
            String(orderDetail.status) === 'pending order' ? t(`api.pending`) : t(`api.submitted`)
        return (
            <div
                title={baseType}
                className={classNames(
                    'capitalize font-bold text-xs',
                    scolor.bg,
                    scolor.text,
                    'rounded-full py-2 px-4 overflow-hidden text-center',
                )}
            >
                {baseType}
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 h-full">
            <div className="flex items-center sticky top-0 z-50 bg-white rounded-md p-4">
                <div
                    ref={sideMenuRef}
                    className={classNames(
                        cardBox,
                        openMenu
                            ? 'block absolute z-50 top-4 md:top-12  right-5 w-[90%] md:w-1/4 max-w-[22rem] drop-shadow-2xl border borer-base-400 h-max-[70vh] z-[9] py-4'
                            : 'w-1/4 max-w-[22rem] md:relative top-0 left-0 md:drop-shadow-none md:border-none hidden z-[9]',
                    )}
                >
                    <div className="flex flex-col justify-start gap-8 items-center">
                        <div className="flex flex-col gap-3 w-full">
                            <SubmenuHeading
                                quoteName={orderDetail.quotation_name}
                                orderStatus={getStatusPill()}
                                customerName={orderDetail.quotation_for}
                                createdBy={orderDetail.name}
                            />
                        </div>
                    </div>
                    <hr
                        className="mt-4"
                        style={{
                            color: '#e6e6e6',
                        }}
                    />
                    <div className="flex flex-col gap-4">
                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => {
                                navigate(
                                    `/order/${orderDetail.order_id}/${orderDetail.quote_id}/download-document`,
                                )
                                closeMenu()
                            }}
                        >
                            <FiDownloadCloud size={24} />
                            <span>{t('api.download_document')}</span>
                        </div>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        />
                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => {
                                navigate(`${orderDetail.quote_id}/upload-document`)
                                closeMenu()
                            }}
                        >
                            <FiUploadCloud size={24} />
                            <span>{t('api.upload_document')}</span>
                        </div>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        />

                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => {
                                navigate(`${orderDetail.quote_id}/rental-contract`)
                                closeMenu()
                            }}
                        >
                            <IoNewspaperOutline size={24} />
                            <span>{t('api.rental_contract')}</span>
                        </div>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        />
                        <div
                            className={`flex items-center gap-4  ${
                                orderDetail?.status === 'submitted_order'
                                    ? 'text-slate-300 cursor-not-allowed'
                                    : 'hover:text-primary cursor-pointer'
                            }`}
                            onClick={() => {
                                if (orderDetail?.status === 'submitted_order') {
                                    return
                                }
                                submitOrderAction.mutate()
                                closeMenu()
                            }}
                        >
                            <FiSend size={24} />
                            <span>{t('api.submit_order')}</span>
                        </div>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        />

                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => {
                                navigate(`${orderDetail.quote_id}/free-text`)
                                closeMenu()
                            }}
                        >
                            <BiText size={24} />
                            <span>{t('api.free_text')}</span>
                        </div>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        />

                        <div
                            className="flex items-center gap-4 cursor-pointer hover:text-primary"
                            onClick={() => {
                                navigate(`${orderDetail.quote_id}/requirement-matrix`)
                                closeMenu()
                            }}
                        >
                            <SiMatrix size={24} />
                            <span>{t('api.requirement_matrix')}</span>
                        </div>
                        <hr
                            style={{
                                color: '#e6e6e6',
                            }}
                        />

                        {project_overview_visible ? (
                            <>
                                <a
                                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                                    href={`/quotes/${orderDetail.quote_id}/overview?type=project-overview`}
                                    onClick={() => closeMenu()}
                                >
                                    <GrOverview size={25} />
                                    <span>{t('api.proect_overview')}</span>
                                </a>
                                <hr
                                    style={{
                                        color: '#e6e6e6',
                                    }}
                                />
                            </>
                        ) : null}
                        {/* <div
                        className="flex items-center gap-4 cursor-pointer hover:text-primary"
                        onClick={() => {
                            downloadExcelDoc(orderDetail.quotation_file_xls)
                            closeMenu()
                        }}
                    >
                        <BsDownload size={24} />
                        <span>{t('api.download_excel_sheet')}</span>
                    </div>
                    <hr
                        style={{
                            color: '#e6e6e6',
                        }}
                    /> */}

                        {/* {order_form_visibility ? (
                        <>
                            <Link
                                to={`/order/${orderDetail.order_id}/order-form`}
                                onClick={() => closeMenu()}
                            >
                                <div className="flex items-center gap-4 cursor-pointer hover:text-primary">
                                    <BiEdit size={30} />
                                    <span>
                                        {orderDetail.customer_id
                                            ? submit_order_form
                                                ? t('api.submit_order_form')
                                                : t('api.update_order_form')
                                            : t('api.order_form')}
                                    </span>
                                </div>
                            </Link>
                            <hr
                                style={{
                                    color: '#e6e6e6',
                                }}
                            />
                        </>
                    ) : null} */}

                        {orderDetail.status === 'cancelled' ? (
                            <>
                                <div
                                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                                    onClick={() => {
                                        handleReOrder.mutate()
                                        closeMenu()
                                    }}
                                >
                                    <MdOutlineSwipe size={24} />
                                    <span>{t('api.re_order')}</span>
                                </div>
                                <hr
                                    style={{
                                        color: '#e6e6e6',
                                    }}
                                />
                            </>
                        ) : cancel_order ? (
                            <>
                                <div
                                    className="flex items-center gap-4 cursor-pointer hover:text-primary"
                                    onClick={() => {
                                        handleCancelOrder.mutate()
                                        closeMenu()
                                    }}
                                >
                                    <TiCancel size={24} />
                                    <span>{t('api.cancel_order')}</span>
                                </div>
                                <hr
                                    style={{
                                        color: '#e6e6e6',
                                    }}
                                />
                            </>
                        ) : null}

                        <div
                            className="flex items-center justify-start gap-4 cursor-pointer hover:text-primary"
                            onClick={() => {
                                setDeleteModal(!deleteModal)
                                closeMenu()
                            }}
                        >
                            {deleteOrderAction.isLoading ? (
                                <ButtonLoader />
                            ) : (
                                <>
                                    <BsTrashFill size={24} />
                                    <span>{t('api.delete_order')}</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex w-full items-center gap-3 justify-between flex-row-reverse">
                    <div
                        className="w-fit cursor-pointer"
                        title="open quote sidebar"
                        onClick={() => {
                            setOpenMenu(!openMenu)
                        }}
                    >
                        {openMenu ? <RiCloseFill size={30} /> : <GiHamburgerMenu size={30} />}
                    </div>
                    <div className="flex items-center justify-start gap-4">
                        <div
                            className="flex items-center hover:text-primary cursor-pointer"
                            onClick={handleBack}
                        >
                            <span>
                                <IoIosArrowBack size={24} color="#E30613" />
                            </span>
                            <span className="font-bold">{t('api.go_back')}</span>
                        </div>
                        <div className="w-fit">
                            <p className="text-lg text-primary">{orderDetail.order_name},</p>
                        </div>
                        <div className="flex items-start flex-col w-fit gap-1">
                            <p className="text-md text-placeholderColor">
                                {orderDetail.quotation_for}
                            </p>
                        </div>
                        {currentModelName && (
                            <div className="flex items-start flex-col w-fit gap-1">
                                <p className="text-md text-lg gap-2 text-placeholderColor">
                                    <span className="text-primary">{t('api.model')}:</span>
                                    {currentModelName}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={`bg-white ${
                    !location.pathname.includes('pricing-overview') && 'p-4 overflow-scroll'
                } rounded-lg flex-1 `}
            >
                <Outlet
                    context={{
                        orderConfigurations,
                        quoteDetail,
                        currentConfiguration: currentModelName,
                        setCurrentModelName,
                    }}
                />
            </div>
            <DeleteModal
                modal={deleteModal}
                closeModal={() => setDeleteModal(!deleteModal)}
                label={t('message.delete_order_title')}
                onConfirm={handleDelete}
            />
        </div>
    )
}

export default OrderLayout

export const useOrderLayout = () => {
    return useOutletContext<ContextType>()
}
