import Button from 'components/button'
import { SiMicrosoftazure } from 'react-icons/si'
import { useAuth } from '../context/useAuth'
import useTranslationData from '../../translation/hooks/useTranslationData'

const LoginForm = () => {
    const { loginUser, loading } = useAuth()

    const {
        translation: { t },
    } = useTranslationData()

    return (
        <form className="bg-transparent rounded-md flex flex-col gap-4 w-auto flex-1">
            <div className="my-2">
                <Button
                    label={t('api.login_with_azure')}
                    leftIcon={<SiMicrosoftazure />}
                    variant="primary"
                    isLoading={Boolean(loading)}
                    onClick={() => {
                        loginUser()
                    }}
                />
            </div>
        </form>
    )
}

export default LoginForm
