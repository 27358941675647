import { useTranslation } from 'react-i18next'
import HistoryTab from './components/HistoryTab'

const ConfigurationHistory = () => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col gap-4">
            <div>
                <p className="text-black text-2xl">{t('api.configuration_history')}</p>
                <hr className="mt-4 text-black border-[#E0E0E0]" />
            </div>
            <HistoryTab />
        </div>
    )
}

export default ConfigurationHistory
