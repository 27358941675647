import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { TextEditor } from 'components/TextEditor'
import Button from 'components/button'
import { useMutation, useQuery } from 'react-query'
import { useState } from 'react'
import { MdChevronLeft, MdChevronRight, MdContentCopy } from 'react-icons/md'
import parse from 'html-react-parser'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import RequirementMatrixQueries from '../queries/requirementMatrix'

interface IRequirementMatrixForm {
    question: any
    answer: any
    subjectId: number
}

const RequirementMatrixForm = (props: IRequirementMatrixForm) => {
    const { t } = useTranslation()
    const { orderId, quoteId } = useParams()

    const { question, answer, subjectId } = props

    const [demoAnswers, setDemoAnswers] = useState<any[]>()
    const [selectedDemoAnswerIndex, setSelectedDemoAnswerIndex] = useState<number>(0)

    useQuery(
        ['getDemoAnswerById', question.id],
        () => RequirementMatrixQueries.getDemoAnswerByQuestionId(question.id),
        {
            enabled: !!question.id,
            onSuccess: (data: any) => {
                setDemoAnswers(data.data.data)
            },
        },
    )

    const saveRequireMatrixToQuote = useMutation(
        ['addDemoAnswer'],
        (formData: { answer: string; quote_id: number; question_id: number }) =>
            RequirementMatrixQueries.addRequirementMatrixToQuote({
                answer: formData.answer,
                question_id: formData.question_id,
                quote_id: formData.quote_id,
                subject_id: subjectId,
            }),
        {
            onSuccess: () => {
                toast.success(t('message.requirement_matrix_save_success'))
            },
            onError: (error: { message: string }) => {
                toast.error(error?.message || t('message.requirement_matrix_save_failed'))
            },
        },
    )

    const updateRequireMatrixToQuote = useMutation(
        ['updateDemoAnswer'],
        (formData: { answer: string; answerId: number }) =>
            RequirementMatrixQueries.updateRequirementMatrixAnswer(formData.answerId, {
                answer: formData.answer,
            }),
        {
            onSuccess: () => {
                toast.success(t('message.requirement_matrix_save_success'))
            },
            onError: (error: { message: string }) => {
                toast.error(error?.message || t('message.requirement_matrix_save_failed'))
            },
        },
    )

    const formik = useFormik({
        initialValues: {
            answer: answer ? answer.answer : '',
        },
        validationSchema: Yup.object().shape({
            answer: Yup.string().required('Category is required'),
        }),
        onSubmit: values => {
            if (answer) {
                updateRequireMatrixToQuote.mutate({
                    answer: values.answer,
                    answerId: parseInt(String(answer.id), 10),
                })
            } else {
                saveRequireMatrixToQuote.mutate({
                    answer: values.answer,
                    question_id: question.id,
                    quote_id: parseInt(String(quoteId), 10),
                })
            }
        },
    })

    const copyText = () => {
        if (demoAnswers) {
            formik.setFieldValue(
                'answer',
                `${formik.values.answer}\n${demoAnswers[selectedDemoAnswerIndex].answer}`,
            )
        }
    }

    return (
        <form className="flex flex-col gap-4 justify-between">
            <div className="flex flex-col gap-4">
                {question ? (
                    <p className="my-2 text-lg">{t(`question.${question.question_key}`)}</p>
                ) : (
                    <p className="my-2 text-lg">{t('api.select_question_for_demo_answer')}</p>
                )}
                {demoAnswers ? (
                    <div className="flex gap-4 w-full">
                        {demoAnswers.length > 0 && (
                            <div
                                className={`p-2 bg-secondary h-fit cursor-pointer ${
                                    selectedDemoAnswerIndex === 0 ? 'bg-gray' : ''
                                }`}
                                onClick={() =>
                                    selectedDemoAnswerIndex !== 0 &&
                                    setSelectedDemoAnswerIndex(selectedDemoAnswerIndex - 1)
                                }
                            >
                                <MdChevronLeft size={24} className="text-white" />
                            </div>
                        )}
                        {demoAnswers.length > 0 ? (
                            <>
                                <p className="flex-1">
                                    {parse(demoAnswers[selectedDemoAnswerIndex].answer)}
                                </p>
                                <MdContentCopy
                                    size={24}
                                    className="hover:text-gray mt-2 cursor-pointer"
                                    onClick={() => {
                                        copyText()
                                    }}
                                />
                            </>
                        ) : (
                            <p>{t('api.no_demo_answer_exists')}</p>
                        )}
                        {demoAnswers.length > 0 && (
                            <div
                                className={`p-2 bg-secondary h-fit cursor-pointer ${
                                    selectedDemoAnswerIndex === demoAnswers.length - 1
                                        ? 'bg-gray'
                                        : ''
                                }`}
                                onClick={() =>
                                    selectedDemoAnswerIndex !== demoAnswers.length - 1 &&
                                    setSelectedDemoAnswerIndex(selectedDemoAnswerIndex + 1)
                                }
                            >
                                <MdChevronRight size={24} className="text-white" />
                            </div>
                        )}
                    </div>
                ) : (
                    <p>{t('api.select_question_for_demo_answer')}</p>
                )}
                <TextEditor
                    className="rounded-md"
                    value={formik.values.answer}
                    onChange={(value: string) => {
                        formik.setFieldValue('answer', value)
                    }}
                    errorMessage={
                        formik.touched.answer && formik.errors.answer
                            ? String(formik.errors.answer)
                            : ''
                    }
                />
            </div>
            <div className="flex w-full justify-end">
                <div className="w-24">
                    <Button
                        variant="primary"
                        onClick={() => formik.submitForm()}
                        label="Save"
                        isLoading={
                            saveRequireMatrixToQuote.isLoading ||
                            updateRequireMatrixToQuote.isLoading
                        }
                        disabled={
                            Boolean(orderId) ||
                            saveRequireMatrixToQuote.isLoading ||
                            updateRequireMatrixToQuote.isLoading
                        }
                    />
                </div>
            </div>
        </form>
    )
}

export default RequirementMatrixForm
