export const numberFormater = (value: number) => {
    let number = ''

    if (value < 1000) {
        number = String(value)
    }

    if (value / 1000 < 1000) {
        number = `${String(value / 1000)} K`
    } else {
        number = `${String(value / 1000000)} M`
    }

    return number
}
