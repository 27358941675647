import Loading from 'components/loading'
import { map } from 'lodash'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { getQuoteDetail } from '../queries/quotes'
import QuoteForm from '../components/QuoteForm'

const CreateQuote = () => {
    const { quoteId } = useParams()
    const { user, userExtra } = useAuth()

    const { data, isLoading } = useQuery(
        ['quoteDetail', quoteId],
        () =>
            getQuoteDetail(
                quoteId || '',
                user.id,
                userExtra.role_id === ROLE_TYPE.technical_support,
            ),
        {
            retryOnMount: true,
            enabled: !!quoteId,
        },
    )
    if (isLoading) {
        return <Loading />
    }

    return (
        <QuoteForm
            quoteData={data?.data?.data?.quote && data?.data?.data?.quote}
            configurations={
                data?.data?.data.configurations
                    ? map(data?.data?.data.configurations, item => {
                          return {
                              label: item?.name || item?.model,
                              value: item.id,
                              is_electric: item?.is_electric,
                          }
                      })
                    : []
            }
        />
    )
}

export default CreateQuote
