import Table from 'components/table'
import { useTranslation } from 'react-i18next'
import { FaBolt, FaLeaf } from 'react-icons/fa'
import OrderAction from '../component/OrderAction'
import { useOrderLayout } from '../OrderLayout'

const OrderDetail = () => {
    const { orderConfigurations, isLoading } = useOrderLayout()

    const { t } = useTranslation()

    const columns = [
        {
            name: t('api.id'),
            left: true,
            width: '5rem',
            selector: (row: { id: string }) => {
                return <p className="text-black text-bold">{row.id || '-'}</p>
            },
        },
        {
            name: '',
            width: '6rem',
            selector: (row: {
                description: string
                lithium_battery: boolean
                is_novo: boolean
                is_electric: boolean
            }) => {
                return (
                    <div className="py-2 flex items-center gap-2">
                        <p
                            className="text-red-500 text-lg text-bold cursor-pointer"
                            title="Novo Configuration"
                        >
                            {/* N: Novo, C: cpq */}
                            {row.is_novo ? 'N' : 'C'}
                        </p>
                        {row.lithium_battery && (
                            <div
                                title="This configuration is based on Lithium Battery"
                                className="text-green-700 cursor-pointer"
                            >
                                <FaLeaf size={15} />
                            </div>
                        )}
                        {row.is_electric && (
                            <div
                                title="This configuration is based on Electric"
                                className="text-yellow-400 cursor-pointer"
                            >
                                <FaBolt size={15} />
                            </div>
                        )}
                    </div>
                )
            },
        },
        {
            name: t('api.model'),
            width: '8rem',
            center: true,
            selector: (row: { model: string }) => {
                return <p className="text-black text-bold">{row.model || '-'}</p>
            },
        },
        {
            name: t('api.series'),
            center: true,
            width: '8rem',
            selector: (row: { series: string }) => {
                return <p className="text-black text-bold">{row.series || '-'}</p>
            },
        },
        {
            name: t('api.description'),
            height: 'fit-content',
            minWidth: '12rem',
            maxWidth: '16rem',
            wrap: true,
            selector: (row: { description: string }) => row.description,
        },
        {
            name: t('api.reference'),
            height: 'fit-content',
            wrap: true,
            minWidth: '12rem',
            maxWidth: '16rem',
            selector: (row: { reference: string }) => (
                <p title={row.reference} className="truncate cursor-pointer">
                    {row.reference}
                </p>
            ),
        },
        {
            name: t('api.quantity'),
            center: true,
            width: '6rem',
            cell: (row: { quantity: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold">{row.quantity}</p>
                    </div>
                )
            },
        },
        {
            name: t('api.action'),
            right: true,
            minWidth: '10rem',
            cell: (row: { id: string }) => <OrderAction configuration={row} />,
            sortable: true,
        },
    ]

    return (
        <div className="h-[100%]">
            <div>
                <p className="text-secondary border-primary font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {t('api.order_configuration')}
                </p>
                <hr className="mt-4 text-black border-[#E0E0E0]" />
            </div>
            <Table
                columns={columns}
                data={orderConfigurations || []}
                loading={isLoading}
                striped
                pagination={false}
                totalRows={5}
            />
        </div>
    )
}

export default OrderDetail
