/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import { Collapse, Dropdown, Select } from 'antd'
import Button from 'components/button'
import Input from 'components/input'
import { filter, includes, map, remove, uniq, uniqBy } from 'lodash'
import PanelHeader from 'modules/configuration/components/panelHeader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloseCircle, AiOutlineCloudDownload } from 'react-icons/ai'

import Modal from 'react-responsive-modal'
import { useParams } from 'react-router-dom'
import BatteryChargerInfo from '../components/BatteryChargerInfo'
import AttachmentView from '../components/extra/AttachmentView'
import ExtraItemDataView from '../components/extra/ExtraItemDataView'
import ExtraItemTableList from '../components/extra/ExtraItemTableList'
import OptionItem from '../components/extra/OptionItem'
import ExtraItemHooks from '../hooks/extraItemHooks'

const { Panel } = Collapse

type ExtraItemSearchProps = {
    [key: string]: string
}

interface AddExtraQuote {
    configuration?: string
    viewOnly?: boolean
}

const AddExtraQuote = (props: AddExtraQuote) => {
    const { configuration, viewOnly } = props
    const { configurationId } = useParams()
    const { extraItemsObject } = ExtraItemHooks({
        configurationId: configuration || configurationId,
    })

    const { t } = useTranslation()

    const [extraItemViewForm, setExtraItemViewForm] = useState<boolean>(false)
    const [modalScreen, setModalScreen] = useState<string>('options')
    const [extraItems, setExtraItems] = useState<string[]>([])
    const [activeKeys, setActiveKeys] = useState<string[]>([])
    const [addExtraModal, setAddExtraModal] = useState<boolean>(false)
    const [filterQuery, setFilterQuery] = useState<string>('all')
    const [searchQuery, setSearchQuery] = useState<ExtraItemSearchProps>({
        charger: '',
    } as ExtraItemSearchProps)

    const handleSearchChange = (value: string, key: string) => {
        setSearchQuery({
            ...searchQuery,
            [key]: value,
        })
    }

    const setExtraItemsArray = (screen: string) => {
        extraItemsObject[screen].listFunction()
        setExtraItems(uniq([...extraItems, screen]))
        setActiveKeys(uniq([...activeKeys, screen]))
        setAddExtraModal(false)
    }

    const getModalScreen = () => {
        switch (modalScreen) {
            case 'options':
                return <OptionItem setExtraItems={setExtraItemsArray} />
            default:
                return <p>{t('api.no_screen_defined')}</p>
        }
    }

    const getComponents = (columnKey: string, extraObject: any) => {
        const getData = () => {
            if (columnKey === 'charger' && filterQuery !== 'all') {
                return filter(extraObject && extraObject[columnKey]?.data, data => {
                    return data.load_time === filterQuery
                })
            }
            return extraObject && extraObject[columnKey]?.data
        }
        switch (columnKey) {
            case 'attachment':
                return <AttachmentView columnKey={columnKey} viewOnly={viewOnly} isAttachment />
            case 'ex-solution':
                return <AttachmentView columnKey={columnKey} viewOnly={viewOnly} />
            case 'special':
                return <AttachmentView columnKey={columnKey} viewOnly={viewOnly} />
            default:
                return (
                    <ExtraItemTableList
                        columnKey={columnKey}
                        searchKeys={extraObject[columnKey]?.searchKeys}
                        columns={(extraObject && extraObject[columnKey]?.columns) || []}
                        searchQuery={searchQuery[columnKey] || ''}
                        data={getData() || []}
                        viewOnly={viewOnly}
                    />
                )
        }
    }

    return (
        <div className={`${viewOnly ? '' : 'py-2'} flex gap-4 flex-col`}>
            <div className="flex justify-between items-center gap-8">
                {!viewOnly && (
                    <div className=" w-full">
                        <p className="text-secondary border-primary text-lg w-fit font-medium underline underline-offset-8 decoration-4 decoration-primary">
                            {t('api.extra_items')}
                        </p>
                    </div>
                )}
                {!viewOnly && (
                    <div className="flex items-center justify-end gap-4">
                        <Button
                            variant="outline"
                            customButtonClass="w-fit"
                            label="Add Extra Item"
                            onClick={() => {
                                setExtraItemViewForm(true)
                                setAddExtraModal(true)
                            }}
                        />
                        {extraItemViewForm && (
                            <Button
                                variant="outline"
                                customButtonClass="w-fit"
                                label="Back to list"
                                onClick={() => {
                                    setExtraItemViewForm(false)
                                    setAddExtraModal(false)
                                    setExtraItems([])
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            {!extraItemViewForm ? (
                <ExtraItemDataView
                    configurationId={configuration || configurationId || ''}
                    viewOnly={viewOnly}
                />
            ) : (
                <div>
                    <Collapse
                        style={{ backgroundColor: 'transparent' }}
                        className="w-full"
                        activeKey={activeKeys}
                        expandIconPosition="end"
                        collapsible="icon"
                        onChange={keys => setActiveKeys(keys as string[])}
                    >
                        {map(extraItems || [], (item: any) => {
                            return (
                                <Panel
                                    key={item}
                                    collapsible="icon"
                                    className="w-full h-full bg-base"
                                    header={PanelHeader({
                                        title: item,
                                        onHeaderClick: () => {
                                            extraItemsObject[item]?.listFunction()
                                        },
                                        content: (
                                            <div className="flex justify-center items-center gap-2">
                                                <div className="flex justify-center items-center">
                                                    {item === 'charger' || item === 'battery' ? (
                                                        <Dropdown
                                                            overlay={
                                                                <BatteryChargerInfo
                                                                    configurationId={
                                                                        configuration ||
                                                                        configurationId ||
                                                                        ''
                                                                    }
                                                                />
                                                            }
                                                            trigger={['click', 'hover']}
                                                        >
                                                            <div
                                                                className="bg-[#00c543] rounded-md px-4 py-2 capitalize text-white w-[300px] text-center"
                                                                onClick={e => e.stopPropagation()}
                                                            >
                                                                <p className="capitalize">
                                                                    {t('api.show_novo_data')}
                                                                </p>
                                                            </div>
                                                        </Dropdown>
                                                    ) : null}
                                                    <Button
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            extraItemsObject[item]?.exportFunction()
                                                        }}
                                                        label={t('api.export')}
                                                        variant="outline"
                                                        isSmall
                                                        customButtonClass="hidden"
                                                        leftIcon={
                                                            <AiOutlineCloudDownload size={20} />
                                                        }
                                                    />
                                                </div>

                                                {!includes(
                                                    ['attachment', 'ex-solution', 'special'],
                                                    item,
                                                ) && (
                                                    <div className="flex items-center -mt-1 gap-2">
                                                        {item === 'charger' && (
                                                            <Select
                                                                value={`Load Time - ${filterQuery}`}
                                                                style={{
                                                                    minWidth: '250px',
                                                                }}
                                                                onChange={value =>
                                                                    setFilterQuery(value)
                                                                }
                                                                className="rounded-md mt-1 capitalize"
                                                                size="large"
                                                                options={uniqBy(
                                                                    [
                                                                        {
                                                                            label: t('api.all'),
                                                                            value: 'all',
                                                                        },
                                                                        ...map(
                                                                            extraItemsObject &&
                                                                                extraItemsObject[
                                                                                    item
                                                                                ]?.data,
                                                                            (load: {
                                                                                load_time: string
                                                                            }) => {
                                                                                return {
                                                                                    label: load.load_time,
                                                                                    value: load.load_time,
                                                                                }
                                                                            },
                                                                        ),
                                                                    ],
                                                                    'value',
                                                                )}
                                                            />
                                                        )}
                                                        <Input
                                                            placeholder={t('api.search')}
                                                            value={searchQuery[item] || ''}
                                                            name={`searchQuery-${item}`}
                                                            onChange={e => {
                                                                e.stopPropagation()
                                                                handleSearchChange(
                                                                    e.target.value,
                                                                    item,
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                <div>
                                                    <Button
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            setExtraItems(
                                                                remove(extraItems, extra => {
                                                                    return extra !== item
                                                                }),
                                                            )
                                                            setActiveKeys(
                                                                remove(activeKeys, extra => {
                                                                    return extra !== item
                                                                }),
                                                            )
                                                        }}
                                                        variant="ghost"
                                                        isSmall
                                                        leftIcon={
                                                            <AiOutlineCloseCircle size={25} />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    })}
                                >
                                    {getComponents(item, extraItemsObject)}
                                </Panel>
                            )
                        })}
                    </Collapse>
                </div>
            )}

            <Modal
                center
                open={addExtraModal}
                onClose={() => {
                    setAddExtraModal(false)
                    setModalScreen('options')
                }}
                showCloseIcon={false}
                classNames={{
                    root: 'rounded-lg',
                    modal: 'rounded-lg w-[80%]',
                }}
            >
                {getModalScreen()}
            </Modal>
        </div>
    )
}

export default AddExtraQuote
