import { useTranslation } from 'react-i18next'
import Table from 'components/table'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AiOutlineEdit } from 'react-icons/ai'
import Input from 'components/input'
import { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import Fuse from 'fuse.js'
import ConfigurationPartTranslation from '../queries/configurationPartTranslation'

const TranslateConfigurationParts = () => {
    const { t } = useTranslation()
    const { configurationId } = useParams()
    const navigate = useNavigate()
    const [pattern, setPattern] = useState<string>('')

    const columns = [
        {
            name: t('api.part_number'),
            left: true,
            selector: (row: any) => row.part_number,
        },
        {
            name: t('api.description'),
            left: true,
            wrap: true,
            selector: (row: any) => row.description,
        },
        {
            name: t('api.translation'),
            left: true,
            wrap: true,
            selector: (row: any) => {
                return (
                    <div className="flex flex-col gap-2">
                        {row.translation && row.translation.length > 0 && (
                            <p className="text-left">{row.translation[0].value}</p>
                        )}
                    </div>
                )
            },
        },
        {
            name: t('api.action'),
            right: true,
            cell: (row: any) => (
                <div className="flex gap-2">
                    <AiOutlineEdit
                        size={25}
                        className="cursor-pointer"
                        onClick={() => {
                            navigate(`${row.part_number}/edit`)
                        }}
                    />
                </div>
            ),
        },
    ]

    const { data: configurationData, isLoading } = useQuery(
        ['getAllPartsByConfigurationId'],
        () =>
            ConfigurationPartTranslation.getAllPartsByConfigurationId(
                parseInt(String(configurationId), 10),
            ),
        {
            refetchOnMount: true,
            enabled: !!configurationId,
            onError: () => {
                toast.error(t('api.getAllPartsFailed'))
            },
        },
    )

    const fuse = new Fuse(configurationData?.data?.data || [], {
        shouldSort: true,
        threshold: 0.1,
        ignoreLocation: true,
        keys: ['part_number', 'description'],
    })

    const results: any = fuse.search(pattern)
    const finalResult = pattern.length
        ? results.map((b: { item: any }) => b.item)
        : configurationData?.data?.data
    return (
        <div className="h-full flex flex-col gap-8">
            <div className="flex w-full justify-between items-center">
                <p className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer">
                    {t('api.configuration_part_list')}
                </p>
                <div className="w-48">
                    <Input
                        type="text"
                        value={pattern}
                        name="search"
                        placeholder={t('api.search_part_number')}
                        leftContent={<BiSearch />}
                        onChange={event => {
                            setPattern(event.target.value)
                        }}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                data={finalResult || []}
                loading={isLoading}
                striped
                pagination
                totalRows={10}
                tableHeight="94%"
            />
        </div>
    )
}

export default TranslateConfigurationParts
