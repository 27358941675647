import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { ORDER_TYPE } from 'utils/orderType'
import Loading from 'components/loading'
import { Collapse } from 'antd'
import { getExtraByQuoteId } from '../queries/quotes'

const ExtraItems = (params: { quoteId: number }) => {
    const { quoteId } = params
    const { t } = useTranslation()
    const { Panel } = Collapse

    const { data: extraData, isLoading: extraLoading } = useQuery(
        ['extraData'],
        () => getExtraByQuoteId(String(quoteId)),
        {
            enabled: !!quoteId,
            onError: (error: any) => {
                toast.error(error?.message || `${t('api.failed_to_fetch_extra_data')}`)
            },
        },
    )

    if (extraLoading) {
        return <Loading />
    }

    return (
        <div className="flex flex-col max-h-[80vh] overflow-auto gap-2">
            {extraData?.data.data.map((extra: any, index: number) => (
                <div className="flex flex-col pr-2">
                    <Collapse
                        bordered
                        defaultActiveKey={extraData?.data.data.map((_: any, i: number) => i + 1)}
                        expandIconPosition="end"
                    >
                        <Panel
                            className="border border-slate-300 bg-white m-0"
                            header={
                                <p className="flex gap-2">
                                    <span className="font-bold">
                                        {t('api.series')}: {extra.series}
                                    </span>
                                    <span className="font-bold">
                                        {t('api.model')}: {extra.model}
                                    </span>
                                </p>
                            }
                            key={index + 1}
                        >
                            <div className="flex flex-col gap-2 px-2 pb-4">
                                <p className="mx-2 mt-2">{extra.description}</p>
                                {extra.chargers.length > 0 && (
                                    <div className="flex flex-col gap-2 p-2 border border-slate-300">
                                        <p className="ml-2 underline underline-offset-8 decoration-4 decoration-primary">
                                            {t('api.chargers')}
                                        </p>
                                        <table className="table-auto shadow-sm">
                                            <thead className="border-b border-slate-300">
                                                <tr>
                                                    <th className="p-2 align-top">
                                                        {t('api.brand')}
                                                    </th>
                                                    <th className="p-2 align-top">
                                                        {t('api.type')}
                                                    </th>
                                                    <th className="p-2 align-top">
                                                        {t('api.battery_type')}
                                                    </th>
                                                    <th className="p-2 align-top">
                                                        {t('api.grid_power')}
                                                    </th>
                                                    <th className="p-2  w-32 align-top text-right">
                                                        {t('api.price')}
                                                    </th>
                                                    <th className="p-2 w-12 text-center align-top">
                                                        {t('api.quantity')}
                                                    </th>
                                                    <th className="p-2  w-32 align-top text-right">
                                                        {t('api.total_price')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {extra.chargers.map((c: any) => (
                                                    <tr
                                                        className={`align-top ${
                                                            c.order_type === 1 && 'bg-green-200'
                                                        }`}
                                                    >
                                                        <td className="p-2 align-top even:rounded-l-lg ">
                                                            {c?.charger[0].charger_brand}
                                                        </td>
                                                        <td className="p-2 align-top ">
                                                            {c?.charger[0].charger_type}
                                                        </td>
                                                        <td className="p-2 align-top">
                                                            {c?.charger[0].battery_type}
                                                        </td>
                                                        <td className="p-2 align-top">
                                                            {c?.charger[0].plug_type}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right ">
                                                            {formatCurrency(
                                                                parseFloat(String(c.rate)),
                                                            )}
                                                        </td>
                                                        <td className="p-2 align-top w-12 text-center ">
                                                            {c?.quantity}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right even:rounded-r-lg ">
                                                            {formatCurrency(
                                                                parseFloat(String(c.total_price)),
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {extra.batteries.length > 0 && (
                                    <div className="flex flex-col gap-2 p-2 border border-slate-300">
                                        <p className="ml-2 underline underline-offset-8 decoration-4 decoration-primary">
                                            {t('api.battery')}
                                        </p>
                                        <table className="table-auto">
                                            <thead className="border-b border-slate-300">
                                                <tr>
                                                    <th className="p-2 align-top">{t('api.id')}</th>
                                                    <th className="p-2 align-top">
                                                        {t('api.type')}
                                                    </th>
                                                    <th className="p-2 align-top">
                                                        {t('api.volt')}
                                                    </th>
                                                    <th className="p-2 align-top">{t('api.ah')}</th>
                                                    <th className="p-2  w-32 align-top text-right">
                                                        {t('api.price')}
                                                    </th>
                                                    <th className="p-2 w-12 text-center align-top">
                                                        {t('api.quantity')}
                                                    </th>
                                                    <th className="p-2  w-32 align-top text-right">
                                                        {t('api.total_price')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {extra.batteries.map((b: any) => (
                                                    <tr
                                                        className={`align-top ${
                                                            b.order_type === 1 && 'bg-green-200'
                                                        } `}
                                                    >
                                                        <td className="p-2 align-top even:rounded-l-lg ">
                                                            {b?.battery[0].id}
                                                        </td>
                                                        <td className="p-2 align-top ">
                                                            {b?.battery[0].battery_type}
                                                        </td>
                                                        <td className="p-2 align-top ">
                                                            {b?.battery[0].volt}
                                                        </td>
                                                        <td className="p-2 align-top ">
                                                            {b?.battery[0].ah}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right">
                                                            {formatCurrency(
                                                                parseFloat(String(b.rate)),
                                                            )}
                                                        </td>
                                                        <td className="p-2 align-top w-12 text-center">
                                                            {b?.quantity}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right even:rounded-r-lg">
                                                            {formatCurrency(
                                                                parseFloat(String(b.total_price)),
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                {extra.battery_accessories.length > 0 && (
                                    <div className="flex flex-col gap-2 p-2 border border-slate-300">
                                        <p className="ml-2 underline underline-offset-8 decoration-4 decoration-primary">
                                            {t('api.battery_accessories')}
                                        </p>
                                        <table className="table-auto">
                                            <thead className="border-b border-slate-300">
                                                <tr>
                                                    <th className="p-2 align-top">
                                                        {t('api.group')}
                                                    </th>
                                                    <th className="p-2 align-top">
                                                        {t('api.description')}
                                                    </th>
                                                    <th className="p-2  w-32 align-top text-right">
                                                        {t('api.price')}
                                                    </th>
                                                    <th className="p-2 w-12 text-center align-top">
                                                        {t('api.quantity')}
                                                    </th>
                                                    <th className="p-2  w-32 align-top text-right">
                                                        {t('api.total_price')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {extra.battery_accessories.map((ba: any) => (
                                                    <tr
                                                        className={`align-top ${
                                                            ba.order_type === 1 && 'bg-green-200'
                                                        } `}
                                                    >
                                                        <td className="p-2 w-52 align-top even:rounded-l-lg">
                                                            {ba.battery_accessories[0].group}
                                                        </td>
                                                        <td className="p-2 align-top w-52">
                                                            {ba.battery_accessories[0].description}
                                                        </td>
                                                        <td className="p-2 w-32 align-top text-right">
                                                            {formatCurrency(
                                                                parseFloat(String(ba.rate)),
                                                            )}
                                                        </td>
                                                        <td className="p-2 w-12 align-top text-center">
                                                            {ba?.quantity}
                                                        </td>
                                                        <td className="p-2 w-32 align-top text-right even:rounded-r-lg">
                                                            {formatCurrency(
                                                                parseFloat(String(ba.total_price)),
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                {extra.prep_lists.length > 0 && (
                                    <div className="flex flex-col gap-2 p-2 border border-slate-300">
                                        <p className="ml-2 underline underline-offset-8 decoration-4 decoration-primary">
                                            {t('api.prep_lists')}
                                        </p>
                                        <table className="table-auto">
                                            <thead className="border-b border-slate-300">
                                                <th className="p-2 align-top">
                                                    {t('api.component')}
                                                </th>
                                                <th className="p-2 align-top">
                                                    {t('api.description')}
                                                </th>
                                                <th className="p-2 align-top">
                                                    {t('api.position')}
                                                </th>
                                                <th className="p-2  w-32 align-top text-right">
                                                    {t('api.price')}
                                                </th>
                                                <th className="p-2 w-12 text-center align-top">
                                                    {t('api.quantity')}
                                                </th>
                                                <th className="p-2  w-32 align-top text-right">
                                                    {t('api.total_price')}
                                                </th>
                                            </thead>
                                            <tbody>
                                                {extra.prep_lists.map((p: any) => (
                                                    <tr
                                                        className={`align-top ${
                                                            p.order_type === 1 && 'bg-green-200'
                                                        } `}
                                                    >
                                                        <td className="p-2 align-top w-52 even:rounded-l-lg">
                                                            {p.prep_list[0].component}
                                                        </td>
                                                        <td className="p-2 align-top  w-52">
                                                            {p.prep_list[0].description}
                                                        </td>
                                                        <td className="p-2 align-top  w-52">
                                                            {p.prep_list[0].position}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right">
                                                            {formatCurrency(
                                                                parseFloat(String(p.rate)),
                                                            )}
                                                        </td>
                                                        <td className="p-2 align-top w-12 text-center">
                                                            {p.quantity}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right even:rounded-r-lg">
                                                            {formatCurrency(
                                                                parseFloat(String(p.total_price)),
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                {extra.hyster_batteries.length > 0 && (
                                    <div className="flex flex-col gap-2 p-2 border border-slate-300">
                                        <p className="ml-2 underline underline-offset-8 decoration-4 decoration-primary">
                                            {t('api.hyster_batteries')}
                                        </p>
                                        <table className="table-auto">
                                            <thead className="border-b border-slate-300">
                                                <th className="p-2 align-top">
                                                    {t('api.battery_type')}
                                                </th>
                                                <th className="p-2 align-top">
                                                    {t('api.capacity')}
                                                </th>
                                                <th className="p-2 align-top">
                                                    {t('api.order_type')}
                                                </th>
                                                <th className="p-2  w-32 align-top text-right">
                                                    {t('api.price')}
                                                </th>
                                                <th className="p-2 w-12 text-center align-top">
                                                    {t('api.quantity')}
                                                </th>
                                                <th className="p-2  w-32 align-top text-right">
                                                    {t('api.total_price')}
                                                </th>
                                            </thead>
                                            <tbody>
                                                {extra.hyster_batteries.map((hb: any) => (
                                                    <tr
                                                        className={`align-top ${
                                                            hb.order_type === 1 && 'bg-green-200'
                                                        } `}
                                                    >
                                                        <td className="p-2 align-top even:rounded-l-lg">
                                                            {hb.hyster_batteries[0].battery_type}
                                                        </td>
                                                        <td className="p-2 align-top">{`${
                                                            hb.hyster_batteries[0].volt
                                                        } ${t('api.volt')} ${
                                                            hb.hyster_batteries[0].ah
                                                        } ${t('api.ah')}`}</td>
                                                        <td className="p-2 align-top">
                                                            {hb?.order_type ===
                                                            ORDER_TYPE.COMPULSORY
                                                                ? t('api.compulsory')
                                                                : t('api.optional')}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right">
                                                            {formatCurrency(
                                                                parseFloat(String(hb.rate)),
                                                            )}
                                                        </td>
                                                        <td className="p-2 align-top w-12">
                                                            {hb.quantity}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right rounded-r-lg">
                                                            {formatCurrency(
                                                                parseFloat(String(hb.total_price)),
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                {extra.attachment.length > 0 && (
                                    <div className="flex flex-col gap-2 p-2 border border-slate-300">
                                        <p className="ml-2 underline underline-offset-8 decoration-4 decoration-primary">
                                            {t('api.attachment')}
                                        </p>
                                        <table className="table-auto">
                                            <thead className="border-b border-slate-300">
                                                <th className="p-2 align-top">{t('api.model')}</th>
                                                <th className="p-2 align-top">
                                                    {t('api.description')}
                                                </th>
                                                <th className="p-2 align-top">
                                                    {t('api.order_type')}
                                                </th>
                                                <th className="p-2 align-top text-right">
                                                    {t('api.price')}
                                                </th>
                                                <th className="p-2 w-12 text-center align-top">
                                                    {t('api.quantity')}
                                                </th>
                                                <th className="p-2 align-top text-right">
                                                    {t('api.total_price')}
                                                </th>
                                            </thead>
                                            <tbody>
                                                {extra.attachment.map((s: any) => (
                                                    <tr
                                                        className={`align-top ${
                                                            s.order_type === 1 && 'bg-green-200'
                                                        } `}
                                                    >
                                                        <td className="p-2 align-top  w-52 even:rounded-l-lg">
                                                            {s?.model}
                                                        </td>
                                                        <td className="p-2 align-top  w-52">
                                                            {s.description}
                                                        </td>
                                                        <td className="p-2 align-top ">
                                                            {s?.order_type === ORDER_TYPE.COMPULSORY
                                                                ? t('api.compulsory')
                                                                : t('api.optional')}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right">
                                                            {formatCurrency(
                                                                parseFloat(String(s.rate)),
                                                            )}
                                                        </td>
                                                        <td className="p-2 align-top w-12 text-center">
                                                            {s?.quantity}
                                                        </td>
                                                        <td className="p-2 align-top w-32 text-right even:rounded-r-lg">
                                                            {formatCurrency(
                                                                parseFloat(String(s.total_price)),
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                {extra.ex_solution.length > 0 && (
                                    <div className="flex flex-col gap-2 p-2 border border-slate-300">
                                        <p className="ml-2 underline underline-offset-8 decoration-4 decoration-primary">
                                            {t('api.ex_solution')}
                                        </p>
                                        <table className="table-auto">
                                            <thead className="border-b border-slate-300">
                                                <th className="p-2 align-top">{t('api.model')}</th>
                                                <th className="p-2 align-top">
                                                    {t('api.description')}
                                                </th>
                                                <th className="p-2 align-top">
                                                    {t('api.order_type')}
                                                </th>
                                                <th className="p-2  w-32 align-top text-right">
                                                    {t('api.price')}
                                                </th>
                                                <th className="p-2 w-12 text-center align-top">
                                                    {t('api.quantity')}
                                                </th>
                                                <th className="p-2  w-32 align-top text-right">
                                                    {t('api.total_price')}
                                                </th>
                                            </thead>
                                            <tbody>
                                                {extra.ex_solution.map((s: any) => (
                                                    <tr
                                                        className={` ${
                                                            s.order_type === 1 && 'bg-green-200'
                                                        } `}
                                                    >
                                                        <td className="p-2 w-52  even:rounded-l-lg">
                                                            {s?.model}
                                                        </td>
                                                        <td className="p-2  w-52">
                                                            {s.description}
                                                        </td>
                                                        <td className="p-2 ">
                                                            {s?.order_type === ORDER_TYPE.COMPULSORY
                                                                ? t('api.compulsory')
                                                                : t('api.optional')}
                                                        </td>
                                                        <td className="p-2 w-32 text-right">
                                                            {formatCurrency(
                                                                parseFloat(String(s.rate)),
                                                            )}
                                                        </td>
                                                        <td className="p-2 w-12 text-center">
                                                            {s?.quantity}
                                                        </td>
                                                        <td className="p-2 w-32 text-right even:rounded-r-lg">
                                                            {formatCurrency(
                                                                parseFloat(String(s.rate)),
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                {extra.special.length > 0 && (
                                    <div className="flex flex-col gap-2 p-2 border border-slate-300">
                                        <p className="ml-2 underline underline-offset-8 decoration-4 decoration-primary">
                                            {t('api.special')}
                                        </p>
                                        <table className="table-auto">
                                            <thead className="border-b border-slate-300">
                                                <th className="p-2 align-top">{t('api.model')}</th>
                                                <th className="p-2 align-top">
                                                    {t('api.description')}
                                                </th>
                                                <th className="p-2 align-top">
                                                    {t('api.order_type')}
                                                </th>
                                                <th className="p-2  w-32 align-top text-right">
                                                    {t('api.price')}
                                                </th>
                                                <th className="p-2 w-12 text-center align-top">
                                                    {t('api.quantity')}
                                                </th>
                                                <th className="p-2  w-32 align-top text-right">
                                                    {t('api.total_price')}
                                                </th>
                                            </thead>
                                            <tbody>
                                                {extra.special.map((s: any) => (
                                                    <tr
                                                        className={` ${
                                                            s.order_type === 1 && 'bg-green-200'
                                                        } `}
                                                    >
                                                        <td className="p-2  even:rounded-l-lg">
                                                            {s?.model}
                                                        </td>
                                                        <td className="p-2 ">{s.description}</td>
                                                        <td className="p-2 ">
                                                            {s?.order_type === ORDER_TYPE.COMPULSORY
                                                                ? t('api.compulsory')
                                                                : t('api.optional')}
                                                        </td>
                                                        <td className="p-2 w-32 text-right">
                                                            {formatCurrency(
                                                                parseFloat(String(s.rate)),
                                                            )}
                                                        </td>
                                                        <td className="p-2 w-12 text-center">
                                                            {s?.quantity}
                                                        </td>
                                                        <td className="p-2 w-32 text-right even:rounded-r-lg">
                                                            {formatCurrency(
                                                                parseFloat(String(s.rate)),
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </Panel>
                    </Collapse>
                    {/* <div className="flex items-center gap-2">
                        <span className="font-bold">
                            {t('api.series')}: {extra.series}
                        </span>
                        <span>
                            {t('api.description')}: {extra.description}
                        </span>
                    </div> */}
                </div>
            ))}
        </div>
    )
}

export default ExtraItems
