/* eslint-disable react/no-array-index-key */
// import { BsArrowRight } from 'react-icons/bs'
import {
    Outlet,
    // useLocation
} from 'react-router-dom'
// import Button from 'components/button'
// import { useAuth } from 'modules/auth/context/useAuth'
// import {
//     CUSTOMER_FEEDBACK_ROUTE,
//     DISCOUNT_REQUEST_ROUTE,
//     HOME_ROUTE,
//     PROFILE_ROUTE,
// } from 'route/appRoutes'

// const dashboardMenus = [
//     {
//         label: 'Dashboard',
//         link: HOME_ROUTE,
//     },
//     {
//         label: 'Profile',
//         link: PROFILE_ROUTE,
//     },
//     {
//         label: 'Discount Requests',
//         link: DISCOUNT_REQUEST_ROUTE,
//     },
//     {
//         label: 'Feedback',
//         link: CUSTOMER_FEEDBACK_ROUTE,
//     },
// ]

const HomeDashboardLayout = () => {
    // const { pathname } = useLocation()
    // const {
    //     user: { name, email },
    //     logoutUser,
    // } = useAuth()

    const cardClass = 'bg-white p-4 rounded-md h-full'

    return (
        <div className="flex justify-between gap-10 h-full">
            {/* <div className={`hidden md:block md:w-3/12 ${cardClass}`}>
                <div>
                    <h2 className="text-2xl font-bold">{name || 'Unknown'}</h2>
                    <p className="text-xl text-placeholderColor mb-8">{email || '-'}</p>
                    <hr />
                </div>
                <div className="py-20">
                    <ul className="flex gap-8 flex-col">
                        {dashboardMenus.map(({ label, link }, index) => {
                            const isActive = pathname === link
                            const navClassName = `${
                                Boolean(isActive) && 'text-primary'
                            } flex justify-between items-center text-xl border-b md:hover:bg-transparent md:border-0 md:hover:text-primary md:p-0 md:dark:hover:bg-transparent`
                            return (
                                <li key={`${label}-${index}`}>
                                    <a href={link} className={navClassName}>
                                        <span>{label}</span>
                                        <span>
                                            <BsArrowRight />
                                        </span>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div>
                    <Button label="Logout" variant="outline" onClick={() => logoutUser()} />
                </div>
            </div> */}
            <div className={`flex-1 ${cardClass}`}>
                <Outlet />
            </div>
        </div>
    )
}

export default HomeDashboardLayout
