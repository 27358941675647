import { Select } from 'antd'
import classNames from 'classnames'
import Loading from 'components/loading'
import { orderBy } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { IoChevronBack } from 'react-icons/io5'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getNovoConfigurationHistory } from '../query'
import NovoDetail from './novoDetail'
import useTranslationData from '../../translation/hooks/useTranslationData'

const cardClass = 'bg-white px-4 py-4 rounded-xl h-fit'

const NovoHistory = () => {
    const {
        translation: { t },
    } = useTranslationData()
    const { novoId } = useParams()
    const [selectedVersion, setSelectedVersion] = useState('')
    const [versions, setVersions] = useState<
        {
            label: string
            value: string
            updated_at: string
        }[]
    >([])

    const { isLoading } = useQuery([], () => getNovoConfigurationHistory(novoId || ''), {
        onSuccess: (data: any) => {
            const novoVersions = data?.data?.data.map(
                (item: { id: string; version_number: string; updated_at: string }) => ({
                    label: item.version_number,
                    value: item.id,
                    updated_at: item.updated_at,
                }),
            )
            setVersions(novoVersions)
            setSelectedVersion(novoVersions[0].value)
        },
    })

    const onChangeVersion = (versionId: string) => {
        setSelectedVersion(versionId)
    }

    if (isLoading) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }
    return (
        <div className="flex flex-col gap-4 overflow-hidden">
            <div className={classNames('flex items-center justify-between', cardClass)}>
                <div className="flex items-center gap-4">
                    <a
                        href="/novo-configuration"
                        className="flex items-center gap-1 text-lg font-medium"
                    >
                        <span>
                            <IoChevronBack size={20} />
                        </span>
                        <span>{t('api.back')}</span>
                    </a>
                    <div className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                        {t('api.novo_configuration_history')}
                    </div>
                </div>
                <Select
                    defaultValue={selectedVersion}
                    placeholder="Select Version"
                    size="large"
                    className="rounded-md min-w-[10vw]"
                    onChange={onChangeVersion}
                >
                    {orderBy(versions, ['value'], ['asc']).map(item => (
                        <Select.Option value={item.value}>
                            <span className="flex items-center gap-2">
                                <span>Version: {item.label}</span>
                                <span>|</span>
                                <span>{moment(item.updated_at).format('MMM-DD-YYYY')}</span>
                            </span>
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div className="overflow-y-scroll">
                <NovoDetail novoVersionId={selectedVersion} />
            </div>
        </div>
    )
}

export default NovoHistory
