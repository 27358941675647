import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import Button from '../button'

/* eslint-disable react/no-unescaped-entities */
interface DeleteModalProps {
    modal: boolean
    label?: string
    title?: string
    loading?: boolean
    onConfirm: () => void
    closeModal: () => void
}

const DeleteModal = (props: DeleteModalProps) => {
    const { modal, closeModal, onConfirm, label, title, loading } = props
    return (
        <Modal
            open={modal}
            onClose={closeModal}
            center
            showCloseIcon={false}
            classNames={{
                root: 'rounded-lg',
                modal: 'rounded-lg',
            }}
        >
            <div className="relative px-4 w-full max-w-md h-full md:h-auto">
                <div className="p-6 pt-0 text-center" onClick={() => closeModal()}>
                    {title && (
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {title}
                        </h3>
                    )}
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {label || 'Are you sure you want to delete this configuration?'}
                    </h3>
                    <div className="flex justify-center gap-4">
                        <Button
                            label="Yes, sure"
                            onClick={() => onConfirm()}
                            variant="error"
                            isLoading={loading}
                            disabled={loading}
                        />
                        <Button
                            label="No, cancel"
                            onClick={() => closeModal()}
                            variant="ghost"
                            isLoading={loading}
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteModal
