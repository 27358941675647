import { Checkbox } from 'antd'
import Button from 'components/button'
import Input from 'components/input'
import AlertModal from 'components/modal/alertModal'
import Select, { Options } from 'components/select'
import TextArea from 'components/textArea'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import {
    batteryServiceOptions,
    tyreTypeOption,
    yesNoOptions,
} from 'modules/siteSurvey/components/siteSurveyDropDown'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCurrencySymbol } from 'utils/currency/formatCurrency'
import { PRICING_STATUS } from 'utils/pricingStatus'
import { useQuoteLayout } from '../QuoteLayout'
import { PricingQueries } from '../queries/pricing'
import ExtraPricingComponent, { IExtraPricingComponent } from './ExtraPricingComponent'
import { IResidualValue } from '../views/Pricing'

interface IPurchasePriceCalculationAlternate {
    formik: any
    extraPricingComponent: IExtraPricingComponent
    isBigTruck: boolean
    totalPreplist: number
    totalChargers: number
    totalBatteries: number
    totalBatteryAccessories: number
    totalAttachments: number
    totalExSolutions: number
    totalSpecial: number
    totalExtra: number
    totalHysterBattery: number
    investmentGroupOptions: Options[]
    isRequestFleet: boolean
    isRequestFleetLoading: boolean
    isSaveLoading: boolean
    handleRequestFleet: () => void
    requestFleetRemark: string
    setRequestFleetRemark: (requestFleetRemark: string) => void
    configurationPriceId: number
}

const PurchasePriceCalculation = (props: IPurchasePriceCalculationAlternate) => {
    const { t } = useTranslation()
    const { userExtra, authorizationMatrix } = useAuth()
    const [searchParams, setSearchParams] = useSearchParams()
    const [showExtra, setShowExtra] = useState<boolean>(false)
    const pricingStatus = searchParams.get('pricing_status')
    const [showSaveWithFeedbackModal, setShowSaveWithFeedbackModel] = useState<boolean>(false)
    const [showRequestFleetModal, setShowRequestFleetModal] = useState<boolean>(false)
    const [showUnlockModal, setShowUnlockModal] = useState<boolean>(false)
    const {
        formik,
        extraPricingComponent,
        isBigTruck,
        totalAttachments,
        totalBatteries,
        totalBatteryAccessories,
        totalChargers,
        totalExSolutions,
        totalExtra,
        totalPreplist,
        totalSpecial,
        totalHysterBattery,
        investmentGroupOptions,
        isRequestFleet,
        isRequestFleetLoading,
        isSaveLoading,
        handleRequestFleet,
        configurationPriceId,
        requestFleetRemark,
        setRequestFleetRemark,
    } = props
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { refetch } = useQuoteLayout()
    const enableOrDisableFields = isBigTruck
    const { configurationId, quoteId } = useParams()

    const checkIfAdditionalResidualPercentageIsBeyondAccess: () => boolean = () => {
        const maxValue = authorizationMatrix?.find(
            (auth: any) => auth.authorization_condition === 'RESIDUAL_VALUE',
        )?.max_value
        if (formik.values.additionalResidualPercentage > parseFloat(maxValue)) {
            return true
        }
        return false
    }

    const resetStatusToDraft = useMutation(
        ['resetStatusToDraft'],
        () =>
            PricingQueries.setStatusToDraft(Number(configurationId), Number(configurationPriceId)),
        {
            onSuccess: () => {
                setSearchParams({ pricing_status: String(1) })
                setShowUnlockModal(false)
                refetch()
            },
            onError: (error: Error) => {
                toast.error(error?.message || t('message.reset_status_failed'))
            },
        },
    )

    const sendFeedback = useMutation(
        ['sendFeedback'],
        (pricingForm: any) =>
            PricingQueries.sendFeedback(pricingForm, parseInt(String(configurationId), 10)),
        {
            onSuccess: data => {
                if (data?.data?.data?.status) {
                    setSearchParams({ pricing_status: String(data?.data?.data?.status) })
                    toast.success(data?.data?.message || t('message.send_feedback_success'))
                }
                setShowSaveWithFeedbackModel(false)
                queryClient.invalidateQueries({ queryKey: ['quoteDetail'] })
                formik.setFieldValue('feedback', '')
                navigate(`/quotes/${quoteId}`)
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.send_feedback_failed'))
            },
        },
    )

    const checkIfMarginIsBeyondAccess: () => boolean = () => {
        const minProfitPercentage = parseFloat(
            authorizationMatrix?.find((auth: any) => auth.authorization_condition === 'MARGIN')
                ?.min_value,
        )
        if (formik.values.profitPercentage < minProfitPercentage) {
            return true
        }
        return false
    }

    return (
        <div className="flex flex-col gap-2">
            <div className="flex gap-2 justify-between">
                <div className="flex flex-1 flex-col gap-2 px-4">
                    <div className="flex justify-between item-center">
                        <div className="flex justify-start gap-8 items-center">
                            <p>{t('fields.net_price_hyster')}</p>
                            <p>{t('fields.standard_price_rate')}</p>
                        </div>
                        <div className="flex justify-between gap-2 items-center">
                            <Input
                                horizontal
                                name="standardDiscountRate"
                                type="number"
                                min={0}
                                max={99}
                                disabled
                                customInputStyles="p-0 text-right"
                                inputWidth="w-[7rem]"
                                value={parseFloat(
                                    String(formik.values.standardDiscountRate),
                                ).toFixed(2)}
                                rightContent={<p>%</p>}
                                onChange={e => {
                                    formik.setFieldValue(
                                        'standardDiscountRate',
                                        parseFloat(e.target.value),
                                    )
                                }}
                                noLabel
                                label={t('fields.standard_price_rate')}
                            />
                            <Input
                                horizontal
                                name="totalCostPrice"
                                type="number"
                                min={0}
                                max={99}
                                customInputStyles="p-0 text-right"
                                inputWidth="w-[14rem]"
                                value={String(formik.values.totalCostPrice.toFixed(0))}
                                leftContent={<p>{getCurrencySymbol()}</p>}
                                showOnlyCurrency
                                disabled
                                noLabel
                                label={t('fields.net_price_hyster')}
                            />
                        </div>
                    </div>

                    <div className="flex justify-between item-center">
                        <div className="flex justify-start gap-8 items-center">
                            <p>{t('fields.special_price_request')}</p>
                            <p>{t('fields.discount')}</p>
                        </div>
                        <div className="flex justify-between gap-2 items-center">
                            <Input
                                horizontal
                                name="specialPriceRequestPercentage"
                                type="number"
                                min={parseFloat(String(formik.values.standardDiscountRate))}
                                max={99}
                                customInputStyles="p-0 text-right"
                                inputWidth="w-[7rem]"
                                value={String(formik.values.specialPriceRequestPercentage)}
                                rightContent={<p>%</p>}
                                onChange={e => {
                                    formik.setFieldValue(
                                        'specialPriceRequestPercentage',
                                        parseFloat(e.target.value),
                                    )
                                }}
                                noLabel
                                label={t('fields.special_price_request')}
                            />
                            <Input
                                horizontal
                                name="totalCostPriceIncludingDiscount"
                                type="number"
                                min={0}
                                noLabel
                                max={99}
                                customInputStyles="p-0 text-right"
                                inputWidth="w-[14rem]"
                                showOnlyCurrency
                                value={formik.values.totalCostPriceIncludingDiscount.toFixed(0)}
                                leftContent={<p>{getCurrencySymbol()}</p>}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="flex justify-between gap-2">
                        <Input
                            horizontal
                            name="vpg"
                            type="number"
                            min={0}
                            max={99}
                            customInputStyles="p-0 text-right"
                            inputWidth="w-[7rem]"
                            value={String(formik.values.vpg)}
                            rightContent={<p>%</p>}
                            onChange={e => {
                                formik.setFieldValue('vpg', Number(e.target.value))
                                const vpgAmount = e.target.value
                                    ? (Number(e.target.value) *
                                          formik.values.totalCostPriceIncludingDiscount) /
                                      100
                                    : 0
                                formik.setFieldValue('vpgAmount', vpgAmount)
                            }}
                            label={t('fields.vpg')}
                        />
                        <Input
                            horizontal
                            name="vpgAmount"
                            type="number"
                            noLabel
                            customInputStyles="p-0 text-right"
                            inputWidth="w-[14rem]"
                            showOnlyCurrency
                            value={parseFloat(formik.values.vpgAmount).toFixed(0)}
                            onChange={e => {
                                formik.setFieldValue('vpgAmount', Number(e.target.value))
                            }}
                            leftContent={<p>{getCurrencySymbol()}</p>}
                            disabled
                        />
                    </div>
                    <Input
                        horizontal
                        name="bridgingMachineAmount"
                        type="number"
                        label={t('fields.bridging_machine')}
                        customInputStyles="p-0 text-right"
                        inputWidth="w-[14rem]"
                        value={String(formik.values.bridgingMachineAmount)}
                        onChange={e => {
                            formik.setFieldValue('bridgingMachineAmount', Number(e.target.value))
                        }}
                        leftContent={<p>{getCurrencySymbol()}</p>}
                    />
                    <Input
                        horizontal
                        name="financialSupportAmount"
                        type="number"
                        label={t('fields.financial_support_amount')}
                        customInputStyles="p-0 text-right"
                        inputWidth="w-[14rem]"
                        value={String(formik.values.financialSupportAmount)}
                        onChange={e =>
                            formik.setFieldValue('financialSupportAmount', Number(e.target.value))
                        }
                        leftContent={<p>{getCurrencySymbol()}</p>}
                    />
                    <ExtraPricingComponent
                        attachment={extraPricingComponent.attachment}
                        batteries={extraPricingComponent.batteries}
                        batteryAccessories={extraPricingComponent.batteryAccessories}
                        chargers={extraPricingComponent.chargers}
                        exSolution={extraPricingComponent.exSolution}
                        prepList={extraPricingComponent.prepList}
                        special={extraPricingComponent.special}
                        hysterBatteries={extraPricingComponent.hysterBatteries}
                        formik={formik}
                        totalPreplist={totalPreplist}
                        totalChargers={totalChargers}
                        totalBatteries={totalBatteries}
                        totalBatteryAccessories={totalBatteryAccessories}
                        totalAttachments={totalAttachments}
                        totalExSolutions={totalExSolutions}
                        totalSpecial={totalSpecial}
                        totalExtra={totalExtra}
                        totalHysterBattery={totalHysterBattery}
                        hideOptions
                    />
                    <Input
                        horizontal
                        disabled
                        name="dataLogger"
                        type="number"
                        label={t('fields.data_logger')}
                        customInputStyles="p-0 text-right"
                        showOnlyCurrency
                        inputWidth="w-[14rem]"
                        value={parseFloat(formik.values.dataLogger)}
                        onChange={e => formik.setFieldValue('dataLogger', Number(e.target.value))}
                        leftContent={<p>{getCurrencySymbol()}</p>}
                    />
                    <Input
                        horizontal
                        disabled
                        name="netAllCost"
                        type="number"
                        label={t('fields.net_all_cost')}
                        customInputStyles="p-0 text-right"
                        inputWidth="w-[14rem]"
                        showOnlyCurrency
                        value={formik.values.netAllCost.toFixed(0)}
                        onChange={e => formik.setFieldValue('netAllCost', Number(e.target.value))}
                        leftContent={<p>{getCurrencySymbol()}</p>}
                    />
                    <div className="flex gap-2">
                        <Input
                            horizontal
                            name="profitPercentage"
                            type="number"
                            min={0}
                            max={99}
                            isBeyondAccess={checkIfMarginIsBeyondAccess()}
                            customInputStyles="p-0 text-right"
                            inputWidth="w-[7rem]"
                            value={String(formik.values.profitPercentage)}
                            rightContent={<p>%</p>}
                            onChange={e => {
                                formik.setFieldValue('profitPercentage', Number(e.target.value))

                                const profitAmount = e.target.value
                                    ? (Number(e.target.value) * formik.values.netAllCost) / 100
                                    : 0
                                formik.setFieldValue('profitAmount', profitAmount)
                            }}
                            label={t('fields.profit')}
                        />
                        <Input
                            horizontal
                            disabled
                            noLabel
                            showOnlyCurrency
                            name="profitAmount"
                            type="number"
                            customInputStyles="p-0 text-right"
                            inputWidth="w-[14rem]"
                            value={formik.values.profitAmount.toFixed(0)}
                            leftContent={<p>{getCurrencySymbol()}</p>}
                        />
                    </div>
                    <Input
                        horizontal
                        disabled
                        name="quotePrice"
                        type="number"
                        showOnlyCurrency
                        labelStyle="font-semibold"
                        label={t('fields.quote_price')}
                        customInputStyles="p-0 text-right font-semibold"
                        inputWidth="w-[14rem]"
                        value={formik.values.quotePrice.toFixed(0)}
                        // onChange={e => formik.setFieldValue('quotePrice', parseFloat(e.target.value))}
                        leftContent={<p>{getCurrencySymbol()}</p>}
                    />
                    {formik.values.financing === 'Purchase' ||
                    formik.values.financing === 'Operational Lease and Purchase' ? (
                        !formik.values.salesPrice && !formik.values.showManualSalesPrice ? (
                            <div className="flex justify-end">
                                <div className="w-fit">
                                    <Button
                                        label={String(t('api.add_sales_price'))}
                                        variant="primary"
                                        onClick={() =>
                                            formik.setFieldValue('showManualSalesPrice', true)
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="flex gap-2">
                                <Input
                                    horizontal
                                    disabled
                                    name="salesProfitPercent"
                                    type="number"
                                    labelStyle="font-semibold"
                                    min={0}
                                    max={99}
                                    isBeyondAccess={formik.values.pricingError.margin.error}
                                    customInputStyles="p-0 text-right"
                                    inputWidth="w-[7rem]"
                                    value={String(formik.values.salesProfitPercent)}
                                    rightContent={<p>%</p>}
                                    label={t('fields.sales_price')}
                                />
                                <Input
                                    horizontal
                                    name="salesPrice"
                                    type="number"
                                    noLabel
                                    labelStyle="font-semibold"
                                    customInputStyles="p-0 text-right font-semibold"
                                    inputWidth="w-[14rem]"
                                    value={formik.values.salesPrice}
                                    onChange={e => {
                                        formik.setFieldValue('salesPrice', Number(e.target.value))
                                        // const profitPercent = e.target.value
                                        //     ? ((Number(e.target.value) - formik.values.netAllCost) /
                                        //           Number(e.target.value)) *
                                        //       100
                                        //     : 0
                                        // formik.setFieldValue('salesProfitPercent', profitPercent?.toFixed(2))
                                    }}
                                    leftContent={<p>{getCurrencySymbol()}</p>}
                                />
                            </div>
                        )
                    ) : null}
                    {!enableOrDisableFields && !formik.values.showTradeIn ? (
                        <div className="flex justify-end">
                            <div className="w-[8rem]">
                                <Button
                                    label={t('api.add_trade_in') || 'Add trade in'}
                                    variant="primary"
                                    onClick={() => formik.setFieldValue('showTradeIn', true)}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <Input
                                horizontal
                                name="tradeInValue"
                                type="number"
                                label={t('fields.trade_in_value')}
                                customInputStyles="p-0 text-right"
                                inputWidth="w-[14rem]"
                                value={String(formik.values.tradeInValue)}
                                onChange={e => {
                                    formik.setFieldValue(
                                        'tradeInValue',
                                        Number(e.target.value) || 0,
                                    )
                                }}
                                leftContent={<p>{getCurrencySymbol()}</p>}
                            />
                            <div className="flex justify-between">
                                <p>{t('fields.total_after_trade_in')}</p>
                                <div className="flex items-center gap-4">
                                    <p>{t('fields.use_this_for_lease_calculation')}</p>
                                    <Checkbox
                                        name="useQuotePriceMinusTradeInForCalculation"
                                        checked={
                                            formik.values.useQuotePriceMinusTradeInForCalculation
                                        }
                                        onChange={formik.handleChange}
                                    />
                                    <Input
                                        horizontal
                                        disabled
                                        name="quoteMinusTradeIn"
                                        type="number"
                                        customInputStyles="p-0 text-right"
                                        inputWidth="w-[14rem]"
                                        value={formik.values.quoteMinusTradeIn.toFixed(0)}
                                        showOnlyCurrency
                                        onChange={e =>
                                            formik.setFieldValue(
                                                'quoteMinusTradeIn',
                                                Number(e.target.value),
                                            )
                                        }
                                        leftContent={<p>{getCurrencySymbol()}</p>}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-between items-center gap-4">
                                <p>{t('fields.trade_in_value_text_field')}</p>
                                <div className="flex items-center gap-4">
                                    <Checkbox
                                        name="show_trade_in_value_text_field"
                                        checked={formik.values.show_trade_in_value_text_field}
                                        onChange={formik.handleChange}
                                    />
                                    <Input
                                        horizontal
                                        noLabel
                                        name="tradeInValueTextField"
                                        type="text"
                                        label={t('fields.trade_in_value_text_field')}
                                        customInputStyles="p-0"
                                        inputWidth="w-[22rem]"
                                        value={String(formik.values.tradeInValueTextField)}
                                        onChange={e => {
                                            formik.setFieldValue(
                                                'tradeInValueTextField',
                                                e.target.value,
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {!(
                        extraPricingComponent.attachment?.length === 0 &&
                        extraPricingComponent.batteries?.length === 0 &&
                        extraPricingComponent.batteryAccessories?.length === 0 &&
                        extraPricingComponent.chargers?.length === 0 &&
                        extraPricingComponent.exSolution?.length === 0 &&
                        extraPricingComponent.prepList?.length === 0 &&
                        extraPricingComponent.special?.length === 0 &&
                        extraPricingComponent.special?.length === 0 &&
                        extraPricingComponent.hysterBatteries?.length === 0
                    ) && (
                        <div className="flex justify-end w-full">
                            <div className="w-fit ">
                                <Button
                                    label={t('api.show_extra')}
                                    variant="primary"
                                    onClick={() => setShowExtra(prev => !prev)}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex flex-1 flex-col gap-2 px-4">
                    <Input
                        horizontal
                        name="additionalResidualPercentage"
                        type="number"
                        customInputStyles="p-0 text-right"
                        inputWidth="w-[7rem]"
                        isBeyondAccess={checkIfAdditionalResidualPercentageIsBeyondAccess()}
                        value={String(formik.values.additionalResidualPercentage)}
                        rightContent={<p>%</p>}
                        onChange={e => {
                            formik.setFieldValue(
                                'additionalResidualPercentage',
                                Number(e.target.value),
                            )
                        }}
                        label={t('fields.additional_residual_percentage')}
                    />
                    <Select
                        horizontal
                        options={investmentGroupOptions || []}
                        label={t('fields.investment_group')}
                        name="investmentGroup"
                        showPlaceHolder
                        placeHolderOption={t('fields.select_investment_group')}
                        selectWidth="w-[14rem]"
                        value={String(formik.values.investmentGroup)}
                        onChange={e => formik.setFieldValue('investmentGroup', e.target.value)}
                    />
                    {formik.values.maintenance !== 'PREVENTIVE_MAINTAINENCE' && (
                        <Input
                            horizontal
                            label={t('fields.discount_service_all_in')}
                            name="discountServiceAllInPercentage"
                            type="number"
                            disabled={enableOrDisableFields}
                            customInputStyles="p-0 text-right"
                            inputWidth="w-[7rem]"
                            value={String(formik.values.discountServiceAllInPercentage)}
                            onChange={e =>
                                formik.setFieldValue(
                                    'discountServiceAllInPercentage',
                                    Number(e.target.value),
                                )
                            }
                            rightContent={<p>%</p>}
                        />
                    )}
                    {formik.values.maintenance !== 'All In' && (
                        <Input
                            horizontal
                            label={t('fields.discount_service_pm')}
                            name="discountServicePreventiveMaintenancePercentage"
                            type="number"
                            disabled={enableOrDisableFields}
                            customInputStyles="p-0 text-right"
                            inputWidth="w-[7rem]"
                            value={String(
                                formik.values.discountServicePreventiveMaintenancePercentage,
                            )}
                            onChange={e =>
                                formik.setFieldValue(
                                    'discountServicePreventiveMaintenancePercentage',
                                    Number(e.target.value),
                                )
                            }
                            rightContent={<p>%</p>}
                        />
                    )}
                    <div className="grid grid-cols-2 py-2">
                        {formik.values.maintenance !== 'PREVENTIVE_MAINTAINENCE' && (
                            <div className="flex justify-end gap-8 my-2 items-center">
                                <p>{t('fields.add_tyre')}</p>
                                <Checkbox
                                    disabled={isBigTruck ? false : enableOrDisableFields}
                                    checked={formik.values.addTyre}
                                    onChange={() =>
                                        formik.setFieldValue('addTyre', !formik.values.addTyre)
                                    }
                                />
                            </div>
                        )}
                        {formik.values.maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                        formik.values.maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE' ? (
                            formik.values.isElectric ? (
                                <div className="flex justify-end gap-8 my-1 items-center">
                                    <p>{t('fields.add_battery')}</p>
                                    <Checkbox
                                        disabled={isBigTruck ? false : enableOrDisableFields}
                                        checked={formik.values.addBattery}
                                        onChange={() =>
                                            formik.setFieldValue(
                                                'addBattery',
                                                !formik.values.addBattery,
                                            )
                                        }
                                    />
                                </div>
                            ) : null
                        ) : (
                            <div className="flex justify-end gap-8 my-1 items-center">
                                <p>{t('fields.add_battery')}</p>
                                <Checkbox
                                    disabled={isBigTruck ? false : enableOrDisableFields}
                                    checked={formik.values.addBattery}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            'addBattery',
                                            !formik.values.addBattery,
                                        )
                                    }
                                />
                            </div>
                        )}
                        {formik.values.maintenance !== 'PREVENTIVE_MAINTAINENCE' && (
                            <div className="flex justify-end gap-8 my-1 items-center">
                                <p>{t('fields.add_attachment')}</p>
                                <Checkbox
                                    disabled={isBigTruck ? false : enableOrDisableFields}
                                    checked={formik.values.addAttachment}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            'addAttachment',
                                            !formik.values.addAttachment,
                                        )
                                    }
                                />
                            </div>
                        )}
                        {formik.values.maintenance !== 'PREVENTIVE_MAINTAINENCE' && (
                            <div className="flex justify-end gap-8 my-1 items-center">
                                <p>{t('fields.add_specials')}</p>
                                <Checkbox
                                    disabled={isBigTruck ? false : enableOrDisableFields}
                                    checked={formik.values.addSpecials}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            'addSpecials',
                                            !formik.values.addSpecials,
                                        )
                                    }
                                />
                            </div>
                        )}
                        {(formik.values.maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                            formik.values.maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE') && (
                            <div className="flex justify-end gap-8 my-1 items-center">
                                <p>{t('fields.bmwt')}</p>
                                <Checkbox
                                    disabled={isBigTruck ? false : enableOrDisableFields}
                                    checked={formik.values.bmwt}
                                    onChange={() =>
                                        formik.setFieldValue('bmwt', !formik.values.bmwt)
                                    }
                                />
                            </div>
                        )}
                        {(formik.values.maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                            formik.values.maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE') && (
                            <div className="flex justify-end gap-8 my-1 items-center">
                                <p>{t('fields.bmwt_battery')}</p>
                                <Checkbox
                                    disabled={isBigTruck ? false : enableOrDisableFields}
                                    checked={formik.values.bmwtBattery}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            'bmwtBattery',
                                            !formik.values.bmwtBattery,
                                        )
                                    }
                                />
                            </div>
                        )}
                        {formik.values.maintenance !== 'PREVENTIVE_MAINTAINENCE' && (
                            <div className="flex gap-4 my-1 justify-end items-center">
                                <p>{t('fields.add_ex_solution')}</p>
                                <Checkbox
                                    disabled={isBigTruck ? false : enableOrDisableFields}
                                    checked={formik.values.addExSolution}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            'addExSolution',
                                            !formik.values.addExSolution,
                                        )
                                    }
                                />
                            </div>
                        )}
                        <div className="flex gap-4 my-1 justify-end items-center">
                            <p>{t('fields.request_fleet')}</p>
                            <Checkbox
                                disabled={isBigTruck ? false : enableOrDisableFields}
                                checked={formik.values.requestFleet}
                                onChange={() =>
                                    formik.setFieldValue(
                                        'requestFleet',
                                        !formik.values.requestFleet,
                                    )
                                }
                            />
                        </div>
                    </div>
                    <Select
                        horizontal
                        options={[
                            {
                                label: t('api.light'),
                                value: 1,
                            },
                            {
                                label: t('api.medium'),
                                value: 2,
                            },
                            {
                                label: t('api.heavy'),
                                value: 3,
                            },
                        ]}
                        label={t('fields.category')}
                        disabled={Number(userExtra.role_id) !== Number(ROLE_TYPE.service_manager)}
                        name="category"
                        placeHolderOption={t('fields.select_category')}
                        selectWidth="w-[14rem]"
                        value={formik.values.category}
                        onChange={e => formik.setFieldValue('category', e.target.value)}
                    />
                    {formik.values.addBattery && (
                        <Select
                            horizontal
                            disabled={enableOrDisableFields}
                            options={batteryServiceOptions || []}
                            label={t('fields.battery_service')}
                            name="batteryService"
                            showPlaceHolder
                            placeHolderOption={t('fields.select_battery_service')}
                            selectWidth="w-[14rem]"
                            value={formik.values.batteryService}
                            onChange={e =>
                                formik.setFieldValue('batteryService', e.target.value || '0')
                            }
                        />
                    )}
                    {formik.values.addBattery && (
                        <Select
                            horizontal
                            disabled={enableOrDisableFields}
                            options={[
                                {
                                    label: '1',
                                    value: 1,
                                },
                                {
                                    label: '2',
                                    value: 2,
                                },
                                {
                                    label: '3',
                                    value: 3,
                                },
                                {
                                    label: '4',
                                    value: 4,
                                },
                            ]}
                            label={t('fields.no_of_batteries')}
                            name="numberOfBatteries"
                            showPlaceHolder
                            placeHolderOption={t('fields.select_number_of_batteries')}
                            selectWidth="w-[14rem]"
                            value={formik.values.numberOfBatteries}
                            onChange={e =>
                                formik.setFieldValue('numberOfBatteries', e.target.value || '0')
                            }
                        />
                    )}
                    {formik.values.addTyre && (
                        <Select
                            horizontal
                            disabled={enableOrDisableFields}
                            options={tyreTypeOption}
                            label={t('fields.tyre_type')}
                            name="tyreType"
                            showPlaceHolder
                            placeHolderOption={t('fields.select_tyre_type')}
                            selectWidth="w-[14rem]"
                            value={formik.values.tyreType}
                            onChange={formik.handleChange}
                        />
                    )}
                </div>
            </div>
            {showExtra && (
                <ExtraPricingComponent
                    attachment={extraPricingComponent.attachment}
                    batteries={extraPricingComponent.batteries}
                    batteryAccessories={extraPricingComponent.batteryAccessories}
                    chargers={extraPricingComponent.chargers}
                    exSolution={extraPricingComponent.exSolution}
                    prepList={extraPricingComponent.prepList}
                    special={extraPricingComponent.special}
                    hysterBatteries={extraPricingComponent.hysterBatteries}
                    formik={formik}
                    totalPreplist={totalPreplist}
                    totalChargers={totalChargers}
                    totalBatteries={totalBatteries}
                    totalBatteryAccessories={totalBatteryAccessories}
                    totalAttachments={totalAttachments}
                    totalExSolutions={totalExSolutions}
                    totalSpecial={totalSpecial}
                    totalExtra={totalExtra}
                    totalHysterBattery={totalHysterBattery}
                    viewOnly
                />
            )}
            <div className="flex mt-2 items-center flex-wrap justify-end gap-4">
                {(isBigTruck ||
                    formik.values.residualValue.find((x: any) => x.error) ||
                    formik.values.addExSolution ||
                    formik.values.requestFleet ||
                    formik.values.pmAdjustment ||
                    formik.values.insuranceAdjustment) &&
                    userExtra.role_id !== ROLE_TYPE.fleet &&
                    (parseInt(String(pricingStatus), 10) === PRICING_STATUS.NEW ||
                        parseInt(String(pricingStatus), 10) === PRICING_STATUS.DRAFT) && (
                        <div className="w-fit">
                            <Button
                                label={t('api.request_fleet')}
                                onClick={() => {
                                    setShowRequestFleetModal(true)
                                }}
                                variant="primary"
                            />
                        </div>
                    )}
                {parseInt(String(pricingStatus), 10) === PRICING_STATUS.REQUEST_FLEET &&
                    (userExtra.role_id === ROLE_TYPE.fleet ||
                        userExtra.role_id === ROLE_TYPE.servicer) && (
                        <div className="w-fit">
                            <Button
                                label={t('api.save_with_feedback')}
                                onClick={() => setShowSaveWithFeedbackModel(true)}
                                variant="outline"
                            />
                        </div>
                    )}
                {userExtra.role_id !== ROLE_TYPE.fleet ? (
                    parseInt(String(pricingStatus), 10) < PRICING_STATUS.FLEET_ADJUSTED ? (
                        <div className="w-fit">
                            <Button
                                label={t('api.save')}
                                isLoading={!isRequestFleet && isSaveLoading}
                                disabled={isSaveLoading}
                                onClick={() => formik.handleSubmit()}
                                variant="outline"
                            />
                        </div>
                    ) : (
                        <div>
                            <Button
                                variant="primary"
                                label={t('api.reset_status_to_draft')}
                                onClick={() => {
                                    setShowUnlockModal(true)
                                }}
                            />
                        </div>
                    )
                ) : null}
            </div>
            <AlertModal
                show={showSaveWithFeedbackModal}
                content={
                    <div className="flex flex-col justify-between">
                        <p>{t('api.feedback_and_save_title')}</p>
                        <TextArea
                            name="feedback"
                            value={formik.values.feedback}
                            onChange={formik.handleChange}
                        />
                    </div>
                }
                isSubmitting={sendFeedback.isLoading}
                onConfirm={() => {
                    sendFeedback.mutate({
                        novo_list_price: formik.values.totalCostPrice,
                        machine_list_price: formik.values.machineListPrice,
                        total_net_value_parts: formik.values.totalNetValueParts,
                        total_options_selected: formik.values.totalOptionsSelected || 0,
                        special_price_request_percentage:
                            formik.values.specialPriceRequestPercentage,
                        discount_amount: formik.values.discountAmount,
                        total_extra: formik.values.totalExtra,
                        total_options: formik.values.totalOptions,
                        vpg_amount: formik.values.vpgAmount,
                        vpg_percentage: formik.values.vpg,
                        net_all_cost: formik.values.netAllCost,
                        novo_quote_price: formik.values.quotePrice,
                        min_investment_group: formik.values.investmentGroup || 0,
                        running_hours: formik.values.runningHours,
                        category: formik.values.category,
                        novo_configuration_id: parseInt(String(configurationId), 10),
                        standard_discount_percentage: formik.values.standardDiscountRate,
                        bridging_machine_amount: formik.values.bridgingMachineAmount,
                        financial_support_amount: formik.values.financialSupportAmount,
                        data_logger: formik.values.dataLogger,
                        profit_percentage: formik.values.profitPercentage,
                        profit_amount: formik.values.profitAmount,
                        manual_price: formik.values.salesPrice,
                        trade_in_value: formik.values.tradeInValue,
                        trade_in_value_text_field: formik.values.tradeInValueTextField,
                        quote_minus_trade_in: formik.values.quoteMinusTradeIn,
                        use_quote_price_minus_trade_in_for_calculation:
                            formik.values.useQuotePriceMinusTradeInForCalculation,
                        number_of_batteries: formik.values.numberOfBatteries,
                        additional_residual_percentage: formik.values.additionalResidualPercentage,
                        add_tyre: formik.values.addTyre,
                        add_battery: formik.values.addBattery,
                        add_attachment: formik.values.addAttachment,
                        discount_service_all_in: formik.values.discountServiceAllInPercentage,
                        discount_service_pm:
                            formik.values.discountServicePreventiveMaintenancePercentage,
                        add_specials: formik.values.addSpecials,
                        add_ex_solutions: formik.values.addExSolution,
                        request_fleet: formik.values.requestFleet,
                        battery_service: formik.values.batteryService,
                        bmwt: formik.values.bmwt,
                        is_whe: formik.values.isWhe,
                        add_bmwt_battery: formik.values.bmwtBattery,
                        margin_for_options: formik.values.marginForOption,
                        type_banden: formik.values.tyreType,
                        selected_duration_of_contract: formik.values.selectedDurationOfContract,
                        monthly_configuration_prices: formik.values.residualValue.map(
                            (residualValue: IResidualValue) => ({
                                duration_of_contract: residualValue.durationOfContract,
                                total_residual_value: residualValue.totalResidualValue,
                                standard_residual_value: residualValue.standardResidualValue,
                                lease_price_options: residualValue.leasePriceOptions,
                                lease_factor: residualValue.factor,
                                financial_part: residualValue.financialPart,
                                insurance_cost: residualValue.insuranceCost,
                                service_big_truck_tyre_per_hour:
                                    residualValue.servicePriceTriesPerHour,
                                basic_truck: residualValue.serviceAllIn.basic_truck,
                                service_big_truck_all_in: residualValue.serviceBigTruckAllIn,
                                ex_solution_service_price: residualValue.exSolutionServicePrice,
                                filled_insurance_rate: residualValue.filledInsuranceCost,
                                filled_preventive_maintenance:
                                    residualValue.filledPreventiveMaintenance,
                                service_big_truck_pm: residualValue.serviceBigTruckPm,
                                service_extra_preplist:
                                    residualValue?.serviceAllIn.service_extra_preplist,
                                service_extra_special:
                                    residualValue?.serviceAllIn.service_extra_special,
                                service_extra_attachments:
                                    residualValue?.serviceAllIn.service_extra_attachments,
                                service_battery: residualValue?.serviceAllIn.service_battery,
                                service_tyre: residualValue.serviceAllIn.service_tyre,
                                total_price_per_year_ex_bmwt:
                                    residualValue?.servicePm?.total_price_per_year_ex_bmwt,
                                total_price_per_year_inclusive_bmwt:
                                    residualValue?.servicePm?.total_price_per_year_inclusive_bmwt,
                                preventive_maintenance:
                                    residualValue?.servicePm?.preventive_maintenance,
                                batteries_service_cost:
                                    residualValue.servicePm.batteries_service_cost,
                            }),
                        ),
                        feedback: formik.values.feedback,
                    })
                }}
                onCancel={() => {
                    setShowSaveWithFeedbackModel(false)
                }}
            />
            <AlertModal
                show={showRequestFleetModal}
                content={
                    <div className="flex flex-col justify-between">
                        <p>{t('api.feedback_and_save_title')}</p>
                        <TextArea
                            name="feedback"
                            value={requestFleetRemark}
                            onChange={e => {
                                setRequestFleetRemark(e?.target?.value)
                            }}
                        />
                    </div>
                }
                isSubmitting={(isRequestFleet && isSaveLoading) || isRequestFleetLoading}
                onConfirm={handleRequestFleet}
                onCancel={() => {
                    setShowRequestFleetModal(false)
                }}
            />
            <AlertModal
                show={showUnlockModal}
                content={
                    <div className="flex flex-col justify-between">
                        <p className="text-xl text-primary uppercase">{t('api.warning')}</p>
                        <p>{t('message.unlock_quote_message')}</p>
                    </div>
                }
                isSubmitting={resetStatusToDraft.isLoading}
                onConfirm={() => {
                    resetStatusToDraft.mutate()
                }}
                onCancel={() => {
                    setShowUnlockModal(false)
                }}
            />
        </div>
    )
}

export default PurchasePriceCalculation
