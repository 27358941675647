import { callAxios } from 'plugins/api'

export type RequestTsType = {
    novo_configuration_id: number
    quote_number: number
    description: string
    type: string
}

export const sendRequestTs = (record: any) => {
    return callAxios({
        url: record.update && record.id ? `request-ts-form/${record?.id}` : `request-ts-form`,
        isHeffiq: true,
        method: 'POST',
        data: record,
    })
}
