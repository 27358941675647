import Input from 'components/input'
import Loading from 'components/loading'
import Table from 'components/table'
import Fuse from 'fuse.js'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import NonNovoConfigurationsQueries from '../queries/nonNovoConfigurations'

const storageUrl = `${process.env.REACT_APP_IMAGE_URL}nonNovoSample`

const NonNovoConfigurations = () => {
    const { t } = useTranslation()
    const [patterns, setPatterns] = useState<string>('')
    const [list, setList] = useState<any>([])

    const columns = [
        {
            name: t('api.machine_name'),
            cell: (row: { machine_name: string }) => {
                return <p className="text-black text-bold">{row.machine_name}</p>
            },
            sortable: true,
        },
        {
            name: t('api.actions'),
            center: true,
            cell: (row: any) => (
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    download=""
                    href={`${storageUrl}/${row.import_file_path}`}
                    className="block font-bold py-3 px-3 w-48 rounded-lg flex items-center justify-center gap-3 whitespace-nowrap bg-primary hover:bg-secondary text-textLight"
                >
                    {t('api.download')}
                </a>
            ),
            sortable: true,
        },
    ]

    const { isLoading, data, isFetching } = useQuery(
        ['getNonNovoConfigurations'],
        () => NonNovoConfigurationsQueries.getAllNonNovo(),
        {
            onError: (error: { message: string }) => {
                toast.error(error?.message || t('message.non_novo_configurations_fetch_failed'))
            },
        },
    )

    const fuse = new Fuse(data?.data, {
        shouldSort: true,
        threshold: 0.1,
        ignoreLocation: true,
        keys: ['machine_name'],
    })

    const resetSearch = () => {
        setList(data)
    }

    useEffect(() => {
        resetSearch()
    }, [data])

    useEffect(() => {
        if (patterns.length > 0) {
            const results: any = fuse.search(patterns)
            setList(results.map((b: any) => b.item).flat())
        } else {
            resetSearch()
        }
    }, [patterns])

    if (isLoading || isFetching) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold cursor-pointer hover:border-gray-300 w-full md:w-auto border-none md:border-b-2 md:border">
                    {t('api.non_novo_configuration')}
                </h1>
                <div className="w-full md:w-auto">
                    <Input
                        type="text"
                        value={patterns}
                        name="search"
                        leftContent={<BiSearch />}
                        onChange={(e: any) => setPatterns(e.target.value)}
                    />
                </div>
            </div>
            <div className="overscroll-auto overflow-auto">
                <Table
                    columns={columns}
                    data={patterns === '' ? list?.data : list || []}
                    striped
                    pagination={false}
                    totalRows={5}
                />
            </div>
        </div>
    )
}

export default NonNovoConfigurations
