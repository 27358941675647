import Loading from 'components/loading'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OrderFormLayout from '../component/OrderFormLayout'
import { orderFormData } from '../queries/order'

const OrderForm = () => {
    const { orderId } = useParams()
    const [error, setError] = useState(false)
    const { t } = useTranslation()
    const { isLoading, data, isSuccess } = useQuery(
        'orderFormData',
        () => orderFormData(orderId || ''),
        {
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: true,
            onSuccess: (d: any) => {
                if (d && d.response && d.response.data.message === 'Undefined offset: 0') {
                    setError(true)
                }
            },
        },
    )

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Loading />
            </div>
        )
    }

    if (error) {
        return (
            <div>
                <p className="text-center">{t('api.cannot_update_this_form_contact_admin')}</p>
            </div>
        )
    }

    return (
        <OrderFormLayout
            order={isSuccess ? data.data?.order[0] : {}}
            configurations={isSuccess ? data.data?.configurations : []}
            orderId={orderId || ''}
        />
    )
}

export default OrderForm
