import { callAxios } from 'plugins/api'

const getAllNonNovo = () =>
    callAxios({
        url: 'non-novo-configuration',
        method: 'GET',
        isHeffiq: true,
    })

const NonNovoConfigurationsQueries = {
    getAllNonNovo,
}

export default NonNovoConfigurationsQueries
