import AppRoutes from 'route'
import { useEffect } from 'react'
import useTranslationData from './modules/translation/hooks/useTranslationData'
import 'antd/dist/antd.min.css'

const App = () => {
    const { fetchTranslations } = useTranslationData()

    useEffect(() => {
        fetchTranslations()
    }, [])

    return <AppRoutes />
}

export default App
